import React, { Component } from 'react';
import { formatDate } from '../xutils';

const initData = {
    menuName: "Complete a Task",
    menuIcon: "far fa-times-circle icon-close",
    heading: "What are you looking for?",
    content: "Find your favourite collections and NFTs using our search tool. A fuzzy search will be carried out - For example, if you serached for 'xdu', your search would bring back results for collections and NFTs whose name or decription contain XDU - For example, xDudes",
    btnText: "Place Offer Now"
}

class ModalActionTask extends Component
{

	state = {
		data:initData,
		checkboxChecked:true,
		walletAction:false
    }


	doAction = e =>
	{

		e.preventDefault();

		window.showWaitMessage("Progressing task");

		const _request = {
			component: "external/tasks",
			method: "proceedWithTask",
			tag:this.props.state.task.tag,
			uuid: this.props.state.task.id,
			redirect:document.location.href,
			dismiss:e.currentTarget.dataset.dismiss,
			action:e.currentTarget.dataset.action
		}

		window.callAPI
		(
			_request,
			function (_jsonData) {
				if (_jsonData.success) {

					if( _jsonData.redirect.length === 0 )
					{
						window.hideWaitMessage();
						window.$('#offer-action').modal('hide');
						/* Master user data reload */
						window.$('#reloader').trigger('click');
						/* Reload the action pages */
						window.$('#actionReloader').trigger('click');

					}
					else
					{
						document.location.href = _jsonData.redirect;
					}

				}
				else {
					window.displayErrorMessage("Error progressing task - " + _jsonData.errorMessage)
				}
			}
		)



	}



    render()
	{

		var buttonText = "--";
		var buttonData = "";

		if(this.props.state.task.walletAuthRequired)
		{
			buttonText = "Approve in Wallet"
			buttonData="approve";
		}
		else if (this.props.state.task.dismissable )
		{
			buttonText = "Dismiss this message"
			buttonData="dismiss";
		}
		else if (this.props.state.task.tag === 'note')
		{
			buttonText = "Delete note"
			buttonData="delete";
		}
		else
		{
			buttonText = this.props.state.task.buttonText.length ? this.props.state.task.buttonText : "Continue to next page";
			buttonData="continue"
		}

		const _image = this.props.config.apiServer + this.props.state.task.preview;

        return (

			<div id="offer-action" className="modal fade p-0">
				<div className="modal-dialog modal-lg dialog-animatedx modal-fullheight modal-fullscreen-lg-down">
					<div className="modal-content h-100">

						<div className="modal-header modal-header--sticky">
							{this.state.data.menuName} <i className="fas fa-times-circle icon-close" id="modal-closer" data-bs-dismiss="modal" />
						</div>


						<div className="show-grid modal-body p-3 pb-4">
							<div className="container row" id="task-items">


								{ this.props.state.task.preview.length ?
									(
										<div className="col-12 text-center mt-5">
											<img 		src=			{ this.props.config.sizes.placeholders.loader }
														style=			{{width:this.props.config.sizes.squareLarge.width+"px"}}
														id=				{"modalTaskImage"}
														data-load=		{ _image }
														className=		"modalImage preload-image"
														key=			{ _image }
														alt=			"Preview" />
										</div>
									) : ( <></> )}


								{
									this.props.state.task.err ? (
															<div className="row">
																<div className="col-12 col-md-4 mb-4">
																	<label htmlFor="sell-price" className="pt-1 errorText">Warning</label>
																</div>
																<div className="col-12 col-md-8 errorText pb-2">
																	{ this.props.state.task.msg.split("[")[0] }
																</div>
															</div>
									) : ( <></> )}

								<div className="row">
									<div className="col-12 col-md-4">
										<label className="fw-bold">Task ID</label>
									</div>
									<div className="col-12 col-md-8 pb-2">
										{this.props.state.task.id}
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-md-4">
										<label className="fw-bold">Task Type</label>
									</div>
									<div className="col-12 col-md-8 pb-2">
										{this.props.state.task.type}
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-md-4">
										<label className="fw-bold">Task Created</label>
									</div>
									<div className="col-12 col-md-8 pb-2">
										{formatDate(this.props.state.task.created, false)}
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-md-4">
										<label className="fw-bold">Link</label>
									</div>
									<div className="col-12 col-md-8 pb-2">

										<>
											{ this.props.state.task.link !== "" ?
												( <a href={this.props.state.task.link} target="_blank" rel="noreferrer"><i className="fas fa-link"></i> View in new window</a> )
												:
												(<>n/a</>)
											}
										</>
									</div>
								</div>




								{this.props.state.task.fields.map((row, idx) => {

									return (
										<div className="row" key={"taskrow_"+idx}>
											<div className="col-12 col-md-4">
												<label className="fw-bold">{row.label}</label>
											</div>
											<div className="col-12 col-md-8 pb-2">
												<span dangerouslySetInnerHTML={{__html:row.value}}></span>
											</div>
										</div>

									);
								})}

							</div>
						</div>

						<div className="modal-footer modal-footer--sticky">
							<div className="row w-100">
								<div className="col-12 text-center">
									{ this.props.state.task.buttonLink ?
										(
											<>
												<a href={this.props.state.task.buttonLink} data-dismiss="true" className="btn w-100">{this.props.state.task.buttonText}</a>
												{ this.props.state.task.dismissable ?
																					( <a className="btn btn-smaller btn-bordered pt-3 my-3" href="/" onClick={this.doAction} data-action={buttonData}>Click here to permanantly delete this task/alert.</a>)
																					:
																					( <></> )}
											</>

										)
										:
										(
											<>

											{ this.props.state.task.dismissable ?
																					( <button type="button" disabled={false} onClick={this.doAction} data-dismiss="true" data-action={buttonData} className="btn w-100">{buttonText}</button>)
																					:
																					( <button type="button" disabled={false} onClick={this.doAction} data-dismiss="false" data-action={buttonData} className="btn w-100">{buttonText}</button> )
											}
											</>

										 )
									}

								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
        );
    }
}

export default ModalActionTask;

