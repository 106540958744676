import React, { Component } from 'react';
import { VideoLink } from '../videoLink'
import ModalBulkUploadMulti from '../Modal/ModalBulkUploadMulti';

class CollectionSpinnerNotUploadedComponent extends Component {


	render() {

		return (

			<div className="row mt-5">



				<div className="col-12 text-center">


					{
						this.props.config.userData.display || this.props.state.isMyCollection ?
						(
							<>
								<h3 className="text-center">Media Requires Uploading</h3>
								<p className="my-2">Spinner collections require a bulk data upload of media and JSON metadata. Use the Button below to choose the file you want to upload.</p>
								<p className="my-2">Additionally, you can include a rarity CSV file. This should contain the pre-calculated rarity for each of the NFT's that are defined in the JSON files. For a more detailed explanation, refer to our <VideoLink label={"How to package a collection"} tag={"package-spinner-collection"} config={this.props.config} /> training video.</p>

								<ModalBulkUploadMulti config={this.props.config} state={this.props.state} modalCallBack={this.myCallbackModal} />

								<p className="text-center">
									<button type="button" className="btn  w-50 my-3" data-bs-toggle="modal" data-bs-target="#bulk-upload-modal">Bulk upload</button>
								</p>

							</>
						)
						:
						(
							<>
								<h3 className="text-center">Collection Building</h3>
								<div className="card nohover mb-3">
									<p className="my-2 text-center">This collection is in the process of being built. Please check back later.</p>
								</div>
							</>
						)
					}


					{
						this.props.config.userData.display ?
						(
							<>
								<p className="text-center mt-4">ADMIN MESSAGE: Once uploaded or if you suspect it's broken, <a href={this.props.config.apiServer + "/com/cron/tasks/imports.cfm"} target="_blank" rel="noreferrer">click here to check the import progress for errors</a></p>
							</>
						)
						:
						( <></> )
					}
				</div>
			</div>

        );
    }
}

export default CollectionSpinnerNotUploadedComponent;