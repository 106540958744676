import React, { Component } from 'react';

class HomeFeatured extends Component {

	componentDidMount = () =>
	{

	}

    render() {

		var preloadKey = 1;

		return (

			<>

				<section className="popular-collections-area home-featured">

					<div className="container p-2">


						{ this.props.state.loading ?
						(
							<div id="loading" className="text-center">
								<h3 className="m-3 mt-5"><strong>Loading...</strong></h3>
								<img src={this.props.config.sizes.placeholders.preloader} alt="Loading" />
							</div>
						) :
						(
							<div className="my-flipster notVisible">
								<ul>
								{this.props.data.featured.map((item, idx) => {
									const _prefix = item.image.indexOf("http") < 0 ? this.props.config.apiServer : "";
									var _img = item.image.length ? _prefix + item.image : this.props.config.sizes.placeholders.square;
									return (
										<li key={"home_featured_" + idx}>
											<p className="ellipsis">{item.title}</p>
											<a href={item.uri}><img src={_img} alt={item.title} /></a>
										</li>
										)
								})}

								</ul>
							</div>

						) }

					</div>

				</section>


				<div className='row text-center my-4 home-featured-buttons'>
					<div className='col-6'>
						<a className="btn btn-sm btn-bordered w-75 py-1" href="/collections/featured">Featured</a>
					</div>
					<div className='col-6'>
						<a className="btn btn-sm btn-bordered w-75 py-1" href='/category/all'>View All</a>
					</div>
				</div>
			</>

        );
    }
}

export default HomeFeatured;