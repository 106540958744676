import React, { Component } from 'react';
import LoadingMessage from './loading-message';

class CollectionSpinnerCalculatingComponent extends Component {


    render() {

		return (

			<div className="row mt-5">
				<div className="col-12">
					<h2 className="mt-5 text-center">The collection rarity is currently being calculated</h2>
					<p className="text-center">A total of {this.props.state.collectionData.bulkInfo.stats.rarityCalcsDone} calculations out of {this.props.state.collectionData.totalNfts} have been completed so far.</p>
					<LoadingMessage config={this.props.config} state={this.props.state} />
				</div>
			</div>

		);
    }
}

export default CollectionSpinnerCalculatingComponent;