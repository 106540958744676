import React, { Component } from 'react';
import $ from 'jquery';
import { VideoLink } from '../../components/videoLink'

import { loginCheck, showWaitMessage, hideWaitMessage, displayErrorMessage, setupXmartDropzones } from '../xutils';
class AuthorProfileCrud extends Component {

	state = {
			redirect:				document.referrer.length ? document.referrer : '/',
			isProfileConfigured:	this.props.config.userData.cnf,
			formCompleted:			false
		}

	handleChangeDescription = e => {
		//this.setState({ description: e.target.value })
		this.props.state.profileData.title = e.target.value
		this.validateForm(e);
	}

	handleChangeURL = e => {
		//this.setState({ profile_url: e.target.value })

		this.props.state.profileData.urlPath = e.target.value
		this.validateForm(e);
	}

	componentDidMount(){

		loginCheck( this.props.config );

		/* Just set up dropzone */
		setTimeout(() => {
			setupXmartDropzones("profile", this.props.config); /* jQuery in main.js */
			setupXmartDropzones("banner", this.props.config); /* jQuery in main.js */
			this.validateForm()
		}, 0)

	}

	validateForm = e => {

		var _invalidFields = [];

		if( $('#profile_heading').val().length < 3 )
		{
			_invalidFields.push({"field":"profile_heading", "message":"Profile heading"})
		}

		if( $('#profile_url').val().length < 3 || $('#profile_url').hasClass("invalid-url") )
		{
			_invalidFields.push({"field":"profile_url", "message":"Profile URL"})
		}

		if( $('#profile-img').prop("src").indexOf('/place') > 0 )
		{
			_invalidFields.push({"field":"nft-img", "message":"Upload a profile picture"})
		}

		this.setState({ formCompleted:_invalidFields.length ? false : true })

		this.setState( { formCompleted:_invalidFields.length ? false : true } )

	}

	handleSubmit = e => {

		e.preventDefault();
		showWaitMessage("Saving Changes to Profile");

		var formData = new FormData( document.getElementById("profile-crud"));
		var _inputs = $(e).find("input,select,textarea")

		$.ajax({
			url: this.props.config.apiServer + "/com/post.cfc?method=crudProfile",
			type: 'POST',
			data: formData,
			dataType: 'json',
			xhrFields: {
						withCredentials: true
						},
			crossDomain: true,
			success: function (json)
			{

				if( ! json.success)
				{
					hideWaitMessage()
					displayErrorMessage("ERROR - " + json.errorMessage, { icon: "error" })
					_inputs.prop("disabled", null);
				}
				else
				{
					document.location.href = json.redirect;
				}

			},
			cache: false,
			contentType: false,
			processData: false
			});
	}


    render() {

		const tImage = this.props.state.profileData.profileImageL.length ? this.props.config.apiServer + this.props.state.profileData.profileImageL : this.props.config.sizes.placeholders.profilePicture.large;

        return (

            <section className="popular-collections-area">
                <div className="container p-0">

					<form action="/profile/post/" method="post" id="profile-crud" className="item-form card no-hover no-bg no-border" onSubmit={this.handleSubmit}>

						<input type="hidden" id="uuid" name="uuid" defaultValue={this.props.state.profileData.id} />

						<button type="button" className="hid" id="validate-form-button" onClick={this.validateForm}>Check</button>

						<div className="row mb-3 pb-2 bigdots">
							<div className="col-12">
								<div className="intro d-flex justify-content-between align-items-end m-0">
									<div className="intro-content">
										<h3 className="m-0">Edit My Profile</h3>
									</div>
								</div>
							</div>
						</div>

						<div className="row">

							<div className="col-12 col-md-3">

								<div className="row">
									<div className="col-12 d-flex justify-content-center">
										<div className="image-over" id="profile-img-dropzone">
											{/* Author */}
											<img id="profile-img" className="rounded-circle" src={tImage} alt="Thumbnail" />
											<button type="button" className="hid" onClick={this.validateForm} id="form-check">Check form</button>
											<div id="profile-img-icon">
												<div className="w-100" data-bs-toggle="tooltip" title="Click here to upload a new profile picture" data-bs-placement="bottom">
													<span className="fa-stack">
														<i className="fas fa-cloud fa-stack-1x dropshadow"></i>
														<i className="fas fa-cloud-upload-alt fa-stack-1x"></i>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="col-12 mt-4 mb-2">
										<p className="text-center text-muted">Tap/Click the cloud icons above to upload an image.</p>
									</div>
								</div>


							</div>


							<div className="col-12 col-md-9">

								{ this.state.isProfileConfigured === false ?
								(
									<div className="alert alert-info d-flex justify-content-start" role="alert">
										<i className="fas fa-info-circle me-3 mt-1" style={{"fontSize":"150%"}}></i>
										<span>Your profile must be completed before you are able to continue, don't worry, all you need to do is <strong>upload a picture</strong>, enter a <strong>profile name</strong> &amp; choose a preferred <strong>vanity URL</strong>. You can come back later to complete the rest.<br/><br/>Need some help doing this? &nbsp;&nbsp; <VideoLink label={"Watch our 'Editing My Profile' tutorial "} tag={"edit-my-profile"} linkType="link" config={this.props.config} /></span>
									</div>) : (<></>)}

								<div className="row">

									<div className="col-12 mb-3">
										<div className="form-group">
											<label htmlFor="title" className="pb-2">Profile Name/Title <span className="req">*</span></label>
											<input type="text" className="form-control" name="profile_heading" id="profile_heading" autoComplete="off" placeholder="Descriptive title for your profile" required="required"  defaultValue={this.state.isProfileConfigured === false ? "" : this.props.state.profileData.title} onChange={this.validateForm} />
										</div>
									</div>

									<div className="col-12 mb-3">
										<div className="form-group">

											<label htmlFor="profile_url" className="form-label">Your Vanity URL <span className="req">*</span> <span className="text-muted" id="profile_url_check"></span></label>
											<div className="input-group">
												<span className="input-group-text" id="profile_url_prefix">{this.props.config.serverURL}/profile/</span>
												<input type="text" className="form-control vanity-url" data-original={this.props.state.vanityOriginal} data-type="profile" name="profile_url" id="profile_url" aria-describedby="profile_url_prefix" autoComplete="off" maxLength={40} placeholder="" required="required"  defaultValue={this.state.isProfileConfigured === false ? "" : this.props.state.profileData.urlPath} onChange={this.handleChangeURL} />
											</div>

										</div>
									</div>

									{
										this.props.config.fees.multiCurrency ?
										(
											<div className="col-12 mb-3">
												<div className="form-group">
													<label htmlFor="title" className="pb-2">Preferred Display Currency</label>
													<select id="currency" name="currency" className="form-select" required="required" onChange={this.validateForm} defaultValue={this.props.state.profileData.currency}>
															{Object.keys(this.props.config.rates).map((_symbol, idx) => {
																var _currency = this.props.config.rates[_symbol];
																const _key = `currency_${idx}`;
																return (
																	<option key={_key} value={_symbol}>{_currency.name}</option>
																);
															})}
															</select>
												</div>
											</div>
										) : ( <></> )}


									<div className="col-12 mb-3">
										<div className="form-group">
											<label htmlFor="description" className="pb-2">Description</label>
											<textarea className="form-control" name="profile_description" id="profile_description" placeholder="Provide an awesome description to entice visitors" cols={30} rows={3} defaultValue={this.props.state.profileData.description} onChange={this.handleChangeDescription}  />
										</div>
									</div>

									<div className="col-12 col-md-12 mb-3">
										<div className="form-group">
											<label htmlFor="title" className="pb-2"><i className="fas fa-link me-2 primary" />Project Website</label>
											<input type="text" className="form-control" name="address_project" id="address_project" placeholder="e.g. https://myproject.nft" defaultValue={this.props.state.profileData.social[3].address} />
										</div>
									</div>

									<div className="col-12 col-md-6 mb-3">
										<div className="form-group">
											<label htmlFor="title" className="pb-2"><i className="fab fa-facebook me-2 primary" />Facebook Username</label>

											<div className="input-group">
												<span className="input-group-text">facebook.com/</span>
												<input type="text" className="form-control" name="address_facebook" id="address_facebook" placeholder="" defaultValue={this.props.state.profileData.social[0].address} />
											</div>


										</div>
									</div>

									<div className="col-12 col-md-6 mb-3">
										<div className="form-group">
											<label htmlFor="title" className="pb-2"><i className="fab fa-instagram me-2 primary" />Instagram Username</label>

											<div className="input-group">
												<span className="input-group-text">instagram.com/</span>
												<input type="text" className="form-control" name="address_instagram" id="address_instagram" placeholder="" defaultValue={this.props.state.profileData.social[1].address} />
											</div>

										</div>
									</div>

									<div className="col-12 col-md-6 mb-3">
										<div className="form-group">
											<label htmlFor="title" className="pb-2"><i className="fab fa-twitter me-2 primary" />Twitter Username</label>

											<div className="input-group">
												<span className="input-group-text">twitter.com/@</span>
												<input type="text" className="form-control" name="address_twitter" id="address_twitter" placeholder="" defaultValue={this.props.state.profileData.social[2].address} />
											</div>

										</div>
									</div>

									<div className="col-12 col-md-6 mb-3">
										<div className="form-group">
											<label htmlFor="title" className="pb-2"><i className="fab fa-discord me-2 primary" />Discord Invite URL</label>

											<div className="input-group">
												<input type="text" className="form-control" name="address_discord" id="address_discord" placeholder="e.g. https://discord.gg/xxxxxxxxx" defaultValue={this.props.state.profileData.social[4].address} />
											</div>

										</div>
									</div>

								</div>

							</div>

							<div className="col-12">
								<button className="btn w-100 mt-3 mt-sm-4" id="save-button" disabled={this.state.formCompleted ? false : true} type="submit">Update My Profile</button>
							</div>

						</div>
					</form>

				</div>
			</section>
        );
    }
}

export default AuthorProfileCrud;