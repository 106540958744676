import $ from 'jquery';
import Swal from 'sweetalert2';
import Dropzone from "dropzone";

//let __vanityURLChanged = false;
//let _changeURLTimeout = 0;

export function loginCheck( config )
{

	if(! config.userData.loggedIn)
	{
		showWaitMessage("Session has expired, redirecting to home page.")
		$("#root").hide()
		document.location.href = '/';
	}

	return true;
}

export function simpleUUID(_value) {
	return _value.split(":")[0];
}

export function splitURL(position) {
	var _arr = window.location.pathname.split('/');
	return _arr[position];

}

export function uuidv4() {

		var result, i, j;
		result = '';
		for(j=0; j<32; j++) {
		  if( j === 8 || j === 12 || j === 16 || j === 20)
			result = result + '-';
		  i = Math.floor(Math.random()*16).toString(16).toUpperCase();
		  result = result + i;
		}

		result += ':' + new Date().getTime();

		return result.toLowerCase();
}

export function filePath(_purpose, _uuid, _size, _extension) {
	var path = `/src/img/${_purpose}/${_uuid.substring(0, 1)}/${_uuid.substring(1, 2)}/${_uuid.substring(2, 3)}/${_uuid}/${_size}.${_extension}`;
	return path
}


export function displayErrorMessage(_error) {
	/* Swith to use Sweetalert */

	Swal.fire({
		title: 'System Error',
		html: _error,
		showCancelButton: true,
		allowOutsideClick: false,
		allowEscapeKey: false,
		didOpen: () => {
			Swal.showLoading()
		},
		willClose: () => {
			//clearInterval(timerInterval)
		}
	}).then((result) => {
		/* Read more about handling dismissals below */
		if (result.dismiss === Swal.DismissReason.timer) {

		}
	})

}

export function hideWaitMessage() {
	Swal.close()
}

export function pageLoaded() {
	$('#page-loading').addClass("hid");
	$('#page-content').removeClass("hid");
}

export function showWaitMessage(_msg, showOKButton = false, noTimer = false) {

	var _timer = showOKButton ? 0 : 30000;

	let timerInterval
	Swal.fire({
		title: 'Please wait....',
		html: _msg,
		timer: _timer,
		timerProgressBar: _timer > 0 ? true : false,
		allowOutsideClick: false,
		allowEscapeKey: false,
		didOpen: () => {
			if (_timer) { Swal.showLoading() }
			//const b = Swal.getHtmlContainer().querySelector('b')
			// timerInterval = setInterval(() => { b.textContent = Swal.getTimerLeft() }, 100)
		},
		willClose: () => {
			clearInterval(timerInterval)
		}
	}).then((result) => {
		/* Read more about handling dismissals below */
		if (result.dismiss === Swal.DismissReason.timer) {

		}
	})

}

export function showDialog(_title, _msg) {

	Swal.fire({
		title: _title,
		html: _msg,
		allowOutsideClick: true,
		allowEscapeKey: true,
	});
}


export function notify(_msg, position="bottom-end") {

	let timerInterval
	Swal.fire({
		title: 'System alert',
		html: _msg,
		timer: 5000,
		position:position,
		toast: true,
		timerProgressBar: true,
		allowOutsideClick: true,
		allowEscapeKey: true,
		didOpen: () => {
			Swal.showLoading()
		},
		willClose: () => {
			clearInterval(timerInterval)
		}
	}).then((result) => {
		/* Read more about handling dismissals below */
		if (result.dismiss === Swal.DismissReason.timer) {

		}
	})

}


export function callAPI(_request, _callback, _method = "post") {

	/* What type of submission are we doing and to where? */
	var _objMethod = _method === 'post' ? $.post : $.get;
	var _url = window.config.apiServer + '/com/' + _request.component + '.cfc';

	/* Remove component value from request so it'#s not passed in URL */
	delete _request.component;

	_objMethod(_url, _request, "json")
		.done(function (_jsonData) {

			/* Parse if not yet parsed */
			try { _jsonData = JSON.parse(_jsonData) } catch (e) { }

			/* Call back to initiator with JSON data */
			_callback(_jsonData);

			return true;

		}).fail(function (_err) {

			const _msg = `Error calling ${_request.component}.${_request.method}`

			displayErrorMessage(_msg);

			logError({ message: _msg, debug: _err })

			return false;
		}

)}

export function externalAPI(_url, _method, _data, _callback ) {

	const _methodObj = _method === "POST" ? $.post : $.get;
	var _request = {};

	_methodObj(_url, _request, "json")
		.done(function (_jsonData) {

			/* Parse if not yet parsed */
			try { _jsonData = JSON.parse(_jsonData) } catch (e) { }

			/* Call back to initiator with JSON data */
			_callback(_jsonData);

			return true;

		}).fail(function (_err) {

			const _msg = `Error calling ${_url}`

			displayErrorMessage(_msg);

			logError({ message: _msg, debug: _err })

			return false;
		}

		)
		;

}

/*
export function convertPrice(_price)
{
	const _rate = config.exchangerates[config.userData.currency];
	if(_rate)
	{

		_price = Number(_price);
		var _priceNew = (_price / _rate.rate).toFixed(_rate.precision).trim();

		var _orginal = _rate.rate !== 1 ? `<small>(${_price} XRP)</small>` : ``;

		var _icon = _rate.sign.length ? `<i className="${_rate.sign} mx-1"></i>` : ``;
		var _html = `${_icon}<strong>${_priceNew}</strong> ${_orginal}`

		return _html

	}
	else
	{
		return _price + " ???"
	}

}
*/

export function convertDropsToXRP(_price)
{
	return _price / 1000000;


}


export function logError(_error) {
	console.error(_error.message);
	console.error(_error.debug);
}



export function setupXmartDropzones(_method, config)
{
	_method = _method.toLowerCase();
	var _id, _purpose, _template, _imgPath;

	if(_method === "nft")
	{
		_id = "nft-img-icon";
		_purpose = "nft";
		_template = `
					<div className="dz-preview dz-file-preview hid">
					</div>`;

	}
	else if (_method === 'profile')
	{
		_id = "profile-img-icon div";
		_purpose = "profilePicture";
		_template = `<div className="dz-preview dz-file-previewTemplate	hid">
					</div>
					`;
	}
	else if (_method === 'banner')
	{
		_id = "banner-icon div";
		_purpose = "profileHeader";
		_template = `<div className="dz-preview dz-file-previewTemplate	hid">
					</div>
					`;
		/* Modify the heroe image */
		$('#banner-icon').removeClass("hid");
	}

	else if (_method === 'collectionpreview')
	{
		_id = "collection-preview div";
		_purpose = "collectionPreview";
		_template = `<div className="dz-preview dz-file-previewTemplate	hid">
					</div>
					`;
	}
	else if (_method === 'collectionbanner')
	{
		_id = "banner-icon div";
		_purpose = "collectionHeader";
		_template = `<div className="dz-preview dz-file-previewTemplate	hid">
					</div>
					`;
		/* Modify the heroe image */
		$('#banner-icon').removeClass("hid");
	}

	else
	{
		alert(_method + " not handled");
		return false;
	}


	// If you are using an older version than Dropzone 6.0.0,
	// then you need to disabled the autoDiscover behaviour here:
	Dropzone.autoDiscover = false;

	let myDropZone = new Dropzone("#"+_id,
				{
				url: `${config.apiServer}/com/files.cfc?method=post`,
				previewTemplate: _template,
				autoProcessQueue: true,
				uploadMultiple: false,
				parallelUploads: 1,
				maxFiles: 1,
				maxFilesize:100,
				headers:{"uuid":$('#uuid').val(), "purpose":_purpose, "temp":true, "collection":$('#collection').val()},
				acceptedFiles:window.config.sizes.extensions,
				addRemoveLinks:false,

				uploadprogress:(file, progress, bytesSent) =>
				{
					progress = parseInt(progress);
					const msg = progress < 100 ? `Uploading ${progress}%` : "Creating thumbnails";
					$('#upload-progress').html( msg );
				},

				sending:() =>
				{
					window.showWaitMessage(`
												<p><strong>Uploading could take some time as you are uploading NFT media. Do not navigate away until the upload has finished fully.</strong></p>
												<p id='upload-progress'></p>
												<p><img src="${window.config.sizes.placeholders.preloader}" /></p>`, false, true);

				},

				success:(file,response,load) =>
					{

						myDropZone.removeFile(file);
						window.hideWaitMessage();

						const _cb = new Date().getTime();


						if(!response.success)
						{
							window.displayErrorMessage(response.errorMessage)
						}
						else if(_method==='profile')
						{

							$('#profile-img').prop("src", config.apiServer + response.url.replace("[[purpose]]", `${config.sizes.squareLarge.width}`) + "?cb=" + _cb);
							var _pis = config.apiServer + response.url.replace("[[purpose]]", `${config.sizes.profilePictureSmall.width}`);
							$('#profile-img-small-1,#profile-img-small-2').prop("src", _pis + "?cb=" + _cb);

						}
						else if(_method==='banner')
						{
							_imgPath = `url(${config.apiServer}${response.url.replace("[[purpose]]", `${config.sizes.profileBanner.width}`)}?cb=${_cb})`;
							$('.breadcrumb-area').css("background-image", _imgPath);

						}
						else if(_method==='collectionbanner')
						{
							_imgPath = `url(${config.apiServer}${response.url.replace("[[purpose]]", `${config.sizes.collectionBanner.width}`)}?cb=${_cb})`;
							$('.breadcrumb-area').css("background-image", _imgPath);

						}
						else if(_method==='collectionpreview')
						{
							_imgPath = config.apiServer + response.url.replace("[[purpose]]", `${config.sizes.squareLarge.width}`) + `?cb=${_cb}`;
							$("#nft-img").prop("src", _imgPath).addClass("uploaded").trigger("click");
						}
						else if(_method==='nft')
						{
							_imgPath = config.apiServer + response.url.replace("[[purpose]]", `${config.sizes.squareLarge.width}`) + `?cb=${_cb}`;
							$("#nft-img").prop("src", _imgPath).addClass("uploaded").trigger("click");

						}
						else
						{
							alert("Upload callback ["+_method+"] not handled in setupXmartDropzones")
						}

						if(response.success)
						{
							/* Push image info to page and click button to transfer to state */
							window.$('#nft-image-path').val(_imgPath)
						}


						try
						{
							window.setTimeout( () => { $('#form-check').trigger("click"); }, 500 )
						}
						catch(e)
						{

						}

					}
			});

};

export function formatDate(_epochSeconds, _utcFormat, _localFormat)
{

	_epochSeconds = Number(_epochSeconds.toString().substring(0,10))

	const _optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
	const _optionsTime = { timeZone: 'UTC', timeZoneName: 'short', hour: '2-digit', minute: '2-digit' };

	const _dateObj = new Date(0); // The 0 here is the key, which sets the date to the epoch
	_dateObj.setUTCSeconds(_epochSeconds);

	if(_localFormat)
	{
		return _dateObj.toDateString(undefined, _optionsDate) + " @ " + _dateObj.toTimeString(undefined, _optionsTime).split(" ")[0]
	}

	return _utcFormat ? _dateObj.toUTCString() : _dateObj.toLocaleDateString(undefined, _optionsDate) + " at " + _dateObj.toLocaleTimeString(undefined, _optionsTime)

}

export function shortDate(_epochSeconds, seperator = " @ ")
{

	const _optionsDate = { year: '2-digit', month: 'short', day: 'numeric' };
	const _optionsTime = { timeZone: 'UTC',  hour: '2-digit', minute: '2-digit', second: '2-digit' };

	const _dateObj = new Date(0); // The 0 here is the key, which sets the date to the epoch
	_dateObj.setUTCSeconds(_epochSeconds);

	return _dateObj.toLocaleDateString(undefined, _optionsDate) + seperator + _dateObj.toLocaleTimeString(undefined, _optionsTime)

}

export function toDaysMinutesSeconds(totalSeconds, short = false, padded = true, showAllSegments = false ) {

	const seconds = Math.floor(totalSeconds % 60);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
	const days = Math.floor(totalSeconds / (3600 * 24));

	var secondsStr;
	var minutesStr;
	var hoursStr;
	var daysStr;

	if (short)
	{
		if(showAllSegments)
		{
			secondsStr = makeHumanReadable(seconds, 's', padded);
			minutesStr = makeHumanReadable(minutes, 'm', padded);
			hoursStr = makeHumanReadable(hours, 'h', padded);
			daysStr = makeHumanReadable(days, 'd', padded);

		}
		else
		{
			secondsStr = hours === 0 || showAllSegments  ? makeHumanReadable(seconds, 's', padded) : '';
			minutesStr = ( hours > 0 || minutes > 0 )  || showAllSegments ?  makeHumanReadable(minutes, 'm', padded) : '';
			hoursStr = hours > 0 || showAllSegments ? makeHumanReadable(hours, 'h', padded) : '';
			daysStr = days > 0 || showAllSegments ?  makeHumanReadable(days, 'd', padded) : '';

		}

		return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,/g, '');
	}
	else
	{

		secondsStr = makeHumanReadable(seconds, ' seconds');
		minutesStr = makeHumanReadable(minutes, ' minutes');
		hoursStr = makeHumanReadable(hours, ' hours');
		daysStr = makeHumanReadable(days, ' days');
		if(days + hours + minutes + seconds <= 0)
		{
			return `0 seconds`;
		}
		else
		{
			return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, '');
		}
	}


  }

function makeHumanReadable(num, singular, padded) {
	var displayNumber

	if(padded)
		{
			displayNumber = num.toString().padStart(2, 0)
		}
		else
		{
			displayNumber = num
		}

	if( ( singular === 'second' || singular === 's' ) && num === 0)
	{
		return `${displayNumber}${singular}`;
	}
	else
	{


	//	if( ( singular === 'd' || singular === 'days' ) && num === 0) { return "" }

		return num > 0 || 0 === 0? ` ${displayNumber}${singular} ` : ``;
	}

}

export function numberRounder(number, trailingZeros = false)
{

	var result, amt

	if(number >= 1000000)
	{
		amt = ( number / 1000000 ).toFixed(2)
		result = amt.indexOf(".0") > -1 ? parseInt(amt) + "M" : amt +"M"
	}
	else if(number >= 1000)
	{
		amt = ( number / 1000 ).toFixed(2)
		result = amt.indexOf(".0") > -1 ? parseInt(amt) + "K" : amt +"K"
	}
	else
	{
		number = number.toLocaleString( undefined, { minimumFractionDigits: 2 } )
		result = number;

		if( ! trailingZeros)
		{

			let numStr = number.toString();

			// Use a regular expression to remove trailing zeros after a decimal point
			numStr = numStr.replace(/(\.\d*[1-9])0+$/, '$1');

			// Remove the decimal point if there are no digits after it
			numStr = numStr.replace(/\.$/, '');

			// If the original number was an integer, parse the result back to an integer
			if (Number.isInteger(number)) {
			return parseInt(numStr, 10);
			}

			// Otherwise, parse the result back to a float
			result =  parseFloat(numStr);
		}

	}



	return result

}

export function networkLookup(uuid)
{

	var info = { network:"None", currency:"???", id:"", primary:false }

	if(typeof uuid === 'undefined')
	{
		// Pass default from below
	}
	else
	{
		window.$.each(window.config.fees.networks, (_num, _item) =>
		{
			if(_item.id === uuid)
			{
				info = _item;
			}
		} )
	}

	return info

}

export function permaLink(e)
{
	const _link = window.config.serverURL + e.currentTarget.dataset.link;

	var cb = document.getElementById("clipboard")

	if( cb === null )
	{
		const e = document.createElement('input');
		e.id = "clipboard";
		document.body.appendChild(e);
		cb = document.getElementById("clipboard")
	}

	cb.style = "display:block";

	/* Fill and select the text field */
	cb.value = _link;
	//cb.focus()
	cb.select();
	cb.setSelectionRange(0, 99999); /*For mobile devices*/

	/* Copy the text inside the text field */
	document.execCommand("copy");

	cb.style = "display:none";
	//$('#clipboard').hide();

	/* Alert the copied text */
	window.displayMessage("The permalink has been copied to your clipboard");

}

export function numberCheck(e)
{
	const keyCode = e.keyCode ? e.keyCode : e.which ? e.which : e.charCode;
	const allowedcodes = [8,9,35,36,37,39,46,48,49,50,51,52,53,54,55,56,57,188,190]; /* Various keys and 48-57 for 0-9, 188 & 190 , & . */
	//console.log(keyCode)
	if (allowedcodes.indexOf(keyCode) === -1) { e.preventDefault() }
	return e

}

export function numberCheckInteger(e)
{
	const keyCode = e.keyCode ? e.keyCode : e.which ? e.which : e.charCode;
	const allowedcodes = [8,9,35,36,37,39,46,48,49,50,51,52,53,54,55,56,57]; /* Various keys and 48-57 for 0-9, 188 & 190 */
	//console.log(keyCode)
	if (allowedcodes.indexOf(keyCode) === -1) { e.preventDefault() }
	return e

}


export function loadPreloadImages(_removeClass = true)
{
	const items = $(".preload-image");
	items.each((idx, item) =>
		{
			var controlJQ = $(item);
			var newSource = controlJQ.data("load");

			if(item.nodeName === "DIV")
			{
				controlJQ.css("background", 'URL("' + newSource + '")').removeClass("preload-image");
			}
			else
			{
				controlJQ.prop("src", newSource).removeClass("preload-image");
			}
			//controlJQ.prop("data-load", "")
		} )

	$( "window" ).trigger("resize")

	window.initialiseJQ()
}