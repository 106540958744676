import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';

import AuthorProfile from '../../components/AuthorProfile/AuthorProfile';
import Tasks from '../../components/AuthorProfile/tasks';



class pageContent extends Component {

	state = { profileData: this.props.config.userData }

    render() {

        return (
            <div className="main">
                <Header 			config={ this.props.config } state={this.state} />
                <Breadcrumb 		config={ this.props.config } state={this.state} title="Tasks" background="null" shallow="true" subpage="Tools" page="My Tasks" />

				<section className="author-area explore-area popular-collections-area">
					<div className="container p-0">
                    	<div className="row justify-content-between">
							<div className="col-12 col-md-3">
								{/* Author Profile */}
								<AuthorProfile	config={ this.props.config } state={this.state} />
							</div>
							<div className="col-12 col-md-9 mt-4">
								<Tasks			config={ this.props.config } />
							</div>
						</div>
					</div>
				</section>

                <Footer				config={ this.props.config } />
                <ModalSearch		config={ this.props.config } />
                <ModalMenu			config={ this.props.config } />
                <Scrollup				config={ this.props.config } />
            </div>
        );
    }
}

export default pageContent;