import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ComingSoon from '../../components/comingSoon';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';

class pageContent extends Component {
    render() {
        return (
            <div className="main">
                <Header				config={ this.props.config } state={this.state}  />
                <Breadcrumb			config={ this.props.config } state={this.state} title="View a wallet's NFT's" background="null" subpage="Tools" page="NFT Viewer" />
                <ComingSoon			config={ this.props.config }  />
                <Footer				config={ this.props.config }  />
                <ModalSearch		config={ this.props.config }  />
                <ModalMenu			config={ this.props.config }  />
                <Scrollup			config={ this.props.config }  />
            </div>
        );
    }
}

export default pageContent;