import React, { Component } from 'react';
import { addXummPayload } from '../../js/xumm';
import { CurrencyPrice } from '../currencyPrice';


const initData = {
    heading: "What are you looking for?",
    content: "Find your favourite collections and NFTs using our search tool. A fuzzy search will be carried out - For example, if you serached for 'xdu', your search would bring back results for collections and NFTs whose name or decription contain XDU - For example, xDudes",
    btnText: "Approve in Wallet"
}

class ModalSwapTokens extends Component
{

	state = this.getCleanState()

	updateState = e =>
	{
		this.setState(this.getCleanState);
	}

	getCleanState()
	{

		var _sc = this.props.state.spinInfo.promoRunning ? this.props.state.spinInfo.promoCost : this.props.state.tokensPerSpin;
		var _ci = this.props.state.spinInfo.promoRunning ? this.props.state.spinInfo.promoWalletLimit : this.props.state.swapRemaining;

		return {
			data: initData,
			clicked:false,
			checkboxChecked:false,
			formCompleted:false,

			spinCost:_sc,
			spinsRequired:1,

			calculationCost: _sc,
			calculationItems:_ci,
			totalQuantity: _sc.toFixed(4)
		}
	}

	priceChanged = e =>
	{

		var theValue
		if( e.currentTarget.value.length && Number(e.currentTarget.value) < this.props.state.spinInfo.promoCost)
		{
			theValue = Number(this.props.state.spinInfo.promoCost)
		}
		else if( e.currentTarget.value.length && Number(e.currentTarget.value) < 0 )
		{
			theValue = 0
		}
		else
		{
			theValue = Number(e.currentTarget.value)
		}

		if(theValue === 0) { theValue = '' } else { theValue = Number(theValue) }
		this.recalculate({spinsRequired:this.state.spinsRequired, spinCost:theValue})

	}

	spinsRequiredChanged = e =>
	{

		var totalSpins;

		if( e.currentTarget.value.length && Number(e.currentTarget.value) > this.state.calculationItems)
		{
			totalSpins = this.state.calculationItems
		}
		else if( e.currentTarget.value.length && Number(e.currentTarget.value) < 1 )
		{
			totalSpins = 1
		}
		else
		{
			totalSpins = e.currentTarget.value
		}

		if(isNaN(totalSpins)) { totalSpins = '' }

		totalSpins = totalSpins > 0 ? Math.abs(parseInt(totalSpins)) : ""
		this.recalculate({spinsRequired:totalSpins, spinCost:this.state.spinCost})

	}

	recalculate = (values) =>
	{

		var tq = values.spinsRequired * values.spinCost;

		this.setState({
			spinsRequired:values.spinsRequired,
			spinCost:values.spinCost,
			totalQuantity: tq.toFixed(4),
			formCompleted:tq > 0 ? true : false
		})

	}

	checkboxChanged = e =>
	{
		this.setState({
						checkboxChecked:e.target.checked ? true : false,
					})

	}


	doSwap = e =>
	{

		//window.showWaitMessage("Swap authorisation in progress....")
		const _curr = this.props.state.tokenCurrency.toUpperCase() === 'XRP' ? "XRP" : "CUSTOM"
		const values = { "collection":this.props.state.spinInfo.id, "amount":Number(this.state.spinsRequired), "authToken":this.props.config.userData.token, "currency":_curr, "spinCost":this.state.spinCost}
		addXummPayload({"NFTokenIDs":[], "OfferIDs":[], "OfferTypes":[], "type":"swapTokensForSpins", "values":values, "redirect":"", callbackButton:"reloadSpinner"})

		window.setTimeout( () => { this.props.reloadSpinner(); document.getElementById("modalSwapTokensClose").click() }, 1000);

	}

	componentDidMount()
	{

	}


    render()
	{
		const _blocked = ( this.props.state.spinInfo.promoRunning && Number(this.state.spinCost) === 0 ) || ( this.props.state.spinInfo.promoRunning === false && Number(this.state.totalQuantity) === 0 )

        return (

				<div id="swap-modal" className="modal fade p-0">

					<button onClick={this.updateState} className="hid" id="refreshState">Clean Slate</button>

               		<div className="modal-dialog modal-lg dialog-animatedx modal-fullheight modal-fullscreen-lg-down">
                    	<div className="modal-content h-100">

							<div className="modal-header modal-header--sticky">
								{ this.props.state.tokenCurrency === 'XRP' ? "Buy Spins" : "Swap Tokens For Spins"} <button className="no-style" id="modalSwapTokensClose" data-bs-dismiss="modal"><i className="fas fa-times-circle icon-close" /></button>
							</div>

							<div className="show-grid modal-body p-3 pb-4">
								<div className="container row">

									<div className="col-12 p-0 mb-5 d-none d-md-blockxxx">
										{ this.props.state.tokenCurrency === 'XRP' ?
										(
											<>
												<p>To power up the spinner, you need to buy 1 or more spins. This will enable you to win an NFT that is randomly selected from this collection.</p>
												<p>Spins purchased for this collection are not transferrable to other collections.</p>
											</>
										)
										:
										(<p>
											To power up the spinner, you need to swap your {this.props.state.tokenCurrency} token(s) for spins.
											Swapping will transfer ownership of the requested amount of tokens to xMart. These tokens will then be burned and can never be used again.
										</p>
										)
										}

									</div>


									{ this.props.state.spinInfo.promoRunning ?
									(
										<>
											<div className="col-12 col-md-4 mb-3">
												<label className="pb-3 pt-1">Amount to Pay</label>
											</div>
											<div className="col-12 col-md-8 mb-3">
												<div className="form-group has-validation">
													<div className="d-flex align-items-center">
													<input
																	type="number"
																	className="form-control value-field"
																	name="spinCost"
																	id="spinCost"
																	min={this.state.calculationCost}
																	step={this.props.state.spinInfo.promoIncrement}
																	placeholder=""
																	maxLength="6"
																	key="spinCost"
																	value={this.state.spinCost}
																	onBlur={this.priceChanged}
																	onChange={this.priceChanged}
																/>
														<span className="text-muted ms-3">(Minimum <CurrencyPrice price={this.props.state.spinInfo.promoCost} config={this.props.config} /> )</span>
													</div>
												</div>
											</div>

											<div className="col-12 col-md-4 mb-3">
												<label htmlFor="sell-price" className="pb-2 me-3">Spins Required <span className="req">*</span></label>
											</div>
											<div className="col-12 col-md-8 mb-3">
												<div className="form-group d-flex align-items-center">
													<input
																	type="number"
																	className="form-control value-field"
																	name="spinsRequired"
																	id="spinsRequired"
																	readOnly={this.props.state.spinInfo.promoWalletLimit === 1}
																	value={this.state.spinsRequired}
																	max={this.props.state.spinInfo.promoWalletLimit}
																	min={1}
																	onChange={this.spinsRequiredChanged}
																	onBlur={this.spinsRequiredChanged}
																	 />
													<span className="text-muted ms-3">(Maximum of {this.state.calculationItems} per purchase)</span>
												</div>
											</div>
										</>
									) :
									(
										<>
											<div className="col-12 col-md-4 mb-3">
												<label className="pb-3 pt-1">Cost Per Spin</label>
											</div>
											<div className="col-12 col-md-8 mb-3">
												<div className="form-group has-validation">
													<div className="d-flex align-items-center">
														<input type="text" className="form-control value-field" readOnly="readonly" value={this.state.spinCost} /> <span className="ps-2">{this.props.state.tokenCurrency}</span>
													</div>
												</div>
											</div>

											<div className="col-12 col-md-4 mb-3">
												<label htmlFor="sell-price" className="pb-2 me-3">Spins Required <span className="req">*</span></label>
											</div>
											<div className="col-12 col-md-8 mb-3">
												<div className="form-group d-flex align-items-center">
														<input
																	type="number"
																	className="form-control value-field"
																	data-ntg="6123564567"
																	name="offer-amount"
																	id="offer-amount"
																	min="1"
																	max={this.props.state.nftsRemaining}
																	placeholder=""
																	maxLength="6"
																	key="spins2"
																	value={this.state.spinsRequired}
																	onBlur={this.spinsRequiredChanged}
																	onChange={this.spinsRequiredChanged}
																/>
													<span className="text-muted ms-3">(Maximum of {this.state.calculationItems})</span>
												</div>
											</div>
										</>
									)}



									{ this.props.config.rs[2] && this.props.config.userData.display === false?
									(
										<div className="alert alert-warning text-center">
											As a temporary restriction, we are limiting the maximum purchase to 1.
										</div>
									) : (<></>)}

									<div className="col-12 col-md-4 mb-3">
										<label className="pb-3 pt-1">Total Transaction Value</label>
									</div>
									<div className="col-12 col-md-8 mb-3">
										<div className="form-group has-validation">
											<div className="d-flex align-items-center">
												<input type="text" className="form-control value-field" readOnly="readonly" value={this.state.totalQuantity} /> <span className="ps-2">{this.props.state.tokenCurrency}</span>
												{/* <span className="text-muted ms-3">(You have ±{this.props.state.tokens} {this.props.state.tokenCurrency})</span> */}
											</div>
										</div>
									</div>

								</div>
							</div>
							<div className="modal-footer">
								<button type="button" disabled={Number(this.state.totalQuantity) === 0 ? true : false} onClick={this.doSwap} className="btn  w-100">{this.state.data.btnText}</button>

							</div>
						</div>
					</div>
				</div>
        );
    }
}

export default ModalSwapTokens;