import React, { Component } from 'react';
import axios from 'axios';
import { displayErrorMessage, hideWaitMessage, showWaitMessage } from '../xutils';
import "../../css/search.css";



const initData = {
    menuName: "Search",
    menuIcon: "far fa-times-circle icon-close",
    heading: "What are you looking for?",
    btnText: "Search Now"
}

class ModalSearch extends Component {

	state = {
        data: initData,
		query:"",
		disabled:true,
		clicked:false,
		results: {nfts:[], collections:[], users:[]}
    }

	criteriaChanged = e =>
	{
		this.setState({
						query:e.currentTarget.value,
						disabled:e.currentTarget.value.length >= 1 ? false : true
					})

		if(e.currentTarget.value.length >= 1  && e.keyCode === 13)
		{
			this.doSearch()
		}
	}

	doSearch = e =>
	{

		var BASE_URL = this.props.config.apiServer + "/com/external/search.cfc?method=doSearch&q=" + escape(this.state.query);

		showWaitMessage("Search in progress....")
		axios.get(`${BASE_URL}`, {withCredentials: true})
		.then(res => {

			hideWaitMessage()
			if( ! res.data.success)
				{
					displayErrorMessage("Search Error - " + res.data.errorMessage, { icon: "error" })
				}
				else
				{

					/* Store results in state and display them */
					this.setState({
						results: res.data.results,
						clicked:true
					})

					window.jQuery('#search-narrative').slideUp();


					window.setTimeout( () => {
						window.$('.modal-content').animate({
							scrollTop: window.$("body").offset().top
						}, 0);
					}, 500
					)

				}
		})
	.catch(err => { displayErrorMessage("Search Error - " + err) })
	}

	componentDidMount(){

	}


    render() {

        return (
            <div id="search" className="modal fade">
                <div className="modal-dialog modal-xl dialog-animatedx modal-fullheight modal-fullscreen-lg-down">
                    <div className="modal-content h-100">

						<div className="modal-header modal-header--sticky" data-bs-dismiss="modal">
                            {this.state.data.menuName} <i className="fas fa-times-circle icon-close" data-bs-dismiss="modal" />
                        </div>

                        <div className="modal-body p-4">
							<div className="col-12 align-self-center">

								<div className="row" id="search-narrative">
									<div className="col-12">
										<h4 className="search-title mt-0 mb-3 primary">{this.state.data.heading}</h4>
										<p className="mb-3">
											Find your favourite collections and NFTs using our search tool. A fuzzy search will be carried out - For example, if you searched for '<strong>xdu</strong>', your search would bring back results for collections and NFTs whose name or decription contain XDU - For example, <strong>xDu</strong>des
										</p>
									</div>
								</div>

								<div id="results"  className={this.state.clicked ? "" : "hid"}>

									<div className="searchGroup mb-3 p-0">

										<h2 className="px-3"><i className="me-2 fas fa-th-large"></i> Collection Results</h2>

										<div className="xcard-body p-0 m-0 px-2">

											{this.state.results.collections.length === 0 ? ( <h4 className="text-muted">No results found</h4> ) : ( <span></span>) }

											<div className={this.state.results.collections.length ? "row g-0 list-unstyled" : "row g-0 hid"}>
												{/* Single Tab List */}
												{this.state.results.collections.map((item, idx) => {

													const _img = item.img.indexOf("http") === -1 ? this.props.config.apiServer + item.img : item.img;
													return (
														<div className="col-12 col-md-6 p-0 result-wrap" key={`result_collection_${idx}`}>
															<a href={item.url} target='_blank'>
																<div className="result">
																	<div className="d-flex align-items-center">
																		<div className="flex-shrink-1"><img src={_img} /></div>
																		<div className="ps-3 w-100" dangerouslySetInnerHTML={{__html: item.title }}></div>
																	</div>
																	<div className="desc hid">
																		{item.desc.length !== 0 ? ( <>{item.desc}</> ) : ( <><em>No collection description</em></>) }
																	</div>
																</div>
															</a>
														</div>
													);
												})}
											</div>
										</div>
									</div>

									<div className="searchGroup mb-3 p-0">

									<h2 className="px-3"><i className="me-2 fas fa-coins"></i> NFT Results</h2>

										<div className="card-body p-0 m-0 px-2">
											{this.state.results.nfts.length === 0 ? ( <h4 className="text-muted">No results found</h4> ) : ( <span></span>) }
											<div className={this.state.results.nfts.length ? "row g-0 list-unstyled" : "row g-0 hid"}>
												{/* Single Tab List */}
												{this.state.results.nfts.map((item, idx) => {
													const _img = item.img.indexOf("http") === -1 ? this.props.config.apiServer + item.img : item.img;
													return (
														<div className="col-12 col-md-6 p-0 result-wrap" key={`result_collection_${idx}`}>
															<a href={item.url} target='_blank'>
																<div className="result">
																	<div className="d-flex align-items-center">
																		<div className="flex-shrink-1"><img src={_img} /></div>
																		<div className="ps-3 w-100" dangerouslySetInnerHTML={{__html: item.title }}></div>
																	</div>
																	<div className="desc hid">
																		{item.desc.length !== 0 ? ( <>{item.desc}</> ) : ( <><em>No NFT description</em></>) }
																	</div>
																</div>
															</a>
														</div>
													);
												})}
											</div>
										</div>
									</div>

									<div className="searchGroup mb-3 p-0">

									<h2 className="px-3"><i className="me-2 fas fa-user-circle"></i> User Results</h2>

										<div className="card-body p-0 m-0 px-2">
											{this.state.results.users.length === 0 ? ( <h4 className="text-muted">No results found</h4> ) : ( <span></span>) }

											<div className={this.state.results.collections.length ? "row g-0 list-unstyled" : "row g-0 hid"}>
												{/* Single Tab List */}
												{this.state.results.users.map((item, idx) => {

													var _img = item.img.indexOf("http") === -1 ? this.props.config.apiServer + item.img : item.img;
													if( ! _img.length)
													{
														debugger
													}

													return (
														<div className="col-12 col-md-6 p-0 result-wrap" key={`result_collection_${idx}`}>
															<a href={item.url} target='_blank'>
																<div className="result">
																	<div className="d-flex align-items-center">
																		<div className="flex-shrink-1"><img src={_img} /></div>
																		<div className="ps-3 w-100" dangerouslySetInnerHTML={{__html: item.title }}></div>
																	</div>
																	<div className="desc hid">
																		{item.desc.length !== 0 ? ( <>{item.desc}</> ) : ( <><em>No Profile description</em></>) }
																	</div>
																</div>
															</a>
														</div>
													);
												})}
											</div>
										</div>
									</div>

								</div>


							</div>

                        </div>

						<div className="modal-footer container modal-footer--sticky">

							<div className="d-flex align-items-center">
								<div className='flex-grow-1'>
									<input type="text" id="search-criteria" placeholder="Enter your keywords" onKeyUp={this.criteriaChanged} />
								</div>
								<div className="flex-shrink-1">
									<button className="btn btn-bordered ms-4" disabled={this.state.disabled} type="button" onClick={this.doSearch}>SEARCH</button>
								</div>
							</div>

						</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalSearch;