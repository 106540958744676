import React, { Component } from 'react';
import axios from 'axios';

class Activity extends Component {

	/* This functio handles the click to launch the wallet authentiction */
	clickMe(e)
	{

		e.preventDefault()

		const authURL = this.props.config.apiServer + "/com/xumm.cfc?method=signIn&userRedirect="+escape(document.location.origin);

		axios.get(authURL, {withCredentials: true})
		.then(res => {
			if(res.data.success)
			{
				document.location.replace(res.data.info.next.always)
			}
			else
			{
				alert(res.data.errorMessage)
			}
		})
		.catch(err => window.logger(err))
	}

    componentDidMount(){


    }


    render() {
		return (
            <section className="wallet-connect-area p-2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                              {/*  <span>{this.props.config.wallets.preHeading}</span> */}
                                <h3 className="mt-3 mb-0">{this.props.config.wallets.heading}</h3>
                                <p>{this.props.config.wallets.content}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center items">
                        {this.props.config.wallets.data.map((item, idx) => {
                            return (
                                <div key={`wd_${idx}`} className="col-12 col-md-6 col-lg-4 item">
                                    {/* Single Wallet */}
                                    <div className="card single-wallet">
										<a className="d-block text-center connect-wallet" href="/" data-for={item.tag} onClick={ (e) => { this.clickMe(e);} }>
                                            <img className="avatar-lg" src={item.img} alt="" />
                                            <h4 className="mb-0">{item.title}</h4>
                                            <p>{item.content}</p>
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default Activity;