import React, { Component } from 'react';

export class CurrencyPrice extends Component {

	render() {

		const rate = this.props.config.rates[ this.props.config.userData.currency ];
		var _total = Number(this.props.price) /  Number(rate.rate)
		var _className = this.props.class ? this.props.class : ""

		var theAmount = _total.toLocaleString( undefined, { minimumFractionDigits: 2 } )

		if(this.props.formField)
		{
			return (
					<>
						<input type="text" className="form-control value-field" readOnly value={ theAmount } />
						<span className="ms-2 hid">{rate.symbol}</span>
					</>
			)
		}
		else
		{
			//return <span className={_className}>{theAmount} {rate.symbol}</span>
			return <span className={_className}>{theAmount}</span>
		}

	}
}

export default CurrencyPrice;
