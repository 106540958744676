import React, { Component } from 'react';
import CollectionEditButtons from '../Collections/editButtons';

class Breadcrumb extends Component {



	componentDidMount()
	{

	}

    render() {

		var breadCrumbStyle = {};
		var breadCrumbClass = "breadcrumb-area-full";

		if (this.props.background === 'null' )
		{
			breadCrumbStyle.backgroundImage = ``
		}
		else
		{
			if(typeof(this.props.background) != "undefined" && this.props.background.length)
			{
				breadCrumbStyle.backgroundImage = `URL(${this.props.background})`
			}
			else
			{
				breadCrumbStyle.backgroundImage = `null`
			}
		}

		/* Shallow overrides height of previous */
		if(typeof(this.props.shallow) != "undefined" && this.props.shallow.toString() === "true")
		{
			breadCrumbClass = "breadcrumb-area-shallow"
		}


		if(typeof(this.props.htmlTitle) != "undefined" && this.props.htmlTitle.length)
		{
			document.title = this.props.htmlTitle;
		}
		else
		{
			// document.title = `${this.props.title} | ${this.props.config.meta.title}`;
		}


		const subPageLink = typeof(this.props.subPageLink) != "undefined" ? this.props.subPageLink : '#'
		const subPageTitle = typeof(this.props.subpage) != "undefined" ? this.props.subpage : '??'
		var showButton = false;
		var showTitle = false;
		try
		{
			showButton = typeof(this.props.state.collectionData) != "undefined" ? true : false;
			showTitle = typeof(this.props.state.collectionData) != "undefined" ? true : false;
		} catch(e) {}


		return (
            <section className={"overlay-dark d-flex align-items-center breadcrumb-area " + breadCrumbClass} style={breadCrumbStyle}>

				<div id="banner-icon" className="hid">
					<div className="w-100" data-bs-toggle="tooltip" title="Click here to upload a new banner image" data-bs-placement="bottom">
						<span className="fa-stack">
							<i className="fas fa-cloud fa-stack-1x dropshadow"></i>
							<i className="fas fa-cloud-upload-alt fa-stack-1x"></i>
						</span>
					</div>
				</div>

                <div className="container">

					<div className="row g-0">
                        <div className="col-12">
                            {/* Breamcrumb Content */}

                            <div className="breadcrumb-content text-center">

							<h1 className="m-0" id="breadcrumb-title">{this.props.title}</h1>

                               {/* <h2 className="m-0" id="breadcrumb-title">{this.props.title}</h2>


								{ this.props.subpage && this.props.subpage.length ?
								(
									<ol className="breadcrumb d-flex justify-content-center">
										<li className="breadcrumb-item">
										<a href="/" id="breadcrumb-1">Home</a></li>
										{ subPageTitle.length ? <li className="breadcrumb-item" id="breadcrumb-2"><a href={subPageLink}>{subPageTitle}</a></li> : ''}
										<li className="breadcrumb-item active" id='breadcrumb-page'>{this.props.page}</li>
									</ol>
								)
								:
								(
									<></>
								)
								}
								*/}

								{ showButton && typeof(this.props.state.collectionData) != "undefined"?
								(
									<>
										<div className="container-fluid g-0">
											<CollectionEditButtons config={ this.props.config } state={this.props.state} />
										</div>
									</>
								)
								: ( <></>)
								}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Breadcrumb;