import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ExploreCategory from '../../components/Explore/ExploreCategory';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';

class ExploreCategories extends Component {

	state = {"tag":"", "description":"", "name":"All Collections",  "background":"/img/categories/hero/all.jpg", data:[], loading:true}

	componentDidMount(){

		if(this.props.tag === 'featured' )
		{
			this.setState({tag:this.props.tag, description:"", name:"Featured Projects", uuid:"", background:""});
			document.title = "Featured Projects" + this.props.config.meta.title;
		}
		else if(this.props.tag === 'mintingnow' )
		{
			this.setState({tag:this.props.tag, description:"", name:"Minting Now", uuid:"", background:""});
			document.title = "Minting Now on xMart" + this.props.config.meta.title;
		}
		else
		{
			this.props.config.categories.data.map((item, idx) => {
				if(item.tag === this.props.tag)
				{
					this.setState({tag:item.tag, description:item.description, name:item.name, uuid:item.uuid, background:`/img/categories/hero/${item.tag}.jpg`});
					document.title = ( this.props.tag === 'all' ? `Browse All Collections` : `Browse the "${item.name}" Category` ) + this.props.config.meta.title;
				}
			})

		}

    }


	render() {

		return (
				<div className="main">
					<Header					config={ this.props.config } />
					<Breadcrumb				config={ this.props.config } state={this.state} title={this.state.name} shallow="true" subpage="Categories" subPageLink="/category/all" page={this.state.name} background="null" />
					<ExploreCategory		config={ this.props.config } state={this.state} />
					<Footer					config={ this.props.config } />
					<ModalSearch			config={ this.props.config } />
					<ModalMenu				config={ this.props.config } />
					<Scrollup				config={ this.props.config } />
				</div>
		);
    }
}

function getState(config)
{

	var tState = {"tag":"all", "description":"Description to go here", "name":"All Collections",  "background":"/img/categories/hero/all.jpg"};

	config.categories.data.map((item) =>
	{

		if(item.tag === config.urlParts[2])
		{
			this.setState( {"tag":item.tag, "description":item.description, "name":item.name, "background":`/img/categories/hero/${item.tag}.jpg` } );
		}

		return true

	})

	return tState

}

export default ExploreCategories;