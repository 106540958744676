import React, { Component } from 'react';
import { VideoLink } from '../videoLink'
import $ from 'jquery';
import axios from 'axios';

import { toDaysMinutesSeconds } from '../xutils'

class ModalBulkUpload extends Component
{

	state = {
		importType:0,
		importURLMedia:this.props.state.collectionData.bulkInfo && this.props.state.collectionData.bulkInfo.previousUpload.length ? this.props.state.collectionData.bulkInfo.previousUpload : ( this.props.config.serverMode !== "LOCAL" ? "" : "http://localhost:2999/import_tests/xxxxxxxx.zip" ),

		uploadDefaultMessage:"Choose file...",

		uploadMessageImages:"Choose file containing images/media...",
		selectedFileImages:null,

		buttonDisabled:true,
		uploadPercent:0,
		fileSize:0,
		selectedFile:null,
		uploading:false,

		timeRemaining:0,
		timerStarted:0,
		uploadSpeed:0,
		controller:new AbortController()

	}

	clearState = e =>
	{

		this.state.controller.abort();

		window.$('#importFileMedia').val("")
		this.setState({selectedFile:null, errorMessage:"", uploading:false, uploadPercent:0, fileSize:0, fileName:""})
		window.$('#realcloser').trigger("click")
	}


	handleChangeImportType = e =>
	{
	/* Swap the button state */
	this.setState({importType:Number(e.currentTarget.value)})
	window.setTimeout(() => { this.validateForm(e); }, 500)
	}

	handleChangeFileDirect = e => {

		var maxSizeInMB = ( this.props.config.maxUploadMB ).toFixed(0)
		var maxSizeInGB = ( this.props.config.maxUploadMB / 1000 ).toFixed(0)

		var sizeInMB = ( e.target.files[0].size / 1e+6 ).toFixed(2)
		var sizeInGB = ( e.target.files[0].size / 1e+9 ).toFixed(2)

		if( this.props.state.collectionData.flagOverride )
		{
			var maxSizeInMB = this.props.config.maxUploadMBOverride
			var maxSizeInGB = this.props.config.maxUploadMBOverride / 1000
		}

		if( Number(sizeInMB) > Number(maxSizeInMB) )
		{
			var _maxSizePretty = maxSizeInGB >= 1 ? maxSizeInGB + " GB" : maxSizeInMB + " MB";
			var _yourSizePretty = sizeInGB >= 1 ? sizeInGB + " GB" : sizeInMB + " MB";

			window.displayErrorMessage(`<p>The file size cannot exceed ${_maxSizePretty}. The file you were trying to upload is ${_yourSizePretty}</p>`)
			$(e.currentTarget).val(null);

			this.setState({selectedFileImages:null, errorMessage:"", uploading:false, uploadPercent:0, fileSize:0, fileName:""})

		}
		else
		{

			/* Change the label */
			this.setState({
				uploadPercent:0,
				fileSize:e.target.files[0].size,
				selectedFileImages: e.target.files[0],
				uploading:false,
				uploadMessageImages: e.currentTarget.value.length ? "File Chosen: " + e.target.files[0].name : this.state.uploadDefaultMessage
			})

		}

		window.setTimeout(() => { this.validateForm(e); }, 500)

	}



	handleChangeFileCloud = e => {

	this.setState({
					importURLMedia: e.currentTarget.value
				})

	window.setTimeout(() => { this.validateForm(e); }, 500)
	}



	validateForm = e =>
	{

	/* Form validation */
	var _invalidFields = [];

	this.setState( { buttonDisabled:_invalidFields.length ? true : false } )

	/* Handle any changes to dropdowns */

	if( Number(this.state.importType) === 1 )
	{

		/* Direct upload */
		if( $('#importFileMedia').length)
		{
			if( $('#importFileMedia').val().length  === 0 )
			{
				_invalidFields.push({"field":"importFileMedia", "message":"Please choose a file to upload"})
			}
		}

	}
	else if( Number(this.state.importType) === 2 )
	{

		/* Cloud upload */

		if( this.state.importURLMedia.length  === 0 )
		{
			_invalidFields.push({"field":"importURLMedia", "message":"Enter a URL for the media file"})
		}
		else if ( this.state.importURLMedia.length && /((https|ftp|http):\/\/)(.*)*\/?/is.test( this.state.importURLMedia ) === false )
		{
			_invalidFields.push({"field":"importURLMedia", "message":"Media URL is invalid - Must begin with HTTP://, HTTPS:// or FTP://"})
		}

	}
	else if( Number(this.state.importType) === 3 )
	{
		// Local drop all good
	}
	else
	{
		_invalidFields.push({"field":"importType", "message":"Choose an upload method"})
	}

	this.setState( { buttonDisabled:_invalidFields.length ? true : false } )

	$('#upload-form *').removeClass("fieldInvalid")

	var _html = "";
	$.each(_invalidFields, (idx, fld) => {
		_html += `<li><span>${fld.message}</span></li>`
		$('#'+fld.field).addClass("fieldInvalid").parent().find("span.error-message").remove().append(`<span className="error-message">Poo</span>`);
		$('#'+fld.field).append(`<span className="error-message">Poo</span>`);
	})

	if(_html.length)
	{
		$('.errorMessages').html(`<div class="card-header alert alert-danger"><strong>There are errors in the form. Please correct them before saving:-</strong></div><div class="card-body p-0"><ol class="mx-4 mb-4">${_html}</ol></div>`).removeClass("hid")
	}
	else
	{
		$('.errorMessages').html("").addClass("hid")
	}

	}


	handleSubmit = e => {

		e.preventDefault();
		//window.showWaitMessage(`<p>Saving Changes to Collection</p>${Number(this.props.state.collectionData.bulkInfo.importType) === 1 ? "<p><strong>This could take some time as you are uploading collection media. Do not navigate away until the upload has finished fully.</strong></p><p id='upload-progress'></p>" : ""}<p><img src="${this.props.config.sizes.placeholders.preloader}" /></p>`, false, true);

		const formData = new FormData( document.getElementById("upload-form") );

		this.setState({timeRemaining:"Calculating ..", timerStarted:new Date(), uploading:true})


		if( Number(this.state.importType) === 1)
		{
			/* Append upload fields to form data */
			//if(this.state.selectedFileImages) { formData.append("bulkuploadfileimages", this.state.selectedFileImages, this.state.selectedFileImages.name ); }
		}

		formData.append("uuid", this.props.state.collectionData.id)
		formData.append("redirect", document.location.href)

		const BASE_URL = this.props.config.apiServer + "/com/post.cfc?method=uploadCollectionFiles";
		//const BASE_URL = this.props.config.fileServer + "/com/post.cfc?method=uploadCollectionFiles";

		//const controller = new AbortController();
		const config = {
						withCredentials: true,
						signal: this.state.controller.signal
						}
		axios.post(BASE_URL, formData, {config,
										onUploadProgress: (progressEvent) => {

											const percentComplete = parseInt( (progressEvent.loaded / progressEvent.total ) * 100);

											var timeElapsed = (new Date()) - this.state.timerStarted; // Assuming that timeStarted is a Date Object
											var uploadSpeed = progressEvent.loaded / (timeElapsed / 1000); // Upload speed in second

											if(percentComplete >= 100)
											{
												this.setState({timeRemaining:"Complete - Checking file for viruses", uploadPercent:percentComplete})
											}
											else
											{
												if(timeElapsed > 5000)
												{
													this.setState({
														uploadPercent:percentComplete,
														timeRemaining:toDaysMinutesSeconds ( (progressEvent.total - progressEvent.loaded) / uploadSpeed ) + " @ " + (uploadSpeed / 1e+6).toFixed(2) + " mb/sec",
														uploadSpeed:uploadSpeed
													})
												}
												else
												{
													this.setState({
														uploadPercent:percentComplete
													})
												}
											}

										}
										})
			.then(res => {

				if( ! res.data.success)
				{
					throw(res.data.errorMessage);
				}
				else
				{
					window.showWaitMessage(`<p>Completed.. Reloading collection</p>`, false, true);
					document.location.href = res.data.redirect;
				}
			})
		.catch(err =>
				{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)

	}




    render()
	{

		const _progressWidthMedia = this.state.uploadPercent + "%"
		const styleMedia = "progress-bar progress-bar-striped progress-bar-animated";

		var _maxUploadSize = this.props.state.collectionData.flagOverride  ? this.props.config.maxUploadMBOverride : this.props.config.maxUploadMB;

		var maxSizeInMB = ( _maxUploadSize ).toFixed(0)
		var maxSizeInGB = ( _maxUploadSize / 1000 ).toFixed(0)
		var _maxSizePretty = maxSizeInGB >= 1 ? maxSizeInGB + " GB" : maxSizeInMB + " MB";

        return (

			<div id="bulk-upload-modal" className="modal fade p-0">
				<div className="modal-dialog modal-lg dialog-animatedx modal-fullheight modal-fullscreen-lg-down">
					<div className="modal-content h-100">

						<div className="modal-header modal-header--sticky">
							Bulk Upload NFTs
							<span  onClick={this.clearState}>
								<i className="fas fa-times-circle icon-close" />
							</span>
							<button id="realcloser" className="hid" data-bs-dismiss="modal" >X</button>
						</div>
						<div className="show-grid modal-body p-3 pb-4">
							<div className="container row p-4">


								<p className="my-2">A bulk upload should ideally consist of both media and jSON files. Each JSON metadata file must have a corresponding media file. This could be an image, video or audio file.</p>
								<p className="my-2">If no JSON file is provided, we will attempt to create one automatically for you. However, after the upload process you will be prompted to provide titles and descriptions for each NFT.</p>
								<p className="my-2">Additionally, you can include a rarity CSV file. This should contain the pre-calculated rarity for each of the NFT's that are defined in the JSON files. For a more details explanation, refer to our "<VideoLink label={"How to package a collection"} tag={"package-standard-collection"} config={this.props.config} />" training video.</p>

								<hr className="mt-3" />
								<h3>Choose your upload method</h3>

								{ /* Checkboxes */}
								{ /* Checkboxes */}
								{ /* Checkboxes */}

								{ this.state.uploading === false ?
									(
									<div className="col-12">

										<form id="upload-form" onSubmit={this.handleSubmit}>

											<p className="mt-0 mb-3">For files located on your device, that are under {"Maximum upload file size is "+_maxSizePretty}, choose "Direct Upload". For files larger than {"Maximum upload file size is "+_maxSizePretty}, choose Use "Cloud based" (Google drive, Dropbox etc) - Find our more about "<VideoLink label={"Types of upload methods"} tag={"upload-methods"} config={this.props.config} />"</p>

											<div className="row d-flex justify-content-evenly mt-4">

												<div className="col-12 col-md-6 mb-4">
													<input name="importType" id="importType_2" value="2" className="btn-check"  type="radio" role="switch" aria-checked={this.state.importType === 2 ? true : false} autoComplete="off" defaultChecked={Number(this.state.importType) === 2} onChange={this.handleChangeImportType} />
													<label className={Number(this.state.importType) === 2 ? "btn btn-bordered me-2 w-100 active" : "btn btn-bordered w-100"} htmlFor="importType_2">Cloud Upload</label>
												</div>

												<div className="col-12 col-md-6 mb-4">
													<input name="importType" id="importType_1" value="1" className="btn-check" type="radio" role="switch" aria-checked={this.state.importType === 1 ? true : false} autoComplete="off" defaultChecked={Number(this.state.importType) === 1} onChange={this.handleChangeImportType} />
													<label className={Number(this.state.importType) === 1 ? "btn btn-bordered ms-2 w-100 active" : "btn btn-bordered w-100"} htmlFor="importType_1">Direct Upload</label>
												</div>

												{ this.props.config.userData.display ?
												(
													<div className="col-12 col-md-6 mb-4">
														<input name="importType" id="importType_3" value="3" className="btn-check" type="radio" role="switch" aria-checked={this.state.importType === 3 ? true : false} autoComplete="off" defaultChecked={Number(this.state.importType) === 3} onChange={this.handleChangeImportType} />
														<label className={Number(this.state.importType) === 3 ? "btn btn-bordered ms-2 w-100 active" : "btn btn-bordered w-100"} htmlFor="importType_3">Local drop</label>
													</div>

												)
												:
												( <></> )}

											</div>

											{ /* Upload boxes */}
											{ /* Upload boxes */}
											{ /* Upload boxes */}
											{
												this.state.importType === 1 ?
												(
													<>

														{/* Form based upload */}

														<div className="col-12 mb-3">
															<div className="form-group">
																<label htmlFor="importFileMedia" className="pb-2">
																	<strong>Collection Media - Max {"Maximum upload file size is "+_maxSizePretty}</strong>  &bull; Includes JSON files &amp; JPG, GIF, MP4, WAV Etc
																	<span className="req">*</span>
																</label>
																<div className="custom-file">
																	<input type="file" className="form-control" accept=".zip" id="importFileMedia" name="importFileMedia"  title=" " placeholder=" " onChange={this.handleChangeFileDirect} />
																</div>
															</div>
														</div>

													</>

												)
												:
												(<></>)
											}

											{
												this.state.importType === 2 ?
												(
													<>

														{/* Cloud based based upload - Provide link to google drive etc */}

														<div className="col-12 mb-3">
															<div className="form-group">
																<label htmlFor="importURLMedia" className="pb-2"><strong>Collection Media ZIP file  URL</strong>  &bull; Includes JSON files &amp; JPG, GIF, MP4, WAV Etc
																	<span className="req">*</span>
																</label>
																<input type="text" className="form-control" name="importURLMedia" id="importURLMedia" autoComplete="off" placeholder="e.g. https://cloudfile.com/file=4098 or ftp://server.com/media.zip" onKeyUp={this.handleChangeFileCloud} defaultValue={this.state.importURLMedia} />
															</div>
														</div>



													</>
												)
												:
												(<></>)
											}





											{/* <div className="errorMessages card mt-5 col-12 p-0 border hid nohover"></div> */}
											<div className="errorMessages card mt-5 col-12 p-0 border hid nohover"></div>

										</form>

									</div>
									)
									:
									(
										<div className="p-0 text-center">

											<p className="mb-3" style={{"fontSize":"130%", "fontWeight":"bold"}}>Uploading Now - Do not close your browser</p>

											<div className="progress" id="progress-media" style={{"height":"35px"}}>
												<div className={styleMedia} role="progressbar" style={{width:_progressWidthMedia, minWidth:"20%"}} aria-valuenow={this.state.uploadPercent} aria-valuemin="0" aria-valuemax={100}>{this.state.uploadPercent}% of { (this.state.fileSize / 1024 / 1024).toFixed(2) } MB</div>
											</div>

											<p className="text-center">Estimated time remaining:-<br /><strong>{this.state.timeRemaining}</strong></p>

										</div>
									)
								}


							</div>
						</div>
						<div className="modal-footer modal-footer--sticky">
							<div className="row  w-100">
								<div className="col-12">
									<button className="btn w-100 mt-3 mt-sm-4" id="save-button" disabled={this.state.uploading || this.state.buttonDisabled} type="submit" onClick={this.handleSubmit}>Save & Begin Media Upload</button>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
        );
    }
}

export default ModalBulkUpload;

