import axios from 'axios';
import { toDaysMinutesSeconds } from '../components/xutils';

var requestedPayloads;
var requestedPayloadUUIDs;
var requestedPayloadTimer;
var expirePayloadAt;
var timerInterval, timerExpiry;
var txnTypeToCheck;
var xummSockets = [];
var reloadFunction = '?';

window.$(document).on("click", "#xummModalClose", function (event) {
	closeTimers(false)
});

window.$(document).on("click", ".xummShowQR", function (event) {

	//console.log(event.currentTarget.dataset.code)
	const elem = window.$(`#code-${event.currentTarget.dataset.code}`)

	elem.find(".code img").removeClass("hid")
	elem.find("button, svg, i").hide()
	elem.find(".status").html('Waiting for response')
});


export function checkXummLoggedIn (e)
{

	if(window.config.userData.sid > 0)
	{
		/* Already logged in */
		return {continue:true}
	}
	else
	{
		e.preventDefault();

		var _redirect
		if( e.currentTarget.dataset.href )
		{
			_redirect = e.currentTarget.dataset.href
		}
		else
		{
			_redirect = e.currentTarget.href ? e.currentTarget.href : window.location.href;
		}

		/* Replace Colons in url */
		var _redirectFull = _redirect.replace ( /(\/[0-9a-zA-Z]{32})\:([0-9]{13})/gm, "$1%3A$2")

		addXummPayload({"NFTokenIDs":[], "OfferIDs":[], "OfferTypes":[], "type":"SignIn", "redirect":_redirectFull, "values":{} })

		return {continue:false}

	}


}

export function addXummPayload (e)
{

	/* Reset for new popup */
	var request
	requestedPayloads = {};
	requestedPayloadUUIDs = [];
	requestedPayloadTimer = null;
	expirePayloadAt = null;

	window.$('body,html,#root').addClass("no-overflow");
	window.$('#xummModalFooter').html("")

	try
	{
		window.showWaitMessage("Submitting Payload to Xaman", false, true)

		if( ! e.currentTarget && ! e.target)
		{
			/* Being passed a data structure */
			request = {
				"NFTokenIDs"	: e.NFTokenIDs,
				"OfferIDs"		: e.OfferIDs,
				"OfferTypes"	: e.OfferTypes,
				"Type"			: e.type,
				"Values"		: e.values,
				"Redirect"		: e.redirect,
				"callbackButton": e.callbackButton
			}

		}
		else
		{

			/* Depends if being called from a setTimeout which target we'll have */
			const theTarget  = e.currentTarget ? e.currentTarget.dataset : e.target.dataset ;

			/* Being passed an event object */
			request = {
				"NFTokenIDs"	: theTarget.nftokenids ? theTarget.nftokenids.indexOf("[") > -1 ? JSON.parse(theTarget.nftokenids) : [theTarget.nftokenids] : [],
				"OfferIDs"		: theTarget.offers ? theTarget.offers.indexOf("[") > -1 ? JSON.parse(theTarget.offers) : [theTarget.offers] : [],
				"OfferTypes"	: theTarget.buysell ? theTarget.buysell.indexOf("[") > -1 ? JSON.parse(theTarget.buysell) : [theTarget.buysell] : [],
				"Type"			: theTarget.type,
				"Redirect"		: theTarget.redirect,
				"Values"		: {},
				"callbackButton": theTarget.callbackbutton
			}
		}

		txnTypeToCheck = request.Type;

		/* Use API for the NFTs as they go into our discovery pot */
		const BASE_URL = window.config.apiServer + "/com/external/xumm.cfc";
		const formData = new FormData();

		const _redirect = typeof(request.Redirect) != "undefined" && request.Redirect.length ? request.Redirect : document.location.href;
		const _callbackButton = typeof(request.callbackButton) !== "undefined" ? request.callbackButton : "";
		const _doWhat = _callbackButton.length ? "click:" + _callbackButton : _redirect;

		reloadFunction = _doWhat;

		formData.append("Type", request.Type);
		formData.append("NFTokenIDs", JSON.stringify(request.NFTokenIDs));
		formData.append("OfferIDs", JSON.stringify(request.OfferIDs));
		formData.append("OfferTypes", JSON.stringify(request.OfferTypes));
		formData.append("Values", JSON.stringify(request.Values));
		formData.append("Redirect", _doWhat);
		formData.append("method", "createPayload");

		axios.post(BASE_URL, formData, { withCredentials: true })
			.then(res => {

				if( ! res.data.success)
				{
					throw({message:res.data.errorMessage});
				}
				else
				{
					window.timerExpiry = res.data.expiry
					requestedPayloads = res.data.payloads; /* NO VAR */
					var _html = '';

					window.$('#xummModalCodes').empty();

					var number = 1
					for (const [key, payload] of Object.entries(requestedPayloads)) {
						requestedPayloadUUIDs.push(key)

						xummSockets.push(new WebSocket(payload.refs.websocket_status));

						if(window.config.isMobile)
						{
							if(request.Type === 'SignIn')
							{
								_html += `

								<div class="xummCode text-center p-2 col-12 col-md-3" id="code-${payload.uuid}">
									<div class="name ellipsis text-center primary py-2">
										<button type="button" class="btn btn-sm mb-3 w-100 launchXumm" data-payload="${payload.uuid}" data-txntype="${txnTypeToCheck}" >Launch Xaman App</button>
									</div>
									<div class="code">
										<img class="qr" src="${payload.refs.qr_png}" id="${expirePayloadAt}" title="QR Code" />
										<i class="fas fa-sync fa-spin hid" style="color:darkgrey; font-size:90px"></i>
									</div>
									<div class="status">
										Opening Xaman
									</div>

								</div>`
							}
							else if(request.Type !== 'SignIn')
							{
								_html += `

								<div class="xummCode text-center p-2 col-12 col-md-3" id="code-${payload.uuid}">
									<div class="name ellipsis text-center primary py-2">
										${payload.title ? number + '. ' + payload.title : "" }
									</div>
									<div class="code">
										<img class="qr ${window.config.isMobile || request.Type === 'SignIn' ? '' : 'hid'}" src="${payload.refs.qr_png}" id="${expirePayloadAt}" title="QR Code" />
										<i class="fas fa-sync fa-spin ${window.config.isMobile || request.Type === 'SignIn' ? 'hid' : ''}" style="color:darkgrey; font-size:90px"></i>
									</div>
									<div class="status">
										Opening Xaman
									</div>

								</div>`
							}
						}

						else
						{
							_html += `

							<div class="xummCode text-center p-2 col-12 col-md-3" id="code-${payload.uuid}">
								<div class="name ellipsis text-center primary py-2">
									${payload.title ? number + '. ' + payload.title : "" }
								</div>
								<div class="code">
									<img class="qr ${request.Type === 'SignIn' ? '' : 'hid'}" src="${payload.refs.qr_png}" id="${expirePayloadAt}" title="QR Code" />
									<i class="fas fa-sync fa-spin ${request.Type === 'SignIn' ? 'hid' : ''}" style="color:darkgrey; font-size:90px"></i>
								</div>
								<div class="status">
									${ request.Type === 'SignIn' ? "" : `Waiting for response<br><button class="btn btn-primary btn-sm w-100 xummShowQR mt-3" data-code="${payload.uuid}">Show QR Code</button>` }
								</div>

							</div>`
						}


						number++

					}

					setupSockets()

					window.$('#xummModalCodes').append(_html);



					expirePayloadAt = Number(res.data.expiry); /* NO VAR */

					var payload = requestedPayloads[ requestedPayloadUUIDs[0] ];

					if( window.config.isMobile)
					{


						//window.$('#xummModalCodes').hide()
						window.$('#xummModal').fadeIn();
						window.$('.xummCode .status').html(``)

						if(request.Type === 'SignIn')
						{
							/* Add QR Codes to the modal */
							//window.$('#xummModalMessage').html(`<p>Use</p>`);

						}
						else
						{
							/* Add QR Codes to the modal */
							window.$('#xummModalMessage').html(`<p>Xaman will now open on your device. Come back here to continue.</p>`);

							/* Add spinning icon */
							window.$('.xummCode .code').html('<i class="fas fa-sync fa-spin" style="color:darkgrey; font-size:90px"></i>')

							window.setTimeout( () => { openXUMMApp(payload.uuid); }, 2500 )
							window.setTimeout( () => { requestedPayloadTimer = window.setInterval( () => { checkPayload(txnTypeToCheck); }, 2500 ) }, 4500 )

							window.timerInterval = window.setInterval( () => { countdown(); }, 1000 )


						}


					}
					else
					{

						/* Insert codes into page */
						window.$('#xummModalCodes').show()

						/* Add QR Codes to the modal */
						if(request.Type !== 'SignIn')
						{
							window.$('#xummModalMessage').html(`<p>You need to open the Xaman App, go to the events tab, verify & approve any xMart transactions.<br>(Info: You may need to drag down to refresh the events list)</p>`);
						}
						else
						{
							window.$('#xummModalMessage').html(`<p>Use the Xaman App on your mobile to scan the QR code</p>`);

						}
						window.$('#xummModal').fadeIn();

						window.setTimeout( () => {
							requestedPayloadTimer = window.setInterval( () => { checkPayload(txnTypeToCheck); }, 2500 )
						}, 1000 )

						window.timerInterval = window.setInterval( () => { countdown(); }, 1000 )


					}

					window.hideWaitMessage()

				}
			})
		.catch(err =>
				{
					window.hideWaitMessage();
					window.displayErrorMessage("Error adding payload - " + err.message); closeTimers() }
		)
	}
	catch(e)
	{
		console.log(e);
	}

}

window.$(document).on("click", ".launchXumm", function (event) {
	const payloadUUID = event.currentTarget.dataset.payload;
	const txnTypeToCheck = event.currentTarget.dataset.txntype;

	window.$(".xummCode .qr").addClass("hid")
	window.$(".xummCode .fa-spin").removeClass("hid")

	/* Start timer decreasing */
	window.timerInterval = window.setInterval( () => { countdown(); }, 1000 )

	/* Start payload checker running */
	window.setTimeout( () => {
		requestedPayloadTimer = window.setInterval( () => { checkPayload(txnTypeToCheck); }, 2500 )
	}, 1000 )

	openXUMMApp(payloadUUID)

});

function checkPayload(checkWhat)
{

	var currentEpoch = Math.round(new Date().getTime() / 1000)

	if(currentEpoch >= expirePayloadAt)
	{
		closeTimers(true)
		window.toast("Xaman authentication has expired", "middle", 5000);
	}
	else
	{

		/* Use API for the NFTs as they go into our discovery pot */
		const BASE_URL = window.config.apiServer + "/com/external/xumm.cfc";
		const formData = new FormData();

		formData.append("uuids", JSON.stringify(requestedPayloadUUIDs));
		formData.append("checkWhat", checkWhat);
		formData.append("method", "payloadResults");


		axios.post(BASE_URL, formData, { withCredentials: true })
			.then(res => {

				var allDone = false;
				var errored = false;
				var loginRequired = false;
				var loginPayload = {};

				if( ! res.data.success)
				{
					allDone = true;
					terminateWithError(res.data.errorMessage)
				}
				else
				{

					for (const [uuid, payload] of Object.entries(res.data.results)) {

						/* Update status */
						var elem = window.$(`#code-${uuid}`)

						if(payload.accepted)
						{

							elem.find(".status").html( "COMPLETED" ).addClass("success")
							elem.find("img").addClass( "blurred" )
							elem.find(".code").html(`<i class="fas fa-check" style="font-size:90px"></i>`).addClass("success")

							/* Remove entry from UUIDS */
							requestedPayloadUUIDs.splice( requestedPayloadUUIDs.indexOf(uuid), 1);

							if(payload.authKey && payload.authKey.length)
							{
								loginRequired = true;
								loginPayload = payload;
							}
						}
						else if(payload.error)
						{
							if( Object.entries(requestedPayloads).length === 1 )
							{
								terminateWithError(payload.errorMessage)
							}
							else
							{
								elem.find(".status").html( payload.errorMessage ).addClass("danger")
								elem.find("img").addClass( "blurred" )
								elem.find(".code").html(`<i class="fas fa-exclamation-triangle" style="font-size:90px"></i>`).addClass("danger")


								/* Remove entry from UUIDS */
								requestedPayloadUUIDs.splice( requestedPayloadUUIDs.indexOf(uuid), 1);
							}
						}
						else if(payload.rejected)
						{
							if( Object.entries(requestedPayloads).length === 1 )
							{
								terminateWithError("Authorization rejected.")
							}
							else
							{
								elem.find(".status").html( "DECLINED" ).addClass("danger")
								elem.find("img").addClass( "blurred" )

								/* Remove entry from UUIDS */
								requestedPayloadUUIDs.splice( requestedPayloadUUIDs.indexOf(uuid), 1);
							}
						}


					  }

				}

				if(requestedPayloadUUIDs.length === 0)
				{
					closeTimers(true)
					if(loginRequired)
					{
						doSignIn(loginPayload.authKey, loginPayload.address)
					}
					window.setTimeout( () =>
					{
						window.$('#xummModal').fadeOut(1000);
					}, 1000)
				}

			})
		.catch(err =>
				{
					console.log(err)
					window.hideWaitMessage()
				}
		)
	}

}

function doSignIn(authkey, address)
{
	/* Use API for the NFTs as they go into our discovery pot */
	const BASE_URL = window.config.apiServer + "/com/external/xumm.cfc";
	const formData = new FormData();

	formData.append("authkey", authkey);
	formData.append("address", address);
	formData.append("method", "signIn");

	axios.post(BASE_URL, formData, { withCredentials: true })
		.then(res => {

			if( ! res.data.success)
			{
				throw({"message":res.data.errorMessage})
			}
			else
			{

				var elem = window.$(`.xummCode`);

				window.$('.xummCode .code').html('<i class="fas fa-check" style="font-size:90px"></i>').addClass("success")

				elem.find(".status").html( "Signed In! Reloading." )
				elem.find("img").addClass( "blurred" )

				window.location.reload();

			}

		})
	.catch(err =>
			{ window.hideWaitMessage()	; window.displayErrorMessage("Error signing in - " + err.message); }
	)
}

function terminateWithError(error)
{

	closeTimers(false)

	window.Swal.fire({
		title: 'Xaman Error',
		html: error,
		showCancelButton: false,
		allowOutsideClick: false,
		allowEscapeKey: false,
		didOpen: () => {

		},
		willClose: () => {
			//clearInterval(timerInterval)
			window.hideWaitMessage();
			window.showWaitMessage("Reloading page data..", false, false);
			window.location.reload()
		}
	})

}

function openXUMMApp(payloadUUID) {

	var xummUrl = `xumm://xumm.app/sign/${payloadUUID}/deeplink`;
	// console.log("Launch " + xummUrl)

	try
	{
		window.location = xummUrl;
	}
	catch(e)
	{
		alert("Error launching Xaman")
	}

	/* Add spinning icon */
	window.$('.xummCode .status').html('Waiting for response')

  }

function countdown()
{
	const timerSecondsLeft = Number(window.timerExpiry) - Number(new Date().getTime().toString().substring(0,10)) + 2;
	window.$('#xummModalFooter').html(` ${toDaysMinutesSeconds( timerSecondsLeft, true, true, false ) } - ${requestedPayloadUUIDs.length} to Approve`)
}

function closeTimers(doReload)
{

	// document.body.scrollTop = document.documentElement.scrollTop = 0;

	closeSockets()

	window.clearInterval(requestedPayloadTimer);
	window.clearInterval(window.timerInterval);
	window.$('body,html,#root').removeClass("no-overflow");

	if(reloadFunction.substring(0,6) === 'click:')
		{
			window.setTimeout(() =>
			{
				window.showWaitMessage("Completing Transaction", false, false);
			}, 100);

			/* Trigger reload click */
			window.setTimeout(() =>
			{
				window.$('#xummModal').fadeOut();
				const theElement = document.getElementById(reloadFunction.substring(6));
				theElement.click()
					window.hideWaitMessage();
			}, 2500);

		}
	else if(doReload)
	{


		window.setTimeout(() =>
		{

			window.showWaitMessage("Completing Transaction", false, false);
		}, 100);

		window.setTimeout(() =>
		{

			window.$('#xummModal').fadeOut();

			var reloaders = window.$('.xummReloader')
			if(reloaders.length)
			{
				reloaders.trigger("click");
				window.hideWaitMessage();
			}
			else
			{
				document.location.reload()
			}
		}, 2500);

	}
	else
	{
		/* Small delay so we see the tick box */
		window.setTimeout(() => { window.$('#xummModal').fadeOut(); }, 400);

	}

}

function setupSockets()
{

	for(var socketNumber = 0; socketNumber < xummSockets.length; socketNumber++)
	{
		const socket = xummSockets[socketNumber];
		socket.addEventListener('message', (event) => {

			if(event.type && event.type === 'message')
			{

				const eventUUID = event.currentTarget.url.split("/")[4];
				const data = JSON.parse(event.data);

				//console.log('Received data:', data);
				//const payload = requestedPayloads[eventUUID];

				const elem = window.$(`#code-${eventUUID}`)

				// Handle XRP ledger updates
				if (data.opened_by_deeplink) {
				//console.log("----> Deeplink: " + data.opened_by_deeplink)
				// Do something with the account info
				}

				if (typeof data.signed != "undefined") {

					if(data.signed)
					{
						elem.find('.code').html('<i class="fas fa-pen-alt" style="color:darkgrey; font-size:90px"></i>')
						elem.find("button").hide()
						elem.find(".status").html('Signed').addClass("success")
					}
					else
					{
						elem.find('.code').html('<i class="fas fa-ban" style="color:red; font-size:90px"></i>')
						elem.find("button").hide()
						elem.find(".status").html('DECLINED').addClass("danger")
					}

					//console.log("----> Signed value: " + data.signed)
				// Do something with the account info
				}

				if (data.opened) {
					elem.find('.code').html('<i class="far fa-envelope-open" style="color:darkgrey; font-size:90px"></i>')
					elem.find("button").hide()
					elem.find(".status").html('Opened').addClass("info")

				// Do something with the account info
				}


			}
		});

	}


}


function closeSockets()
{
	for(var socketNumber = 0; socketNumber < xummSockets.length; socketNumber++)
	{
		const socket = xummSockets[socketNumber];
		socket.close()
	}
}
