import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// importing all the pages
import MainV2 from "../pages/mainV2";
import Category from "../pages/categories/explore";
import Auctions from "../pages/auctions";

import ViewNFT from "../pages/NFT/view";

import Author from "../pages/Author/Main";
import AuthorNFTs from "../pages/Author/NFTs";

import AuthorProfileCrud from "../pages/Author/crud";
import AuthorBalances from "../pages/Author/balances";
import AuthorOffers from "../pages/Author/offers-and-nfts";
import AuthorTasks from "../pages/Author/tasks";
import AuthorCollections from "../pages/Author/collections";

import WalletConnect from "../pages/wallet-connect";
import Contact from "../pages/Pages/contact-us";

import CollectionView from "../pages/collections/view";
import CollectionCrud from "../pages/collections/crud";

import LatestNFTs from "../pages/NFT/latest";

import ToolsNoWallet from "../pages/tools/nowallet";
import ToolsPhysical from "../pages/tools/physical";
import ToolsSwap from "../pages/tools/swap";
import ToolsViewer from "../pages/tools/viewer";
import ToolsBulkSpins from "../pages/tools/bulk-spins";
import ToolsMinterCheck from "../pages/tools/minter-check";

import Store from '../pages/store.js';
import StoreCheckout from '../pages/storeCheckout.js';

import PagesHelp from '../pages/Pages/help-center';
import PagesAbout from '../pages/Pages/about-us';
import PagesContact from '../pages/Pages/contact-us';
import PagesTerms from '../pages/Pages/terms-of-service';
import PagesPrivacy from '../pages/Pages/privacy-policy';

import NotFound from '../pages/404';
import MaintenanceMode from '../pages/maintenance';



class MyRoutes extends React.Component {

	state = { config:{} }

	componentDidMount(){
        this.setState({
            config: this.props.config
        })
    }

	render()
	{

		var showPage = this.props.config.rs[0] === 0 ? true : false;

		/* Allow to log in */
		if(document.location.search.indexOf("opensesame") > -1)
		{
			showPage = true;
		}

		/* Superuser */
		if( this.props.config.userData.display )
		{
			showPage = true;
		}

		const isThisMyCollection = this.props.config.urlParts.length >= 3 && this.props.config.urlParts[2] === this.props.config.userData.urlPath;

		const _pageTitle = isThisMyCollection ? "My NFTs" : "Items Purchased";
		const _SellingTitle = isThisMyCollection ? "Items I'm Selling" : "Items for sale by user";
		const _createdTitle = isThisMyCollection ? "Items You Have Created" : "Items Created by user";



		return (

		<div>

			{  showPage === true ?
			(
				<Router>

					<Switch>

						<Route exact path="/"												render={(match) => <MainV2 				config={ this.props.config } />} />

						/* Redirects */
						<Route exact path="/collections/tbbob/detective-tiger/"				render={(match) => <Redirect to="/collections/tbbob/tigers/" 		config={ this.props.config } />} />

						/* Site pages */
						<Route exact path="/category/:tag/"									render={(match) => <Category 			config={ this.props.config }	tag={match.match.params.tag} />} />

						<Route exact path="/collections/featured"							render={(match) => <Category 			config={ this.props.config }	tag={"featured"} />} />
						<Route exact path="/collections/minting-now"						render={(match) => <Category 			config={ this.props.config }	tag={"mintingnow"} />} />

						<Route exact path="/collections/:who/:collectionID/browse-all"		render={(match) => <CollectionView		config={ this.props.config }	who={match.match.params.who}	collectionID={match.match.params.collectionID} />} />
						<Route exact path="/collections/:who/:collectionID"					render={(match) => <CollectionView 		config={ this.props.config }	who={match.match.params.who}	collectionID={match.match.params.collectionID} />} />

						<Route exact path="/auctions"										render={(match) => <Auctions		 	config={ this.props.config } />} />
						<Route exact path="/contact" 										render={(match) => <Contact 			config={ this.props.config } />} />
						<Route exact path="/wallet-connect"									render={(match) => <WalletConnect 		config={ this.props.config } />} />

						<Route exact path="/nfts/latest-mints"								render={(match) => <LatestNFTs 			config={ this.props.config } 	dataType="minted" 			showDefaultSortOption={true}	title="Recently Minted NFTs on xMart"		collectionID=""	 />} />
						<Route exact path="/nfts/latest-mints-xrpl"							render={(match) => <LatestNFTs 			config={ this.props.config }	dataType="mintedxrpl"		showDefaultSortOption={true}	title="Recently Minted NFTs on the XRPL"	collectionID=""	 />} />
						<Route exact path="/nfts/for-sale" 									render={(match) => <LatestNFTs 			config={ this.props.config } 	dataType="forSale" 			showDefaultSortOption={true}	title="Recently Listed For Sale"			collectionID="" />} />
						<Route exact path="/nfts/latest-sales"								render={(match) => <LatestNFTs 			config={ this.props.config }	dataType="recentSales" 		showDefaultSortOption={true}	title="Recent NFT Sales"					collectionID=""	 />} />

						<Route exact path="/view-nft/:nft" 									render={(match) => <ViewNFT 			config={ this.props.config }	nft={match.match.params.nft} />} />

						<Route exact path="/pages/help-center" 								render={(match) => <PagesHelp 			config={ this.props.config } />} />
						<Route exact path="/pages/about-us" 								render={(match) => <PagesAbout 			config={ this.props.config } />} />
						<Route exact path="/pages/contact-us" 								render={(match) => <PagesContact 		config={ this.props.config } />} />
						<Route exact path="/pages/privacy-policy" 							render={(match) => <PagesPrivacy 		config={ this.props.config } />} />
						<Route exact path="/pages/terms-of-service" 						render={(match) => <PagesTerms 			config={ this.props.config } />} />


						<Route exact path="/profile/:who" 									render={(match) => <AuthorCollections	config={ this.props.config }	who={match.match.params.who}	function={"purchased"} 			title={_pageTitle} />} />
						{/* <Route exact path="/profile/:who" 									render={(match) => <AuthorNFTs			config={ this.props.config }	who={match.match.params.who}	function={"purchased"} 			title={_pageTitle} />} /> */}

						<Route exact path="/profile/:who/created" 							render={(match) => <AuthorNFTs 			config={ this.props.config }	who={match.match.params.who}	function={"created"}			title={_createdTitle} />} />
						<Route exact path="/profile/:who/selling" 							render={(match) => <AuthorNFTs 			config={ this.props.config }	who={match.match.params.who}	function={"selling"}			title={_SellingTitle} />} />
						<Route exact path="/profile/:who/offers" 							render={(match) => <AuthorNFTs 			config={ this.props.config }	who={match.match.params.who}	function={"offers"}				title="Items I've Offered to Buy" />} />
						<Route exact path="/profile/:who/offers-received" 					render={(match) => <AuthorNFTs 			config={ this.props.config }	who={match.match.params.who}	function={"offersReceived"}		title="Offers Received From Buyers" />} />

						<Route exact path="/profile/:who/collections" 						render={(match) => <Author 				config={ this.props.config }	who={match.match.params.who} />} />

						{/*
						<Route exact path="/profile/:who/create-nft/" 						render={(match) => <CreateNFT 			config={ this.props.config }	who={match.match.params.who} />} />
						<Route exact path="/profile/:who/edit-nft/:nft" 					render={(match) => <CreateNFT 			config={ this.props.config }	who={match.match.params.who}	nft={match.match.params.nft} />} />
						*/}
						<Route exact path="/profile/:who/view-nft/:nft" 					render={(match) => <ViewNFT 			config={ this.props.config }	who={match.match.params.who}	nft={match.match.params.nft} />} />
						<Route exact path="/profile/:who/balances" 							render={(match) => <AuthorBalances 		config={ this.props.config }	who={match.match.params.who} />} />
						<Route exact path="/profile/:who/wallet-offers" 					render={(match) => <AuthorOffers 		config={ this.props.config }	who={match.match.params.who} />} />
						<Route exact path="/profile/:who/edit" 								render={(match) => <AuthorProfileCrud 	config={ this.props.config }	who={match.match.params.who} />} />
						<Route exact path="/profile/:who/tasks" 							render={(match) => <AuthorTasks 		config={ this.props.config }	who={match.match.params.who} />} />

						<Route exact path="/profile/:who/collections/add" 					render={(match) => <CollectionCrud 		config={ this.props.config }	who={match.match.params.who} 	collectionID={""} />} />
						<Route exact path="/profile/:who/collections/:collectionID/edit"	render={(match) => <CollectionCrud 		config={ this.props.config }	who={match.match.params.who}	collectionID={match.match.params.collectionID} />} />
						<Route exact path="/profile/:who/collections/:collectionID"			render={(match) => <CollectionView 		config={ this.props.config }	who={match.match.params.who}	collectionID={match.match.params.collectionID} />} />

						<Route exact path="/tools/nowallet"									render={(match) => <ToolsNoWallet 		config={ this.props.config } />} />
						<Route exact path="/tools/nowallet/:walletAddress"					render={(match) => <ToolsNoWallet 		config={ this.props.config }	walletAddress={match.match.params.walletAddress} />} />

						{ /* To do  */}
						<Route exact path="/tools/bulk-spins"								render={(match) => <ToolsBulkSpins 			config={ this.props.config } />} />
						<Route exact path="/tools/viewer/:address"							render={(match) => <ToolsViewer 			config={ this.props.config } />} />
						<Route exact path="/tools/viewer"									render={(match) => <ToolsViewer 			config={ this.props.config } />} />
						<Route exact path="/tools/minter-check"								render={(match) => <ToolsMinterCheck 		config={ this.props.config } />} />


						{/*
						<Route exact path="/store"											render={(match) => <Store				 	config={ this.props.config } />} />
						<Route exact path="/store/checkout"									render={(match) => <StoreCheckout		 	config={ this.props.config } />} />
						*/}



						<Route exact path="/tools/swap"										render={(match) => <ToolsSwap 			config={ this.props.config } />} />
						<Route exact path="/tools/physical"									render={(match) => <ToolsPhysical 		config={ this.props.config } />} />

						<Route path="*" status={404}										render={(match) => <NotFound 			config={ this.props.config } />} />
					</Switch>
				</Router>
			)
			:
			(
			<>
				<MaintenanceMode 			config={ this.props.config } />
			</>)
			}



				{/*
				<Route exact path="/activity" component={Activity} />
				<Route exact path="/blog" component={Blog} />
				<Route exact path="/blog-single" component={BlogSingle} />
				<Route exact path="/authors" component={Authors} />
				<Route exact path="/author" component={Author} />
				<Route exact path="/login" component={Login} />
				<Route exact path="/signup" component={Signup} />
				*/}

		</div>
		);
	}
}
export default MyRoutes;