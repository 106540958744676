import React, { Component } from 'react';
import axios from 'axios';
import { shortDate } from '../xutils';
import { CurrencyPrice } from '../currencyPrice';
import { CurrencyFees } from '../currencyFees';
import { VideoLink } from '../../components/videoLink'
import { elementInside } from 'dropzone';
import { addXummPayload } from '../../js/xumm';


class ModalViewNFTButtons extends Component
{

	launchCRUD = e =>
	{
		e.preventDefault()
		var _cid = typeof e.currentTarget.dataset.uuid !== 'undefined' ? e.currentTarget.dataset.uuid : "";

		if(_cid == '')
		{
			window.displayErrorMessage("Invalid NFT ID");
		}
		else
		{
			document.getElementById("crud-nft-loader-button").dataset.nft = _cid;
			document.getElementById("crud-nft-loader-button").click()
		}
	}

	doPayload = e =>
	{

		var values = {};
		for (const [key, config] of Object.entries(this.state.formData[this.state.chosenTab]))
		{
			if(typeof config === 'object' )
			{
				values[key] = config.value;
			}
		}

		var _offerIDs = [];
		var _offerTypes = [];

		if(this.state.chosenTab === 'buyNFT')
		{
			/* We're buying an NFT from a sell offer */
			_offerIDs = [this.state.buyOfferNetwork];
			_offerTypes = ["sell"]
		}

		else if(this.state.chosenTab === 'cancelsell')
		{
			_offerIDs = [this.state.cancelOfferIDNetwork];
			_offerTypes = ["sell"]
		}

		else if(this.state.chosenTab === 'offerAccept')
		{
			_offerIDs = [this.state.acceptOfferIDNetwork];

			_offerTypes = ["buy"]

		}

		else if(this.state.chosenTab === 'makeOfferToBuy')
		{
			_offerIDs = [];
			_offerTypes = ["buy"]
		}

		else if(this.state.chosenTab === 'sell')
		{
			_offerIDs = [];
			_offerTypes = ["sell"]
		}

		else if(this.state.chosenTab === 'transfer')
		{
			_offerIDs = [];
			_offerTypes = ["sell"]
		}

		else

		{
			alert(`[${this.state.chosenTab}] Not handled in doPayload()`)
			return false
		}


		if(e.currentTarget.dataset.type === 'NFTokenCancelOffer')
		{
			_offerIDs = [this.state.cancelOfferIDNetwork];
			_offerTypes = [this.state.formData[this.state.chosenTab].offerType]
		}
		else if(e.currentTarget.dataset.type === 'NFTokenCancelBuyOffer')
		{
			e.currentTarget.dataset.type = "NFTokenCancelOfferBulk"
			_offerIDs = [this.state.acceptOfferIDNetwork];
			_offerTypes = ["buy"]
		}



		var _request = { "type":e.currentTarget.dataset.type, "OfferIDs":_offerIDs, "OfferTypes":_offerTypes, "NFTokenIDs":[this.props.state.nft.mintInfo.address], "values":values };

		if(this.state.formData[this.state.chosenTab].offerType)
		{
			_request.OfferTypes = _offerTypes;
		}




		addXummPayload( _request )

	}

	cancelMint = e =>
	{

		window.Swal.fire({
			title: 'Cancel Mint?',
			html:"<p>This will not delete this NFT but will instead allow you to approve the transaction again in Xumm.</p><p>Do you want to cancel it and start again?</p>",
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: 'Yes',
			denyButtonText: 'No'
			}).then((result) => {
			if (result.isConfirmed) {

				var _request = {
					component:		"post",
					method:			"cancelMint",
					uuid:			this.props.state.nft.id,
					redirect:		this.props.state.nft.URI
				}

				window.callAPI(
					_request,
					function (_jsonData)
					{
						if(_jsonData.success)
						{

							window.toast("Mint Cancelled", "middle")
							window.$('.xummReloader').trigger("click");
						}
						else
						{
							window.displayErrorMessage("Mint error - " + _jsonData.errorMessage)
						}
					}
				)
			} else if (result.isDenied) {
				// Do nothing
			}
			})


	}

	constructor(props) {
		super(props);
		this.state = {
						props:this.props,
						chosenTab:"",
						submitEnabled:false,
						submitErrors:[],
						cancelOfferID:"",
						cancelOfferIDNetwork:"",
						acceptOfferIDNetwork:"",
						buyOfferNetwork:"",
						formData:{
									archive: {
										confirmation:
										{
														value:"",
														default:"",
														validation:"_theValue === _element.dataset.word",
														validationMessage:"Please enter the correct validation message"
										},
										acceptCheck:
										{
														value:"",
														default:"0",
														checkedValue:1,
														validation:"_element.checked",
														validationMessage:"Please confirm this is what you want to do"
										},
										title:"Archive This NFT",
										button:this.props.state.nft.mintedQty ? "Approve in Wallet" : "Archive NFT",
										walletFunction:"_ArchiveNFT"

									},
									mint: {
										royalty:
										{
														value:"",
														default:Number(this.props.state.nft.collection.defaultRoyalty) > 0 ? this.props.state.nft.collection.defaultRoyalty : "",
														validationTransform:"Number(_theValue)",
														validation:`_theValue >= ${this.props.config.fees.minimumRoyaltyPercent} && _theValue <= ${this.props.config.fees.maximumRoyaltyPercent}`,
														validationMessage:`Please enter a royalty between ${this.props.config.fees.minimumRoyaltyPercent} & ${this.props.config.fees.maximumRoyaltyPercent}%`
										},
										acceptCheck:
										{
														value:"",
														default:1,
														checkedValue:1,
														validation:"_element.checked",
														validationMessage:"Please confirm this is what you want to do"
										},
										total:
										{
														value:"",
														default:"",
														validationTransform:"",
														validation:``,
														validationMessage:""
										},
										UUID:
										{
														value:this.props.state.nft.id,
														default:"",
														validationTransform:"",
														validation:``,
														validationMessage:""
										},
										title:"Mint This NFT",
										button:"Approve in Wallet",
										showDecline:true
									},
									offerAccept: {
										offerPrice:
										{
														value:"",
														default:"",
														validationTransform:"",
														validation:"",
														validationMessage:""
										},
										offerIDNetwork:
										{
														value:"",
														default:"",
														validationTransform:"",
														validation:"",
														validationMessage:""
										},

										xMartCommission:
										{
											value:"",
											default:"",
											validationTransform:"",
											validation:"",
											validationMessage:""
										},
										hasFunds:
										{
											value:false,
											default:"",
											validationTransform:"",
											validation:"",
											validationMessage:""
										},
										walletAddress:
										{
											value:"",
											default:"",
											validationTransform:"",
											validation:"",
											validationMessage:""
										},
										balance:
										{
											value:"",
											default:"",
											validationTransform:"",
											validation:"",
											validationMessage:""
										},

										ignoreCheck:
										{
														value:"",
														default:1,
														checkedValue:1,
														validation:"_element.checked",
														validationMessage:"Please confirm this is what you want to do"
										},
										acceptCheck:
										{
														value:"",
														default:1,
														checkedValue:1,
														validation:"_element.checked",
														validationMessage:"Please confirm this is what you want to do"
										},
										id:
										{
														value:"",
														default:"",
														validationTransform:"",
														validation:``,
														validationMessage:""
										},
										title:"Accept a Buy Offer",
										button:"Approve in Wallet",
										walletFunction:"NFTokenAcceptOffer"
									},
									sell: {
										sellPrice:
										{
														value:"",
														default:"",
														min:0.00001,
														validationTransform:"Number(_theValue)",
														validation:`_theValue > 0`,
														validationMessage:"Please enter a valid sale price"
										},
										reserved:
										{
														value:"No",
														default:"No"
										},
										walletAddress:
										{
														value:"",
														default:"",
														validation:`window.isValidAddress(_theValue)`,
														validationWhen:"_formData.reserved.value === 'Yes'",
														validationMessage:"Please enter a valid wallet address"
										},

										sellCheckBox:
										{
														value:"",
														default:1,
														checkedValue:1,
														validation:"_element.checked",
														validationMessage:"Please confirm this is what you want to do"
										},
										total:
										{
														value:"",
														default:"",
														validationTransform:"",
														validation:``,
														validationMessage:""
										},
										title:"Sell This NFT",
										button:"Approve in Wallet",
										walletFunction:"NFTokenCreateOffer"
									},
									cancelsell: {
										cancelCheckBox:
										{
														value:"",
														default:1,
														checkedValue:1,
														validation:"_element.checked",
														validationMessage:"Please confirm this is what you want to do"
										},
										title:"Cancel the sale of this NFT",
										button:"Approve in Wallet",
										walletFunction:"NFTokenCancelOffer"

									},
									makeOfferToBuy: {

										balance:
										{
														value:0,
														default:0,
														min:0.00001,
														validationTransform:"Number(_theValue)",
														validation:`Number(_theValue) < 100`,
														validationMessage:"Offer cannot exceed your balance"
										},
										balanceCalculation:
										{
														value:0,
														default:0,
														validationTransform:"Number(_theValue)",
														validation:`Number(_theValue) < 100`,
														validationMessage:"Offer cannot exceed your balance"
										},
										offerPrice:
										{
														value:"",
														default:"",
														validationTransform:"Number(_theValue)",
														validation:``,
														validationMessage:"Please enter a valid sale price"
										},
										askingPriceMinimum:
										{
														value:"",
														default:"200",
														validationTransform:"",
														validation:``,
														validationMessage:""
										},
										offerExpiry:
										{
														value:"",
														default:"",
														validationTransform:"",
														validation:``,
														validationMessage:""
										},
										makeOfferCheck:
										{
														value:"",
														default:1,
														checkedValue:1,
														validation:"_element.checked",
														validationMessage:"Please confirm this is what you want to do"
										},
										total:
										{
														value:"",
														default:"",
														validationTransform:"",
														validation:``,
														validationMessage:""
										},
										title:"Make an Offer to Buy NFT",
										button:"Approve in Wallet",
										walletFunction:"NFTokenCreateOffer"
									},
									buyNFT: {

										askingPrice:
										{
														value:"",
														default:""
										},
										makeOfferCheck:
										{
														value:"",
														default:1,
														checkedValue:1,
														validation:"_element.checked",
														validationMessage:"Please confirm this is what you want to do"
										},
										hasFunds:
										{
											value:false,
											default:"",
											validationTransform:"",
											validation:"",
											validationMessage:""
										},
										external:
										{
											value:false,
											default:"",
											validationTransform:"",
											validation:"",
											validationMessage:""
										},
										externalWho:
										{
											value:"",
											default:"",
											validationTransform:"",
											validation:"",
											validationMessage:""
										},
										externalFee:
										{
											value:0,
											default:"",
											validationTransform:"",
											validation:"",
											validationMessage:""
										},
										externalFeeCap:
										{
											value:0,
											default:"",
											validationTransform:"",
											validation:"",
											validationMessage:""
										},
										total:
										{
														value:"",
														default:"",
														validationTransform:"",
														validation:``,
														validationMessage:""
										},
										ignoreCheck:
										{
														value:"",
														default:1,
														checkedValue:1,
														validation:"_element.checked",
														validationMessage:"Please confirm this is what you want to do"
										},
										buyCheck:
										{
														value:"",
														default:1,
														checkedValue:1,
														validation:"_element.checked",
														validationMessage:"Please confirm this is what you want to do"
										},
										title:"Buy this NFT",
										button:"Approve in Wallet",
										walletFunction:"NFTokenAcceptOffer"
									},
									transfer: {
										sellPrice:
										{
														value:0,
														default:0,
														validationTransform:"",
														validation:"",
														validationMessage:""
										},
										walletAddress:
										{
														value:"",
														default:"",
														validation:`window.isValidAddress(_theValue)`,
														validationWhen:"",
														validationMessage:"Please enter a valid wallet address"
										},

										transferCheckBox:
										{
														value:"",
														default:1,
														checkedValue:1,
														validation:"_element.checked",
														validationMessage:"Please confirm this is what you want to do"
										},
										title:"Transfer This NFT to another wallet",
										button:"Approve in Wallet",
										walletFunction:"NFTokenCreateOffer"
									},



								}
					};
	}

	myCallback = (newData) =>
	{
		/* Update the total in the parent form */
		window.setTimeout( () => {
									this.setState({formData:newData});
									this.formValidationCheck();
								}, 150)
	}

	cancelModal = e =>
	{
		/* Pass tab back to parent - This will re-render this component */
		this.props.callbackFromParent("");
	}

	showModal = e =>
	{

		/* Called by clicking the modal button. Tag is set by check script e.g. mintCheck()
		/* Pass tab back to parent - This will re-render this component */
		var _modalButton = document.getElementById("showModal");

		this.setState({chosenTab:_modalButton.dataset.tag})
		this.props.callbackFromParent(_modalButton.dataset.tag);

		/* We have already set our minter - display the modal */
		window.setTimeout( (chosenTab = _modalButton.dataset.tag) => {

								/* Reset our form */
								var _fd = this.state.formData;

								try{

									const _elements = document.forms["modal-" + chosenTab].querySelectorAll('input,select,textarea');

									for (var i=0; i < _elements.length; i++)
									{
										var _element = _elements[i];
										if(_element.id)
										{
											try{

												var _fieldConfig = this.state.formData[chosenTab][_element.id]
												if(!_fieldConfig) { window.displayErrorMessage("Error getting field config for ["+chosenTab+"] element ["+_element.id+"]"); return false }
												var _theValue = _fieldConfig.default;

												/* Store values */
												_element.value = _theValue
												_fd[chosenTab][_element.id]['value'] = _theValue;

											}
											catch(e)
											{
												/* Field has no default */
											}
										}
									}

									this.setState({formData:_fd})
									this.formValidationCheck()


									/* Scroll to top */
									window.setTimeout( () => {
										if( window.$('#view-full-screen').length )
										{
											document.getElementById("view-full-screen").scrollTop = document.documentElement.scrollTop = 0;
										}
										else
										{
											window.$('.modal-content').animate({ scrollTop: 0 }, 'slow');
										}

									}, 500);

									window.setTimeout( () => { window.initialiseJQ(); }, 150);

								} catch(e) {
										window.displayErrorMessage("Ensure a form exists with the ID [modal-" + chosenTab + "]... Also check that any form field default values reference correct state variables.")
										console.log(e)
										console.log(document.forms)
									}


								}, );

	}

	formValidationCheck = event =>
	{

		if(this.state.formData && this.state.chosenTab)
		{
			var _errors = [];
			var _fields = [];

			/* Update form data */
			if(event)
			{
				var _fd = this.state.formData;
				_fd[this.state.chosenTab][event.currentTarget.id]['value'] = event.currentTarget.value;
				this.setState({formData:_fd})
			}

			/* Delay to allow re-draw */
			window.setTimeout( () =>
			{
				/* Validate form */
				var _elements = document.forms["modal-" + this.state.chosenTab].getElementsByTagName("input");
				var _formData = this.state.formData[this.state.chosenTab]

				for (var i=0; i < _elements.length; i++)
				{

					var _element = _elements[i];

					_element.classList.remove("fieldInvalid");

					if(_element.disabled && _element.type == 'checkbox' )
					{
						_element.checked = false;

					}

					if (_element.required && _element.readOnly === false)
					{

						/* Do we need to transform our field value to something else */
						var _fieldConfig = _formData[_element.id]
						if(!_fieldConfig) { window.displayErrorMessage("Error getting field config for ["+this.state.chosenTab+"] element ["+_element.id+"]"); return false }

						var _theValue = _element.value;
						var _evaluationResult

						var shouldEvaluate = true;
						if(_fieldConfig.validationWhen)
						{
							try{
								shouldEvaluate = eval(_fieldConfig.validationWhen)
							}
							catch(e)
							{
								console.log(e)
							}
						}

						if( ! shouldEvaluate)
						{
							/* Do nothing - Field may be a dependency */
							_evaluationResult = true
						}
						else if(_theValue.length===0)
						{
							/* Fail immediately as the field is blank */
							_evaluationResult = eval( false )
						}
						else
						{

							if(_fieldConfig.validationTransform)
							{
								_theValue = eval(_fieldConfig.validationTransform)
							}

							var _evaluationString = _fieldConfig.validation;
							if(_evaluationString.length)
							{
								_evaluationResult = eval( _evaluationString )
							}
							else
							{
								_evaluationResult = true
							}

						}

						if( ! _evaluationResult )
						{
							_errors.push({"field":_element.id, "message":_fieldConfig.validationMessage, "evs":_evaluationString,  "evr":_evaluationResult, "formValue":_theValue})
							_element.classList.add("fieldInvalid");
						}

						_fields.push({"field":_element.id, "message":_fieldConfig.validationMessage, "evs":_evaluationString,  "evr":_evaluationResult, "formValue":_theValue, "shouldEvaluate":shouldEvaluate})


					}
				}

				this.setState({
								submitErrors:_errors,
								submitEnabled:_errors.length === 0 ? true : false
							})

			}, 300)
		}
	}

	/* FORM SUBMISSIONS */
	/* FORM SUBMISSIONS */
	/* FORM SUBMISSIONS */
	/* FORM SUBMISSIONS */
	/* FORM SUBMISSIONS */
	/* FORM SUBMISSIONS */
	/* FORM SUBMISSIONS */

	submitForm = e =>
	{

		var _request
		this.formValidationCheck()

		if(this.state.submitEnabled)
		{

			window.showWaitMessage(`<p>Opening Authorization</p><p><img src="${this.props.config.sizes.placeholders.preloader}" /></p>`, false, true);

			switch (this.state.chosenTab) {
				case "mint":

					window.showWaitMessage("<p class='fw-bold'>Uploading Media to IPFS</p><p>This may take a minute - Please do not close the browser or leave this page.</p><p>Once the upload has completed, you will connect to your wallet to approve the transaction.</p><p><img src='" + this.props.config.sizes.placeholders.preloader +"' /></p>", false, true);

					_request = {
						component:"post",
						method:"mintNFT",

						uuid:			this.props.state.nft.id,
						lastModified:	this.props.state.nft.updated,
						quantity:1,

						amount:0,
						currency:"",

						royalty:this.state.formData.mint.royalty.value,

						redirect:this.props.state.nft.URI
					}

					window.callAPI(
						_request,
						function (_jsonData)
						{
							if(_jsonData.success)
							{
								window.Swal.close()

								addXummPayload( { "type":"NFTokenMint", "OfferTypes":[""], "OfferIDs":[], "NFTokenIDs":[], "values":{"UUID":_jsonData.uuid, "mint":_jsonData.mint, "royalty":_request.royalty} } )

								//document.location.href = _jsonData.info.redirect;
							}
							else
							{
								window.displayErrorMessage("Mint error - " + _jsonData.errorMessage)
							}
						}
					)

					break;

				case "archive":

					window.showWaitMessage("Archiving NFT", false, true);

					_request = {
						component:"post",
						method:"archiveNFT",
						uuid:this.props.state.nft.id,
						redirect:this.props.state.nft.collection.url
					}

					window.callAPI(
						_request,
						function (_jsonData)
						{
							if(_jsonData.success)
							{
								document.location.href = _jsonData.info.redirect;
							}
							else
							{
								window.displayErrorMessage("Deletion error - " + _jsonData.errorMessage);
							}
						}
					)

					break;


				case "cancelsell":

					alert("Needs converting to xumm v2")
					return false


					_request = {
						component:"post",
						method:"cancelSale",
						uuid:this.props.state.nft.id,
						offerID:this.state.cancelOfferID,
						offerIDNetwork:this.state.cancelOfferIDNetwork,
						redirect:this.props.state.nft.URI
					}

					window.callAPI(
						_request,
						function (_jsonData)
						{

							if(_jsonData.success)
							{
								document.location.href = _jsonData.info.redirect;
							}
							else
							{
								window.displayErrorMessage("Cancellation error - " + _jsonData.errorMessage);
							}
						}
					)

					break;

				case "offerAccept":


				alert("Needs converting to xumm v2")
				return false



				/*debugger;
					if( Number(this.state.formData.offerAccept.xMartCommission.value) )
					{
						_request = {
							component:			"post",
							method:				"acceptBuyOffer",
							nftID:				this.props.state.nft.id,
							offerID:			this.state.formData.offerAccept.id.value,
							redirect:			this.props.state.nft.URI
						}
					}
					else
					{
						_request = {
							component:			"post",
							method:				"acceptOfferNoFee",
							nftID:				this.props.state.nft.id,
							offerID:			this.state.formData.offerAccept.id.value,
							redirect:			this.props.state.nft.URI
						}

					}
					*/


					_request = {
						component:			"post",
						method:				"acceptBuyOffer",
						nftID:				this.props.state.nft.id,
						offerID:			this.state.formData.offerAccept.id.value,
						redirect:			this.props.state.nft.URI
					}



					window.callAPI
					(
						_request,
						function (_jsonData) {
							if (_jsonData.success) {

								document.location.href = _jsonData.info.redirect;

							}
							else {
								window.displayErrorMessage("Error buying NFT - " + _jsonData.errorMessage)
							}
						}
					)

					break;

				case "buyNFT":


				alert("Needs converting to xumm v2")
				return false



					_request = {
						component:			"post",
						method:				"buyItNow",
						uuid:				this.props.state.nft.id,
						offerUUID:			window.uuidv4(),
						redirect:			this.props.state.nft.URI
					}

					window.callAPI
					(
						_request,
						function (_jsonData) {
							if (_jsonData.success) {

								document.location.href = _jsonData.info.redirect;

							}
							else {
								window.displayErrorMessage("Error buying NFT - " + _jsonData.errorMessage)
							}
						}
					)

					break

				case "makeOfferToBuy":


				alert("Needs converting to xumm v2")
				return false



					_request = {
						component:			"post",
						method:				"placeBuyOffer",
						uuid:				this.props.state.nft.id,
						offerUUID:			window.uuidv4(),
						amount:				this.state.formData.makeOfferToBuy.offerPrice.value,
						redirect:			this.props.state.nft.URI,
						expiry:				this.state.formData.makeOfferToBuy.offerExpiry.value,
						quantity:			1
					}

					window.callAPI
					(
						_request,
						function (_jsonData) {
							if (_jsonData.success) {

								document.location.href = _jsonData.info.redirect;

							}
							else {
								window.displayErrorMessage("Error placing offer - " + _jsonData.errorMessage)
							}
						}
					)

				break;

				case "sell":


				alert("Needs converting to xumm v2")
				return false



					_request = {
						component:			"post",
						method:				"sellNFT",
						uuid:				this.props.state.nft.id,
						lastModified:		this.props.state.nft.updated,
						quantity:			1,
						amount:				this.state.formData.sell.sellPrice.value,
						currency:			"",
						reservedFor:		this.state.formData.sell.reserved.value === 'Yes' ? this.state.formData.sell.walletAddress.value : "",
						redirect:		this.props.state.nft.URI
					}

					window.callAPI(
						_request,
						function (_jsonData)
						{
							if(_jsonData.success)
							{
								document.location.href = _jsonData.info.redirect;
							}
							else
							{
								window.displayErrorMessage("Mint error - " + _jsonData.errorMessage)
							}
						}
					)
				break;

				default:
					window.displayErrorMessage("Tab not handled ["+this.state.chosenTab+"]")
					break;
			}
		}

	}

	/* PRE MODAL DISPLAY CHECKS */
	/* PRE MODAL DISPLAY CHECKS */
	/* PRE MODAL DISPLAY CHECKS */
	/* PRE MODAL DISPLAY CHECKS */
	/* PRE MODAL DISPLAY CHECKS */
	/* PRE MODAL DISPLAY CHECKS */
	/* PRE MODAL DISPLAY CHECKS */

	transferCheck = e =>
	{

		launchModal("transfer");

		// window.displayErrorMessage("Need to do this bit.. To replace Right hand area and replace with feature functions");
			//			return false;

		/* Display the modal */
		window.$('#transferShowModal').trigger("click")

		/* We have already set our minter - display the modal */
		window.convertScreenPrices("transfer", true); /* in xutils.js */

		/* Set focus on input field */
		window.setTimeout( () => { window.$('#wallet-address').trigger("click").focus().select() }, 550)

	}

	sellCheck = e =>
	{

		launchModal("sell");

		/* Set focus on input field */
		window.setTimeout( () => { window.$('#sellPrice').trigger("click").focus().select() }, 550)

	}

	cancelSellOfferCheck = e =>
	{

		const _offerID = e.currentTarget.dataset.offer;
		const _offerIDNetwork = e.currentTarget.dataset.offernetwork;
		this.setState({"cancelOfferID":_offerID, "cancelOfferIDNetwork":_offerIDNetwork})

		/* Set focus on input field */
		window.setTimeout( () => { launchModal("cancelsell"); }, 550)

	}

	declineOffer = e =>
	{

		const _offerID = e.currentTarget.dataset.offer;

		window.Swal.fire(
			{
				title: 'Decline Offer',
				html: '<p className="text-center">Are you sure you want to decline the offer? If you do, there will be no way to accept it in the future.<p>',
				showCancelButton: true,
				confirmButtonText:"Decline Offer",
			}).then((result) => {
				if (! result.isDismissed)
				{
					window.callAPI(
						{
										component: "post",
										method: "declineOffer",
										offerID:_offerID,
										nftID:this.props.state.nft.id,
										redirect:this.props.state.nft.URI
									},
						function (_jsonData)
						{
							if(_jsonData.success)
							{
								//window.$('#reloader').trigger("click")
								//window.$('#reloadNFT').trigger("click")
								document.location.replace( _jsonData.info.redirect )
							}
							else
							{
								window.displayErrorMessage("Unable to decline offer - " + _jsonData.errorMessage)
							}
						}
					)
				}
			})
	}

	spinAcceptCheck = e =>
	{

		window.showWaitMessage("Checking offer values and buyers balance");

		const BASE_URL = this.props.config.apiServer + "/com/external/offers.cfc";

		const formData = new FormData();
			formData.append("method", "getSpincheck" );
			formData.append("uuid", e.currentTarget.dataset.offer );
			formData.append("nftID", this.props.state.nft.id );
			formData.append("redirect", this.props.state.nft.URI );

		axios.post(BASE_URL, formData, { withCredentials: true })
		.then(res => {

			window.hideWaitMessage();

			if( ! res.data.success)
			{
				throw(res.data.errorMessage)
			}
			else
			{
				addXummPayload( { "type":"NFTokenAcceptOffer", "OfferTypes":["Sell"], "OfferIDs":[res.data.offer], "NFTokenIDs":[this.props.state.nft.mintInfo.address], "values":{} } )
				/* Approve in Xumm */
			//	document.location.href = res.data.redirect;

			}
		})
		.catch(err =>
			{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)

	}


	offerAcceptCheck = e =>
	{

		window.showWaitMessage("Checking offer values and buyers balance");

		const BASE_URL = this.props.config.apiServer + "/com/external/offers.cfc";

		const formData = new FormData();
			formData.append("method", "getOffer" );
			formData.append("uuid", e.currentTarget.dataset.offer );
			formData.append("nftID", this.props.state.nft.id );

		axios.post(BASE_URL, formData, { withCredentials: true })
		.then(res => {

			window.hideWaitMessage();

			if( ! res.data.success)
			{
				throw(res.data.errorMessage)
			}
			else
			{
				/* Move data into form object */
				var _fd = this.state.formData;

				_fd.offerAccept.offerPrice.value = res.data.offer.amountGross
				_fd.offerAccept.xMartCommission.value = res.data.offer.fee
				_fd.offerAccept.balance.value = res.data.offer.amountNet
				_fd.offerAccept.id.value = res.data.offer.id
				_fd.offerAccept.walletAddress.value = res.data.offer.account.address
				_fd.offerAccept.hasFunds.value = res.data.offer.account.hasFunds
				_fd.offerAccept.offerIDNetwork.value = res.data.offer.offerIDNetwork.hasFunds

				//_fd.makeOfferToBuy.askingPriceMinimum.default = res.data.askingPriceMinimum / 1000000
				//_fd.makeOfferToBuy.offerPrice.default = _fd.makeOfferToBuy.askingPrice.default
				this.setState({formData:_fd, "acceptOfferIDNetwork":res.data.offer.offerIDNetwork})

				/* Display the modal */
				launchModal("offerAccept");

			}
		})
		.catch(err =>
			{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)

	}

	offerCheck = e =>
	{

		if( this.props.config.userData.sid === 0 ) {
			window.$('#sign-in-button').trigger("click");
			return false
		}

		window.showWaitMessage("Checking NFT values and wallet balance");

		const BASE_URL = this.props.config.apiServer + "/com/external/nfts.cfc";

		const formData = new FormData();
			formData.append("method", "getOfferStatus" );
			formData.append("uuid", this.props.state.nft.id );

		axios.post(BASE_URL, formData, { withCredentials: true })
		.then(res => {

			window.hideWaitMessage();

			if( ! res.data.success)
			{
				throw(res.data.errorMessage)
			}
			else
			{
				/* Move data into form object */
				var _fd = this.state.formData;
				_fd.makeOfferToBuy.offerPrice.default = ""
				_fd.makeOfferToBuy.offerExpiry.default = shortDate(res.data.offerExpiry, " @ ")
				_fd.makeOfferToBuy.offerExpiry.value = res.data.offerExpiryXRPL;
				_fd.makeOfferToBuy.balance.value = res.data.balance;
				this.setState({formData:_fd})


				/* Display the modal */
				launchModal("makeOfferToBuy");

			}
		})
		.catch(err =>
			{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)

	}

	buyCheck = e =>
	{

		if( this.props.config.userData.sid === 0 ) {
			window.$('#sign-in-button').trigger("click");
			return false
		}

		window.showWaitMessage("Checking NFT values and wallet balance");

		const BASE_URL = this.props.config.apiServer + "/com/external/nfts.cfc";

		const formData = new FormData();
			formData.append("method", "getPurchasePrice" );
			formData.append("uuid", this.props.state.nft.id );

		axios.post(BASE_URL, formData, { withCredentials: true })
		.then(res => {

			window.hideWaitMessage();

			if( ! res.data.success)
			{
				throw(res.data.errorMessage)
			}
			else
			{

				/* Move data into form object */
				var _fd = this.state.formData;
				_fd.buyNFT.askingPrice.default = res.data.askingPrice
				_fd.buyNFT.hasFunds.value = res.data.hasFunds
				_fd.buyNFT.external.value = res.data.external
				_fd.buyNFT.externalFee.value = res.data.externalFee
				_fd.buyNFT.externalFeeCap.value = res.data.externalFeeCap
				_fd.buyNFT.externalWho.value = res.data.externalWho

				this.setState({formData:_fd, "buyOfferNetwork":res.data.offerNetwork})

				/* Display the modal */
				launchModal("buyNFT");

			}
		})
	.catch(err =>
			{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)

	}

	mintCheck = e =>
	{

		if( 0 == 0)
		{
			/* Pass tab back to parent - This will re-render this component */
			launchModal("mint");

			/* Set focus on input field */
			window.setTimeout( () => {
				window.$('#royalty').trigger("click").focus().select();
				window.$('#mint-price').trigger("click").focus().select()
			}, 550)

		}
		else
		{

			window.showWaitMessage("Checking mint values");

			const _URI = this.props.state.nft.URI
			window.callAPI(
				{
								component: "external",
								method: "checkMinterStatus",
								redirect:_URI
							},
				function (_jsonData)
				{
					window.hideWaitMessage();
					if(_jsonData.success)
					{
						if ( ! _jsonData.isMinterSet)
						{
							window.Swal.fire(
								{
									title: 'Wallet needs authorising',
									html: '<p className="text-center">To enable us to mint NFTs on your behalf, we must be approved to do so.</p><p>A small XRPL transaction cost will be incurred to set the "Minter" value on your account.<p>',
									showCancelButton: true,
									confirmButtonText:"Authorise in wallet",
								}).then((result) => {

									if (! result.isDismissed)
									{

										window.callAPI(
											{
															component: "external",
															method: "setMinterStatus",
															redirect:_URI
														},
											function (_jsonData)
											{
												if(_jsonData.success)
												{
													document.location.replace( _jsonData.redirect )
												}
												else
												{
													window.displayErrorMessage("Unable to set minter status - " + _jsonData.errorMessage)
												}
											}
										)
									}
								})
						}
						else
						{

							/* Pass tab back to parent - This will re-render this component */
							launchModal("mint");

							/* Set focus on input field */
							window.setTimeout( () => {
															window.$('#royalty').trigger("click").focus().select();
															window.$('#mint-price').trigger("click").focus().select()
														}, 550)
						}
					}
					else
					{
						window.displayErrorMessage("Mint error - " + _jsonData.errorMessage)
					}
				}
				)
		}
	}

	archiveCheck = e =>
	{

		const BASE_URL = this.props.config.apiServer + "/com/external/nfts.cfc?method=getDeletionStatus&uuid="+this.props.state.nft.id;

		axios.get(BASE_URL, { withCredentials: true })
            .then(res => {

				if( ! res.data.success)
				{
					throw(res.data.errorMessage)
				}
				else
				{
					if( ! res.data.info.deleteable)
					{
						window.displayErrorMessage(res.data.info.message);
					}
					else
					{
						/* Display the modal */
						launchModal("archive");
					}

				}
            })
        .catch(err =>
				{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)
	}

    render()
	{

		const mintButton = this.props.config.signMode === 'w' ? "Mint NFT" : "Mint and Sell NFT";
		const mintIcon = this.props.config.signMode === 'w' ? "fas fa-hammer me-2" : "fas fa-store me-2";

		const _editLink = `${this.props.config.userData.url}/edit-nft/${this.props.state.nft.id}`

		var _showEditButton = this.props.state.nft.flagEditable
								&& this.props.state.nft.flagSpinner === false									?	true : false;

		var _showTransferButton = this.props.state.nft.flagOwner
								&& this.props.state.nft.flagForSale === false
								&& this.props.state.nft.mintInfo.transferPending === false
								&& this.props.state.nft.offers.systemPending === false
								&& this.props.state.nft.mintInfo.pending === false
								&& this.props.state.nft.flagBurned === false
								&& this.props.state.nft.mintedQty > 0
								&& this.props.state.disableTransfers == false									? true : false;

		var _showMintButton = this.props.state.nft.flagOwner
								&& this.props.state.nft.flagSpinner === false
								&& this.props.state.nft.flagForSale === false
								&& this.props.state.nft.mintInfo.pending === false
								&& this.props.state.nft.mintInfo.transferPending === false
								&& this.props.state.nft.mintedQty === 0										?	true : false;

		var _showSellButton = this.props.state.nft.flagOwner
								&& this.props.state.nft.flagForSale === false
								&& this.props.state.nft.mintInfo.pending === false
								&& this.props.state.nft.mintInfo.transferPending === false
								&& this.props.state.nft.offers.systemPending === false
								&& this.props.state.nft.flagBurned === false
								&& this.props.state.nft.mintedQty > 0
															? true : false;

		var _showCancelSellButton = this.props.state.nft.flagOwner
								&& (
										this.props.state.nft.mintInfo.transferPending === true
										||
										this.props.state.nft.flagForSale === true
									)
								&& this.props.state.nft.mintInfo.pending === false
								&& this.props.state.nft.offers.systemPending === false
								&& this.props.state.nft.flagBurned === false
								&& this.props.state.nft.mintedQty > 0
								&& 0 === 1																		? true : false;



		var _showArchiveButton = this.props.state.nft.flagOwner
								&& this.props.state.nft.flagSpinner === false
								&& this.props.state.nft.mintedQty === 0
								&& this.props.state.nft.mintInfo.pending === false								? true : false;

		var _showOfferButton	= this.props.state.nft.flagOwner === false
								&& this.props.state.nft.offers.buyOfferMade === false
								&& this.props.state.nft.mintInfo.pending === false
								&& this.props.state.nft.offers.systemPending === false
								&& this.props.state.nft.mintInfo.transferPending === false
								&& this.props.state.nft.flagAllowOffers === true
								&& this.props.state.nft.flagBurned === false
								&& this.props.state.nft.mintedQty > 0
																											? true : false;

		var _showBuyButton = 	this.props.state.nft.flagOwner === false
								&& this.props.state.nft.flagForSale === true
								&& this.props.state.nft.offers.systemPending === false
								&& this.props.state.nft.mintInfo.pending === false
								&& this.props.state.nft.mintInfo.transferPending === false
								&& this.props.state.nft.offers.buyOfferMade === false
								&& this.props.state.nft.flagBurned === false
								&& this.props.state.nft.mintedQty > 0										? true : false;


		var _showSpinButton = 	this.props.state.nft.flagSpinner === true
								&& this.props.state.nft.mintedQty === 0										? true : false;

        return (

			<>

				{ this.props.state.modalVisible === true ?
				(
					<div className="row card no-bg no-border nohover shadow-sm m-0 p-0 mt-4 mb-4" id="modal-wrapper">
						{/* Display nothing as the NFT data is visible */}

						<div className="card-header">
							<h4 className="m-0">{this.state.chosenTab.length ? this.state.formData[this.state.chosenTab].title : "xx"}</h4>
						</div>

						{/* Display MINT tab */}
						{ this.props.parentState.modalTag === "mint" ?
						(
							<form name="modal-mint" id="modal-mint">
								<div className="card-body">
									<div className="row g-0">


										<div className="col-12 col-lg-6 mt-3">
											<label htmlFor="mint-royalty" className="pb-2">Royalty for future sales <span className="req">*</span></label>
											<VideoLink label={"Explaining royalties tutorial"} tag={"explaining-royalties"} linkType="link" config={this.props.config} />
										</div>

										<div className="col-12 col-lg-6 mt-3">
											<div className="form-group">

												<div className="d-flex align-items-center">
													<input type="number" className="form-control value-field" id="royalty" name="royalty" placeholder="" min="0" max="99.9" maxLength="5" required style={{width:"50%"}} onChange={this.formValidationCheck} defaultValue={this.state.formData.mint.royalty.value} />
													<span className="ps-2">( {this.props.config.fees.minimumRoyaltyPercent}-{this.props.config.fees.maximumRoyaltyPercent}% )</span>
												</div>
											</div>
										</div>

										<CurrencyFees 	config={this.props.config}
																feeType="mint"

																formData={ this.state.formData }
																formGroup={"mint"}
																formField={"royalty"}

																hasSourceField={false}
																sourceLabel={"Target price for this item"}
																sourceEditable={true}

																feeLabel={"Mint Fee"}

																includeSourceInTotal={false}
																callback={this.myCallback} />

										<div className="col-12 mb-2 mt-4">
											<div className="form-check">
												<input className="form-check-input" type="checkbox" id="acceptCheck" onChange={this.formValidationCheck} required />
												<label className="form-check-label px-3 w-75 w-75" htmlFor="acceptCheck">I confirm that I want to mint this NFT on the network and have read the terms of service agreement.</label>
											</div>
										</div>
									</div>
								</div>
							</form>
						) : ( <></>) }

						{/* Display ARCHIVE tab */}
						{this.props.parentState.modalTag === "archive" ?
						(
							<form name="modal-archive" id="modal-archive">
								<div className="card-body">
									<div className="container row">

										<div className="col-12 text-center mb-5">
											<p>You are about to delete and archive an NFT. It will be removed from the collection &amp; audience facing parts of the website.</p>
											<p>The NFT will be permanently deleted from xMart after 7 days. If in the meantime you need to recover an archived NFT, please file a support request.</p>
											<p>To begin the archive process, please enter the word "<strong className="secretword">{this.props.parentState.secretWord}</strong>" into the confirmation box below.</p>
										</div>

										<div className="col-12 col-lg-6 mt-1">
											<label htmlFor="mint-royalty" className="pb-2 text-end">Archive confirmation<span className="req">*</span></label>
										</div>
										<div className="col-12 col-lg-6">
											<div className="form-group">

												<div className="d-flex align-items-center">
													<input type="text" className="form-control uppercase w-75" id="confirmation" name="confirmation"
																	data-word={this.props.parentState.secretWord}
																	placeholder="Type the word in bold shown above"
																	maxLength="15"
																	required="required"
																	onChange={this.formValidationCheck}
																	defaultValue={this.state.formData.archive.confirmation.value} />
												</div>
											</div>
										</div>

									<div className="col-12 mb-3 mt-3">
										<div className="form-check">
											<input className="form-check-input" type="checkbox" id="acceptCheck" onChange={this.formValidationCheck} required/>
											<label className="form-check-label px-3 w-75 pt-1" htmlFor="acceptCheck">I confirm that I would like to archive this NFT.</label>
										</div>
									</div>

									</div>
								</div>
							</form>
						) : ( <></>) }


						{/* Display TRANSFER tab */}
						{this.props.parentState.modalTag === "cancelsell" ?
						(
							<form name="modal-cancelsell" id="modal-cancelsell">
								<div className="card-body">
									<div className="container row">
										<p className="px-3">By cancelling the sell offer, your NFT will no longer be listed for sale in the marketplace.</p>
										<p className="px-3">You will be cancelling offer:-<br/><strong>{this.state.cancelOfferIDNetwork}</strong></p>

										<div className="col-12 mb-3 mt-3">
											<div className="form-check">
												<input className="form-check-input" type="checkbox" id="cancelCheckBox" name="cancelCheckBox" onClick={this.formValidationCheck} required/>
												<label className="form-check-label px-3 w-75 pt-1" htmlFor="cancelCheckBox">I confirm that I want to cancel the sell offer for this NFT. It will no longer be listed for sale in the marketplace and will be made open to buy offers.</label>
											</div>
										</div>

									</div>
								</div>
							</form>
						) : ( <></>) }

						{/* Display OFFER ACCEPT tab */}
						{this.props.parentState.modalTag === "offerAccept" ?
						(
							<form name="modal-offerAccept" id="modal-offerAccept">
								<div className="card-body">
									<div className="container row">

										{ this.state.formData.offerAccept.hasFunds.value ?
											(
												<div className="alert alert-primary mb-4" role="alert">
													The buyer appears to have sufficient balance to purchase this NFT. A further check will be carried out if you approve the transaction.
												</div>
											)
											:
											(
												<div className="alert alert-danger mb-4" role="alert">
													We have checked the buyer's wallet and it appears they may NOT have sufficient funds to complete the purchase.
													<a className="ms-1" href={this.props.config.links.explore + "/" + this.state.formData.offerAccept.walletAddress.value} target="_blank" rel="noreferrer">Click here to confirm</a>
													{/*
													<div className="form-check mt-3">
														<input className="form-check-input" type="checkbox" id="ignoreCheck" onChange={this.formValidationCheck} required />
														<label className="form-check-label px-3 w-75" htmlFor="ignoreCheck">
															I acknowledge the warning but wish to proceed.</label>
													</div>
													*/}
												</div>
											)
										}

										<div className="col-12 col-lg-6 mt-3">
											<label htmlFor="mint-royalty" className="pb-2">Buyer's Offer Price</label>
										</div>
										<div className="col-12 col-lg-6 mt-3">
											<div className="form-group">
												<div className="d-flex align-items-center">
													<CurrencyPrice price={this.state.formData.offerAccept.offerPrice.value} formField={true} config={this.props.config} />
												</div>
											</div>
										</div>

										<div className="col-12 col-lg-6 mt-3">
											<label htmlFor="mint-royalty" className="pb-2">Less xMart Fee</label>
										</div>
										<div className="col-12 col-lg-6 mt-3">
											<div className="form-group">
												<div className="d-flex align-items-center">
													<CurrencyPrice price={this.state.formData.offerAccept.xMartCommission.value} formField={true} config={this.props.config} />
												</div>
											</div>
										</div>

										<div className="col-12 col-lg-6 mt-3">
											<label htmlFor="mint-royalty" className="pb-2">Balance You Receive</label>
										</div>
										<div className="col-12 col-lg-6 mt-3">
											<div className="form-group">
												<div className="d-flex align-items-center">
													<CurrencyPrice price={this.state.formData.offerAccept.balance.value} formField={true} config={this.props.config} />
												</div>
											</div>
										</div>

										<div className={"col-12 mb-3 mt-3 " + ( this.state.formData.offerAccept.hasFunds.value ? "" : " hid")}>
											<div className="form-check">
												<input className="form-check-input" type="checkbox" id="acceptCheck" onChange={this.formValidationCheck} required />
												<label className="form-check-label px-3 w-75" htmlFor="acceptCheck">
													I confirm that I want to accept the buyers offer and will receive the balance indicated above.</label>
											</div>
										</div>

{/*
										<CurrencyFees 	config={this.props.config}
																feeType="buy"

																formData={ this.state.formData }
																formGroup={"makeOfferToBuy"}
																formField={"offerPrice"}

																minValue={0}
																maxValue={0}

																hasSourceField={false}
																sourceLabel={"Your Offer"}
																sourceEditable={true}

																feeLabel={"Buyers Fee"}

																includeSourceInTotal={true}
																callback={this.myCallback} />*/}
									</div>
								</div>
							</form>
						) : ( <></>) }

						{/* Display BUY tab */}
						{this.props.parentState.modalTag === "buyNFT" ?
						(
							<form name="modal-buyNFT" id="modal-buyNFT">
								<div className="card-body">
									<div className="container row">

									{ this.state.formData.buyNFT.hasFunds.value ?
										(
											<></>
										)
										:
										(
											<div className="alert alert-danger mb-4" role="alert">
												We have checked your wallet and it appears you may NOT have sufficient funds to purchase this NFT.
												<a className="ms-1" href={this.props.config.links.explore + "/" + this.props.config.userData.walletAddress} target="_blank" rel="noreferrer">Click here to check your balance</a>

													{/*
													<div className="form-check mt-3">
														<input className="form-check-input" type="checkbox" id="ignoreCheck" onChange={this.formValidationCheck} required />
														<label className="form-check-label px-3 w-75" htmlFor="ignoreCheck">
															I acknowledge the warning but wish to proceed.
														</label>
													</div>
													*/}

											</div>
										)
									}

									<CurrencyFees 	config = {this.props.config}
													feeType="buy"

													formData={ this.state.formData }
													formGroup={"buyNFT"}
													formField={"askingPrice"}

													hasSourceField={true}
													sourceLabel={"Asking price for this item"}
													sourceEditable={false}
													sourceValue={this.state.formData.buyNFT.askingPrice.value}

													feeLabel={"Buyers Fee"}

													includeSourceInTotal={true}
													callback={this.myCallback} />

										{ this.props.state.nft.burnable ?
										(
											<div className="row alert alert-danger mt-4">
												<div className="col-2 text-center pt-3"><i className="fas fa-exclamation-triangle mt-3" style={{"fontSize":"180%"}}></i></div>
												<div className="col-10 text-left">This NFT was originally minted with a "burnable" flag. This means that at any time, the original issuer of the token could destroy it. Proceed at your own risk.</div>
											</div>)
										:
										( <></> )
										}

										{ this.props.state.nft.royalty ?
											(<blockquote className="mb-1 mt-3">NOTE: This NFT has a royalty applied to it. If you, or future owners sell this NFT, {this.props.state.nft.royalty.toFixed(2)}% will be automatically deducted (by the ledger) from any sale price &amp; paid to the creator of the NFT. You will receive the remaining {(100-this.props.state.nft.royalty).toFixed(2)}% of the sale price.</blockquote>)
										:
										(<></>)
										}

										<div className={"col-12 mb-3 mt-3 " + ( this.state.formData.buyNFT.hasFunds.value ? "" : " hid")}>
											<div className="form-check">
												<input className="form-check-input" type="checkbox" id="buyCheck" name="buyCheck" disabled={this.state.formData.buyNFT.hasFunds.value ? false : true} onClick={this.formValidationCheck} required/>
												{ this.props.state.nft.royalty ?
												(
													<label className="form-check-label px-3 w-75 pt-1" htmlFor="buyCheck">I confirm that I want to buy this NFT and I understand that a future royalty of {this.props.state.nft.royalty.toFixed(2)}% will be paid to the creator should I re-sell the NFT.</label>
												)
												:
												(
													<label className="form-check-label px-3 w-75 pt-1" htmlFor="buyCheck">I confirm that I want to buy this NFT.</label>
												)
												}

											</div>
										</div>

									</div>
								</div>
							</form>
						) : ( <></>) }

						{/* Display OFFER tab */}
						{this.props.parentState.modalTag === "makeOfferToBuy" ?
						(
							<form name="modal-makeOfferToBuy" id="modal-makeOfferToBuy">
								<div className="card-body">
									<div className="container row">

										<p className="px-3">You are about to make an offer for an NFT that is not yet listed for sale. The owner of the NFT may not respond to such offers in a reasonable timescale. The offer will automatically expire on the date shown below.</p>

										<div className="col-12 col-lg-6">
											<label className="pt-2">Offer Expires</label>
										</div>
										<div className="col-12 col-lg-6">
											<div className="form-group">
												<div className="d-flex align-items-center">
													<input type="text" className="form-control" readOnly defaultValue={this.state.formData.makeOfferToBuy.offerExpiry.default} />
												</div>
											</div>
										</div>

										<div className="col-12 col-lg-6 mt-3">
											<label className="pt-2">Wallet Balance</label>
										</div>
										<div className="col-12 col-lg-6 mt-3">
											<div className="form-group">
												<div className="d-flex align-items-center">
													<CurrencyPrice price={this.state.formData.makeOfferToBuy.balance.value} formField={true} config={this.props.config} />
												</div>
											</div>
										</div>





										{ this.props.state.nft.flagForSale === true ?
								(
									<>
									<div className="col-12 col-lg-6 mt-3">
											<label className="pt-2">Asking Price</label>
										</div>
										<div className="col-12 col-lg-6 mt-3">
											<div className="form-group">
												<div className="d-flex align-items-center">
													<CurrencyPrice price={this.props.state.nft.askingPrice} formField={true} config={this.props.config} />
												</div>
											</div>
										</div>
									</>) : ( <></>)}

									{ this.props.state.nft.flagForSale === false && this.props.state.nft.collection.id.length ?
									(
										<>
											<div className="col-12 col-lg-6 mt-3">
												<label className="pt-2">Collection Floor</label>
											</div>
											<div className="col-12 col-lg-6 mt-3">
												<div className="form-group">
													<div className="d-flex align-items-center">
														<CurrencyPrice price={this.props.state.nft.collection.floor} formField={true} config={this.props.config} />
													</div>
												</div>
											</div>
										</>) : ( <></>)}


										<CurrencyFees 	config={this.props.config}
																feeType="offer"

																formData={ this.state.formData }
																formGroup={"makeOfferToBuy"}
																formField={"offerPrice"}

																minValue={0}
																maxValue={0}

																hasSourceField={true}
																sourceLabel={"Your Offer"}
																sourceEditable={true}

																feeLabel={"Buyers Fee"}

																includeSourceInTotal={true}
																callback={this.myCallback} />



										{ this.props.state.nft.burnable ?
											(
												<div className="row alert alert-danger mt-4">
													<div className="col-2 text-center pt-3"><i className="fas fa-exclamation-triangle mt-3" style={{"fontSize":"180%"}}></i></div>
													<div className="col-10 text-left">This NFT was originally minted with a "burnable" flag. This means that at any time, the original issuer of the token could destroy it. Proceed at your own risk.</div>
												</div>
											)
											:
											( <></> )
										}


										{ this.state.formData.makeOfferToBuy.offerPrice.value > this.state.formData.makeOfferToBuy.balance.value ?
											(
												<div className="row alert alert-danger mt-4"><i className="fas fa-exclamation-triangle" style={{"fontSize":"140%"}}></i> You do not have enough funds to make this offer.</div>
											) :
											(
												<></>
											)
										}

										{ this.state.chosenTab === 'makeOfferToBuy' && this.props.state.nft.askingPrice > 0 && ( Number(this.state.formData[this.state.chosenTab].offerPrice.value) > this.props.state.nft.askingPrice ) ?
											(
												<blockquote className="w-100 mb-3 mt-2">
												<p className="danger">WARNING: You are paying more than the current asking price. Automated bots will snipe the sale and take the profit. The original buyer will not receive any of this XRP.</p>
												</blockquote>) : (<></>)
										}

										<div className="col-12 mb-3 mt-3">
											<div className="form-check">
												<input className="form-check-input" type="checkbox" id="makeOfferCheck" disabled={this.state.formData.makeOfferToBuy.offerPrice.value > this.state.formData.makeOfferToBuy.balance.value ? true : false} name="makeOfferCheck" onClick={this.formValidationCheck} required/>
												<label className="form-check-label px-3 w-75" htmlFor="makeOfferCheck">I confirm that I want to place the above offer for this NFT. No money will be taken unless the seller accepts your offer.</label>
											</div>
										</div>






									</div>
								</div>
							</form>
						) : ( <></>) }

						{/* Display SELL tab */}
						{this.props.parentState.modalTag === "sell" ?
						(
							<form name="modal-sell" id="modal-sell">
								<div className="card-body">
									<div className="container row">


									<CurrencyFees 	config={this.props.config}
																feeType="sell"

																formData={ this.state.formData }
																formGroup={"sell"}
																formField={"sellPrice"}

																HasRoyalty={true}
																royaltyPercent={this.props.state.nft.royalty}

																hasSourceField={true}
																sourceLabel={"Target price for NFT"}
																sourceEditable={true}

																feeLabel={"Seller Fee"}

																includeSourceInTotal={true}
																callback={this.myCallback} />

									{ this.props.state.disableTransfers === false ?
									(
										<>
											<div className="col-12 col-lg-6 mt-3">
												<label htmlFor="reserved" className="pt-2">Reserve for a buyer?</label>
											</div>
											<div className="col-12 col-lg-6 mt-3">
												<div className="form-group">

													<div className="form-group">
														<select id="reserved" name="reserved" className="form-select w-20 yesno" onChange={this.formValidationCheck} defaultValue={this.state.formData.sell.reserved.value}>
															<option key={"res_1"} value={"No"}>No</option>
															<option key={"res_2"} value={"Yes"}>Yes</option>
														</select>
													</div>

												</div>

											</div>
										</>
									) : (<></>)}


									{ this.state.formData.sell.reserved.value === 'Yes' ?
									(
										<div className="row p-0">

											<div className="col-12 mt-3">
												<label htmlFor="sell-royalty" className="pt-2 heading">Destination wallet address<span className="req">*</span></label>
											</div>

											<div className="col-10">
												<div className="form-group">

													<div className="d-flex align-items-center">
														<input type="text" className="form-control w-100" id="walletAddress" name="walletAddress" placeholder="e.g. r3pc36jf9Dn7VZKKAusREVsXQK7NXtqa5u" maxLength="34" required="required" onChange={this.formValidationCheck} defaultValue={this.state.formData.sell.walletAddress.value} />
													</div>
												</div>

											</div>

											<div className="col-1 text-center pt-2">
												{ window.isValidAddress( this.state.formData.sell.walletAddress.value ) ?
												(
													<a href={this.props.config.links.explore + "/" + this.state.formData.sell.walletAddress.value} target="_blank"><i className="fas fa-search fa-lg"></i></a>
												)
												:
												(
													<span><i className="fas fa-exclamation-triangle errorText fa-lg"></i></span>
												)
												}

											</div>

											<div className="col-12 m-0">
												<blockquote>This must be a valid public XRP wallet address and NOT a family seed/private key (Public wallets begin with an "r"). This action is immediate and <strong>CAN NOT BE REVERSED</strong></blockquote>
											</div>

										</div>
									)
									:
									( <></> )
									}

									{ this.props.state.nft.offers.buyOfferCount > 0 &&  this.state.formData.sell.sellPrice.value > this.props.state.nft.offers.buyOfferMinimum ?
										(
											<blockquote className="w-100 mb-3 mt-4">
											<p className="danger">WARNING: A buy offer for <CurrencyPrice price={this.props.state.nft.offers.buyOfferMaximum} formField={false} config={this.props.config} /> already exists for this NFT. Automated bots will snipe the sale and take the profit.</p>
											</blockquote>) : (<></>)
									}

									<div className="col-12 mb-3 mt-3">
										<div className="form-check">
											<input className="form-check-input" type="checkbox" id="sellCheckBox" required onClick={this.formValidationCheck}/>
											<label className="form-check-label px-3 w-75" htmlFor="sellCheckBox">
												I confirm that I want to sell this NFT and have read the terms of service agreement.</label>
										</div>
									</div>

									</div>
								</div>
							</form>
						) : ( <></>) }


						{/* Display SELL tab */}
						{this.props.parentState.modalTag === "transfer" ?
						(
							<form name="modal-transfer" id="modal-transfer">
								<div className="card-body p-0">
									<div className="container row">

									<p className="px-3">You are about to create a zero XRP offer to another wallet which will transfer ownership to them.</p>

									{ this.props.state.nft.offers.buyOfferCount > 0 ?
											(
												<blockquote className="w-100 mb-3 mt-2">
												<p className="danger">WARNING: A buy offer for <CurrencyPrice price={this.props.state.nft.offers.buyOfferMaximum} formField={false} config={this.props.config} /> already exists for this NFT. Automated bots will snipe the sale and take the profit. For your safety and potential financial loss, we have disabled the transfer button.</p>
												</blockquote>) : (<></>)
										}




										<div className="row p-0">

											<div className="col-12 mt-3">
												<label htmlFor="walletAddress" className="pt-2 heading">Destination wallet address<span className="req">*</span></label>
											</div>

											<div className="col-10">
												<div className="form-group">

													<div className="d-flex align-items-center">
														<input type="text" className="form-control w-100" id="walletAddress" name="walletAddress" placeholder="e.g. r3pc36jf9Dn7VZKKAusREVsXQK7NXtqa5u"  disabled={this.props.state.nft.offers.buyOfferCount} maxLength="34" required="required" onChange={this.formValidationCheck} defaultValue={this.state.formData.transfer.walletAddress.value} />
													</div>
												</div>

											</div>

											<div className="col-1 text-center pt-2">
												{ window.isValidAddress( this.state.formData.transfer.walletAddress.value ) ?
												(
													<a href={this.props.config.links.explore + "/" + this.state.formData.transfer.walletAddress.value} target="_blank"><i className="fas fa-search fa-lg"></i></a>
												)
												:
												(
													<span><i className="fas fa-exclamation-triangle errorText fa-lg"></i></span>
												)
												}

											</div>

											<div className="col-12 m-0">
												<blockquote>This must be a valid public XRP wallet address and NOT a family seed/private key (Public wallets begin with an "r"). This action is immediate and <strong>CAN NOT BE REVERSED</strong></blockquote>
											</div>

											</div>

											<div className="col-12 mb-3 mt-3">
												<div className="form-check">
													<input className="form-check-input" type="checkbox" id="transferCheckBox" required onClick={this.formValidationCheck} disabled={this.props.state.nft.offers.buyOfferCount}/>
													<label className="form-check-label px-3 w-75" htmlFor="transferCheckBox">
														I confirm that I want to transfer this NFT to the wallet provided above and have read the terms of service agreement.</label>
												</div>
											</div>


									</div>
								</div>
							</form>
						) : ( <></>) }









						<div className="card-footer mt-4 py-2 m-0 text-center">

							{/* <button type="button" className="btn w-100" id="nft-sell-button" onClick={addXummPayload} data-type="NFTokenCreateOffer" data-tokens={this.props.state.nft.mintInfo.address}><i className="fas fa-store me-2 fa-lg"></i>Sell this NFT X</button> */}

							{ this.state.formData[this.state.chosenTab].walletFunction ?
								(
									<button type="button" className="my-3 btn btn-sm w-75" onClick={this.doPayload} data-type={this.state.formData[this.state.chosenTab].walletFunction} disabled={this.state.submitEnabled ? false : true}>{this.state.chosenTab.length ? this.state.formData[this.state.chosenTab].button : "--"}</button>
								)
								:
								(
									<button type="button" className="my-3 btn  btn-sm w-75" onClick={this.submitForm} disabled={this.state.submitEnabled ? false : true}>{this.state.chosenTab.length ? this.state.formData[this.state.chosenTab].button : "--"}</button>
								)
							}

							<button type="button" className="mb-3 btn btn-sm w-75" onClick={this.cancelModal}>Cancel</button>


							{ this.props.parentState.modalTag === 'offerAccept' ?
							(
								<>
									{/* <button type="button" className="my-3 btn  w-75" onClick={this.doPayload} data-offer={this.state.formData.offerAccept.id.value} data-type={"NFTokenCancelBuyOffer"}>Decline This Offer</button> */}
									{/* <button type="button" className="mb-3 btn w-75" data-offer={this.state.formData.offerAccept.id.value} onClick={this.declineOffer}>Decline This Offer</button> */}
									<blockquote className="w-100 mb-3 mt-2">
										Unfortunately, the XRP Ledger does not allow users to reject offers unless the potential buyer specified you as the offer destination.
									</blockquote>
								</>
							) : ( <></> )
							}

						</div>

					</div>
				)
				:
				(
					<div className="">

						{/* Message shown if the NFT is awaiting acceptance from a reserved wallet */}
						{  this.props.state.nft.mintInfo.pending ?
									(
									<blockquote className="w-100 mb-3 mt-4">
										<p className="primary">This NFT is in the process of being minted on the ledger.
										{ this.props.state.nft.mintInfo.queuePosition > 0 ? ( <>&nbsp;It is currently number {this.props.state.nft.mintInfo.queuePosition} in the queue. </>) : ( <></> )}
										&nbsp;Please check back in a few minutes.</p>

										{ this.props.state.nft.flagOwner && _showSpinButton === false ?
										(
											<>
												<p className="mt-3"><i className="fas fa-exclamation-triangle me-2 primary"></i> <strong className="primary">Please check your wallet to approve or decline the transaction.</strong></p>
												<button type="button" className="btn btn-sm w-100" onClick={this.cancelMint}>Cancel/Restart Mint</button>
											</>

										) :
										(
											<></>
										) }

									</blockquote>
								) : (<></>)
						}

						{  this.props.state.nft.mintInfo.transferPending && this.props.state.nft.mintInfo.pending === false  ?
								(
									<>
										{ this.props.state.nft.flagReservee ?
										(
											<>


												<blockquote className="w-100 mb-3 mt-4">
													<p><strong className="primary">You must sign for this NFT using your wallet to transfer ownership to yourself.</strong></p>

													{ this.props.state.nft.flagReserveeNoOffer ?
													(<p className="mt-4 text-center">
														<a className={"btn w-100 rem1"} href={this.props.config.userData.url + "/wallet-offers/"}>Re-Send Offer</a>
													</p>)
													:
													(<p className="mt-4 text-center">
														<button type="button" className={"btn w-100"} data-href={this.props.state.nft.URI} id="nft-acceptspin" onClick={this.spinAcceptCheck}>Authorize in Wallet</button>
													</p>)}

												</blockquote>
											</>
										) :
										( <></> ) }
									</>

								) : (<></>)
						}


						{/* Display buttons as the NFT data is visible */}
						{ _showEditButton ? (
							<div className="text-center d-flex justify-content-center mt-3">
								<a href="/" onClick={this.launchCRUD} data-uuid={this.props.state.nft.id} className="btn btn-sm w-100" id="nft-edit"><i className="fas fa-edit me-2"></i>Edit NFT</a>
							</div>
							) : (<span></span>) }

						{_showMintButton ? (
							<>
								<div className="text-center d-flex justify-content-center mt-3">
									<button type="button" className="btn btn-sm w-100" id="nft-mint-button" onClick={this.mintCheck}><i className={mintIcon}></i>{mintButton}</button>
								</div>
							</>
							) : (<span></span>) }

						{_showSellButton ? (
							<>
								<div className="text-center d-flex justify-content-center mt-3">
									<button type="button" className="btn w-100" id="nft-sell-button" onClick={this.sellCheck}><i className="fas fa-store me-2 fa-lg"></i>Sell this NFT</button>
								</div>
							</>
							) : (<span></span>) }

						{/*}
						{_showCancelSellButton ? (
							<>
								<div className="text-center d-flex justify-content-center mt-3">
									<button type="button" className="btn w-100" id="nft-cancelsell-button" onClick={this.cancelSellCheck}><i className="fas fa-store me-2 fa-lg"></i>Cancel Sale</button>
								</div>
							</>
							) : (<span></span>) }
							*/}

						{_showOfferButton ?
								(
								<>
									<div className="text-center d-flex justify-content-center mt-3">
										<button type="button" className={"btn btn-sm w-100"} data-href={this.props.state.nft.URI} id="nft-place-offer" onClick={this.offerCheck}><i className="fas fa-handshake me-3 fa-lg"></i>{this.props.state.nft.flagMadeAnOffer ? "Modify Offer" : "Make an Offer"}</button>
									</div>
								</>
							) : (<></>) }

						{ this.props.state.nft.mintInfo.pending === false && this.props.state.nft.collection.offers === false && this.props.state.nft.mintedQty > 0 && this.props.state.nft.flagForSale === false
								 ?
							(
							<blockquote>This collection does not allow unsolicited offers.</blockquote>
							) : ( <></>)
						}

						{_showBuyButton ?
											(
								<>
									<div className="text-center d-flex justify-content-center mt-3">
										<button type="button" className={"btn btn-sm w-100"} data-href={this.props.state.nft.URI} id="nft-buy" onClick={this.buyCheck} ><i className="fas fa-money-bill-alt me-3 fa-lg"></i>Buy It Now for <CurrencyPrice price={this.props.state.nft.askingPrice} formField={false} config={this.props.config} /></button>
									</div>
								</>
							) : (<></>) }

						{_showSpinButton ?
										(
							<>
								<p>This NFT is part of a spinner collection. You purchase spins and win a randomly selected NFT from the collection.</p><p className="text-center"><VideoLink label={"Watch our 'Spin to Win' tutorial "} tag={"spin-to-win"} linkType="link" config={this.props.config} /> </p>
								<div className="text-center d-flex justify-content-center mt-3">
									<a className={"btn btn-sm w-100"} href={this.props.state.nft.collection.url}><i className="fas fa-dice me-2 fa-lg"></i>Spin to win</a>
								</div>
							</>
						) : (<></>) }

						{ _showTransferButton ? (
							<>
								<div className="text-center d-flex justify-content-center mt-3">
									<button type="button" className="btn btn-sm w-100" id="nft-transfer" onClick={this.transferCheck} ><i className="fas fa-exchange-alt me-2 fa-lg"></i>Transfer NFT</button>
								</div>
							</>
							) : (<span></span>) }


						{_showArchiveButton ?
							(
								<div className="col-12 text-center">
									<button className="btn btn-sm btn-muted w-100 mt-3 mt-sm-4" type="button" onClick={this.archiveCheck}>Delete and archive NFT</button>
								</div>
							) : (<></>)
						}
					</div>
					)
				}


				{ this.props.state.modalVisible === false ?
				(
					<>



						{  this.props.state.nft.offers.systemPending ?
								(
									<blockquote className="w-100 mb-3 mt-2">
										<p><strong className="primary">The system is matching the buy & sell orders. This should take no more than one minute.</strong></p>
									</blockquote>
								) :
								(
									<>
									{  this.props.state.nft.offers.offersMade > 0 && this.props.state.nft.flagOwner && this.props.state.nft.offers.systemPending === false ?
											(
												<blockquote className="w-100 mb-3 mt-2">
													<p><strong className="primary">You have one or more offers to purchase this NFT. Use the "Buy Offers" tab below to review them.</strong></p>
													{/* <p className="mt-4 text-center"><a className="btn w-100" href={this.props.config.userData.url + "/tasks"}>View your tasks</a></p> */}
												</blockquote>
											) : (<></>)
									}

									{  this.props.state.nft.offers.buyOfferMade ?
											(
												<blockquote className="w-100 mb-3 mt-2">
													<p><strong className="primary">You have made an offer to buy this NFT. It can be viewed/cancelled in the "Buy Offers" tab below.</strong></p>
													{ this.props.state.nft.offers.external ?
													( <>
														<p>The offer will be matched by {this.props.state.nft.offers.sell.externalWho}. This could take a few minutes to complete. If there are any issues, please contact us.</p>
													</>
													) : ""}
												</blockquote>
											) : (<></>)
									}



									{  this.props.state.nft.offers.sell.length > 0 && this.props.state.nft.offers.offersMade === 0 && this.props.state.nft.flagOwner ?
											(
												<blockquote className="w-100 mb-3 mt-2">
													<p><strong className="primary">This NFT is currently on sale but has not yet received any buy offers.</strong></p>
												</blockquote>
											) : (<></>)
									}
									</>
								)
						}



						{  this.props.state.nft.flagForSale && this.props.state.nft.mintInfo.transferPending === false && this.props.state.nft.flagOwner  ?
								(
									<blockquote className="w-100 mb-3 mt-2">
										<p><strong className="success">This NFT is listed for sale in the marketplace and is awaiting a buyer. Refer to the "Sell Offers" tab below to review or cancel the offer.</strong></p>
										{ this.props.state.disableTransfers === false ? ( <p>To transfer the NFT to another use for 0 XRP, you need to cancel your existing sell offer. The transfer option will be enabled.</p> ) : ( <></> )}
									</blockquote>
								) : (<></>)
						}

						{ 0 === 1 & this.props.state.nft.flagForSale && this.props.state.nft.mintInfo.transferPending === false && this.props.state.nft.flagOwner && this.props.state.nft.offers.external ?
								(
									<blockquote className="w-100 mb-3 mt-2">
										<p className="danger fw-bold text-uppercase">This is a locked sale through { this.props.state.nft.offers.sell.offerVia.length ? ( <span> {this.props.state.nft.offers.sell.offerVia}</span> ) : ( <span> an external marketplace</span> )}.</p>
										<p className="danger">The sale is locked to ensure that only they can complete the sale and receive a commission. xMart cannot complete the sale for you if a buyer is found.</p>
										<p className="danger">If you wish to enjoy a <strong>Fee-free sale</strong> which is <strong>not locked</strong> to just one marketplace, click the "Sell Offers" tab below and use the cancel sale button You can then relist the NFT for sale on xMart.</p>
									</blockquote>
								) : (<></>)
						}


						{ 0 === 1 &&  this.props.state.nft.flagForSale && this.props.state.nft.mintInfo.transferPending === false && this.props.state.nft.flagOwner === false && this.props.state.nft.offers.external ?
								(
									<blockquote className="w-100 mb-3 mt-2">
										<p className="danger fw-bold text-uppercase">This is a locked sale through { this.props.state.nft.offers.sell.offerVia.length ? ( <span> {this.props.state.nft.offers.sell.offerVia}</span> ) : ( <span> an external marketplace</span> )}.</p>
										<p className="danger ">Unfortunately, xMart is unable to complete the transaction as they have set a destination flag on the sell offer. This means only they are able to broker a deal for you.</p>
										<p className="danger ">However, you can still <strong>"Make an offer"</strong> for this NFT and await the sellers response. The nearer your offer is to the original sale offer will improve your chances of being accepted. </p>
									</blockquote>
								) : (<></>)
						}



						{/* This is for wallet transactions */}
						{  this.props.state.nft.mintInfo.transferPending && this.props.state.nft.mintInfo.pending === false  ?
								(
									<>
										{ this.props.state.nft.flagReservee ?
										(
											<>
												{/* Handled in modalViewNFTButtons */}
											</>
										) :
										(
											<blockquote className="w-100 mb-3 mt-2">
												<p><strong className="primary">The new owner of this NFT has not yet accepted the token sale offer.</strong></p>
											</blockquote>
										) }
									</>

								) : (<></>)
						}



						{/*
								{ this.props.state.nft.flagOwner && this.props.state.nft.mintInfo.pending === false && this.props.state.nft.mintInfo.transferPending === false && this.props.state.nft.mintedQty > 0 ?
								(
									<div className="text-center mt-5 mb-5">
										<a href={"/store/?deeplink=/aliprints/350x500W/" + this.props.state.nft.mintInfo.address}><img src="/img/store/cta-1.png" alt="Make it Physical!" /></a>
									</div>
								)
								: (<></>)}
								*/}
						</>
				) :
				(
					<></>
				)
				}


				<button type="button" id="showModal" data-tag="?" className="hid" onClick={this.showModal}>SHOW MODAL TAB</button>

				<button type="button" id="showModalOfferButton" data-offer="??" className="hid" onClick={this.offerAcceptCheck}>offer</button>

				<button type="button" id="showModalCancelSellOfferButton" className="hid" data-offer="??" data-offernetwork="??" onClick={this.cancelSellOfferCheck}>sale</button>

			</>


        );
    }
}

function launchModal(tag)
{
	var _modalButton = document.getElementById("showModal");
	_modalButton.dataset.tag = tag;
	_modalButton.click();
}

export default ModalViewNFTButtons;

