import React, { Component } from 'react';
import axios from 'axios';
import ViewItems from '../Tools/ViewComponent'

class viewChainNFTS extends Component {

	state = {
        address: "",
		dataLoading:false,
		dataLoaded:false,
		addressSearched:"",
		hasPending:false,
		hasErrors:false,
		data:[]
    }

	// rPcCsv7uwNiKWp3UCF6MTBXVjazhZAXyxs

	handleWalletChange = e =>
	{
		this.setState({address:e.target.value.trim()})
		window.setTimeout( () => { this.setState({disabled:this.isDisabled()}) }, 100)
	}

	isDisabled = e =>
	{
		const RippleAddressRegex = new RegExp(/r[0-9a-zA-Z]{24,34}$/)
		var _eval;

		try{
			_eval = this.state.address.match(RippleAddressRegex) ? false : true;
		} catch(e)
		{
			_eval = true
		}

		return _eval;
	}

	getNFTS = e =>
	{

		this.setState({data:[], dataLoaded:false, dataLoading:true, addressSearched:this.state.address })

		const authURL = this.props.config.apiServer + "/com/external/wallet.cfc?method=getNFTS&address=" + this.state.address;

		axios.get(authURL, {withCredentials: true})
		.then(res => {

			if(res.data.success)
			{
				this.setState({
									data:res.data.nfts,
									dataLoaded:true,
									dataLoading:false,
									hasPending:res.data.pending > 0 ? true : false,
									hasErrors:res.data.errors > 0 ? true : false
								})
			}
			else
			{
				throw(res.data.errorMessage)
			}
		})
		.catch(err =>
			{
				window.displayErrorMessage(err)
				this.setState({data:[], dataLoaded:false, dataLoading:false })
			})
	}

    componentDidMount(){

		var _address = "";
		if(this.props.config.urlParts.length >= 4 && this.props.config.urlParts[3].length > 20)
		{
			_address = this.props.config.urlParts[3].trim()
			window.setTimeout(() => {
					document.getElementById("loader").click()
					this.setState({disabled:this.isDisabled()})
				}, 200)
		}

		this.setState({
						disabled:this.isDisabled(),
						address:_address
					})




    }
    render() {

		return (
			<section className="popular-collections-area">
                <div className="container p-0">
                    <div className="row">
                        {/* Main */}

						<button id="loader" className="hid" onClick={this.getNFTS}>Reload</button>

						<div className="card nohover mt-3">
							<div className="row">
								<div className="col-12 text-center mb-4">
									<p>To view the NFTs owned by another wallet holder, simply enter their public wallet address below and click "View NFTs".</p>
								</div>

								<div className="col-12 col-md-8 mt-1 mb-4">
									<input type="text" className="form-control" size="36" id="wallet" name="wallet" defaultValue={this.state.address} onChange={this.handleWalletChange} />
								</div>

								<div className="col-12 col-md-4">
									<button className="btn w-100" id="save-button" disabled={this.state.disabled} type="submit" onClick={this.getNFTS}>View NFTs</button>
								</div>
							</div>
						</div>

						<div className="col-12">
							{ this.state.dataLoading ?
								(
										<div className={'mt-5 text-center'}>
											<h3>Loading NFTs from ledger</h3>
											<img src={this.props.config.sizes.placeholders.preloader} alt="Loading.." />
										</div>
								) :
								(
									<>
										{this.state.dataLoaded ?
										(
											<>
												<h3 className={'bigdots mt-5 pb-3'}>
													NFTs belonging to {this.state.addressSearched}
												</h3>

												{ this.state.hasErrors ?
														(
															<div className="alert alert-danger text-center" role="alert">
																<p><i className="fas fa-exclamation-triangle me-2 fa-lg"></i> There were one or more NFTs which had errors. We were unable to retrieve either the image or metadata from the cloud.</p>
															</div>) : ( <></> )}

												{ this.state.hasPending ?
														(
															<div className="alert alert-warning text-center" role="alert">
																<p><i className="fas fa-exclamation-triangle me-2 fa-lg"></i> We are currently indexing the NFTs for this wallet - This may take some time to generate the thumnbails &amp; get the descriptions as we are downloading the data from an external source (For example <a href="https://en.wikipedia.org/wiki/InterPlanetary_File_System" rel="noreferrer" target="_blank">IPFS</a>).</p>
																<p>Please check back later to view the results - We cannot predict how long it may take as it depends network conditions.</p>
															</div>) : ( <></> )}

												<h4 className={this.state.dataLoaded && this.state.data.length === 0 ? 'mt-5' : 'hid'}>There are no NFT's in this wallet <span className="primary">:-(</span></h4>
											</>
										)
										: (<></>)
										}
									</>
								)
							}
						</div>

						{/* Display items */}
						<ViewItems config={this.props.config} nfts={this.state.data} walletAddres={this.state.address} />

                    </div>
                </div>
            </section>
        );
    }
}

export default viewChainNFTS;