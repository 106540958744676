import React, { Component } from 'react';
import LoadingMessage from './loading-message';

class CollectionSpinnerDownloadingComponent extends Component {

	render() {

		const _progressWidthMedia = this.props.state.collectionData.bulkInfo.stats.media.percent + "%"

		const styleMedia = "progress-bar " + ( this.props.state.collectionData.bulkInfo.stats.media.percent > 0 && this.props.state.collectionData.bulkInfo.stats.media.percent < 100 ? "progress-bar-striped progress-bar-animated" : "" );

		return (

			<div className="row mt-5">

				<div className="col-12">
					<h2 className="mt-5 text-center">Media Downloading</h2>
					{
						this.props.config.userData.display || ( this.props.state.isMyCollection && this.props.state.collectionData.bulkInfo ) ?
						(
							<>
								<p className="text-center">The media and metadata for this collection is being downloaded from a remote source.</p>
								<p className="text-center">This could take a while depending on file sizes.</p>
								<LoadingMessage config={this.props.config} state={this.props.state} />




								{ this.props.state.collectionData.bulkInfo.stats.media.size > 0 ? (
										<>
											<h3>Download Progress:-</h3>
											<h4>Media Files</h4>
											<div className="progress" id="progress-media" style={{"height":"35px"}}>
												<div className={styleMedia} role="progressbar" style={{width:_progressWidthMedia, minWidth:"20%"}} aria-valuenow={this.props.state.collectionData.bulkInfo.stats.media.percent} aria-valuemin="0" aria-valuemax={this.props.state.collectionData.bulkInfo.stats.media.percent}>{this.props.state.collectionData.bulkInfo.stats.media.percent}% of { (this.props.state.collectionData.bulkInfo.stats.media.size / 1024 / 1024).toFixed(2) } MB</div>
											</div>
										</>
										) : ( <></> )
								}



							</>
						)
						:
						( <></> )
					}
					{
						this.props.config.userData.display ?
						(
							<>
								<p className="text-center mt-5">ADMIN MESSAGE: Once uploaded or if you suspect it's broken, <a href={this.props.config.apiServer + "/com/cron/tasks/imports-downloader.cfm"} rel="noreferrer" target="_blank">click here to check the download progress for errors</a></p>
								{
									this.props.state.collectionData.bulkInfo.importType === 3 ?
									( <p className="text-center fw-bold">You need to drop the ZIP file into import/{this.props.state.collectionData.recordID}/</p>)
									:
									( <></> )
								}
							</>
						)
						:
						( <></> )
					}
				</div>
			</div>

        );
    }
}

export default CollectionSpinnerDownloadingComponent;