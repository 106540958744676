import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import Masonry from 'react-masonry-css';

import { numberRounder, preloader } from '../../components/xutils'
import "../../css/authorcollections.css"

import NFTBrowser from '../../components/NFT/cBrowse';


import axios from 'axios';

class pageContent extends Component {


	state = {
        data: [],
		function: this.props.config.urlParts.length <=3 ? "" : ( this.props.config.urlParts.length >= 4 & this.props.config.urlParts[3].length ? this.props.config.urlParts[3] : "" ),
		profileData:{title:"", urlPath:"", url:"", social:[], profileImageM:"", profileImageL:""},
		dataType:"null",
		categories:[],
		noDataMessage:"",
		breadCrumb:"",

		collectionID:"",
		collectionTitle:"",
		collectionImage:"",
		collectionURI:"",
		indexes:"",
		totalNFTs:0,
		nftsImporting:0,
		nftsImportingRetry:0,

		collectionData:{},
		profile: this.props.config.urlParts[2]
    }

	back = e =>
	{
		this.setState({
			collectionID:"",
			collectionTitle:"",
			collectionImage:"",
			collectionURI:"",
			collectionTotal:0,
			collectionCatchall:false
		})

		window.location.hash = "";

		window.setTimeout( () => { window.$( "img" ).trigger("resize"); }, 100)

	}

	viewCollection = e =>
	{

		/*Scroll to the top of the filters */
		window.setTimeout( () => {

			window.scrollTop()

		}, 200)

		window.location.hash = e.currentTarget.dataset.uuid.split(":")[0];

		const _title = e.currentTarget.dataset.catchall === "Yes" ? "NFTs Not In A Collection" : e.currentTarget.dataset.title;

		this.setState({
							collectionID:e.currentTarget.dataset.uuid,
							collectionTitle:_title,
							collectionImage:e.currentTarget.dataset.preview,
							collectionURI:e.currentTarget.dataset.uri,
							collectionTotal:e.currentTarget.dataset.total,
							collectionCatchall:e.currentTarget.dataset.catchall === 'Yes' ? true : false
						})

	}

	componentDidMount(){

		/* A profile's collections */
		var BASE_URL = this.props.config.apiServer + "/com/external/collections.cfc?method=getCollectionGroups&includeNFTS=true&datatype=featured&results=1000&profile="+this.props.who;

		axios.get(`${BASE_URL}`, {withCredentials: true})
			.then(res => {

				if( ! res.data.success )
				{
					throw new Error(res.data.errorMessage);
				}
				else
				{
					const isThisMyCollection = this.props.config.urlParts.length >= 3 && this.props.config.urlParts[2] === this.props.config.userData.urlPath;

					if(isThisMyCollection)
					{
						document.title = `My NFTs ${this.props.config.meta.title}`;
						window.$('#breadcrumb-title').html(`NFTs Owned by You`)
					}
					else
					{
						document.title = `NFTs owned by "${res.data.owner}" ${this.props.config.meta.title}`;
						window.$('#breadcrumb-title').html(`NFTs Owned by "${res.data.owner}"`)
					}

					this.setState({
						data: res.data.groups,
						indexes:res.data.indexes,
						totalNFTs:Number(res.data.total),
						nftsImporting:Number(res.data.nftsImporting),
						nftsImportingRetry:Number(res.data.nftsImportingRetry),
						loading:false
					})

					/* Initialise jQuery components */
					window.setTimeout(() => {
						window.loadPreloadImages()
					}, 300);

					if(window.location.hash.length > 20)
					{
						window.setTimeout(() => {
							window.$('.'+window.location.hash.substring(1)).trigger("click")
						}, 600);
					}


				}

			})
		.catch(err => window.displayErrorMessage("Error: " + err.message, false, false) )


    }

    render() {

		var colClass, preloadKey = 1;

		const _sizes = { default: 5, 1200:5, 992: 4, 768: 3, 576: 2 };


		//const _sizes = this.props.config.masonryBreakpoints
		if(this.props.config.wideScreen)
		{
			colClass = "col-6 col-md-1 col-lg-1 col-xl-2 col-xxl-2 ";
		}
		else
		{
			colClass = "col-6 col-md-1 ";
		}


		const lettersAndNumbers = ['0', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

		{/* Populate a-z index */}
		var childElements = lettersAndNumbers.map((item, idx) =>
			{
				return ( <li key={`atoz_${idx}`}>
							<a href={this.state.indexes.indexOf(item) > -1 ? `#${item}` : ""} className={this.state.indexes.indexOf(item) === -1 ? 'disabled' : ''}>{item}</a>
						</li> )
			})


		var childElementsGroups = this.state.data.map((item, idx) => {

			//	var _nftPath = `/profile/${item.profilePath}/view-nft/${item.id}`;
			const _img = item.preview.length ? this.props.config.apiServer + item.preview : this.props.config.sizes.placeholders.square;

			return (
				<div key={`exo_${idx}`} className={"item explore-item mt-0 cursor-pointer " + item.id.split(":")[0]} id={item.index} onClick={this.viewCollection} data-uuid={item.id} data-preview={_img} data-title={item.title} data-total={item.nfts} data-uri={item.uri} data-catchall={item.catchAll ? "Yes" : "No"}>
					<div className={"card mb-4 p-0"}>

						<a href={item.collectionURL}>

							<div className="image-over">

								<img className="card-img-top preload-image" src={this.props.config.sizes.placeholders.loader} id={"preload_featured_"+preloadKey++} data-load={_img} alt={item.title} />

								{/*
								<img className="card-img-top preload-image" src={this.props.config.sizes.placeholders.loader} id={"preload_featured_"+preloadKey++} data-load={_img} alt={item.title} />
								<img className="card-img-top preload-image" src={this.props.config.sizes.placeholders.loader} id={"preload_featured_"+preloadKey++} data-load={_img} alt={item.title} />
								<img className="card-img-top preload-image" src={this.props.config.sizes.placeholders.loader} id={"preload_featured_"+preloadKey++} data-load={_img} alt={item.title} />
								<img className="card-img-top preload-image" src={this.props.config.sizes.placeholders.loader} id={"preload_featured_"+preloadKey++} data-load={_img} alt={item.title} />
								*/}

								{/* This will only show on featured projects page and be hidden if a profile is specified */}
								{/*
								<span className={_className}>
									<div className="seller-thumb avatar-lg">
										<img className="rounded-circle preload-image" src={this.props.config.sizes.placeholders.loader} id={"preload_featured_"+preloadKey++} data-load={_creatorImage} alt={item.creatorName}/>
									</div>
								</span>
								*/}


							</div>

							<div className="card-caption col-12 px-2">
								<div className="card-body pt-2">
									<h5 className="mb-2 ellipsis">{item.catchAll ? "NFTs Not In A Collection" : `${item.title}` }</h5>
									<h6 className="mb-2 ellipsis">{item.catchAll ? "" : `By ${item.creatorName}` }</h6>
								</div>
							</div>

						</a>

						{/*
							<div className="card-links">

							<div className="row mb-1">
								<div className="px-2 pb-2 text-start col-12">
									<span title="Total NFT's in collection" data-bs-toggle="tooltip" data-bs-placement="bottom" >Contains {numberRounder(item.nfts)} { item.nfts === 1 ? "NFT" : "NFTs" }</span>
								</div>
							</div>

							</div>
						*/}

					</div>

				</div>
			);

		})


        return (
            <div className="main">
                <Header				config={ this.props.config } state={this.state} />
                <Breadcrumb			config={ this.props.config } state={this.state} title="My NFTs" background="null" subpage="Tools" page="My NFTs" />

				<section className="popular-collections-area p-0 mt-4">
					<div className={this.props.config.wideScreenClass}>

						{ this.state.nftsImporting > 0 && 0 === 1 ?
						(
							<div class="alert alert-success mt-4">We are currently of importing {this.state.nftsImporting} NFTs from your wallet. This could take some time based on server load. {this.state.nftsImportingRetry > 0 ? ( <span>( {this.state.nftsImportingRetry} are pending a retry. )</span> ) : ''}</div>
						) : (<></>)}

						{ this.state.collectionID.length ?
						(
							<>

								{/*
								<div className="row mb-3 pb-2 bigdots" id="collections-header">
									<div className="col-12">
										<div className="intro d-flex justify-content-between align-items-end m-0">
											<div className="intro-content">
												<h3 className="mb-0">
													<button href="/" className="btn underline" onClick={this.back}> My NFTs</button> \\ <a href={this.state.collectionURI} target="_blank">{this.state.collectionTitle} <i className="ms-2 fas fa-arrow-right"></i></a>
												</h3>
											</div>
										</div>
									</div>
								</div>

								{this.state.collectionTotal ? (<>({this.state.collectionTotal})</>) : (<></>)}


								*/}

								<div className="mb-2 pb-2 bigdots" id="collections-header">
									<div className="intro-content primary text-center">
										<button className="btn py-1 mt-1 btn-sm underline mb-2" onClick={this.back}>
											<span>
												<i className="me-2 fas fa-backward"></i> My NFTs
											</span>
											</button>
										<span>

											<a href={this.state.collectionURI} className='btn btn-bordered py-2 mb-1 ms-2' target="_blank">
												{this.state.collectionTitle}
												<i className="ms-2 fas fa-external-link-alt"></i>
											</a>
										</span>
									</div>
								</div>

								<div className="row d-flex">
									<div className="col-12">
										<NFTBrowser
											config={ this.props.config }
											breakPoints={ this.props.config.masonryBreakpoints }
											state={this.state}
											collectionID={this.state.collectionID}
											showSweep={false}
											title="null"
											dataType={"purchased"}
											infiniteScroll={true}
											bulkCollection={false}
											maxResults={0}
											who={this.props.who}

											/>
									</div>

								</div>
							</>
						)
						:
						(

							<>


								{/*
									<div className="mb-2 pb-2 bigdots" id="collections-header">
										<div className="intro-content primary text-center">
											<button className="btn py-1 btn-bordered" type="button"> My NFTs</button>
										</div>
									</div>
									*/}


								{/*
								<ul id="a-to-z-index" className="align-items-start flex-column">
									{childElements}
								</ul>
								*/}

								<Masonry breakpointCols={this.props.config.masonryBreakpoints} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
									{childElementsGroups}
								</Masonry>

								<div className={"row items explore-items d-flex justify-content-center pe-5"}  id="theForm">
									{ this.state.data.length === 0 ? ( <h3 className="my-5 text-center primary">There are no items available.</h3> ) : ( <></> )}
								</div>
							</>

						)
						}




					</div>
				</section>

                <Footer				config={ this.props.config }/>
                <ModalSearch		config={ this.props.config } />
                <ModalMenu			config={ this.props.config } />
                <Scrollup			config={ this.props.config } />
            </div>
        );
    }
}

export default pageContent;