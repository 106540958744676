import React, { Component } from 'react';
import LoadingMessage from './loading-message';

class CollectionSpinnerDownloadPendingComponent extends Component {

	render() {

		return (

			<div className="row mt-5">

				<div className="col-12">
					<h2 className="mt-5 text-center">Media Download Pending</h2>
					{
						this.props.config.userData.display || this.props.state.isMyCollection ?
						(
							<>
								<p className="text-center">The media and metadata for this collection will start downloading shortly.</p>
								<LoadingMessage config={this.props.config} state={this.props.state} />
							</>
						)
						:
						( <></> )
					}
					{
						this.props.config.userData.display ?
						(
							<>
								<p className="text-center">ADMIN MESSAGE: Once uploaded or if you suspect it's broken, <a href={this.props.config.apiServer + "/com/cron/tasks/imports-downloader.cfm"} rel="noreferrer" target="_blank">click here to check the download progress for errors</a></p>
							</>
						)
						:
						( <></> )
					}
				</div>
			</div>

        );
    }
}

export default CollectionSpinnerDownloadPendingComponent;