import React, { Component } from 'react';
import { toDaysMinutesSeconds } from '../xutils'
import { VideoLink } from '../videoLink'
import SpinnerBuyButton from './spinner-buy-button'

var t = new Date();
t.setSeconds(t.getSeconds() + 2);
var te = new Date();
te.setSeconds(te.getSeconds() + 222);

var startInterval=null
var endInterval=null

class LaunchPadComponent extends Component {

	state=	{
				showCountdown:0,
				showEndCountdown:0,
				timeTilLaunch:0,
				timeTilEnd:0,
				started:false,
				ended:false,
				startDate:this.props.state.collectionData.launchpadInfo.startDate,
				xstartDate:Number(t.getTime().toString().substring(0,10)),
				endDate:this.props.state.collectionData.launchpadInfo.endDate,
				xendDate:Number(te.getTime().toString().substring(0,10)),

			}

	componentDidMount()
	{
		this.updateState()
	}

	updateState = e =>
	{
		const currentTimelocal = new Date()
		const currentUTC = Number(currentTimelocal.getTime().toString().substring(0,10))

		/* Initialise start countdown */
		if( this.state.showCountdown === 0)
		{
			if(this.state.startDate > currentUTC)
			{
				this.setState({showCountdown:1, started:false })
				if(startInterval === null)
				{
					window.setTimeout( () => { startInterval = window.setInterval( () => { this.updateState() }, 1000 ) }, 100 )
				}
			}
			else
			{
				//this.setState({showCountdown:2, started:true})
				//this.updateStateEnd()
			}
		}

		const _ttl = this.state.startDate - currentUTC

		this.setState({
						timeTilLaunch: _ttl,
						started:_ttl <=0,
					})

		if(_ttl <= 0 )
		{

			//window.showWaitMessage("Collection has launched - Reloading Spinner");
			//document.location.reload()


			this.setState({showCountdown:2, started:true })
			window.clearInterval(startInterval)
			window.setTimeout( () => { window.setTimeout( () => { this.updateStateEnd() }, 1000 ) }, 100 )


		}


	}


	updateStateEnd = e =>
	{

		if(this.state.showCountdown !== 1)
		{
			const currentTimelocal = new Date()
			const currentUTC = Number(currentTimelocal.getTime().toString().substring(0,10))

			/* Initialise start countdown */
			if( this.state.showEndCountdown === 0)
			{
				if(this.state.endDate > currentUTC)
				{
					this.setState({showEndCountdown:1 })
					if(endInterval === null)
					{
						endInterval = window.setInterval( () => { this.updateStateEnd() }, 1000 )
					}
				}
				else
				{
					//this.setState({showEndCountdown:2, started:true})
					//window.clearInterval(endInterval)
				}
			}

			const _tte = this.state.endDate - currentUTC

			this.setState({
							timeTilEnd: _tte,
							ended:_tte <= 0
						})

			if(_tte <= 0 )
			{
				this.setState({showEndCountdown:2 })
				window.clearInterval(endInterval)
			}
		}
	}


    render() {

		return (

			<>

				{/* Spacer div */}
				<div className="mt-5 d-none d-md-block"></div>

				{ this.state.showCountdown === 1 ?
					(
						<div className="row">
							<div className="col-12">
								<h3 className="mt-1 mb-1 text-center primary">Launchpad will open in:-</h3>
								<h3 className="mt-0 text-center">{toDaysMinutesSeconds( this.state.timeTilLaunch, true, true, true )}</h3>
							</div>
						</div>)
				: (<></>)}

				{ this.state.showEndCountdown === 1 ?
					(
						<div className="row">
							<div className="col-12">
								<h4 className="mt-1 mb-1 text-center">Launchpad will close in:-</h4>
								<h5 className="mt-0 text-center">{toDaysMinutesSeconds( this.state.timeTilEnd, true, true, true  )}</h5>
							</div>
						</div>)
				: (<></>)}


				{ this.props.state.collectionData.spinnerInfo.spinsLeft === 0?
				(
					<>
						<h3 className="primary text-center">This Launchpad has no spins left</h3>
						<p>Unfortunately, all spins have been purchased for this collection. Keep an eye on the collection to see what comes up for sale.</p>
						{ this.props.state.collectionData.br === 'Yes' ?
						(
							<p>Click the "View Collection Items" link to browse the collection.</p>
						) : ( <></>)}
					</>
				) :
				(
					<>

						<div className="m-2 d-none d-md-block text-center">
							<VideoLink label={"How Does The Spinner Work?"} tag={"spin-to-win"} linkType="link" config={this.props.config} />
						</div>

						{ this.state.ended ?
							(
								<div className="text-center mt-3">

									<p><strong className="primary">Hurry!!</strong> First-come, first-served basis. Buy spins to mint a random NFT. Each spin costs <strong className="primary">{this.props.state.collectionData.launchpadInfo.costAfterLaunch} XRP</strong>.</p>

									{ this.props.state.collectionData.launchpadInfo.postTokens === 'Yes' ? ( <p>You can also use your <strong className="primary">{this.props.state.collectionData.trustLineCurrency} tokens</strong> for spins. Each spin costs <strong className="primary">{this.props.state.collectionData.trustLinePerSpin} {this.props.state.collectionData.trustLineCurrency}</strong>.</p>) : ( <></> )}

								</div>)
							:
							(
								<div className="text-center mt-3">
									{ this.props.state.collectionData.trustLineCurrency === 'XRP' ?
										(
											<>
												<p><strong className="primary">Hurry!!</strong> First-come, first-served basis. Buy spins to mint a random NFT. Each spin costs <strong className="primary">{this.props.state.collectionData.trustLinePerSpin} XRP</strong>.</p>

												{ Number(this.props.state.collectionData.launchpadInfo.costAfterLaunch) > 0 ? ( <p>After the pre-sale, the price per spins will be set at <strong className="primary">{this.props.state.collectionData.launchpadInfo.costAfterLaunch} XRP</strong>.</p> ) : ( <></> )}


											</>
										) :
										(
											<>

												<p><strong className="primary">Hurry!!</strong> First-come, first-served basis. You need to swap <strong className="primary">{this.props.state.collectionData.trustLineCurrency} tokens</strong> for spins. Each spin costs <strong className="primary">{this.props.state.collectionData.trustLinePerSpin} {this.props.state.collectionData.trustLineCurrency}</strong>.</p>


												{ this.props.state.collectionData.launchpadInfo.postTokens === "Yes" ?
												(
													<p>After the pre-sale, you will be able to buy spins for <strong className="primary">{this.props.state.collectionData.launchpadInfo.costAfterLaunch} XRP</strong> and continue to use <strong className="primary">{this.props.state.collectionData.trustLineCurrency} tokens</strong>.</p>
												)
												:
												(
													<p>After the pre-sale, you will be able to buy spins for <strong className="primary">{this.props.state.collectionData.launchpadInfo.costAfterLaunch} XRP</strong>.</p>
												)
												}


											</>
										)

									}

								</div>
							)
						}


					</>
				)
				}

				{ this.state.ended === false && this.props.state.collectionData.launchpadInfo.oTL === false ?
				(
					<>
						<div className="alert alert-warning mt-4 text-center">
							<p className="fw-bold">This Launchpad is for invited members only.</p>
							{ this.state.started === true ? ( <p>You will be able to access it once the Launchpad has closed (See the timer below)</p> ) : ( <></> )}
						</div>
					</>
				) :
				(
					<>
						{/* SHOW ONLY TO DESKTOP USERS */}
						{/* SHOW ONLY TO DESKTOP USERS */}
						{/* SHOW ONLY TO DESKTOP USERS */}
						{ this.props.state.collectionData.launched ?
						(
							<div className="d-none d-md-block mt-3">
								<SpinnerBuyButton
												state={this.props.state}
												parentState={this.props.parentState}
												config={this.props.config}
												spinInfo={this.props.state.spinInfo}
												qtyLeft={this.props.qtyLeft}
												getFreeSpin={this.getFreeSpin}
												showTokenButton ={this.props.showTokenButton}
												showXRPButton={this.props.showXRPButton}
												showPromoButton={this.props.showPromoButton}
												checkTotals={this.props.checkTotals}
												showTotalMessage={true}
												disabled={this.state.showShuffleDiv  || this.state.shufflePendingPayment} />
							</div>
						) : ( <></>)}
					</>
				)}

			</>

		);
    }
}

export default LaunchPadComponent;