import React, { Component } from 'react';


import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import AuthorProfileCrud from '../../components/AuthorProfile/crud';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
class authorCrud extends Component {

	state = {
        profileData: this.props.config.userData,
		vanityOriginal:this.props.config.userData.urlPath
    }

    componentDidMount(){

		window.initialiseJQ();

    }

    render() {

		const subPage = "My Profile";
		const tImage = this.state.profileData.headerImage.length ? this.props.config.apiServer + this.state.profileData.headerImage : `/img/placeHolders/${this.props.config.sizes.collectionBanner.width}x${this.props.config.sizes.collectionBanner.height}.jpg`;

		return (
            <div className="main">
                <Header					config={ this.props.config } state={this.state} />
                <Breadcrumb				config={ this.props.config } state={this.state} title={this.state.profileData.title} subpage={subPage} page="Edit My Profile" background={tImage}  />

                <AuthorProfileCrud		config={ this.props.config } state={this.state} />

                <Footer					config={ this.props.config } />
                <ModalSearch			config={ this.props.config } />
                <ModalMenu				config={ this.props.config } />
                <Scrollup				config={ this.props.config } />
            </div>
        );
    }
}

export default authorCrud;