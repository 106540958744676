import React, { Component } from 'react';

class CollectionSpinnerNotFundedComponent extends Component {

	render() {

		return (

			<div className="row mt-5">

				<div className="col-12">
					<h2 className="mt-5 text-center">The collection wallet has not yet been activated</h2>


					{
						( this.props.config.userData.display || this.props.state.isMyCollection ?
							(
								<>
									<p className="text-center">Ensure the collection has been funded with at least {this.props.config.fees.collectionMinimumBalance} XRP - <a href={this.props.state.collectionData.explorer} rel="noreferrer" target="_blank">Check it here</a></p>
									<p className="text-center">Once funded, it can take about 1 minute before the balance relects here.</p>
								</> )
							:
							( <><p className="text-center mt-5">If you are the owner of this collection, please log in to view further instructions.</p></>  )
						)
					}


					{
						this.props.config.userData.display ?
						(
							<>
								<p className="text-center">ADMIN MESSAGE: Once funded, <a href={this.props.config.apiServer + "/toolkit/collections/check-balance.cfm?collection=" + this.props.state.collectionData.urlPath} rel="noreferrer" target="_blank">click here to re-check the balance</a></p>
							</>
						)
						:
						( <></> )
					}


				</div>
			</div>

        );
    }
}

export default CollectionSpinnerNotFundedComponent;