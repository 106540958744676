import React, { Component } from 'react';
import ModalCRUDNFT from '../Modal/ModalCRUDNFT';
import { loginCheck, setupXmartDropzones, showWaitMessage, hideWaitMessage, displayErrorMessage, uuidv4, notify } from '../xutils';
import axios from 'axios';
import validator from "validator";
import ChatComponent from "./chat";

class Footer extends Component {

	state = {
		loading:true,
		nft:{},
		emailAddress:"",
		subscribed:false
	};


	componentDidMount(){

    }

	launchCRUD = e =>
	{
		if(this.props.config.userData.id.length)
		{
			e.preventDefault()
			document.getElementById("crud-nft-loader-button").dataset.nft = "";
			document.getElementById("crud-nft-loader-button").dataset.collection = "";
			document.getElementById("crud-nft-loader-button").click()
		}
	}

	changeEmail = e =>
	{
		this.setState({emailAddress:e.currentTarget.value})
	}

	joinMailer = e =>
	{
		var _isValid = validator.isEmail(this.state.emailAddress);

		if(_isValid)
		{

			var formData = new FormData();
			formData.append("email", this.state.emailAddress);
			formData.append("method", "subscribe");

			var BASE_URL = this.props.config.apiServer + "/com/external/mailshot.cfc";
			axios.post(`${BASE_URL}`, formData, {withCredentials: true})
				.then(res => {
					if(!res.data.success)
					{
						displayErrorMessage(res.data.errorMessage)
					}
					else
					{
						notify("Welcome to the club. You've been added to the mailing list.", "center")
						this.setState({subscribed:true})

					}
				})
			.catch(err => {
				window.logger(err)
			})
		}
		else
		{
			displayErrorMessage("This doesn't appear to be a valid email address")
		}

	}


	myCallback = (data) =>
	{
		/* Update the total in the parent form */
		var _nftData = this.state.nft;
		_nftData[data.field] = data.value;
		this.setState({nft:_nftData});
	}

	myCallbackXumm = () =>
	{
		// debugger;
	}

	prepareCRUD = e =>
	{

		loginCheck( this.props.config );
		this.setState({ loading:true })

		var _showWindow = false

		if(e.currentTarget.dataset.nft)
		{

			var BASE_URL = this.props.config.apiServer + "/com/external/nfts.cfc?method=getNFT&uuid="+e.currentTarget.dataset.nft;
			var _nftData

			axios.get(`${BASE_URL}`, {withCredentials: true})
				.then(res => {
					if(!res.data.success)
					{
						window.Swal.fire( { html:res.data.errorMessage } )
						window.$('#nft-crud').hide();
					}
					else
					{

						var _cid = res.data.nft.flagInCollection === false ? "null" : res.data.nft.collection.id.toLowerCase();
						window.nftData = res.data.nft;

						var tAttributes = res.data.nft.attributeData.length ? res.data.nft.attributeData : []

						_nftData = {
							temp:				false,
							title:				res.data.nft.title,
							description:		res.data.nft.description,
							link:				res.data.nft.link,
							price:				res.data.nft.askingPrice,
							royalty:			res.data.nft.royalty,
							previewImage:		res.data.nft.preview.length ? this.props.config.apiServer + res.data.nft.preview : '/img/placeHolders/upload.png',
							collection:			_cid,
							network:			res.data.nft.network.toLowerCase(),
							hasAttributes:		res.data.nft.collection.hasAttributes,
							mintedQty:			0,
							supply:				1,
							saveButton:			"Save changes",
							windowTitle:		"Edit an NFT",
							saveDisabled:		true,
							formCompleted:		false,
							attributeData:		tAttributes,
							metadataEditable:	false,
							preview:			"",
							id:					res.data.nft.id.toLowerCase()
						}

						this.showWindow(_nftData)



					}
				})
			.catch(err => {
					window.logger(err)
				})
		}
		else
		{

			var _cid = this.props.config.userData.collections.length === 0 ? "null" : ( typeof e.currentTarget.dataset.collection !== 'undefined' ? e.currentTarget.dataset.collection.toLowerCase() : "");

			/* Just set up dropzone */
			_nftData = {
								temp:				true,
								title:				"",
								description:		"",
								link:				"",
								price:				"",
								royalty:			"",
								previewImage:		'/img/placeHolders/upload.png',
								collection:			_cid,
								network:			this.props.config.fees.defaultNetwork.toLowerCase(),
								hasAttributes:		false,
								mintedQty:			0,
								supply:				1,
								saveButton:			"Create NFT",
								windowTitle:				"Edit an NFT",
								saveDisabled:		true,
								formCompleted:		false,
								attributes_json:	"[]",
								attributeData:		[],
								preview:			"",
								id:					uuidv4()
							}

			this.showWindow(_nftData)

		}

	}


	showWindow = _nftData =>
	{
		this.setState({
			nft:_nftData
		})

		/* Show the modal */
		document.getElementById("crud-nft-popper-button").click()

		setTimeout(() => {

				window.$('#nft-img').prop("src", this.state.nft.previewImage);

				this.setState({
					loading:false
				})

		}, 600)

		/* Leave enough delay for form to be rendered */
		setTimeout(() => {

			window.$('#validate-form-button').trigger("click");
			window.$('#title').focus();
			if (_nftData.temp)
			{
				document.getElementById("get-collection-info").click()
			}

			try {

				setupXmartDropzones("nft", this.props.config); /* jQuery in main.js */ } catch(e) {
					// debugger
				}

		}, 900)
	}

	expandChat = (e) =>
	{
		this.setState({chatExpanded:! this.state.chatExpanded})
	}

    render() {

		const _year = new Date().getFullYear();
		const _urlPrefix = this.props.config.userData.loggedIn ? this.props.config.userData.url : "/profile";
		const _walletClass = this.props.config.userData.loggedIn ? "nav-link" : "nav-link wallet-disconnected";

        return (
            <footer className="footer-area">
                {/* Footer Top */}


				{ this.props.config.chatEnabled ?
				(
					<ChatComponent state={this.state} config={this.props.config} />
				) : (<></>)}




                <div className="footer-top hid">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Logo */}
                                    <a className="navbar-brand" href="/">
										<div id="footer-logo">
											{/* <img className="navbar-brand-sticky" src="/img/logo.png" alt="sticky brand-logo" /> */}
										</div>
                                    </a>

									<p>{this.props.config.footer.content}</p>

                                    {/* Social Icons */}
                                    <div className="social-icons d-flex">
                                        {this.props.config.footer.socialData.map((item, idx) => {
                                            return (
                                                <a key={`sd_${idx}`} className={item.link} href={item.url} target="_blank" rel="noreferrer">
                                                    <i className={item.icon} />
                                                    <i className={item.icon} />
                                                </a>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h4 className="footer-title">Useful Links</h4>
                                    <ul>
										<li><a href={_urlPrefix + "/collections/add"}className={_walletClass}>Create a Collection</a></li>
										<li><a href="/" onClick={this.launchCRUD} className={_walletClass}>Create an NFT</a></li>
										<li><a href="/category/all/">Explore Collections</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h4 className="footer-title">Community</h4>
                                    <ul>
										{/* <li><a href="/pages/help-center/">Help Center</a></li>
										<li><a href="/pages/suggestions/">Suggestions</a></li>
										<li><a href="/pages/about-us/">About Us</a></li>
										<li><a href="/pages/contact-us/">Contact Us</a></li>
										 */}
										<li><a href="/pages/privacy-policy/">Privacy Policy</a></li>
										<li><a href="/pages/terms-of-service/">Terms of Service</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h4 className="footer-title">Subscribe for updates</h4>
									<p className="smaller">Join our list and we'll keep you up to date on the latest & greatest features in xMart. We will NOT spam you and your email address wil not be shared with 3rd party.</p>
                                    {/* Subscribe Form */}
                                    <div className="subscribe-form d-flex align-items-center">
										{ this.state.subscribed ? (
											<span className="text-center fw-bold primary">You're now subcribed</span>
										) : (
											<>
												<input type="email" className="form-control" placeholder="e.g info@yourmail.com" value={this.state.emailAddress} onChange={this.changeEmail} />
                                        		<button type="button" onClick={this.joinMailer} className="btn"><i className="icon-paper-plane" /></button>
											</>
										)}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Footer Bottom */}
                <div className="footer-bottom g-0">
                    <div className="container-fluid">
                        <div className="row  g-0">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area text-center py-4">
                                    <i className='fas fa-copyright'></i> 2021-{_year} xMart, All Rights Reserved. v{this.props.config.version} &bull; <a href="/pages/privacy-policy/">Privacy Policy</a> &bull;  <a href="/pages/terms-of-service/">Terms of Service</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

				{/* Button to launch NFT viewer - This is so it can be used in child components */}
				<button type="button" className="hid" id="crud-nft-loader-button" data-nft="" onClick={this.prepareCRUD}>Load fresh data</button>
				<button type="button" className="hid" id="crud-nft-popper-button" data-bs-toggle="modal" data-nft="" data-bs-target="#edit-nft-modal">Show modal</button>

				{/* The actual viewing section */}
				<ModalCRUDNFT config={this.props.config} state={this.state} callback={this.myCallback} fullPage={false} />

				{/* The actual viewing section
				<ModalXumm config={this.props.config} state={this.state} callback={this.myCallbackXumm} />
				*/}

				<div id="xummModal" style={{"display":"none"}}>
					<span id="xummModalClose"><i className='fas fa-times-circle'></i></span>
					<div id="xummModalMessage"></div>
					<div id="xummModalCodes" className="row g-0"></div>
					<div id="xummModalFooter"></div>
				</div>

            </footer>

        );
    }
}

export default Footer;