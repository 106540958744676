import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import CollectionSpinner from '../../components/Collections/spinner';
//import Explore from '../components/Explore/ExploreSix';

import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';

import Scrollup from '../../components/Scrollup/Scrollup';

var downloading = false

class viewCollection extends Component {

	state = {
		wasLoadedWhileInProgress:false,
		isMyCollection:false,
		background:"",
		subPageLink:"/category/all/",
		subPageTitle:"Collections",
        collectionData: { totalNfts:0, id:0, title:"", creator:{"url":""}, explorer:"", "urlPath":"", "fullURL":"", attributes:{}, spinner:"No", hasAttributes:false, previewImage:"", sales:0, volume:0, totalForSale:0, totalNfts:0, floorPrice:0, description:""} ,
		nfts:[],
		bulkCollection:"Unsure",
		spinnerCollection:"Unsure",
		standardCollection:false,
		timerRemaining:(this.props.config.importRefreshInterval / 1000),
		timersDone:0,
		maxReloads:6 * 40,
		interval1:0,
		interval2:0,
		loading:true
    }

    componentDidMount(){

		/* Intial load */
		this.loadCollectionData(true);

    }

	decreaseTimer = e =>
	{

		try{

			if(this.state.timerRemaining > 0)
			{
				this.setState({timerRemaining:this.state.timerRemaining - 1})
				document.getElementById("reload-timer").innerHTML = this.state.timerRemaining;
			}
		}
		catch(e) {}

	}

	loadCollectionData = initialLoad =>
	{

		/* Already downloading? Skip[ this one 8*/
		if(downloading) { return false; }

		/* So we only do one request at a time */
		downloading = true
		var BASE_URL;
		BASE_URL = this.props.config.apiServer + "/com/external/collections.cfc?method=getCollection&includeNFTS=false&user="+this.props.who+"&collection="+this.props.collectionID+"&maxresults=2&observationMode";

		axios.get(`${BASE_URL}`, {withCredentials: true})
			.then(res => {

				if(!res.data.success)
				{
					throw({message:res.data.errorMessage});
				}

				if( res.data.info.soldOut && res.data.info.spinner === 'Yes' && document.location.href.indexOf("browse-all") === -1 )
				{
					window.showWaitMessage("Spinner is minted out, loading NFT list.")
					document.location.href = res.data.info.fullURL + "browse-all";
					return false;
				}

				$('#breadcrumb-title').html(res.data.info.title);
				document.title = `${res.data.info.title} Collection ${this.props.config.meta.title}`;

				/* Set chat channel to collection ID */
				document.selectedChannel = "c-" + res.data.info.id;


				this.setState({
					background:res.data.info.headerImage.length ? this.props.config.apiServer + res.data.info.headerImage : "",
					isMyCollection:res.data.info.creator.id === this.props.config.userData.id ? true : false,
					subPageLink:res.data.info.creator.url + "/collections",
					collectionData: res.data.info,
					nfts: res.data.nfts,
					oTL: res.data.info.launchpadInfo.oTL,
					bulkCollection:res.data.info.bulk === 'Yes' ? "Yes" : "No",
					spinnerCollection:res.data.info.spinner === 'Yes' ? "Yes" : "No",
					launchpadCollection:res.data.info.launchpad === 'Yes' ? "Yes" : "No",
					standardCollection:res.data.info.bulk !== 'Yes' ? true : false,
					loading:false
				})


				/* If intial load, check where we're in import mode */
				if( initialLoad )
				{

					if( document.getElementById("reload-timer") )
					{

						/* Setup timer to re-check download stats */
						const interval1 = window.setInterval(() => { this.loadCollectionData(false) }, this.props.config.importRefreshInterval + 1000)
						const interval2 = window.setInterval(() => { this.decreaseTimer({}) }, 1000)

						/* Store this so we know that when the page was loaded bulkinfo existed */
						this.setState({wasLoadedWhileInProgress:true, interval1:interval1, interval2:interval2})

					}

				}

				/*Loading message has gone -= Disable timers */
				if( ! document.getElementById("reload-timer") )
				{
					window.clearInterval(this.state.interval1)
					window.clearInterval(this.state.interval2)
				}

				/* Have we exceeded the max number of page reloads? */
				this.setState({timersDone:this.state.timersDone+1})

				if(this.state.timersDone > this.state.maxReloads)
				{
					//document.getElementById("reload-timer").innerHTML = "Automatic page updates have been disabled";
					window.clearInterval(this.state.interval1)
					window.clearInterval(this.state.interval2)
				}

				this.setState({timerRemaining:(this.props.config.importRefreshInterval / 1000) + 1})

				if( ! res.data.info.bulkInfo && this.state.wasLoadedWhileInProgress)
				{
					/* No bulk info provided - Disable reload timer and reload page to show populated collection as importing is complete */
					document.location.href = res.data.info.fullURL;
					return false;
				}
				downloading = false


			})
		.catch(err => {
			window.displayErrorMessage("There was an error retrieving data:<br/><br/><strong>" + err.message + "</strong>")
			window.logger(err)
		})

	}

    render() {

        return (
            <div className="collection-viewer">
                <Header 			config={ this.props.config } />
                <Breadcrumb 		config={ this.props.config } state={this.state} title="" subpage={this.state.subPageTitle} subPageLink={this.state.subPageLink} page={this.state.collectionData.title} shallow="true" background={this.state.background} />

				{/* Button must be in here to trigger the reload function */}
				<button id="reload-collection" className="hid" onClick={this.loadCollectionData}>RC</button>

				<CollectionSpinner	config={ this.props.config } state={this.state} spinnerCollection={this.state.spinnerCollection === "Yes" ? true : false}	who={this.props.who}	collectionID={this.props.collectionID} />


                <Footer 			config={ this.props.config } />
                <ModalSearch 		config={ this.props.config } />
                <ModalMenu 			config={ this.props.config } />
                <Scrollup 			config={ this.props.config } />
            </div>
        );
    }
}

export default viewCollection;