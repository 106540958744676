import React, { Component } from 'react';
import axios from 'axios';
import { VideoLink } from '../videoLink'

class SpinnerGiveawayComponment extends Component {

	componentDidMount()
	{
	}


	switchPromotion = e =>
	{

		var mx = true
		if(this.props.parentState.spinInfo.promoRunning)
		{

			window.Swal.fire(
							{
								title: 'Stop Promotion',
								html: '<p className="text-center">By stopping the promo, ALL previous allocations will be cleared and users will be able to claim spins again if you restart it.</p>',
								showCancelButton: true,
								confirmButtonText:"Stop Promotion",
								cancelButtonText:"Don't Stop",
							}).then((result) => {
								if (! result.isDismissed)
								{
									this.doSwitch()
								}
							})
		}
		else
		{
			this.doSwitch()
		}

	}

	signIn = e =>
	{
		window.$('#sign-in-button').trigger("click");
	}

	doSwitch = e =>
	{
		window.showWaitMessage(`Changing promo status`, false, true);

									/* Good valid address - Do a lookup to get its configuration */
									const BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc";

									const formData = new FormData();
									formData.append("method", "changePromoStatus");
									formData.append("collection", this.props.state.collectionData.id);

									axios.post(BASE_URL, formData, { withCredentials: true })
										.then(res => {

											if( ! res.data.success)
											{
												throw(res.data.errorMessage);
											}
											else
											{
												window.showWaitMessage("Reloading Page");
												window.location.reload();
												//this.props.updatePromoInfo()
												//window.hideWaitMessage();

											}
										})
									.catch(err =>
											{ window.hideWaitMessage(); window.displayErrorMessage(err); }
									)
	}



    render() {

		const _showStopButton = this.props.config.userData.display && this.props.parentState.spinInfo.promoRunning;
		const _showStartButton = this.props.config.userData.display && this.props.parentState.spinInfo.promoRunning === false;

		return (

			<>

				{/* Spacer div */}
				<div className="mt-5 d-none d-md-block"></div>

				{ this.props.state.collectionData.spinnerInfo.spinsLeftPromo === 0 && this.props.parentState.spinInfo.promoRunning === true ?
					(
						<>
							<h3 className="primary text-center">Sorry, You're Too Late!</h3>
							<p>Unfortunately, all spins have been claimed for this promotion. Check our social media for news of any upcoming promotions.</p>
						</>
					) :
					(
						<div className="text-center mb-4">


							<div className="m-2 d-none d-md-block">
								<VideoLink label={"How Does The Spinner Work?"} tag={"spin-to-win"} linkType="link" config={this.props.config} />
							</div>



							{ this.props.parentState.spinInfo.promoCost === 0 ?
							(
								<>
									<p><strong className="primary">"Promo Mode"</strong> This spinner has been set to PROMO mode by the collection issuer. Grab one now and enjoy!</p>
									<p><strong>NOTE:</strong> Mint cost is 0.0001 XRP + transaction fee.</p>
								</>
							)
							:
							(
								<>
									<p><strong className="primary">"Fair Price Minting"</strong> - Do you want to support the project and pay above minimum price. Now you can!</p>
									{/* <p>There is a minimum purchase price of <CurrencyPrice price={this.props.parentState.spinInfo.promoCost} config={this.props.config} /> but if you believe the NFT is worth more, you can raise the purchase price in increments of <CurrencyPrice price={this.props.parentState.spinInfo.promoIncrement} config={this.props.config} />.</p> */}
								</>
							)
							}

							{/* this.props.parentState.spinInfo.promoRunning ? ( <p>There are only {this.props.parentState.spinInfo.promoLimit} spins available during the promotion period!</p> ) : ( <></> ) */}



						</div>
					)
				}

				{ this.props.parentState.spinInfo.promoRunning === false ?
				(
					<>
						<div className="alert alert-danger p-3 fw-bold text-center m-0">
							<div>This promotion is not currently running. Check our social media for news of any upcoming promotions.</div>
							{ this.props.parentState.spins ? ( <div className="mt-3">You have {this.props.parentState.spins} spins for this collection.</div> ) : ( <></> )}
						</div>
					</>
				)
				: (
					<>
						{ this.props.parentState.spinInfo.promoAllowed === false ?
						(
							<div className="text-center">
								{ this.props.config.userData.id  ?
								(
									<>
										<div className="alert alert-danger p-3 fw-bold text-center m-0">
											{ this.props.parentState.spins === 0 ?
											(
												<div>You have used your spin for this promotion.</div>
											)
											:
											(
												<>
													<div>You have claimed your FREE spin for this promotion.</div>
													<div className="mt-3">GOOD LUCK!!</div>
												</>
											)}

											{ this.props.parentState.spins > 1 ?
											(
												<div className="mt-2">You have {this.props.parentState.spins} spins for this collection.</div>
											) :
											(
												<></>
											) }

										</div>
									</>
								) :
								(
									<button type="button" className="btn w-100 mt-3" onClick={this.signIn} >Sign in To Spin</button>
								)}
							</div>

						)
						:
						(
							<div className="text-center d-none d-md-block">
								{ this.props.parentState.spinInfo.promoCost === 0 ?
								(
									<>
										<p className="fw-bold text-center">There are {this.props.qtyLeft} spins available in this promotion.</p>
										<button type="button" className="btn  w-75" disabled={this.props.parentState.spinInfo.mySpins.promo > 0 ? true:false} onClick={this.props.getFreeSpin}>Get my Spin</button>
									</>
								)
								:
								(
									<>
										<p className="fw-bold text-center">There are {this.props.qtyLeft} spins available to buy (Limited to {this.props.parentState.spinInfo.promoWalletLimit} per wallet)</p>
										<button type="button" className="btn btn-bordered w-75" disabled={this.props.parentState.spinInfo.promoAllowed === false || this.props.qtyLeft === 0 ? true: false} onClick={this.props.checkTotals}  data-type="xrp" >Buy a Spin</button>
									</>

								)
								}
							</div>
						)
						}


					</>
				)}



				{/* Superuser can enable or disable */}
				{ _showStopButton || _showStartButton ?
				(
					<div className="pt-5 px-5 text-center">
						{ _showStopButton ? (<button type="button" className="btn btn-bordered btn-sm w-75 my-4" onClick={this.switchPromotion}>Stop Promotion</button>) : (<></>)}
						{ _showStartButton ? (<button type="button" className="btn btn-bordered btn-sm w-75 my-4" onClick={this.switchPromotion}>Start Promotion</button>) : (<></>)}
					</div>
				) : ( <></> )}








			</>

		);
    }
}

export default SpinnerGiveawayComponment;