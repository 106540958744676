import React, { Component } from 'react';
import { numberRounder } from '../xutils';
import Masonry from 'react-masonry-css';
import axios from 'axios';

class ExploreFour extends Component {

	state = {"data":[], loading:true, hasMoreToLoad:false, totalLoaded:0, prevY:0, offset:0, collections:[], "title":"----"}

	componentDidMount ()
	{

		/* Setup the listener so when we approach the loading message more data is loaded */
		var options = { root: null, rootMargin: "0px", threshold: 0.0 };
		if(this.loadingRef)
		{
			this.observer = new IntersectionObserver( this.handleObserver.bind(this), options );
			this.observer.observe(this.loadingRef);
		}

		window.setTimeout( () => { this.loadData(); }, 350)
	}

	handleObserver(entities, observer) {

		if(this.state.hasMoreToLoad)
		{
			const y = entities[0].boundingClientRect.y;

			if (this.state.prevY > y)
			{
				/* New position found. Load more daata */
				window.setTimeout( () => { this.loadData(); }, 350)
			}

			/* Mark how far we've got in the page */
			this.setState({ prevY: y });
		}
	}



	loadData = e =>
	{

		const formData = new FormData();
		formData.append("method", "getCollections" );
		formData.append("category", this.props.state.tag );
		formData.append("itemsPerPage", 25 );
		formData.append("profile", this.props.config.urlParts[2] );
		formData.append("offset", this.state.offset );

		var BASE_URL = this.props.config.apiServer + "/com/external/collections.cfc";

		axios.post(`${BASE_URL}`, formData, {withCredentials: true})
			.then(res => {

				this.setState({
					collections: 	this.state.collections.concat(res.data.collections),
					offset:			this.state.offset + res.data.collections.length,
					totalLoaded:	this.state.totalLoaded + res.data.collections.length,
					hasMoreToLoad:	this.state.offset + res.data.collections.length < res.data.totalCollections ? true : false,
					loading:		false,
					title:			res.data.title
				})

				/* Initialise jQuery components */
				window.setTimeout(() => { window.loadPreloadImages() }, 300);

			})
		.catch(err => window.logger(err))

	}


    render() {

		var childElements = this.state.collections.map((item, idx) =>
		{

			var _img = item.preview.length ? this.props.config.apiServer + item.preview : this.props.config.sizes.placeholders.square;
			var _thumbClass = "thumb-up";
			if( item.liked ) { _thumbClass += " thumb-up-chosen" }
			if( this.props.config.userData.loggedIn === false ) { _thumbClass += " wallet-disconnected" }
			const _creatorImage = item.creatorImage.length ? `${this.props.config.apiServer}${item.creatorImage}` : 'xxx/img/placeHolders/profile_300x300.jpg';

			return (

				<div key={`exf_${idx}`} className="item is-item grid-item m-0 p-0">
					<div className="card mb-3 p-0">

						<div className="image-over">
							<a href={item.collectionURL} target="_blank">
								{ item.spinner ? ( <span className='collection-feature'><img src="/img/sashes/left-spinner.png" alt="Spiner Collection" /></span> ) : ( <></> )}
								<img className="card-img-top preload-image" id={"preload_category_"+preloadKey++} src={this.props.config.sizes.placeholders.loader} data-load={_img} alt={item.title} />
							</a>
						</div>

						<div className="card-caption col-12 px-2">
							<div className="card-body pt-2">
								<h5 className="mb-2 ellipsis" title={item.title}>{item.title}</h5>
								<h6 className="mb-2 ellipsis">By {item.creatorName.substr(0, 30)}</h6>
							</div>
						</div>


						<div className="row collection-prices mt-3 mb-3">

							<div className="col-6 px-1">
								<strong>Floor:</strong> {numberRounder(item.floorPrice)}
							</div>
							<div className="col-6 text-end px-1">
								<strong>Vol:</strong> {numberRounder(item.volume)}
							</div>

						</div>

							<div className="card-links row mb-1">
								<div className="px-0 ps-2 pb-2 text-start col-6">
									<span className={_thumbClass} data-type="Collection" data-uuid={item.id} data-index={idx} data-bs-toggle="tooltip" data-bs-placement="left" title="Like/Unlike this collection"><i className="fas fa-heart"></i></span>
									<span className="link" data-link={item.uri} data-bs-toggle="tooltip" data-bs-placement="top" title="Copy permaLink" ><i className="ms-2 fas fa-link"></i></span>
								</div>
								<div className="px-0 pb-2 text-center col-6">
									{numberRounder(item.nfts)} { item.nfts === 1 ? "NFT" : "NFTs" }
								</div>
								{/*
								<div className="px-0 pe-2 pb-2 text-end col-4">
									{numberRounder(item.likes)} { item.likes === 1 ? "Like" : "Likes" }
								</div>
								*/}
							</div>


					</div>
				</div>
			);
		})

		const loadingCSS = { marginTop: "50px", fontSize:"150%" };
		const loadingTextCSS = { display: this.state.hasMoreToLoad ? "block" : "none" };
		const loadingFinishedCSS = { display: this.state.hasMoreToLoad === false ? "block" : "none", "color":"#aaaaaa" };
		const breakpoints = this.props.hasSideNav ? this.props.config.masonryBreakpointsWithNav : this.props.config.masonryBreakpoints;

		var preloadKey = 1;

        return (
					<>

						{ this.props.state.description.length > 10 ?
						(
							<div className="container-fluid g-0">
								<div className="text-center py-3  buttonbar">
									<div className="container popular-collections-description">
										{this.props.state.description}
									</div>
								</div>
							</div>
						)
						:
						(<></>)}


						<section className="popular-collections-area pt-5">

							<div className={this.props.config.wideScreenClass}>

								{ this.state.loading ?
								(
									<div className="text-center col-12 mt-5" id="loading">
										<h3>Loading Collections</h3>
										<img src={this.props.config.sizes.placeholders.preloader} alt="Loading.." />
									</div>
								)
								:
								(
									<>

										{/* Message if no items */}
										{ this.state.collections.length === 0 ?
										(
											<div className="text-center w-100"><h4>There are no collections in this category yet.</h4></div>
										)
										:
										(
											<Masonry breakpointCols={breakpoints} id="infinite-load-wrapper" className="my-masonry-grid" columnClassName="my-masonry-grid_column">
												{childElements}
											</Masonry>
										)}

									</>
								)
								}


							<div id="infinite-loader" ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS} className="col-12 mt-4 pt-5 text-center">
								<div style={loadingTextCSS}>
									<div><h3>Loading additional Collections...</h3></div>
									<img src={this.props.config.sizes.placeholders.preloader} alt="Loading" />
								</div>
								{/* <div style={loadingFinishedCSS} className={this.state.loading ? "hid" : ""}><h3 className="line"><span>End of list</span></h3></div> */}
							</div>

						</div>
					</section>

				</>
        );
    }
}

export default ExploreFour;