import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import AuthorProfile from '../../components/AuthorProfile/AuthorProfile';

import NFTBrowser from '../../components/NFT/cBrowse';

import axios from 'axios';


class AuthorNFTs extends Component {

	state = {
			profile: this.props.who,
			function: this.props.function,
			background: this.props.config.userData.urlPath === this.props.who ? this.props.config.apiServer + this.props.config.userData.headerImage : "",
			shallow: this.props.config.userData.urlPath === this.props.who ? false : true,
			htmlTitle:`${this.props.title} | ${this.props.config.meta.title}`,
			title: this.props.title,
			isMyCollection: this.props.config.userData.urlPath === this.props.who ? true : false,
			profileData:{url:"", urlPath:"", title:"Error", "profileImageL":"", "social":[]}
		}

	componentDidMount(){

		const BASE_URL = this.props.config.apiServer + "/com/external/profile.cfc?method=getProfile&profile="+this.state.profile;

        axios.get(`${BASE_URL}`, {withCredentials: true})
            .then(res =>
			{
				if(res.data.success)
				{

					this.setState({
						profileData:res.data.info,
						nftData:[]
					})

					/* Inistialse jQuery components */
					window.initialiseJQ()

				}
				else
				{
					window.displayErrorMessage(res.data.errorMessage)

					this.setState({
						profileData:{url:"", urlPath:"", title:"Error"},
						nftData:[],
						noDataMessage:`<h3>There was an error loading the NFTs</h3><p>${res.data.errorMessage}</p>`
					})

				}

            })
        .catch(err => window.displayErrorMessage( err ) )
	}


    render() {

		const _sizes = this.props.who !== this.props.config.userData.urlPath ? this.props.config.masonryBreakpointsWithNav : this.props.config.masonryBreakpoints ;

		var col1Class = "col-12 col-md-9 mt-2 ";
		var col2Class = "col-12 col-md-3 mt-2 ";

		if(this.props.config.wideScreen)
		{
			col1Class += "col-lg-9 col-xl-9 col-xxl-10";
			col2Class += "col-lg-3 col-xl-3 col-xxl-2";
		}

		return (
            <div className="main">
                <Header					config={ this.props.config } state={this.state} />
                <Breadcrumb				config={ this.props.config } state={this.state} htmlTitle={this.state.htmlTitle} title={this.state.title} subpage="My Profile" subPageLink={this.props.config.userData.url} page={this.state.title} shallow={true} background={this.state.background}  />

				<section className="author-area explore-area popular-collections-area">
					<div className={this.props.config.wideScreenClass}>
                    	<div className="row d-flex flex-row-reverse g-0">

							<div className={col1Class}>

								<NFTBrowser
										config={ this.props.config }
										breakPoints={ _sizes }
										state={this.state}
										collectionID={""}
										title={this.props.title}
										showSweep={false}
										dataType={this.state.function}
										infiniteScroll={true}
										bulkCollection={false}
										maxResults={0}
										who={this.props.who}

										/>

							</div>

							<div className={col2Class} style={{"maxWidth":"400px"}}>
								{/* Author Profile */}
								<AuthorProfile	config={ this.props.config } state={this.state} />
							</div>

						</div>
					</div>
				</section>



                <Footer					config={ this.props.config } />
                <ModalSearch			config={ this.props.config } />
                <ModalMenu				config={ this.props.config } />
                <Scrollup				config={ this.props.config } />
            </div>
        );
    }
}

function ArrayPrepend(value, array) {
	var newArray = array.slice();
	newArray.unshift(value);
	return newArray;
  }


export default AuthorNFTs;