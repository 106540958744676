import React, { Component } from 'react';
import LoadingMessage from './loading-message';

class CollectionSpinnerImportingComponent extends Component {


    render() {


		return (

			<div className="row mt-5">
				<div className="col-12">
					<h2 className="mt-5 text-center">The collection is still importing</h2>
					{ this.props.state.collectionData.bulkInfo.status.length ? ( <p className="mt-3 text-center"><strong>Current Status:</strong>&nbsp;&nbsp;{this.props.state.collectionData.bulkInfo.status}</p> ) : ( <></> )}

					<p className="mt-3 text-center">A total of {this.props.state.collectionData.totalNfts} NFTs have been created so far. Once imported, the next step will be to calculate rarity.</p>
					<LoadingMessage config={this.props.config} state={this.props.state} />
				</div>
			</div>

		);
    }
}

export default CollectionSpinnerImportingComponent;