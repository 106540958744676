import React, { Component } from 'react';
import axios from 'axios';
import ModalFilters from '../Modal/ModalFilters';
import ModalViewNFT from '../Modal/ModalViewNFT';
import ModalSweep from '../Modal/ModalSweep';

import ModalBulkUploadMulti from '../Modal/ModalBulkUploadMulti';

import Masonry from 'react-masonry-css';
import "../../css/cBrowse.css";
import { permaLink, numberRounder } from '../xutils'
import { addXummPayload } from '../../js/xumm';

class CollectionSpinnerBrowseComponent extends Component {

	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	state = {
		tab:"assets",
		useModalViewNFTs:true,
		modalVisible:false,
		prevY: 0,
		loading:true,
		loadingNFT:true,
		homePageData:{"featured":[], forSale:[], minted:[], recentSales:[]},
		nfts:[],
		nftsImporting:0,
		nftsImportingRetry:0,
		nft:{
			creator:{"headerImage":"", "id":"", "url":"", "profileImageM": this.props.config.sizes.placeholders.profilePicture.medium, "title":""},
			collection: {url:"", "name":"", title:"", "preview":""},
			auction:{end:0, isAuction:false, bids:[]},
			mintedQty:false,
			flagOwner:false,
			flagEditable:false,
			flagCreator:false,
			flagForSale:false,
			flagMadeAnOffer:false,
			flagBurned:false,
			flagDupe:false,
			floorPrice:0,
			ceilingPrice:0,
			description:"",
			burnable:false,
			attributes:{},
			importing:true,
			attributeData:[],
			owners:[],
			media:[],
			history:[],
			offers:{ buy:[], sell:{}, forSale:false, forSalePrice:0, offersMade:0, buyOfferMade:false, pending:false, transferPending:false },
			mintInfo:{"address":"", when:0, "flags":0},
			metaData:{mediaType:"", width:0, height:0},
			preview: this.props.config.sizes.placeholders.square,
			supply:"?",
			size:"?",
			title:"?",
			previewMedia:"",
			previewAudio:""
		},
		collection:this.props.collectionID,
		dataType:this.props.dataType,
		infiniteScroll:this.props.infiniteScroll,
		bulkCollection:this.props.bulkCollection,
		maxResults:this.props.maxResults,
		offset:0,
		itemsPerPage:this.props.config.masonryItemsToLoad,
		totalFilteredNFTs:-1,
		totalLoaded:0,
		collectionFilters:[],
		sortOrder:"",
		showSearchStuff:false,
		searchCriteria:"",

		filterType:"",
		showFilterHTML:false,
		filterHTML:"",
		loadedInitial:false,
		mediaPlaying:false,
		disableTransfers:false,

		selectedForSale:[],
		selectedForSaleIDs:[],

	};

	myCallbackModal = (visible) => {


		/* This enables grandchild component to change the state of modal tab visibility */
		this.setState({modalVisible:visible})

		/* Scroll to top */
		window.setTimeout( () => {
			//alert(1)
			//document.getElementById("view-nft-modal").scrollTop = document.documentElement.scrollTop = 0;
		}, 500);


	}

	myCallbackPlaybutton = e => {
		/* Enable play of preview video */
		this.setState({mediaPlaying:! this.state.mediaPlaying})
	}

	loadNFT = e =>
	{

		const _id = e.currentTarget ? e.currentTarget.dataset.nft : e;

		this.setState({loadingNFT:true});

		/* Change to preloader image */
		window.$('#viewing-nft-image').prop( "src", this.props.config.sizes.placeholders.loader);

		const BASE_URL = this.props.config.apiServer + "/com/external/nfts.cfc?method=getNFT&uuid="+_id;

		axios.get(`${BASE_URL}`, {withCredentials: true})
			.then(res => {


				if( ! res.data.success)
				{
					window.displayErrorMessage("Invalid NFT - Reloading collection", false, false);
				}
				else{

					//window.$('#breadcrumb-2 a').prop("href", res.data.nft.collection.url).html(res.data.nft.collection.name)

					/* Ensure images and URL's point to API server */
					//res.data.nft.preview = res.data.nft.preview.length ? this.props.config.apiServer + res.data.nft.preview + "?cb="+cachebuster: "";
					res.data.nft.creator.headerImage = res.data.nft.creator.headerImage.length ? this.props.config.apiServer + res.data.nft.creator.headerImage : "";
					//res.data.nft.media = res.data.nft.media ? this.props.config.apiServer + res.data.nft.media : "";

					/* Copy to window for jQuery sharing */
					window.nftData = res.data.nft;
					var _tab = "assets"
					if( res.data.nft.offers.offersMade && res.data.nft.flagOwner > 0 ) { _tab = "offers" }		/* We have offers and are the owner */
					if( res.data.nft.offers.buyOfferMade ) { _tab = "offers" }									/* Buyer has made an offer */

					this.setState({
						isMine:res.data.nft.flagOwner,
						buyDisabled:res.data.nft.flagOwner ? true : false,
						nft: res.data.nft,
						loadingNFT:true,
						tab:_tab,
						playMedia:false,
						modalVisible:false,
						mediaPlaying:false
					})

					/* Reveal page parts */
					this.setState({loadingNFT:false});
					window.setTimeout(() => { window.loadPreloadImages(); }, 500);

				}
			})
		.catch(err => window.logger(err))

	}

	bulkBuySell = e =>
	{
		var NFTokenIDs = [];
		var offerIDsNew = [];
		var offerTypes = []
		var values = [];

		this.state.selectedForSale.forEach( (item, idx) =>
		{
			offerTypes.push("sell")
			NFTokenIDs.push(item.id)
			values.push(item.price)
			offerIDsNew.push("")
		}
		)

		addXummPayload( { "type":"CreateBulkOffers", "which":"BulkSell", "OfferIDs":[], "NFTokenIDs":NFTokenIDs, "OfferTypes":offerTypes, "values":values } )
	}

	updateForSale = e =>
	{
		// selectedForSale:[],
		var _ids = [];
		var _maxXRP = 100000000;
		var _minXRP = .000001;
		var _maxDecimals = 6
		const _id = e.currentTarget.dataset.id
		var _existsingItems = this.state.selectedForSale;

		if(e.currentTarget.nodeName === 'BUTTON')
		{
			_existsingItems.forEach( (item, idx) =>
			{
				if(item.id === _id)
				{
					_existsingItems.splice(idx, 1)
					const el = window.$("[data-id='" + item.id + "']");
					el.val("")
				}
			}
			)
		}
		else
		{
			var _price = Number(e.currentTarget.value)

			/* Above max price? */
			if(_price > _maxXRP)
			{
				e.currentTarget.value = _maxXRP;
			//	return false;
			}

			/* Below min price? */
			if(_price > 0 && _price < _minXRP)
			{
			//	e.currentTarget.value = _minXRP;
			//	return false;
			}

			/* Too many decimals? */
			var _stringNumber = _price.toString()
			var _dPos = _stringNumber.indexOf(".");
			if(_dPos > -1)
			{

				var _decimalDigits = _stringNumber.substring(_dPos+1);
				if(_decimalDigits.length > _maxDecimals)
				{
					e.currentTarget.value = _stringNumber.substring(0, _dPos+1) + _decimalDigits.substring(0,_maxDecimals);
				}
			}

			_price = Number(e.currentTarget.value)

			var exists = false;

			_existsingItems.forEach( (item, idx) =>
			{
				if(item.id === _id)
				{
					if(_price > 0)
					{
						exists = true
						item.price = _price;
					}
					else
					{
						/* Remove from list */
						_existsingItems.splice(idx, 1)
					}

				}
			} )

			if( ! exists && _price)
			{
				_existsingItems.push( {id:_id, price:_price })
			}

		}

		/* Build list of just ID's for UI to use */
		_existsingItems.forEach( (item) =>
		{
			_ids.push(item.id)
		})

		this.setState({selectedForSale:_existsingItems, selectedForSaleIDs:_ids})

	}

	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROrankWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */

	launchCRUD = e =>
	{
		e.preventDefault()
		var _cid = typeof e.currentTarget.dataset.collection !== 'undefined' ? e.currentTarget.dataset.collection : "";
		document.getElementById("crud-nft-loader-button").dataset.nft = "";
		document.getElementById("crud-nft-loader-button").dataset.collection = _cid;
		document.getElementById("crud-nft-loader-button").click()
	}

	showFilters = e =>
	{
		this.hideTips()
		document.getElementById("showFilterModal").click()
	}

	showSweepModal = e =>
	{
		this.hideTips()
		document.getElementById("showSweepModal").click()
	}



	showSearchStuff = e =>
	{
		if( this.state.showSearchStuff )
		{
			/* We're doing an actual search */
			alert("Do search")
		}
		else
		{
			/* Literally enabling the search box */
			this.setState({showSearchStuff:true, searchCriteria:""})
			window.setTimeout( () => {
				window.$("#collectionSearch").focus()
				window.$("#collectionSearchbutton").addClass("submittable")
			}, 200)
		}
		this.hideTips()
	}

	hideSearchStuff = e =>
	{
		this.hideTips()

		/* If we've searched, clear the variables otherwise don't to save server load */
		if( this.state.searchCriteria.length )
		{

			this.hideTips()
			this.showLoading()

			this.setState({showSearchStuff:false, searchCriteria:"", nfts:[], offset:0, totalFilteredNFTs:-1, hasMoreToLoad:false})

			/* Load em up */
			window.setTimeout(() => { this.getListings() }, 50);
		}
		else
		{
			this.setState({showSearchStuff:false})
		}

	}

	changeSearchStuff = e =>
	{
		if(e.keyCode === 13)
		{
			this.doSearch();
		}
	}


	changeSort = e =>
	{

		e.preventDefault()

		this.hideTips()
		this.showLoading()

		/* Change offset so serach starts at beginning */
		this.setState({nfts:[], offset:0, sortOrder:e.currentTarget.dataset.sort, totalFilteredNFTs:-1, hasMoreToLoad:false})


		/* Load em up */
		window.setTimeout(() => { this.getListings() }, 50);

	}

	showLoading = e =>
	{
		window.jQuery('#loading').show();
		window.jQuery('#spinner-browse').addClass("notVisible");
	}

	doSearch = e =>
	{
		this.hideTips()
		this.showLoading()

		/* Change offset so serach starts at beginning */
		this.setState({nfts:[], offset:0, searchCriteria:document.getElementById("collectionSearch").value, totalFilteredNFTs:-1, hasMoreToLoad:false})

		/* Load em up */
		window.setTimeout(() => { this.getListings() }, 50);
	}


	hideTips = e =>
	{
		var tips = window.$('.browse-filters button');
		window.$.each(tips, function(id, elem) {
			var tooltip = window.bootstrap.Tooltip.getInstance(elem)
			tooltip.hide()
		})
	}


	myCallback = (dataFromChild) => {

		this.setState({
			nfts: [],
			totalFilteredNFTs:dataFromChild.NFTCount,
			filterType:dataFromChild.filterType,
			totalLoaded:0,
			prevY:0
		})

		if(dataFromChild.appliedFilters.length)
		{
			/* Make some html to show in the filter box */
			var _html = ""
			var newArray = []
			dataFromChild.appliedFilters.forEach( (item) => { _html += `${item.label.replace("|", ": ")} .. `; newArray.push(item.filterHash) } )

			/* Populate state and reload data */
			this.setState({offset:0, showFilterHTML:true, filterHTML:_html, collectionFilters:newArray})

		}
		else
		{
			this.setState({offset:0, showFilterHTML:false, filterHTML:"", collectionFilters:[]})
		}


		this.hideTips()
		this.showLoading()

		/* Must do this reload ina timeout to allow state to be set properly */
		window.setTimeout( () => { this.getListings(); }, 350)

	}

	componentDidMount(){


		/* Setup the listener so when we approach the loading message more data is loaded */
		if (this.props.infiniteScroll)
		{
			var options = { root: null, rootMargin: "0px", threshold: 0.0 };
			this.observer = new IntersectionObserver( this.handleObserver.bind(this), options );
			this.observer.observe(this.loadingRef);
		}

		this.showLoading()
		window.setTimeout( () => { this.getListings(); }, 350)

    }

	handleObserver(entities, observer) {

		if(this.state.hasMoreToLoad)
		{
			const y = entities[0].boundingClientRect.y;

			if (this.state.prevY > y)
			{
				/* New position found. Load more daata */
				window.setTimeout( () => { this.getListings(); }, 350)
			}

			/* Mark how far we've got in the page */
			this.setState({ prevY: y });
		}
	}

	getListings = () => {

		const BASE_URL = this.props.config.apiServer + "/com/external/nfts.cfc"

		const formData = new FormData();
		formData.append("method", "getNFTS" );
		formData.append("collection", this.props.collectionID );
		formData.append("user", this.props.who );
		formData.append("dataType", this.state.dataType );
		formData.append("maxResults", this.state.maxResults );
		formData.append("itemsPerPage", this.state.itemsPerPage );
		formData.append("offset", this.state.offset );
		formData.append("filters", JSON.stringify(this.state.collectionFilters));
		formData.append("searchString", this.state.searchCriteria);
		formData.append("filterType", this.state.filterType );
		formData.append("sortOrder", this.state.sortOrder );

		axios.post(`${BASE_URL}`, formData, {withCredentials: true})
			.then(res => {

				if(res.data.importingNFTS)
				{
					this.setState({
						nftsImporting:res.data.importingNFTS,
						nftsImportingRetry:res.data.importingNFTSRetry
					})
				}

				if(res.data.nfts.length)
				{

					this.setState({
						offset:this.state.offset + res.data.nfts.length,
						hasMoreToLoad:this.state.offset+res.data.nfts.length < res.data.totalFilteredNFTs ? true : false,
						nfts: this.state.nfts.concat(res.data.nfts),
						totalFilteredNFTs:res.data.totalFilteredNFTs,
						totalLoaded:this.state.totalLoaded + res.data.nfts.length
					})

				}
				else
				{
					this.setState({
						hasMoreToLoad:false
					})

					this.setState({totalFilteredNFTs:0})


				}

				window.jQuery('#spinner-browse').removeClass("notVisible");
				window.jQuery('#loading').hide();
				this.setState({ loadedInitial:true});

			})
		.catch(err => {
			window.displayErrorMessage("There was an error retrieving the NFT list\n\n" + err.message)
			window.logger(err)
		})

	};

    render() {

		const loadingCSS = { marginTop: "50px", fontSize:"150%" };
		const loadingTextCSS = { display: this.state.hasMoreToLoad ? "block" : "none" };
		const loadingFinishedCSS = { display: this.state.hasMoreToLoad === false ? "block" : "none", "color":"#aaaaaa" };

		var _title = ""
		var _link = ""
		var _message = ""

		if(this.props.title)
		{
			_title = this.props.title
			_link = this.props.link
		}
		else
		{
			switch (this.state.dataType) {


				case "recentSales":
					_title = "Recent Sales"
					_link = "/nfts-for-sale/"

					break;

				case "created":
					_title = "Items I've Created"
					_link = "/latest-nfts/"
					_message = this.props.config.userData.spin === 'Yes' ? "NOTE: The items shown below will not include any items from spinner collection." : ""

					break;

				case "purchased":
					_title = "Items I've Purchased"
					_link = "/latest-nfts/"

					break;


				case "offers":
					_title = "Items I've Offered to Buy"
					_link = "/latest-nfts/"

					break;

				case "selling":
					_title = "Items I'm Selling"
					_link = "/latest-nfts/"

					break;



				case "spin-browse":
					_title = ""
					_link = ""

					_message = ""
					//_message = this.props.state.collectionData.hu === 'Yes' && this.props.state.collectionData.soldOut === "No" ? "NOTE: Only minted items are browseable in this collection." : ""

					break;

				case "browse":
						_title = "Collection"
						_link = ""

						_message = ""
						//_message = this.props.state.collectionData.hu === 'Yes' && this.props.state.collectionData.soldOut === "No" ? "NOTE: Only minted items are browseable in this collection." : ""

						break;

				default:
					_title = "???? - [" + this.state.dataType + "] not handled"
					break;
			}
		}



		var _number = 0;
		var preloadKey = 1;
		var _tabIndex = 1

		var childElements = this.state.nfts.sort().map((item, idx) =>
			{

				var _nftPath = `/profile/${item.profilePath}/view-nft/${item.id}`;
				var _linkClass = this.state.useModalViewNFTs ? " nft-popper " : "";
					if(item.importing) { _linkClass += ' noInteraction '}
				const _preview = item.preview.indexOf("://") < 0 ? this.props.config.apiServer + item.preview : item.preview;
				const _AmITheOwner = item.owner === this.props.config.userData.walletAddress ? true : false;

				const _showSellBox = item.forSale === false && _AmITheOwner && item.reserved === false && item.NFTokenID.length > 0 && window.location.href.indexOf("/profile/") > -1
				const _showApproveButton = _AmITheOwner && item.reserved === true && item.minting === false
				const _showMintingButton = item.minting === true

				const _showBuyButton = _AmITheOwner === false && item.forSale && item.reserved === false
				const _showOfferButton = 	_AmITheOwner === false
											&& item.NFTokenID.length
											&& item.dupe === false
											&& item.forSale === false
											&& item.reserved === false
											&& item.allowOffers === true ? true : false


				const _showSpinButton = this.props.state.collectionData && this.props.state.collectionData.spinner === 'Yes' && item.forSale === false && item.reserved === false && item.mintedQty === 0 ? true : false

				const _showSpacerButton = _showOfferButton || _showSpinButton || _showSellBox || _showApproveButton || _showMintingButton || _showBuyButton ? false : true;


				_tabIndex++

				_number++

				return (

					<div key={idx} className="item is-item grid-item">
						<div className={"card mb-3 p-0 grid-item-content" + (item.dupe ? " duplicate" : "") }>

							{/* Used for search engines */}
							{this.state.useModalViewNFTs ? ( <a href={_nftPath} className="hid">${item.title}</a> ) : ( <></> )}

								<a href={_nftPath} data-nft={item.id} className={_linkClass}>
									<div className="image-over">


										{ item.forSale ? 										( <span className='collection-feature'><img src="/img/sashes/left-for-sale.png" alt="For Sale" /></span> ) : ( <></> )}
										{ item.reserved ? 										( <span className='collection-feature'><img src="/img/sashes/left-reserved.png" alt="Reserved" /></span> ) : ( <></> )}

										{ item.NFTokenID.length
												&& item.dupe === false
												&& item.forSale === false
												&& item.reserved === false
												&& item.allowOffers === true ?
																								( <span className='collection-feature'><img src="/img/sashes/left-make-an-offer.png" alt="Make an offer" /></span> ) : ( <></> )}

										{ item.NFTokenID.length
												&& item.forSale === false
												&& item.reserved === false
												&& item.allowOffers === false ?
																								( <span className='collection-feature'><img src="/img/sashes/left-minted.png" alt="Minted" /></span> ) : ( <></> )}

										{ item.mediaType === "video"						?	( <div className="viewing-nft-play"><img  src="/img/placeHolders/play-button.png" alt="Play" /></div> ) : (<></>) }

										{ _preview.indexOf("_pr.mp4") > -1 ?
											(
												<video className="card-img-top preload-image" loop="loop" autoPlay="autoPlay" play="play" muted="muted" style={{width:"100%", borderRadius:"10px"}} poster={this.props.config.sizes.placeholders.loader} data-load={_preview} id={"preload_cbrowse_"+preloadKey++}>
												</video>
											) : ( <img className="card-img-top preload-image" width={item.sizes.tw} height={item.sizes.th} src={this.props.config.sizes.placeholders.loader} id={"preload_cbrowse_"+preloadKey++} data-load={_preview} alt="" /> )
										}



									</div>
								</a>

								<div className="card-caption col-12 px-2">

									<div className="card-body p-0 mt-2">

										<h5 className="mb-1 ellipsis primary" title={item.title}>{item.title}</h5>
										<h6 className="m-0 mb-1 ellipsis primary" title={item.collection}>{item.collection}</h6>

										<div className="card-bottom mb-2 mt-2 row p-0">

											<div className="col-12 col-md-7 p-0 text-start">
												<strong className="primary">Price</strong> { Number(item.askingPrice) > 0 ? ( numberRounder(Number(item.askingPrice)) ) : ( <>n/a</>) }
											</div>
											<div className="col-12 col-md-5 p-0">
												<strong className="primary">Rank</strong> { Number(item.rank) > 0 ? ( Number(item.rank) ).toFixed(0) : ( <>n/a</>) }
											</div>


											{ _showSellBox ? (
												<div className="row g-0 mt-2 sellrow">

													<div className='col-12 col-md-3 pt-2 text-center form-group d-none d-md-block'>Sell for:</div>

													<div className='col-9 col-md-6 form-group ps-2'>
														<input type="number" key={"sellPrice_"+idx} className='form-control w-80 value-field' tabIndex={_tabIndex} min="0" onChange={this.updateForSale} data-id={item.NFTokenID}></input>
													</div>

													<div className='col-3 col-md-3 text-center'>
														{ this.state.selectedForSaleIDs.indexOf(item.NFTokenID) > -1 ?
														(
															<button type="button" className='nostyle' onClick={this.updateForSale} data-id={item.NFTokenID}><i className="fas fa-times-circle mt-2"></i></button>
														)
														: (<div className="mt-2">XRP</div>)
														}

													</div>

												</div>
											) : (
												<></> )}

											{ _showApproveButton ? (
												<a href={"/profile/polartest/wallet-offers/#approve-" + item.NFTokenID} className="btn buysell cancel w-100 mt-2" type='button'><i className="fas fa-wallet me-2 fa-lg"></i> Approve <span className="d-none d-md-inline-block"> Transfer</span></a>
											) : ( <></> )}

											{ _showMintingButton ? (
												<button className={"btn buysell minting w-100 mt-2" + _linkClass} type='button'  data-nft={item.id}><i className="fas fa-hourglass-half me-2 fa-lg"></i> MINTING</button>
											) : ( <></> )}

											{ _showBuyButton ? (
													<button className="btn buysell buy w-100 mt-2" type='button' onClick={addXummPayload} data-type="NFTokenAcceptOffer" data-buysell={"buy"} data-offers={"-1"} data-nftokenids={item.NFTokenID}><i className="fas fa-money-bill-alt me-2 fa-lg"></i> Buy NFT</button>
											) : ( <></> )}


											{ _showOfferButton ? (
													<button className={"btn buysell offer w-100 mt-2 " + _linkClass} type='button'  data-nft={item.id}><i className="fas fa-handshake me-2 fa-lg"></i> Make an Offer</button>
											) : ( <></> )}

											{ _showSpinButton ? (
													<a href={this.props.state.collectionData.fullURL} className="btn buysell winme w-100 mt-2" type='button'><i className="fas fa-dice me-2 fa-lg"></i> Win me</a>
											) : ( <></> )}

											{ _showSpacerButton ? (
													<button className="btn buysell visibility-hidden" type='button'>Dummy</button>
											) : ( <></> )}


										</div>

									</div>


								</div>


							<div className="card-links row">

								{ item.importing === false ?
								(
								<div className="ps-2 pb-2 text-center col-md-4 col-6">
									{ item.liked === 'No' ?
									(

										<span className={ "thumb-up" + ( this.props.config.userData.loggedIn === false ? " wallet-disconnected " : "" ) } data-type="NFT" data-uuid={item.id} data-bs-toggle="tooltip" data-bs-placement="left" title="Like/Unlike this NFT"><i className="fas fa-heart"></i></span>
									) :
									(
										<span className="thumb-up-chosen like-icon" data-type="NFT" data-uuid={item.id} data-bs-toggle="tooltip" data-bs-placement="top" title="Like/Unlike this NFT"><i className="fas fa-heart"></i></span>
									)
									}

									<span className="link" data-link={item.viewLink} data-bs-toggle="tooltip" data-bs-placement="top" title="Copy permaLink" onClick={permaLink}><i className="ms-2 fas fa-link"></i></span>

								</div>
								) : ( <></> )}

								<div className="px-0 pb-2 text-center  col-md-4 col-6">
									<span title={"This is item number " + _number + " of " + this.state.totalFilteredNFTs} data-bs-toggle="tooltip" data-bs-placement="bottom" >{_number}/{numberRounder(this.state.totalFilteredNFTs)}</span>
								</div>

								<div className="px-0 pe-3 pb-2 text-end d-none d-md-block col-md-4 col-12">
									<span title="Total Likes" className="like-count" data-bs-toggle="tooltip" data-bs-placement="right" >{item.likes} { item.likes === 1 ? "Like" : "Likes" }</span>
								</div>
							</div>

						</div>
					</div>
			 );
		})

		window.setTimeout(() => { window.loadPreloadImages(); }, 500);

		return (

			<section className="popular-collections-area">

				<div className={this.props.config.wideScreenClass}>

					{/* Bulk buy and sell buttons */}
					{ this.state.selectedForSaleIDs.length ?
					(
						<div className="container-fluid" id="buysellbuttons">
							<button type='button' className="btn w-100" onClick={this.bulkBuySell}><i className="fas fa-fingerprint fa-lg fa-flip-horizontal me-2 "></i> Approve In Wallet</button>
						</div>
					)
					:
					(<></>)
					}

					{ this.state.nftsImporting > 0 ?
					(
						<div class="alert alert-success mt-4">We are currently of downloading {this.state.nftsImporting} NFTs from the ledger. This could take some time based on server load. {this.state.nftsImportingRetry > 0 ? ( <span>( {this.state.nftsImportingRetry} are pending a retry. )</span> ) : ''}</div>
					) : (<></>)}



					<button type="button" id="reloadNFT" className="hid" data-nft={this.state.nft.id} onClick={this.loadNFT}>Reload NFT</button>

					{/* NOTE... NOTE... NOTE... NOTE... NOTE... NOTE... The modal wrap is HERE and not in the component as it's used in view.js and would make the page invisible */}
					{/* NOTE... NOTE... NOTE... NOTE... NOTE... NOTE... The modal wrap is HERE and not in the component as it's used in view.js and would make the page invisible */}
					{/* NOTE... NOTE... NOTE... NOTE... NOTE... NOTE... The modal wrap is HERE and not in the component as it's used in view.js and would make the page invisible */}
					<div id="view-nft-modal" className="modal fade p-0">
						<div className="modal-dialog modal-xl modal-fullheight modal-fullscreen-lg-down">
							<div className="modal-content h-100">
								{/* Button to launch NFT viewer */}
								<button type="button" className="hid" id="nft-popper-button" data-bs-toggle="modal" data-nft="" data-bs-target="#view-nft-modal" data-keyboard="false" data-backdrop="static" onClick={this.loadNFT}>Show modal</button>
								{/* The actual viewing section */}
								<ModalViewNFT config={this.props.config} state={this.state} modalCallBack={this.myCallbackModal} playButtonCallBack={this.myCallbackPlaybutton} loadNFT={this.loadNFT} componentCalledFrom="cbrowse.js" fullPage={false} />
							</div>
						</div>
					</div>


					{ _message.length ?
					(
						<div className="alert alert-info" role="alert">
							<i className="fas fa-info-circle fa-lg me-3"></i> {_message}
						</div>

					) : ( <></> )}

					{ this.state.sortOrder === "priceAsc" || this.state.sortOrder === "priceDesc" ?
					(
						<div className="alert alert-primary" role="alert">
							<i className="fas fa-info-circle fa-lg me-3 "></i> When sorting by price, only NFTs with a price will be shown. Any unminted items or those not listed for sale will be hidden.
						</div>

					)
					: (<></>) }

					{ ( this.props.config.userData.display || this.props.state.isMyCollection ) && this.props.state.collectionData && this.props.state.collectionData.offersEnabled === "No" ?
					(
						<div className="alert alert-warning" role="alert">
							<i className="fas fa-info-circle fa-lg me-3 "></i> Offers for this collection are disabled. To enable them, tap the "Edit Collection" button.
						</div>

					)
					: (<></>) }



					<div className="row cbrowse-header">


							{ _title.length && _title !== 'null' ?
							(
								<div className="col-12 pb-2 intro-content bigdots">
								<h3 className="m-0">
								{ this.state.dataType === 'spin-browse'  && this.props.collectionID.length && this.props.state.collectionData.soldOut === 'No' ? (
										<>
											<a href={this.props.state.collectionData.fullURL} className="btn px-3 py-1 me-3"  style={{"top":"-4px"}}><i className="fas fa-arrow-left"></i></a>
										</>) : (<></>) }
									{_title}
								</h3>
							</div>
							) : ( <></> )}



						<div className="col-12 text-end">

							{ this.props.infiniteScroll ?
								(
									<div className="browse-filters pt-1">

									{ this.state.showSearchStuff ? (
										<>
											<input type="text" id="collectionSearch" name="collectionSearch" onKeyUp={this.changeSearchStuff} defaultValue="" />
											<button type="button" className="button" id="collectionSearchbuttonGo" data-bs-toggle="tooltip" title="Search within this collection" onClick={this.doSearch}><i className="fas fa-search"></i></button>
											<button type="button" className="button" id="collectionSearchbuttonCancel" data-bs-toggle="tooltip" title="Clear search criteria" onClick={this.hideSearchStuff}><i className="fas fa-times"></i></button>
										</> ) : ( <>
											<button type="button" className="button" id="collectionSearchbutton" data-bs-toggle="tooltip" title="Search within this collection" onClick={this.showSearchStuff}><i className="fas fa-search"></i></button>
										</> )}


										{ this.props.collectionID.length && this.props.state.collectionData.hasAttributes === "Yes" ?
												(
													<button type="button" data-bs-toggle="tooltip" title="Open collection filters" onClick={this.showFilters} className="button"><i className="fas fa-filter fa-fw"></i></button>
												)
												:
												( <></>	)
										}

										{ this.props.collectionID.length && this.props.showSweep ?
												(
													<button type="button" data-bs-toggle="tooltip" title="Sweep floor" onClick={this.showSweepModal} className="button"><i className="fas fa-broom fa-fw"></i></button>
												)
												:
												( <></>	)
										}


											{/*
											<div className="dropdown button px-2">
												<a className="dropdown-toggle" href="/" role="button" id="dropdownMenuView" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-th"></i></a>
												<ul className="dropdown-menu" aria-labelledby="dropdownMenuView">
													<li><a className="dropdown-item" href="#">Grid View</a></li>
													<li><a className="dropdown-item" href="#">ListView</a></li>
												</ul>
											</div>
											*/}

											<div className="dropdown button px-2">
												<a className="dropdown-toggle" href="/" role="button" id="dropdownMenuSort" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-sort-alpha-down"></i></a>
												<ul className="dropdown-menu" aria-labelledby="dropdownMenuSort">

													{ this.props.showDefaultSortOption ?
													(
														<>
															<li key="sort_1"><a className={this.state.sortOrder === "" ? "dropdown-item active": "dropdown-item"} href="/" onClick={this.changeSort} data-sort=""><i className="fas fa-list-ol fa-fw"></i> Default Sort Order</a></li>
														</>
													) : ( <></> )}

													<li key="sort_2"><a className={this.state.sortOrder === "titleAsc" ? "dropdown-item active": "dropdown-item"} href="/" onClick={this.changeSort} data-sort="titleAsc"><i className="fas fa-sort-alpha-up fa-fw"></i> Title Ascending</a></li>
													<li key="sort_3"><a className={this.state.sortOrder === "titleDesc" ? "dropdown-item active": "dropdown-item"} href="/" onClick={this.changeSort} data-sort="titleDesc"><i className="fas fa-sort-alpha-down-alt fa-fw"></i> Title Descending</a></li>
													{ ( this.props.collectionID.length && this.props.state.collectionData.hasAttributes === "Yes" ) || window.location.href.indexOf("/profile/") > -1 ?
													(
														<>
															<li key="sort_4"><a className={this.state.sortOrder === "rank" ? "dropdown-item active": "dropdown-item"} href="/" onClick={this.changeSort} data-sort="rank"><i className="fas fa-list-ol fa-fw"></i> Rank (Best First)</a></li>
														</>
													) : ( <></> )}

													<li key="sort_5"><a className={this.state.sortOrder === "priceAsc" ? "dropdown-item active": "dropdown-item"} href="/" onClick={this.changeSort} data-sort="priceAsc"><i className="fas fa-sort-numeric-up fa-fw"></i> Price Ascending</a></li>
													<li key="sort_6"><a className={this.state.sortOrder === "priceDesc" ? "dropdown-item active": "dropdown-item"} href="/" onClick={this.changeSort} data-sort="priceDesc"><i className="fas fa-sort-numeric-down-alt fa-fw"></i> Price Descending</a></li>

												</ul>
											</div>
									{/*
										<button type="button" data-bs-toggle="tooltip" disabled={true} title="In development" titlex="Display the collection as a grid"><i className="fas fa-th"></i></button>
										<button type="button" data-bs-toggle="tooltip" disabled={true} title="In development" titlex="Display the collection as a list"><i className="fas fa-th-list"></i></button>
									*/}
									</div>
								) : ( <></>) }

								{ this.props.infiniteScroll === false ? ( <a className="btn content-btn intro-btn mt-2" href={_link}><span>View All</span></a> ) : (<></>) }

						</div>


					</div>


					{ this.props.collectionID.length && this.props.state.collectionData.hasAttributes === "Yes" ?
							(  <ModalFilters config={this.props.config} state={this.props.state} callbackFromParent={this.myCallback} parentState={this.state} /> )
							:
							( <></>	)
					}

					{ this.props.collectionID.length ?
							(  <ModalSweep config={this.props.config} state={this.props.state} callbackFromParent={this.myCallback} parentState={this.state} /> )
							:
							( <></>	)
					}

					<div className="text-center col-12 mt-5" id="loading">
						<h3>Loading NFTs</h3>
						<img src={this.props.config.sizes.placeholders.preloader} alt="Loading.." />
					</div>

					<div className="div notVisible" id="spinner-browse">

					{ this.state.totalFilteredNFTs > 0 && this.state.loadedInitial ?
						(
							<>
								{/* Text showing the applied filters */}
								{ this.state.showFilterHTML === true ?
											(  <div id='appliedFilters' className="text-center filters-chosen bg-success text-white"><strong>Applied Filters:</strong> {this.state.filterHTML} Results: {this.state.totalFilteredNFTs}</div> ) :
											( <></>	)
								}

								{/* {{ default: 3, 1100: 3, 700: 2, 500: 1 }} */}
								<Masonry breakpointCols={this.props.breakPoints} id="infinite-load-wrapper" className="my-masonry-grid" columnClassName="my-masonry-grid_column">
									{childElements}
								</Masonry>
							</>
						)
						:
						(
							<></>
						)
					}

					{/* If sort is price and no results, show a different message */}
					{ this.state.loadedInitial === true && this.state.totalFilteredNFTs === 0 && this.state.sortOrder.indexOf("price") > -1 ?
					(
						<div id="empty-collection" className="m-5 text-center">
							<h3>There are no results</h3>
							<p>Only NFTs with a selling price are included in this sort.</p>
						</div>
					)
					: ( <></> )}

					{/* If there are no NFTS and price is not sorted by price */}
					{ this.state.loadedInitial === true && this.state.totalFilteredNFTs === 0 && this.state.sortOrder.indexOf("price") === -1 ?
					(
						<>
						{
							this.props.state.isMyCollection && this.props.config.userData.bulk === "Yes" && ( this.state.dataType === 'spin-browse' || this.state.dataType === 'browse' ) ?
							(
								<div id="empty-collection" className="m-5 text-center">

									<h3>This collection is empty</h3>
									<p>Let's change that and get creative!</p>

									<div className="row d-flex justify-content-around">

										<div className="col-12 col-md-5 mb-3 text-center">
											<button type="button" className="btn  w-75" data-bs-toggle="modal" data-bs-target="#bulk-upload-modal">Bulk upload</button>
											<p>Do you want to create multiple NFTs in one go? If so, choose the "Bulk Upload" button. You can upload a single ZIP file containing your NFT media. You can also include JSON data to describe properties of the NFTs and any traits.</p>

											<ModalBulkUploadMulti config={this.props.config} state={this.props.state} modalCallBack={this.myCallbackModal} />

										</div>

										<div className="col-12 col-md-1 mb-3 text-center pt-2 primary">
											<strong>- or -</strong>
										</div>

										<div className="col-12 col-md-5 mb-3 text-center">
											<button className="btn  w-75" onClick={this.launchCRUD} data-collection={this.props.state.collectionData.id}>Single NFT</button>
											<p>As the button implies, you'll add a single NFT to the collection. Ideal for small collections that don't have traits.</p>
										</div>
									</div>
								</div>
							)
							:
							(
								<div id="empty-collection" className="m-5 text-center">
								{ this.props.state ?
									(
										<>
											<h3>This collection is empty</h3>
											<p>The creator hasn't yet added any NFTs to this collection.</p>
										</>
									)
									:
									(
										<h3 className="my-5 text-center primary">There are no items available.</h3>
									)
								}
								</div>
							)
						}
						</>
					)
					: ( <></> )
					}


					</div>


					{ this.props.infiniteScroll && this.state.totalFilteredNFTs ?
						(
						<div id="infinite-loader" ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS} className="col-12 mt-4 pt-5 text-center">
							<div style={loadingTextCSS}>
								<div><h3>Loading additional NFTs...</h3></div>
								<img src={this.props.config.sizes.placeholders.preloader} alt="Loading" />
							</div>
							{/* <div style={loadingFinishedCSS}><h3 className="line"><span>End of list</span></h3></div> */}
						</div>
						)
						:
						( <></> )
					}


				</div>

			</section>

        );
    }
}

export default CollectionSpinnerBrowseComponent;