import React, { Component } from 'react';

class AuthorProfile extends Component {

    render() {

		var _data, _class

		if(this.props.state.profileData)
		{
			_data = this.props.state.profileData;
			_class = 'card no-hover text-center mt-4 no-bg no-border'
		}
		else
		{
			_data = this.props.state.collectionData.creator;
			_class = 'card no-hover text-center mt-4 no-bg no-border'
		}

		const _authorImage = _data.profileImageL.length ? this.props.config.apiServer + _data.profileImageL : this.props.config.sizes.placeholders.profilePicture.large;

        return (
            <div className={_class}>

				<div className="image-over mt-3">
                    <img className="author-profile-image" src={_authorImage} alt="" />
                </div>

				{/* Card Body */}
				<div className="card-body p-0 pt-2">
					<h5 className="mb-3 author-profile-heading">{_data.title}</h5>
					<p className="my-3 author-profile-description">{_data.description}</p>

					{/*

						Wallet address

						<div className="input-group">
							<input type="text" className="form-control" placeholder={this.props.profileData.id} />
							<div className="input-group-append">
								<button><i className="icon-docs" /></button>
							</div>
						</div>
						*/}

					{/* Social Icons */}
					<div className="social-icons d-flex justify-content-center my-3">
						{_data.social.map((item, idx) => {
							const _class = item.link.length ? "" : "hid";
							return (
								<a key={`asd_${idx}`} className={_class} href={item.link} target="_blank" rel="noreferrer">
									<i className={item.icon} />
									<i className={item.icon} />
								</a>
							);
						})}
					</div>

					{ _data.id === this.props.config.userData.id ? ( <a href={_data.url + "/edit/"} className="btn btn-bordered btn-smaller w-100">Edit Profile</a>) : ( <></> )}


				{ this.props.isMyCollection ?
				(
					<>
					<a className="btn btn-bordered btn-smaller  w-100 mt-3" href={`${this.props.config.userData.url}/collections/add`}>
						<i className="fas fa-plus-circle me-2"></i> New Collection
					</a>
					<button className="btn btn-bordered btn-smaller w-100 mt-3" onClick={this.launchCRUD}>
						<i className="fas fa-plus-circle me-2"></i> New NFT
					</button>
					</>
				)
				:
				( <></> )
				}

                </div>
            </div>
        );
    }
}

export default AuthorProfile;