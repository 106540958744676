import React, { Component } from 'react';
import axios from 'axios';
import { toDaysMinutesSeconds,numberRounder, shortDate  } from '../xutils'
import { addXummPayload } from '../../js/xumm';
import CanvasJSReact from '../../js/canvasjs.react';

var loadTimeout = null;
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class ModalCollectionStats extends Component {

	resetModal = e =>
	{

		window.$('#nav-sales-tab').trigger("click");
		window.$('#close-stats-modal').trigger("click");
	}

	resize = e =>
	{
		window.dispatchEvent(new Event('resize'));
	}

	componentDidMount()
	{

	}

	render() {

		const now = new Date()
		const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000)
		const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000)

		const secondsSinceEpoch = Math.round(Date.now() / 1000)

		var blank = {
			title:	{ text: "" },
			chart:{
					zoomEnabled:true,
					interactivityEnabled:true
			},
			data: [{
					  type: "column",
					  dataPoints: []
			 }]
		 }

		 var options = blank
		 var options2 = blank

		 if(this.props.state.analysisData.charts.length)
		 {

		 }


        return (


			<>

				<div className="hid" id="btnFilters">
					<button id="showStatsModal" className="btn btn-bordered" data-bs-toggle="modal" data-bs-target="#statsmodal">Sweeper</button>
				</div>

				<div id="statsmodal" className="modal fade p-0">
					<div className="modal-dialog modal-lg dialog-animatedx modal-fullheight modal-fullscreen-lg-down">
						<div className="modal-content h-100">

							<div className="modal-header modal-header--sticky">
								<span>Stats</span>
								<button type='button' className="nostyle"  onClick={this.resetModal} ><i className="fas fa-times-circle icon-close"/></button>
								<button type='button' id="close-stats-modal" data-bs-dismiss="modal" className="hid">X</button>
							</div>


							<div className="modal-body d-block">

								<nav className="siteTabs">
									<div className="nav nav-tabs justify-content-center mt-4" id="nav-tab" role="tablist">
										<button className="nav-link active" id="nav-sales-tab" data-bs-toggle="tab" data-bs-target="#nav-sales" type="button" role="tab" aria-controls="nav-sales" aria-selected="false">Sales</button>
										<button className="nav-link" id="nav-charts-tab" data-bs-toggle="tab" data-bs-target="#nav-charts" type="button" role="tab" aria-controls="nav-sales" aria-selected="false" onClick={this.resize}>Charts</button>
										<button className="nav-link" id="nav-info-tab" data-bs-toggle="tab" data-bs-target="#nav-info" type="button" role="tab" aria-controls="nav-info" aria-selected="true">Project Info</button>

									</div>
								</nav>
								<div className="tab-content container pt-3" id="nav-tabContent">

									<div className="tab-pane fade show active" id="nav-sales" role="tabpanel" aria-labelledby="nav-sales-tab">


										<div className="row fw-bold g-0">
											<div className="col-2"></div>
											<div className="col-4">NFT</div>
											<div className="col-3 text-center">When</div>
											<div className="col-2 text-center">XRP</div>
											<div className="col-1 text-center">BH</div>
										</div>

										{this.props.state.analysisData.sales.map((item, idx) => {

										const _prefix = item.image.indexOf("http") < 0 ? this.props.config.apiServer : "";
										const _img = item.image.length ? _prefix + item.image : this.props.config.sizes.placeholders.square;


										return (
											<div className="row g-0" key={`sales_${idx}`}>
												<div className="col-2"><a href={`${this.props.config.apiServer}/nft/${item.NFTokenID}`} target="_bithomp"><img src={_img} /></a></div>
												<div className="col-4 mt-2">{item.title}</div>
												<div className="col-3 mt-2 text-center">{toDaysMinutesSeconds(secondsSinceEpoch - item.created, true)}</div>
												<div className="col-2 mt-2 text-center">{numberRounder(item.value)}</div>
												<div className="col-1 mt-1 text-center"><a href={`https://bithomp.com/explorer/${item.NFTokenID}`} target="_bithomp"><i className="fas fa-external-link-alt fa-xs"></i></a></div>


											</div>
											);
										})}

										<p className='my-5 primary text-center text-small'>Results are limited to 400 sales.</p>

									</div>

									<div className="tab-pane fade" id="nav-info" role="tabpanel" aria-labelledby="nav-info-tab">
										<div className="description" dangerouslySetInnerHTML={{__html: this.props.state.analysisData.description.replace(/[\n]/g, '<br/>') }}></div>
									</div>

									<div className="tab-pane fade" id="nav-charts" role="tabpanel" aria-labelledby="nav-charts-tab">
										{ this.props.state.analysisData.charts.length ?
											(
												<>
													{this.props.state.analysisData.charts.map((item, idx) => {
														return (
																<>
																	<h4>{this.props.state.analysisData.charts[idx].xTitle}</h4>
																	<CanvasJSChart options={this.props.state.analysisData.charts[idx]} containerProps={{ width: '100%', height: '320px' }}/>
																</>
														);
													})}


												</>
											)
											: ( <></>)}


									</div>

								</div>

							</div>

						</div>
					</div>
				</div>
			</>
    	)
	}
};

export default ModalCollectionStats;

