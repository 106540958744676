import React, { Component } from 'react';
import CollectionSlideout from './slideOut';

/* Modules for this page */
import NFTBrowser from '../NFT/cBrowse';

import CollectionSpinnerImporting from './spinner-importing';
import CollectionSpinnerCalculating from './spinner-calculating';
import CollectionSpinnerNotFunded from './spinner-not-funded';
import CollectionSpinnerNotUploaded from './spinner-not-uploaded';
import CollectionSpinnerDownloading from './spinner-downloading';
import CollectionSpinnerDownloadPending from './spinner-download-pending';
import CollectionSpinnerLockedForEditing from './spinner-locked';
import CollectionSpinnerFailed from './spinner-failed';
import CollectionSpinnerWheel from './spinner-wheel';
import CollectionSpinnerUploadBoxes from './spinner-upload-boxes';
import CollectionSpinnerAuthorise from './spinner-wallet-authorise';
import CollectionSpinnerNotReady from './spinner-not-ready';


class CollectionSpinnerComponent extends Component {



	render() {

		/* Force browser to be show if on the browse-all page, collection is sold out or parent component says it's a standard collection */
		var showBrowser = 	(
							window.location.href.indexOf("/browse-all") > -1
							|| this.props.state.collectionData.soldOut === "Yes"
							|| this.props.state.spinnerCollection === "No"
						)
						? true : false

		var mobileStyle = showBrowser ? "mb-3" : "mb-3 d-none d-md-block"
		var mobileStyle2 = showBrowser ? "d-none" : "m-0 d-md-none text-center"

		return (

			<section className="explore-area p-0 pt-2">

				<div className={mobileStyle}><CollectionSlideout config={ this.props.config } state={this.props.state} /></div>
				<div className={mobileStyle2}><a className="text-center primary" style={{"fontSize":"90%"}} href={this.props.state.collectionData.fullURL + 'browse-all'} target="_blank">View Collection Items <i className="fas fa-arrow-right" style={{"fontSize":"90%"}}></i></a></div>

				<div className={this.props.config.wideScreenClass}>

					{/* Loading message shows until axios has completed and populated the ID */}
					{ this.props.state.loading ?
						(
						<div className="mt-5 text-center">
							<h3>Loading collection</h3>
							<img src={this.props.config.sizes.placeholders.preloader} alt="Loading.." />
						</div>
						) : ( <></>) }

					{ /* Depending on state, show the relevant include */}
						{ this.props.state.collectionData.status === 'notReady' ?
						(
						<CollectionSpinnerNotReady config={ this.props.config } state={this.props.state} showKeys={this.showKeys} who={this.props.who} collectionID={this.props.collectionID} />
						) : ( <></>) }

						{ this.props.state.collectionData.status === 'notFunded' ?
						(
						<CollectionSpinnerNotFunded config={ this.props.config } state={this.props.state} showKeys={this.showKeys}  who={this.props.who} collectionID={this.props.collectionID} />
						) : ( <></>) }

						{ this.props.state.collectionData.status === 'downloadPending' ?
						(
						<CollectionSpinnerDownloadPending config={ this.props.config } state={this.props.state} showKeys={this.showKeys} who={this.props.who} collectionID={this.props.collectionID} />
						) : ( <></>) }

						{ this.props.state.collectionData.status === 'uploadBoxes' ?
						(
						<CollectionSpinnerUploadBoxes config={ this.props.config } state={this.props.state} showKeys={this.showKeys} who={this.props.who} collectionID={this.props.collectionID} />
						) : ( <></>) }

						{ this.props.state.collectionData.status === 'locked' ?
						(
						<CollectionSpinnerLockedForEditing config={ this.props.config } state={this.props.state} showKeys={this.showKeys} who={this.props.who} collectionID={this.props.collectionID} />
						) : ( <></>) }

						{ this.props.state.collectionData.status === 'downloading' ?
						(
						<CollectionSpinnerDownloading config={ this.props.config } state={this.props.state} showKeys={this.showKeys} who={this.props.who} collectionID={this.props.collectionID} />
						) : ( <></>) }

						{ this.props.state.collectionData.status === 'needMinterSet' ?
						(
						<CollectionSpinnerAuthorise config={ this.props.config } state={this.props.state} showKeys={this.showKeys} who={this.props.who} collectionID={this.props.collectionID} />
						) : ( <></>) }


						{ this.props.state.collectionData.status === 'editable' ?
						(
						<CollectionSpinnerNotUploaded config={ this.props.config } state={this.props.state} showKeys={this.showKeys} who={this.props.who} collectionID={this.props.collectionID} />
						) : ( <></>) }

						{ this.props.state.collectionData.status === 'importing' ||  this.props.state.collectionData.status === 'downloaded' ?
						(
						<CollectionSpinnerImporting config={ this.props.config } state={this.props.state} showKeys={this.showKeys} who={this.props.who} collectionID={this.props.collectionID} />
						) : ( <></>) }

						{ this.props.state.collectionData.status === 'calculating' ?
						(
						<CollectionSpinnerCalculating config={ this.props.config } state={this.props.state} showKeys={this.showKeys} who={this.props.who} collectionID={this.props.collectionID} />
						) : ( <></>) }

						{ this.props.state.collectionData.status === 'importFailed' ?
						(
						<CollectionSpinnerFailed config={ this.props.config } state={this.props.state} showKeys={this.showKeys} who={this.props.who} collectionID={this.props.collectionID} />
						) : ( <></>) }


						{ showBrowser === true && ( this.props.state.collectionData.status === "spin" || this.props.state.collectionData.status === 'notbulk' || this.props.state.collectionData.status === 'browse' ) ?
						(
							<>
								{ this.props.state.collectionData.br !== 'Yes' || this.props.state.collectionData.pr === 'Yes' ?
								(
									<div className="mt-5 text-center">
										<h2>This collection cannot be browsed</h2>
									</div>
								)
								:
								(
									<NFTBrowser	config={ this.props.config }
													state={this.props.state}
													breakPoints={this.props.config.masonryBreakpoints}
													showKeys={this.showKeys}
													collectionID={this.props.collectionID}
													who={this.props.who}
													showSweep={true}
													dataType={this.props.state.spinnerCollection === "Yes" ? "spin-browse" : "browse"}
													infiniteScroll={true}
													showDefaultSortOption={true}
													spinnerCollection={true}
													maxResults={0} />
								)}
							</>
						) : ( <></>) }

						{ showBrowser === false && this.props.state.collectionData.status === "spin" ?
						(
							<>
								<CollectionSpinnerWheel config={ this.props.config } state={this.props.state} showKeys={this.showKeys} who={this.props.who} collectionID={this.props.collectionID} spinnerType={this.props.state.collectionData.spinnerInfo.type} />
							</>
						) : ( <></>)
					}

				</div>

				</section>

		);
	}
}

export default CollectionSpinnerComponent;