import React, { Component } from 'react';
import axios from 'axios';
import ViewItems from '../Tools/ViewComponent'

class balancesComponent extends Component {
    state = {
        data: {Account:""},
		nfts:[],
		nftsLoaded:false,
		balances:[],
		spins:[]
    }

	componentDidMount(){

		this.loadBalances()
		document.title = "Wallet Balances"
		// this.loadNFTs()

    }

	loadBalances = e =>
	{
		/* Use API for the NFTs as they go into our discovery pot */
		const BASE_URL = this.props.config.apiServer + "/com/external/wallet.cfc?method=getAccountInfo";
		axios.post(BASE_URL, {}, { withCredentials: true })
            .then(res => {

				if( ! res.data.success)
				{
					throw(res.data.errorMessage);
				}
				else
				{
					this.setState({data:res.data.info, balances:res.data.balances, spins:res.data.spins});
				}
            })
        .catch(err =>
				{ window.hideWaitMessage()	; window.displayErrorMessage(err); }
		)

	}


	loadNFTs = e =>
	{
		/* Use API for the NFTs as they go into our discovery pot */
		const BASE_URL = this.props.config.apiServer + "/com/external/wallet.cfc?method=getNFTs";
		axios.post(BASE_URL, {}, { withCredentials: true })
            .then(res => {

				if( ! res.data.success)
				{
					throw(res.data.errorMessage);
				}
				else
				{
					this.setState({nfts:res.data.nfts, nftsLoaded:true});
				}
            })
        .catch(err =>
				{ window.hideWaitMessage()	; window.displayErrorMessage(err); }
		)

	}


    render() {

        return (

			<>
				<div id="wallet-balances">

					<div className="row mb-3 mt-4 pb-2 bigdots">
						<div className="col-12">
							<div className="intro d-flex justify-content-between align-items-end m-0">
								<div className="intro-content">
									<h3 className="m-0">My Wallet Balances</h3>
								</div>
							</div>
						</div>
					</div>

					{ this.state.data.Account.length === 0 ?
					(
						<div id="loading" className="text-center">
							<h3>Loading Balances</h3>
							<img src={this.props.config.sizes.placeholders.preloader} alt="Loading" />
						</div>
					) : (<></>)}

					{ this.state.data.Account.length > 0 ?
					(
					<div id="output">

						<h5 className='primary'>Account Balances</h5>
						<div className="row">
							<div className="col-8">
								<ul id="items">
									<li><label>Wallet Address</label><span><a href={this.props.config.links.explore + "/" + this.state.data.Account} target="_blank" rel="noreferrer">{this.state.data.Account}</a></span></li>
									<li><label>Total</label><span>{ this.state.data.Balance } XRP</span></li>
									<li><label>Less Reserve</label><span>{ this.state.data.Reserve } XRP</span></li>
									<li><label>Balance</label><span>{ this.state.data.BalanceNet } XRP</span></li>
									<li><label>NFTs Minted</label><span>{ typeof this.state.data.MintedNFTokens !== 'undefined' ? this.state.data.MintedNFTokens : 0 }</span></li>
									<li><label>NFTs Burned</label><span>{ typeof this.state.data.BurnedNFTokens !== 'undefined' ? this.state.data.BurnedNFTokens : 0 }</span></li>
								</ul>
							</div>
							<div className="col-4 garvatarImage">
								{ this.state.data.urlgravatar.length ?
								(
									<img src={this.state.data.urlgravatar}></img>
								)
								: (<></>)}
							</div>
						</div>

						<h5 className='primary'>Spinner Credits</h5>
						<div id="credits">
							<ul id="items">

								{ this.state.spins.length ?
									(
										this.state.spins.sort().map((item, idx) =>
										{

											return (

												<li key={"spins" + idx}>
													<label>Collection</label>
														<strong className="primary" style={{minWidth:"30%", "display":"inline-block"}}><a href={item.cu} target="_blank">{item.cn} [{item.cc}]</a></strong>
													<label>Balance</label>
													<span>{ item.bal } Spins</span>

													{ item.pn > 0 ?
														(
															<>
																<div>
																	<label></label>
																	<span className="primary" style={{minWidth:"30%", "display":"inline-block"}}></span>
																	<label>Pending</label>
																	<span>{ item.pn } Spins </span>
																</div>

																{ item.pe.length ?
																	( <div className="text-center errorText mb-4 mt-3">Error approving spins: {item.pe}</div>) : ( <></>)
																}
															</>

														)
														:
														( <></> )
													}

												</li>
											);
										})
									)
									:
									(
										<p className="text-center">You do not have any unused spinner credits</p>
									)
								}

							</ul>
						</div>

					</div>
					) : (<></>)}


					{ 0 === 1 ?

					( <>

						<div className="row mb-3 pb-2 bigdots mt-4">
							<div className="col-12">
								<div className="intro d-flex justify-content-between align-items-end m-0">
									<div className="intro-content">
										<h3 className="m-0">NFTs on the ledger</h3>
									</div>
								</div>
							</div>
						</div>

						{ this.state.nftsLoaded === false ?
						(
							<div id="loading" className="text-center">
								<h3>Loading Balances</h3>
								<img src={this.props.config.sizes.placeholders.preloader} alt="Loading" />
							</div>
						) :
						(
						<div className="row items explore-items" id="nfts">

							{/* Display items */}
							<ViewItems config={this.props.config} nfts={this.state.nfts} walletAddres={this.state.data.Account} />

						</div>
						)
						}
					</> ) : ( <></> )}

				</div>
			</>

        );
    }
}

export default balancesComponent;