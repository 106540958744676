import React from 'react';

const PrivacyTerms = () => {
    return (
		<div className="container text-center mt-5">
			<h1>Soon</h1>
			<p>We're still writing this page</p>
		</div>

    );
};

export default PrivacyTerms;