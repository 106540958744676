import React from "react";
import '../../css/termsAndConditions.css'

const PrivacyTerms = () => {
	return (
		<div className="container termsAndConditions">

			<div className="row mb-3 mt-4 pb-2 bigdots">
				<div className="col-12">
					<div className="intro d-flex justify-content-between align-items-end m-0">
						<div className="intro-content">
							<h3 className="m-0">Terms & Conditions</h3>
						</div>
					</div>
				</div>
			</div>

			{/*

			https://www.textfixer.com/html/convert-word-to-html.php
			https://www.textfixer.com/html/convert-word-to-html.php
			https://www.textfixer.com/html/convert-word-to-html.php
			https://www.textfixer.com/html/convert-word-to-html.php
			https://www.textfixer.com/html/convert-word-to-html.php
			https://www.textfixer.com/html/convert-word-to-html.php

			*/}

			<p>
				xMart Marketplace website is operated by xMart, (hereinafter,
				“xMart”,“We”, or “Us”). These terms and conditions (the “Terms”) govern
				your access to the xMart marketplace website, nfts and any mobile
				applications, and services owned, controlled, or offered by xMart, now
				or in the future (all collectively referred to as, the "services"). The
				term <strong>“</strong>You<strong>”</strong> or <strong>“</strong>User
				<strong>”</strong> shall refer to any individual that views, uses,
				accesses, browses or submits any content or material to the Services.
			</p>

			<p>
				These Terms are important and affect your legal rights, so please read
				them carefully. Note that these Terms contain a{" "}
				<strong>mandatory arbitration provision</strong> that requires the use
				of arbitration on an individual basis and limits the remedies available
				to you in the event of certain disputes.
			</p>

			<p>
				The Services are offered to you conditioned on your acceptance without
				modification of Terms contained herein. Certain features, services or
				tools of the Services may be subject to additional guidelines, terms, or
				rules, which will be posted with those features and are a part of these
				Terms. Your use of the Services constitutes your agreement to all such
				Terms. Please read these terms carefully and keep a copy of them for
				your reference.
			</p>

			<p>
				<strong>
					YOU ACKNOWLEDGE AND AGREE THAT BY ACCESSING, USING, CONSUMMATING A
					FINANCIAL TRANSACTION, OR BROWSING THE SERVICES, YOU ARE AFFIRMING
					THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS,
					WHETHER OR NOT YOU HAVE REGISTERED WITH THE SERVICES. IF YOU DO NOT
					AGREE TO THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE
					SERVICES.
				</strong>
			</p>

			<h2>PRIVACY POLICY</h2>

			<p>
				Your use of the Services is subject to the xMart’s Privacy Policy.
				Please review our Privacy Policy, which also governs the Services and
				informs Users of our data collection practices.
			</p>

			<p>
				<strong>1. USE OF OUR SERVICES</strong>
			</p>

			<p>
				<strong>2. </strong>Your use of the Services is subject to the xMart’s
				Privacy Policy. Please review our{" "}
				<a href="/pages/privacy-policy/">Privacy Policy</a>, which
				also governs the Services and informs Users of our data collection
				practices.
			</p>

			<p>
				<strong>3. </strong>Subject to your compliance with its obligations
				under these Terms, we will provide you with access to the Services.
				Access to the Services is permitted on a temporary basis, and we reserve
				the right to withdraw or amend the service we provide without notice. We
				will not be liable if for any reason our Services are unavailable at any
				time or for any period.
			</p>

			<p>
				<strong>4. </strong>You must be eighteen (18) years or over in order to
				use the Services.
			</p>

			<p>
				<strong>5. REGISTRATION, ACCOUNT AND COMMUNICATION PREFERENCES</strong>
			</p>

			<p>
				<strong>6. </strong>In order to access and use certain areas or features
				of the Services, you may need to have an account with us (the{" "}
				<strong>“</strong>Account<strong>”</strong>). By creating an Account,
				you agree to (i) provide accurate, current and complete account
				information about yourself as necessary, (ii) maintain and promptly
				update from time to time as necessary your Account information, (iii)
				maintain the security of your password and accept all risks of
				unauthorised access to your Account and the information you provide to
				Us, and (iv) immediately notify Us if you discover or otherwise suspect
				any security breaches related to your Account. In creating an Account,
				you represent that you are of legal age to form a binding contract and
				are not a person barred from receiving services under the laws of the
				United States or any other applicable jurisdiction. We reserve the right
				to suspend or terminate your Account if any information provided during
				the registration process or thereafter proves to be inaccurate, false or
				misleading.
			</p>

			<p>
				<strong>7. ACCESS TO THE SERVICES</strong>
			</p>

			<p>
				<strong>8. </strong>We shall use commercially reasonable efforts to
				ensure the availability of the Services, except that we shall not be
				liable for: (a) scheduled downtime; or (b) any unavailability caused
				directly or indirectly by circumstances beyond our reasonable control,
				including without limitation, (i) a force majeure event; (ii) Internet
				Host, web hosting, cloud computing platform, or public
				telecommunications network failures or delays, or denial of service
				attacks; (iii) a fault or failure of your computer systems or networks;
				or (iv) any breach by of these Terms by you.
			</p>

			<p>
				<strong>9. TERMINATION OR SUSPENSION OF SERVICES</strong>
			</p>

			<p>
				<strong>10. </strong>xMart reserves the right to refuse or suspend
				access to any user, for any reason or no reason, and without any notice.
				xMart may suspend your use of the xMart’s Services or any portion
				thereof if xMart believes that you have breached these Terms, or for any
				other reason, at its sole discretion. You agree that any termination of
				your access to the xMart Services may be without prior notice, and you
				agree that xMart will not be liable to you or any third party for any
				such termination. Where needed, we reserve the right to alert local law
				enforcement authorities about suspected fraudulent, abusive, or illegal
				activity that may be grounds for termination of your use of the
				Services.
			</p>

			<p>
				<strong>11. PROHIBITED USES</strong>
			</p>

			<p>
				<strong>12. </strong>Use of the Services is limited to the permitted
				uses expressly authorised by us. Any violation of this Agreement by your
				user content, as determined by xMart in its sole discretion, may result
				in the termination of your access to the Services.{" "}
				<strong>The Services shall not be used to:</strong>
			</p>

			<p>
				a. Harass, abuse, stalk, threaten, defame, or otherwise infringe or
				violate the rights of any other party (including but not limited to
				rights of publicity or other proprietary rights);
			</p>

			<p>b. Engage in any unlawful, fraudulent, or deceptive activities;</p>

			<p>
				c. Misrepresent the source, identity, or content of information
				transmitted via the Services;
			</p>

			<p>
				d. Use technology or other means to access unauthorised content or
				non-public spaces;
			</p>

			<p>
				e. Use or launch any automated system or process, including without
				limitation, "bots," "spiders," or "crawlers," to access unauthorised
				content or non-public spaces;
			</p>

			<p>
				f. Attempt to introduce viruses or any other harmful computer code,
				files or programs that interrupt, destroy or limit the functionality of
				any computer software or hardware or telecommunications equipment;
			</p>

			<p>
				g. Probe, scan, or test the vulnerability of the Services or any system
				or network; use any robot, spider, scraper or other automated means to
				access the Services for any purpose without our express written
				permission;
			</p>

			<p>
				h. Use the Services in connection with hazardous environments requiring
				fail-safe performance or any application in which the failure or
				inaccuracy of that application or the Services could lead to death,
				personal injury, or physical or property damage;
			</p>

			<p>
				i. Modify the Services in any manner or form; use or develop any
				application that interacts with the Services or provides access to other
				users' content or information without our written permission; or use
				modified versions of the Services, including to obtain unauthorised
				access to the Services;
			</p>

			<p>
				j. Attempt to damage, disable, overburden, or impair our servers or
				networks;
			</p>

			<p>
				k. Attempt to gain unauthorised access to the Services, or any part of
				it, other accounts, computer systems or networks connected to xMart, or
				any part of it, through hacking, password mining or any other means, or
				interfere or attempt to interfere with the proper working of or any
				activities conducted on the Services;
			</p>

			<p>
				l. Display the Services or profile data on any external display or
				monitor or in any public setting without obtaining the prior consent of
				all participants. Furthermore, you may not display the Services or
				profile data on any external display or monitor or in any public setting
				in a manner that infringes on the intended use of the Services;
			</p>

			<p>
				m. Encourage the commission or encouragement of any illegal purpose, or
				in violation of any local, state, national, or international law,
				including laws governing criminal acts, prohibited or controlled
				substances, intellectual property and other proprietary rights, data
				protection and privacy, and import or export control; or
			</p>

			<p>n. violate these Terms in any manner;</p>

			<p>
				<strong>13. INTELLECTUAL PROPERTY RIGHTS</strong>
			</p>

			<p>
				<strong>14. </strong>You are granted a non-exclusive, non-transferable,
				revocable license to access and use the Services strictly in accordance
				with these Terms. As a condition of your use of the Services, you
				warrant to Us that you will not use the Services for any purpose that is
				unlawful or prohibited by these Terms. You may not use the Services in
				any manner which could damage, disable, overburden, or impair the
				Services or interfere with any other party's use and enjoyment of the
				Services. You may not obtain or attempt to obtain any materials or
				information through any means not intentionally made available or
				provided for through the Services.
			</p>

			<p>
				<strong>15. </strong>All content included as part of the Services, such
				as text, graphics, videos, logos, images, as well as the compilation
				thereof, and any software used on the Services, is the property of xMart
				or its suppliers and protected by copyright and other laws that protect
				intellectual property and proprietary rights. You agree to observe and
				abide by all copyright and other proprietary notices, legends or other
				restrictions contained in any such content and will not make any changes
				thereto.
			</p>

			<p>
				<strong>16. </strong>You will not modify, publish, transmit, reverse
				engineer, participate in the transfer or sale, create derivative works,
				or in any way exploit any of the content, in whole or in part, found on
				the Services. Our content is not for resale. Your use of the Services
				does not entitle you to make any unauthorised use of any protected
				content, and in particular you will not delete or alter any proprietary
				rights or attribution notices in any content. You will use protected
				content solely for your personal use and will make no other use of the
				content without the express written permission of xMart and the
				copyright owner. You agree that you do not acquire any ownership rights
				in any protected content. We do not grant you any licenses, express or
				implied, to the intellectual property of xMart or our licensors except
				as expressly authorised by these Terms.
			</p>

			<p>
				<strong>17. DMCA NOTICE AND TAKEDOWN POLICY</strong>
			</p>

			<p>
				<strong>18. </strong>xMart’s respects intellectual property rights and
				expects its users to do the same. If you are a copyright owner or an
				agent thereof, and you believe that any content hosted on any of the
				Services infringes Your copyrights, then you may submit a notification
				by providing xMart Designated Copyright Agent with the following
				information in writing:
			</p>

			<p>
				o. A physical or electronic signature of a person authorised to act on
				behalf of the owner of an exclusive right that is allegedly infringed;
			</p>

			<p>
				p. Identification of the copyrighted work claimed to have been
				infringed, or if multiple copyrighted works on the applicable Services
				are covered by a single notification, a representative list of such
				works on the applicable Services;
			</p>

			<p>
				q. Identification of the material that is claimed to be infringing or to
				be the subject of infringing activity and that is to be removed or
				access to which is to be disabled, and information reasonably sufficient
				to permit xMart to locate the material; Information reasonably
				sufficient to permit xMart to contact the complaining party, such as an
				address, telephone number, and, if available, an electronic mail address
				at which the complaining party may be contacted;
			</p>

			<p>
				r. A statement that the complaining party has a good faith belief that
				use of the material in the manner complained of is not authorised by the
				copyright owner, its agent, or the law (for example, "I am under the
				good faith belief that the use of the copyrighted content that is
				identified herein is not authorised by the copyright owner, its agent,
				or the law."); and
			</p>

			<p>
				s. A statement that the information in the notification is accurate, and
				under penalty of perjury, that the complaining party is authorised to
				act on behalf of the owner of an exclusive right that is allegedly
				infringed (for example, "I swear, under penalty of perjury, that the
				information in this notification is accurate and that I am the copyright
				owner or authorised to act on behalf of the copyright owner, of the
				copyright(s) that is allegedly infringed by the aforementioned
				content.").
			</p>

			<p>
				t. xMart’s Designated Copyright Agent to receive notifications of
				claimed infringement can be reached as follows:
			</p>

			<p>· xMart.art</p>

			<p>
				· <a href="mailto:info@xmart.art">info@xmart.art</a>
			</p>

			<p>· Attention: Copyright Claims</p>

			<p>
				· For clarity, only notices under this section should go to the xMart
				Designated Copyright Agent. You acknowledge that if you fail to comply
				with all of the requirements of this Section, your DMCA notice may not
				be valid.{" "}
			</p>

			<p>
				· <strong>FEEDBACK</strong>
			</p>

			<p>
				<strong>19. </strong>You can submit questions, comments, suggestions,
				ideas, original or creative materials or other information about xMart
				or the Services (collectively, <strong>“Feedback”</strong>). Feedback is
				non-confidential and shall become the sole property of xMart. We shall
				own exclusive rights, including, without limitation, all intellectual
				property rights, in and to such Feedback and shall be entitled to the
				unrestricted use and dissemination of this Feedback for any purpose,
				commercial or otherwise, without acknowledgment or compensation to you.
			</p>

			<p>
				<strong>20. NFT SALES</strong>
			</p>

			<p>
				u. <strong>NFT Terms.</strong> As used herein, “Own” means your rights
				with respect to an NFT you have purchased or otherwise rightfully
				acquired from a legitimate source, where proof of such purchase is
				recorded on the applicable blockchain. You agree, in relation to any NFT
				that you Own, that you Own that NFT in accordance with any description
				which accompanies that NFT. Issuer may, at its option, use third party
				platforms or wallet extensions (which may be owned or operated by third
				parties) to sell NFTs (“Third Party Sites”). You agree to adhere to any
				applicable terms of service or privacy policies applicable to the use of
				any Third-Party Sites. UNLESS STATED OTHERWISE IN THE DESCRIPTION OF THE
				APPLICABLE NFT, AN NFT THAT YOU OWN WILL BE TRANSFERABLE, BUT ANY
				TRANSFEREE WILL BE SUBJECT TO THESE NFT TERMS. NFTS THAT WE PROVIDE ARE
				TIED TO UNIQUE GOODS, SERVICES AND EXPERIENCES. YOU SHOULD NOT PURCHASE
				OUR NFTS WITH A VIEW TO INVESTMENT, RESALE OR SPECULATION. THERE CAN BE
				NO ASSURANCE AS TO THEIR PRESENT OR FUTURE VALUE, TRANSFERABILITY OR
				MARKETABILITY.
			</p>

			<p>
				v. <strong>Risks of NFTs.</strong> Once you Own any NFT, you are
				responsible for any loss or damage to, or loss of access to, the NFT and
				neither Issuer nor any of its licensors shall have any liability in such
				circumstances, regardless of cause. You expressly understand and agree
				that your use of the Services and any NFTs is at your sole risk and that
				the Services and NFTs are provided “as is” and “as available.”
			</p>

			<p>
				w. <strong>Terms of Sale.</strong> All sales of NFTs are final. Once you
				have made a purchase of an NFT, you should promptly take the necessary
				steps to complete your transaction (e.g., activate or download any
				content or secure any seed phrase or corresponding private key). We
				encourage the use of secure, offline storage measures for NFTs. You may
				purchase NFTs using real or virtual/cryptocurrency, where multiple
				payment options are made available to you. You may resell or otherwise
				transfer an NFT that you Own where this is permitted by these NFT Terms
				and applicable law. Any sale or transfer must provide for the transfer
				of all of your rights then outstanding with respect to such NFT. Anyone
				receiving such NFTs from you agrees to and is bound by these NFT Terms.
				You are strictly forbidden to sell, swap, donate, give away, transfer,
				or otherwise dispose of your purchased NFT to any person who is not
				eighteen (18) years or older.
			</p>

			<p>
				x. <strong>Pricing.</strong> Each NFT will be priced on the Services (at
				USD or the equivalent value in XRPL based assets as determined by us in
				our sole discretion and displayed on the Service at the time of
				purchase), inclusive of all applicable NFT minting and “gas” costs,
				excise taxes and U.S. domestic shipping. Additional sales tax may be
				charged.
			</p>

			<p>
				y. <strong>Inherent Risks with Digital Assets.</strong> There are risks
				associated with using Internet-based digital assets such as NFTs and
				cryptocurrency, including, but not limited to, the risk of hardware,
				software and Internet connections, the risk of malicious software
				introduction, and the risk that third parties may obtain unauthorised
				access to information stored within your electronic wallet. You accept
				and acknowledge that we will not be responsible for any communication
				failures, disruptions, errors, distortions or delays you may experience
				when using the XRPL network, however caused.
			</p>

			<p>
				z. <strong>Value, Volatility, Not an Investment.</strong> The prices of
				NFTs can be extremely volatile and subjective, and NFTs have no inherent
				or intrinsic value. To the extent there is a price or market for a
				blockchain asset such as an NFT, (a) those prices and markets are
				extremely volatile, (b) variations in the price of other digital assets
				could materially and adversely affect the value of any digital assets
				you own, including NFTs, and (c) there is no guarantee that NFTs will
				have or retain any value. NFTs are not securities or financial
				instruments and are not offered for investment purposes. The commercial
				or market value of NFTs may materially diminish in value as a result of
				a variety of things, including negative publicity associated with
				Issuer. You accept and acknowledge that we will not be responsible for
				the risks of engaging in any transactions relating to your NFTs with
				third parties (e.g., transferring your NFT from a third party on any
				so-called “secondary market”).
			</p>

			<p>
				aa. <strong>Use of Blockchain.</strong> The Services does not store,
				send, or receive NFTs. This is because NFTs exist only by virtue of the
				ownership record maintained on the Services’ supporting blockchain in
				the XRPL. Any transfer of NFTs occurs within the supporting blockchain
				in the Network, and not on the Services. We do not make any promises or
				guarantees about the availability of NFTs or that it will host your or
				any other NFTs at any specific location and/or for any specific period
				of time. Upgrades to the XRPL, a hard fork or other change to the XRPL,
				a failure or cessation of the XRPL or its underlying cryptocurrency, or
				a change in how transactions are confirmed on the XRPL may have
				unintended, adverse effects on all blockchains using those or similar
				technologies, including the NFTs. We do not make any promises or
				guarantees related to the XRPL, or any third parties related to the NFTs
				or the Services (including any of their respective applications and/or
				services, as well as to the continued availability of or the protection
				or storage of any data you provide to those parties). You accept and
				acknowledge that we will not be responsible for any loss of access to
				your NFTs due to loss of your private key(s), custodial error or
				purchaser error, mining attacks, hacking, security weaknesses, fraud,
				counterfeiting, cyberattacks and other technological difficulties.
			</p>

			<p>
				bb. <strong>Tax Calculation.</strong> You are solely responsible for
				determining what, if any, taxes apply to your NFT-related transactions.
				We are not responsible for determining the taxes that apply to your
				transactions on the Services or other NFT-related transactions.
			</p>

			<p>
				cc. <strong>General.</strong> You also acknowledge and agree that: (a)
				you have obtained sufficient information to make an informed decision
				regarding the NFTs; (b) you are solely responsible for determining the
				nature, potential value, suitability and appropriateness of these risks
				for yourself; (c) Issuer does not represent or warrant that any NFTs, or
				its supporting systems or technology, are reliable, current or
				error-free or otherwise meets your requirements, that defects in the
				NFTs, or their supporting systems or technology, will be corrected, or
				that the delivery mechanism for NFTs will be free of viruses or other
				harmful components; and (d) Issuer shall not be responsible for any
				communication failures, disruptions, errors or delays you may experience
				related to the NFTs.
			</p>

			<p>
				2.{" "}
				<strong>
					NFT sales on the xMart platform will be subject to transaction fees in
					the near future. Please check back for updated TOS and pricing
					structure.{" "}
				</strong>
			</p>

			<p>
				<strong>3. LINKS TO THIRD PARTY WEBSITES/THIRD-PARTY SERVICES</strong>
			</p>

			<p>
				<strong>21. </strong>The Services may contain links to other brands or
				services (<strong>"Linked Website"</strong>). The Linked Websites are
				not under our control, and We are not responsible for the contents of
				any Linked Websites, including without limitation any link contained in
				a Linked Website, products or merchandise sold through the Services, or
				any changes or updates to a Linked Website. We are providing these links
				to you only as a convenience, and the inclusion of any link does not
				imply our endorsement of the services or any association with its
				operators. Certain services made available through the Services are
				delivered by third parties and organisations and these Terms do not
				apply to any Linked Website. By using any product, service or
				functionality originating from the Services, you hereby acknowledge and
				consent that We may share such information and data with any third party
				with whom We have a contractual relationship to provide the requested
				product, service or functionality on behalf of xMart and customers.
			</p>

			<p>
				<strong>22. ERRORS, INACCURACIES AND OMISSIONS</strong>
			</p>

			<p>
				<strong>23. </strong>Occasionally there may be information on the
				Services that may contain typographical errors, inaccuracies or
				omissions that may relate to product or service descriptions, pricing,
				promotions, offers, charges and availability. We reserve the right to
				correct any errors, inaccuracies, or omissions, and to change or update
				information or cancel transactions if any information in the Services is
				inaccurate at any time without prior notice. We undertake no obligation
				to update, amend or clarify information on the Services, except as
				required by law. No specified update or refresh date applied in the
				Services, should be taken to indicate that all information in the
				Services has been modified or updated.
			</p>

			<p>
				<strong>24. </strong>WE DO NOT REPRESENT, WARRANT OR OTHERWISE ENDORSE
				THAT THE SERVICES OR ANY CONTENT, INFORMATION OR SERVICES THAT ARE
				AVAILABLE OR ADVERTISED OR SOLD THROUGH THE SERVICES ARE ACCURATE,
				COMPLETE, AVAILABLE, CURRENT. WE RESERVE THE RIGHT TO CORRECT ANY ERRORS
				OR OMISSIONS IN THE SERVICES.
			</p>

			<p>
				<strong>25. ELECTRONIC COMMUNICATIONS</strong>
			</p>

			<p>
				<strong>26. </strong>Using the Services or sending emails to us
				constitutes electronic communications. If you provide Us with your email
				address to our contact form, you consent to receive electronic
				communications from Us and you agree that all agreements, notices,
				disclosures and other communications that we provide to you
				electronically, via email and on the Services, satisfy any legal
				requirement that such communications be in writing.
			</p>

			<p>
				<strong>27. INDEMNIFICATION</strong>
			</p>

			<p>
				<strong>28. </strong>WITH THE EXCEPTION OF XMART’S GROSS NEGLIGENCE AND
				WILFUL MISCONDUCT, YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS
				XMART, ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AGENTS,
				REPRESENTATIVES, AND THIRD PARTIES, FOR ANY LOSSES, COSTS, LIABILITIES
				AND EXPENSES (INCLUDING REASONABLE ATTORNEY'S FEES) RELATING TO OR
				ARISING OUT OF YOUR USE OF, OR INABILITY TO USE, THE SERVICES, YOUR
				VIOLATION OF THESE TERMS OR YOUR VIOLATION OF ANY RIGHTS OF A THIRD
				PARTY, OR YOUR VIOLATION OF ANY APPLICABLE LAWS, RULES OR REGULATIONS.
				WE RESERVE THE RIGHT TO ASSUME THE EXCLUSIVE DEFENCE AND CONTROL OF ANY
				MATTER OTHERWISE SUBJECT TO INDEMNIFICATION BY YOU, IN WHICH EVENT YOU
				WILL FULLY COOPERATE WITH US IN ASSERTING ANY AVAILABLE DEFENCES.
			</p>

			<p>
				<strong>29. HARM FROM COMMERCIAL USE</strong>
			</p>

			<p>
				<strong>30. </strong>You agree that the consequences of commercial use
				or re-publication of content or information from the Services may be so
				serious and incalculable, that monetary compensation may not be a
				sufficient or appropriate remedy and that We will be entitled to
				temporary and permanent injunctive relief to prohibit such use.
			</p>

			<p>
				<strong>31. NO INVESTMENT OR FINANCIAL ADVICE</strong>
			</p>

			<p>
				<strong>32. </strong>The information provided through the Services is
				for informational purposes only. None of the content is intended to be,
				and does not, constitute financial advice or any other advice. All of
				the content is general in nature and not specific to you. You should not
				rely on any of our content to make an investment decision. You should
				consult with a financial professional to determine what may be best for
				your individual needs. We do not make any guarantee or other promise as
				to any results that may be obtained from using the Services. No one
				should make any investment decision without first consulting your own
				financial advisor and conducting your own research and due diligence.
			</p>

			<p>
				<strong>33. </strong>None of the information and/or content available
				through the Services is intended as an offer or solicitation of an offer
				to buy or sell, or as a recommendation, endorsement, or sponsorship of
				any company, financial product, security, or commodity. To the maximum
				extent permitted by law, we disclaim any and all liability in the event
				any information, commentary, analysis, opinions, advice and/or
				recommendations prove to be inaccurate, incomplete or unreliable, or
				result in any investment or other losses.
			</p>

			<p>
				<strong>
					34. XMART IS NOT A BROKER-DEALER OR PLACEMENT AGENT. AT NO TIME DOES
					XMART OFFER, BROKER, ADVISE, PURCHASE, SELL OR OTHERWISE TRANSACT IN
					SECURITIES REGULATED BY THE SEC OR FEDERAL OR STATE LAW. XMART IS NOT
					A PERSONAL FINANCIAL ADVISOR. XMART, WHETHER THROUGH THE WEBSITE OR
					OTHERWISE, DOES NOT PROVIDE PERSONAL FINANCIAL ADVICE, LOANS OR
					CREDIT, BANKING, CONSUMER CREDIT RATINGS, CREDIT DECISIONS, FINANCIAL
					PRODUCTS, BROKERAGE ACCOUNTS, INSURANCE, TAX ADVICE, LEGAL ADVICE, OR
					FINANCIAL OR LEGAL SERVICES OF ANY KIND. EVEN IF FEATURED ON THE
					WEBSITE, UNLESS EXPRESSLY STATED OTHERWISE, WE DO NOT PROVIDE
					ENDORSEMENT TO OR FOR ANY COMPANY SEEKING CAPITAL OR INVESTMENT
					OPPORTUNITY.
				</strong>
			</p>

			<p>
				<strong>35. DISCLAIMERS; NO WARRANTIES</strong>
			</p>

			<p>
				<strong>36. </strong>EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN
				WRITING BY XMART, THE SERVICES AND THE INFORMATION CONTAINED ON SERVICES
				ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES
				OF ANY KIND, EITHER EXPRESS OR IMPLIED. XMART DISCLAIMS ALL OTHER
				WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED
				WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE
				AND NON-INFRINGEMENT AS TO THE SERVICES AND INFORMATION CONTAINED
				THEREIN. XMART MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO
				ANY THIRD-PARTY PRODUCTS, AND EXPRESSLY DISCLAIMS ANY WARRANTY OR
				CONDITION OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A
				PARTICULAR PURPOSE.
			</p>

			<p>
				<strong>37. LIMITATION OF LIABILITY</strong>
			</p>

			<p>
				<strong>38. </strong>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
				IN NO EVENT SHALL XMART OR ANY OF ITS OWNERS, EMPLOYEES, OFFICERS,
				AGENTS, AFFILIATES, AND SUBSIDIARIES (“RELEASEES”) BE LIABLE FOR ANY
				DAMAGES OR LOSSES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE
				SERVICES. NEITHER XMART NOR ANY OTHER PARTY INVOLVED IN CREATING,
				PRODUCING, OR DELIVERING SERVICES WILL BE LIABLE FOR ANY INCIDENTAL,
				SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS,
				LOSS OF DATA, OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE
				OR SYSTEM FAILURE, OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, FROM
				THE USE OF OR INABILITY TO USE THE SERVICES WHETHER BASED ON WARRANTY,
				CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT PRODUCT LIABILITY, OR ANY
				OTHER LEGAL OR EQUITABLE THEORY (EVEN IF THE PARTY HAS BEEN ADVISED OF
				THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER SUCH DAMAGES
				WERE FORESEEABLE).
			</p>

			<p>
				<strong>39. </strong>In no event will xMart’s, its subsidiaries or
				insurers aggregate liability arising out of or in connection with this
				Agreement or your use of the Services, exceed the greater of (i) the
				amounts you have paid for the Services, if applicable, in the twelve
				(12) month period prior to the event giving rise to the liability, or
				(ii) UK £100. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
				ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN XMART AND YOU.{" "}
			</p>

			<p>
				<strong>40. INFORMAL DISPUTE RESOLUTION</strong>
			</p>

			<p>
				<strong>41. </strong>You and xMart agree that any dispute that has
				arisen or may arise between Us relating in any way to Your use of or
				access to the Services, any validity, interpretation, breach,
				enforcement, or termination of this Agreement, or otherwise relating to
				xMart in any way (collectively,{" "}
				<strong>"Covered Dispute Matters"</strong>) will be resolved in
				accordance with the provisions set forth in this Section. If You have
				any dispute with Us, you and xMart agree that before taking any formal
				action, contact us at <a href="mailto:info@xmart.art">info@xmart.art</a>{" "}
				and provide a brief, written description of the dispute and your contact
				information (including your email address) and allow sixty (60) days to
				pass, during which We will attempt to reach an amicable resolution of
				any issue with you.
			</p>

			<p>
				<strong>42. MANDATORY ARBITRATION</strong>
			</p>

			<p>
				<strong>
					43. BY AGREEING TO THE TERMS, YOU AGREE THAT YOU ARE REQUIRED TO
					RESOLVE ANY CLAIM THAT YOU MAY HAVE AGAINST XMART ON AN INDIVIDUAL
					BASIS IN ARBITRATION WITH XMART DIRECTLY. THIS WILL PRECLUDE YOU FROM
					BRINGING ANY CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION AGAINST
					XMART, AND ALSO PRECLUDE YOU FROM PARTICIPATING IN OR RECOVERING
					RELIEF UNDER ANY CURRENT OR FUTURE CLASS, COLLECTIVE, CONSOLIDATED, OR
					REPRESENTATIVE ACTION BROUGHT AGAINST XMART BY SOMEONE ELSE.
				</strong>
			</p>

			<p>
				<strong>44. CLASS ACTION WAIVER</strong>
			</p>

			<p>
				<strong>45. </strong>Any arbitration or action under these Terms will
				take place on an individual basis; class arbitrations and
				class/representative/collective actions are not permitted. THE PARTIES
				AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN THEIR
				INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
				PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN
				THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER.
				Further, the arbitrator or judge may not consolidate more than one
				person's claims and may not otherwise preside over any form of a
				representative or class proceeding.
			</p>

			<p>
				<strong>46. ENTIRE AGREEMENT</strong>
			</p>

			<p>
				<strong>47. </strong>Unless otherwise specified herein, this agreement
				constitutes the entire agreement between you and us with respect to the
				Services and it supersedes all prior or contemporaneous communications
				and proposals, whether electronic, oral or written, between you and us.
			</p>

			<p>
				<strong>48. RELIANCE ON INFORMATION POSTED</strong>
			</p>

			<p>
				<strong>49. </strong>Commentary and other materials posted on the
				Services is not intended to amount to advice on which reliance should be
				placed. We therefore disclaim all liability and responsibility arising
				from any reliance placed on such materials by any visitor of the
				Services, or by anyone who may be informed of any of its contents.
			</p>

			<p>
				<strong>
					50. No information or content on the Services is a replacement for
					performing your own due diligence, exercising good judgment, and
					seeking financial, investment, tax or legal advice from qualified and
					licensed professionals with knowledge of your personal circumstances.
				</strong>
			</p>

			<p>
				<strong>51. CHANGES TO TERMS</strong>
			</p>

			<p>
				<strong>52. </strong>We reserve the right, in our sole discretion, to
				change the Terms under which the Services is offered. The most current
				version of the Terms will supersede all previous versions. We encourage
				you to periodically review the Terms to stay informed of our updates. We
				may alter or amend our Terms by giving you reasonable notice. By
				continuing to use the Services after expiry of the notice period or
				accepting the amended Terms (as We may decide at our sole discretion),
				you will be deemed to have accepted any amendment to these Terms.
			</p>

			<p>
				<strong>53. RELATIONSHIP BETWEEN THE PARTIES</strong>
			</p>

			<p>
				<strong>54. </strong>The parties are independent contractors and nothing
				in these Terms shall be construed as making either party the partner,
				joint venturer, agent, legal representative, employer, contractor, or
				employee of the other. Each Party has sole responsibility for its
				activities and its personnel and shall have no authority and shall not
				represent to any third party that it has the authority to bind or
				otherwise obligate the other party in any manner.
			</p>

			<p>
				<strong>55. SEVERABILITY</strong>
			</p>

			<p>
				<strong>56. </strong>If any term, clause or provision of these Terms is
				held invalid or unenforceable, then that term, clause or provision will
				be severable from these Terms and will not affect the validity or
				enforceability of any remaining part of that term, clause or provision,
				or any other term, clause or provision of these Terms.
			</p>

			<p>
				<strong>57. FORCE MAJEURE</strong>
			</p>

			<p>
				<strong>58. </strong>We shall be excused from performance under these
				Terms of Use, to the extent We are prevented or delayed from performing,
				in whole or in part, as a result of an event or series of events caused
				by or resulting from: (a) weather conditions or other elements of nature
				or acts of God; (b) acts of war, acts of terrorism, insurrection, riots,
				civil disorders, or rebellion; (c) quarantines or embargoes; (d) labour
				strikes; (e) error or disruption to major computer hardware or networks
				or software failures; or (g) other causes beyond the reasonable control
				of xMart
			</p>

			<p>
				<strong>59. EXPORT CONTROLS</strong>
			</p>

			<p>
				<strong>60. </strong>The Services may not be exported or re-exported by
				you to certain countries, or those persons or entities prohibited from
				receiving exports from the United States. In addition, the Services may
				be subject to the import and export laws of other countries. You agree
				to comply with all United Kingdom and foreign laws related to use of the
				Services.
			</p>

			<p>
				<strong>61. MISCELLANEOUS</strong>
			</p>

			<p>
				<strong>62. </strong>These Terms constitute the entire agreement between
				you and Us relating to your access to and use of the Services. When you
				purchase any services from xMart, The Services are controlled and
				operated from within the United Kingdom. Without limiting anything else,
				We make no representation that the Services, information or other
				materials available on, in, or through the Services are applicable or
				available for use in other locations, and access to them from
				territories where they are illegal is prohibited. Those who choose to
				access our Services from other locations do so on their own volition and
				are responsible for compliance with applicable laws. These Terms, and
				any rights and licenses granted hereunder, may not be transferred or
				assigned by you without our prior written consent. The waiver or failure
				of xMart to exercise in any respect any right provided hereunder shall
				not be deemed a waiver of such right in the future or a waiver of any of
				other rights established under these Terms. Headings used in these Terms
				are for reference only and shall not affect the interpretation of these
				Terms. No person or entity not party to this agreement will be deemed to
				be a third-party beneficiary of these Terms or any provision hereof.
				When used herein, the words “includes” and “including” and their
				syntactical variations shall be deemed followed by the words “without
				limitation.”
			</p>

			<p>
				<strong>CONTACT US</strong>
			</p>

			<p>We welcome your questions or comments regarding these Terms:</p>

			<p>
				Please email: <a href="mailto:info@xmart.art">info@xmart.art</a>{" "}
			</p>
		</div>
	);
};

export default PrivacyTerms;
