import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ContactSection from '../../components/Pages/Contact';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';

class Contact extends Component {
    render() {
        return (
            <div className="main">
                <Header				config={ this.props.config } state={this.state} />
                <Breadcrumb			config={ this.props.config } state={this.state} title="Contact Us" subpage="Community" page="Contact Us" />
                <ContactSection		config={ this.props.config } />
                <Footer				config={ this.props.config } />
                <ModalSearch		config={ this.props.config } />
                <ModalMenu			config={ this.props.config } />
                <Scrollup			config={ this.props.config } />
            </div>
        );
    }
}

export default Contact;