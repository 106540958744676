import React, { Component } from 'react';
import axios from 'axios';
import { addXummPayload } from '../../js/xumm';
import { CurrencyPrice } from '../currencyPrice';

import '../../css/CollectionSpinnerType2.css'

const initData = {
    heading: "What are you looking for?",
    content: "Find your favourite collections and NFTs using our search tool. A fuzzy search will be carried out - For example, if you serached for 'xdu', your search would bring back results for collections and NFTs whose name or decription contain XDU - For example, xDudes",
    btnText: "Approve in Wallet"
}

var end = 0

class ModalSpinResult extends Component
{

	congratulate = e =>
	{
		try
		{
			var ffObj = document.getElementById("spinner-fanfare");
			ffObj.play()
		} catch(e) {}

		this.confettiCannonStop()

		/* Start after 1/2 seconds */
		window.setTimeout( () => {
			this.confettiCannon();
		 }, 500 )



	}

	confettiCannonStop()
	{
		end = 0;
	}

	confettiCannon() {

		end = Date.now() + (2.5 * 1000);

		// go Buckeyes!
		var colors = ['#e423b4', '#57048A', "#000000"];


		(function frame() {

			window.confetti({
				particleCount: 5,
				angle: 60,
				spread: 55,
				origin: { x: 0 },
				zIndex:50000,
				colors: colors
			});

			window.confetti({
				particleCount: 5,
				angle: 120,
				spread: 55,
				origin: { x: 1 },
				zIndex:50001,
				colors: colors
			});

			if (Date.now() < end) {
				requestAnimationFrame(frame);
			}
		}());
	}

	closeWindow = e =>
	{

		this.confettiCannonStop()

		if(this.props.state.NFTFromShuffle)
		{
			/* Reload as spinner doesn't work after shuffle */
			//document.location.reload()
			this.props.reloadSpinner()
		}
		else
		{
			this.props.reloadSpinner()

			//	window.setTimeout( () => { window.$('#spinner-init').trigger("click"); }, 850 )
		}

		/* Reload collection data after DB has done it's stuff */
		window.setTimeout( () => { window.$('#reload-collection').trigger("click"); }, 1200 )



		window.$('#modal-spinresult-close').trigger("click");
		this.props.callbackFromParent({shufflePendingPayment:false, shuffleAllowed:false, NFTFromShuffle:false, })

	}


    render()
	{

		//console.log(this.props)

        return (
				<div id="spin-result-modal" className="modal fade p-0">
               		<div className="modal-dialog modal-lg dialog-animatedx modal-fullheight modal-fullscreen-lg-down">
                    	<div className="modal-content h-100">

							<div className="modal-header modal-header--sticky">
								Spin Result
								<button className='no-style' onClick={this.closeWindow}><i className="fas fa-times-circle icon-close" /></button>
								<button id="modal-spinresult-close" className="hid" data-bs-dismiss="modal" />
							</div>

							<div className="show-grid modal-body p-3 pb-4">
								<div className="container row g-0">

									<div className='col-12'>
										<h3 className="text-center my-3">Congratulations, you have won {this.props.state.awardedNFT.title}</h3>
									</div>

									{/* Image */}
									{/* Image */}
									{/* Image */}
									<div className='col-12 text-center pt-3 px-4'>
										<img src={this.props.state.awardedNFT.img.indexOf("http") > -1 ? this.props.state.awardedNFT.img : this.props.config.apiServer + this.props.state.awardedNFT.img} />
									</div>


									<div className="col-12 p-3 mt-1  d-none">
										<p className="alert alert-info text-center">
										Spun items can be found by going to <a href={this.props.config.userData.url + '#' + this.props.state.collectionID.split(":")[0]} target="_blank">"Account\My NFTs"</a>.
										</p>
									</div>


								</div>
							</div>

							<div className="modal-footer">

								{ this.props.state.shuffleCollection && this.props.state.NFTFromShuffle === false ?
								( <>

									<p className="text-center my-3">Not happy with your random allocation? Then why not re-shuffle at a reduced cost?</p>
									<div className='row'>
										<div className='col-12 col-md-6 mb-2'>
											<button type="button" className="btn w-100 btn-sm" onClick={this.props.extendShuffleXummTimer} data-type="payForShuffle" data-offers={'n/a'} data-callbackbutton="reloadSpinner" data-buysell="buy" data-nftokenids={this.props.state.awardedNFT.id} disabled={false} >Shuffle for {this.props.state.tokensPerRespin} XRP &bull; {this.props.state.timeTilAutoMintTime}</button>
										</div>
										<div className='col-12 col-md-6 mb-2'>
											<button type="button" className="btn w-100 btn-sm btn-bordered" onClick={this.props.doNoShuffle} data-bs-dismiss="modal">Keep it</button>
										</div>
									</div>

								</>) : (
									<>
										<button type="button" className="btn w-100" onClick={this.closeWindow}>Close Window &amp; Spin Again</button>
									</>)}


								<button type="button" onClick={this.congratulate} id="congratulate" className="btn hid w-100">Congrats</button>
								<button type="button" onClick={this.shuffleStopped} id='shuffleStopped' className="btn hid w-100">shuffleStopped</button>

								<div className="hid">
									<audio id="spinner-fanfare" controls src="/assets/mp3/fanfare.mp3">
									</audio>
								</div>

							</div>

						</div>
					</div>
				</div>
        );
    }
}

export default ModalSpinResult;

