import React, { Component } from 'react';

class CollectionSpinnerLoadingMessageComponent extends Component {


    render() {


		return (

			<>
				{ this.props.state.timersDone > this.props.state.maxReloads ?
					(
						<p className="text-center"><em><strong>Automatic page updates have expired. Reload the page to restart them..</strong></em></p>
					)
					:
					(
						<p className="text-center">This page will check for updates in <span id="reload-timer">{this.props.config.importRefreshInterval / 1000}</span> seconds so there is no need to reload the page.</p>
					)
				}


			</>

		);
    }
}

export default CollectionSpinnerLoadingMessageComponent;