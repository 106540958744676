import React, { Component } from 'react';
import { CurrencyPrice } from '../currencyPrice';
import { networkLookup } from '../xutils';
import { displayErrorMessage, shortDate, formatDate } from '../xutils';
import { addXummPayload } from '../../js/xumm';
import axios from 'axios';

import {
	EmailShareButton,
	FacebookShareButton,
	HatenaShareButton,
	InstapaperShareButton,
	LineShareButton,
	LinkedinShareButton,
	LivejournalShareButton,
	MailruShareButton,
	OKShareButton,
	PinterestShareButton,
	PocketShareButton,
	RedditShareButton,
	TelegramShareButton,
	TumblrShareButton,
	TwitterShareButton,
	ViberShareButton,
	VKShareButton,
	WhatsappShareButton,
	WorkplaceShareButton
  } from "react-share";


class ModalTransferNFTTabcontent extends Component
{



	offerAcceptCheckProxyClick = e =>
	{
		const _offerID = e.currentTarget.dataset.offer
		const _obj = document.getElementById("showModalOfferButton")
		_obj.dataset.offer = _offerID
		_obj.click();
	}


	offerSellCancelCheckProxyClick = e =>
	{
		const _offerID = e.currentTarget.dataset.offer
		const _offerIDNetwork = e.currentTarget.dataset.offernetwork
		const _obj = document.getElementById("showModalCancelSellOfferButton")
		_obj.dataset.offer = _offerID
		_obj.dataset.offernetwork = _offerIDNetwork
		_obj.click();
	}


	refreshMedia = e =>
	{

		const BASE_URL = this.props.config.apiServer + "/com/external/medias.cfc?method=refreshMedia&uuid=" + this.props.state.nft.id;
		axios.get(BASE_URL, { withCredentials: true })
			.then(res => {

				if (res.data.success) {

					alert("Media set to update via CRON")

				}
				else {
					throw({message:res.data.errorMessage});
				}

			})
			.catch(err => {

				window.displayErrorMessage("Error getting media URL - " + err.message);
				return false;

			})

	}

    render()
	{

		var _network = networkLookup(this.props.state.nft.network)
		var _shareTitle = this.props.state.nft.title + " - At the xMart NFT marketplace";

		return (

			<>


				{this.props.state.modalVisible === false || 0 === 0 ?
				(
					<div className={this.props.state.modalVisible === true ? "blurred" : ""}>


							{Object.keys(this.props.state.nft.attributeData).length ?
							(

								<div id="nft-traits" className="row g-0 mt-3">

								{/* Single Tab List */}

									{this.props.state.nft.attributeData.map((item, idx) => {
										return (
													<>
														{  item.value !== 'N/A' ?
														(
															<div className="col-6 col-md-4 p-2">
																<div className="trait p-2">
																<div className="ellipsis" title={ item.attribute_name }>{ item.attribute_name }</div>
																<div className="ellipsis" title={ item.value }>{ item.value }</div>
																{ item.percentWith > 0 ?
																(
																	<div>{ item.percentWith > 0 ? Number(item.percentWith).toFixed(2) + "%" : "-"}</div>
																)
																:
																(<></>)}

																</div>
															</div>
														) : ( <></> )
														}
													</>
										);
									})}

									{/* <p className="text-center mt-4" style={{fontSize:"80%"}}>The percentage value shown indicates how many other NFTs in this collection share the same trait.</p> */}
								</div>


							)
							:
							(
								<></>
							)
							}


						<div className="mt-3">

							<nav className="siteTabs">
								<div className="nav nav-tabs justify-content-center mt-4" id="nav-tab" role="tablist">
									<button className="nav-link active" id="nav-nftinfo-tab" data-bs-toggle="tab" data-bs-target="#nav-nftinfo" type="button" role="tab" aria-controls="nav-nftinfo" aria-selected="false">Info</button>
									<button className="nav-link" id="nav-history-tab" data-bs-toggle="tab" data-bs-target="#nav-history" type="button" role="tab" aria-controls="nav-history" aria-selected="true">History</button>
									<button className="nav-link" id="nav-offers-tab" data-bs-toggle="tab" data-bs-target="#nav-offers" type="button" role="tab" aria-controls="nav-offers" aria-selected="false">Offers</button>
									<button className="nav-link" id="nav-token-tab" data-bs-toggle="tab" data-bs-target="#nav-token" type="button" role="tab" aria-controls="nav-token" aria-selected="false">Token</button>
								</div>
							</nav>

							<div className="tab-content container pt-3" id="nav-tabContent">

								<div className="tab-pane fade show active" id="nav-nftinfo" role="tabpanel" aria-labelledby="nav-nftinfo-tab">

									{/* <h3 className="m-0 mt-3">{this.props.state.nft.title}</h3> */}

									{/* Item Info List */}
									<div className="row">
										<div className="col-12 col-md-6">
											<div className="item-info-list mt-4">
												<ul className="list-unstyled list-fixed-width-label mb-4">

													{/* Supply */}
													<li key="top-supply" className='hid'>
														<div className="row w-100 g-0">
															<div className="col-6 col-md-6">
																<strong className="me-3 mb-3">Total Supply</strong>
															</div>
															<div className="col-6 col-md-6">
																<em>{this.props.state.nft.supply}</em>
															</div>
														</div>
													</li>

													{/* Network */}
													{ this.props.config.fees.networks.length > 1 ?
													(<li key="top-network">
														<div className="row w-100 g-0">
															<div className="col-6 col-md-6">
																<strong className="me-3 mb-3">Network</strong>
															</div>
															<div className="col-6 col-md-6">
																<em>{_network.network}</em>
															</div>
														</div>
													</li>)
													:
													(<></>)
													}



													{/* Price */}
													<li key="top-price">
														<div className="row w-100 g-0">
															<div className="col-6 col-md-6">
																<strong className="me-3 mb-3">Mint Date</strong>
															</div>
															<div className="col-6 col-md-6">
																<strong>{shortDate(this.props.state.nft.mintInfo.when)}</strong>
															</div>
														</div>
													</li>

													{/* Price */}
													<li key="top-price">
														<div className="row w-100 g-0">
															<div className="col-6 col-md-6">
																<strong className="me-3 mb-3">Asking Price</strong>
															</div>
															<div className="col-6 col-md-6">
															{
																this.props.state.nft.flagForSale ?
																	(	<strong>

																			<em><CurrencyPrice price={this.props.state.nft.askingPrice} config={this.props.config} /></em>
																		</strong>
																	)
																	:
																	( <em>Not for sale</em> )
															}
															</div>
														</div>
													</li>

													{/* Royalty */}
													<li key="top-royalty">
														<div className="row w-100 g-0">
															<div className="col-6 col-md-6">
																<strong className="me-3 mb-3">Creator Royalty</strong>
															</div>
															<div className="col-6 col-md-6">
															{
																this.props.state.nft.royalty ?
																	(	<em>{this.props.state.nft.royalty}%</em> )
																	:
																	( <em>Royalty Free</em> )
															}
															</div>
														</div>

													</li>

													{/* Collection */}
													{ this.props.state.nft.link.length ?
													(
														<li key="top-deeplink" className="owner d-flex align-items-center">
															<div className="row w-100 g-0">
																<div className="col-6 col-md-6">
																	<strong className="me-3 mb-3">External Link</strong>
																</div>
																<div className="col-6 col-md-6">
																	<em><a className="me-3 mb-2" target="_blank" href={this.props.state.nft.link} rel="noreferrer"><i className="fas fa-link"></i> Open link</a></em>
																</div>
															</div>
														</li>
													) : (<></>)}


													{/* Rarity rank */}
													{ Number(this.props.state.nft.rank) ?
													(
														<li key="top-rank" className="owner d-flex align-items-center">
															<div className="row w-100 g-0">
																<div className="col-6 col-md-6">
																	<strong className="me-3 mb-3">Rank</strong>
																</div>
																<div className="col-6 col-md-6">
																	<em>#{this.props.state.nft.rank}</em>
																</div>
															</div>
														</li>
													) : (<></>)}


												</ul>
											</div>
										</div>
										<div className="col-12 col-md-6">
											<div className="row w-100 g-0">
												<div className="col-12 col-md-4 pt-3 mb-3">
													<strong>Created By</strong>
												</div>
												<div className="col-12 col-md-8 ellipsis">
													<a className="owner-meta d-flex align-items-center" href={this.props.state.nft.creator.url}>
														<img className="avatar-sm rounded-circle" src={this.props.state.nft.creator.profileImageM.length ? this.props.config.apiServer + this.props.state.nft.creator.profileImageM : this.props.config.sizes.placeholders.profilePicture.medium} alt={this.props.state.nft.creator.title} />
														<h6 className="ms-2">{this.props.state.nft.creator.title}</h6>
													</a>
												</div>
											</div>

											{/* Collection */}
											{ this.props.state.nft.flagInCollection ?
											(
												<div className="row w-100 g-0">
													<div className="col-12 col-md-4 pt-3 mb-3">
														<strong>Collection</strong>
													</div>
													<div className="col-12 col-md-8 ellipsis">
														<a className="owner-meta d-flex align-items-center" href={this.props.state.nft.collection.url}>
															<img className="avatar-sm rounded-circle" src={this.props.state.nft.collection.preview.length ? this.props.config.apiServer + this.props.state.nft.collection.preview : this.props.config.sizes.placeholders.profilePicture.medium} alt={this.props.state.nft.collection.name} />
															<h6 className="ms-2">{this.props.state.nft.collection.name}</h6>
														</a>
													</div>
												</div>
											) : (<></>)}


											<div className="row w-100 g-0">
												<div className="col-12 col-md-4 pt-3 mb-3">
													<strong>Owned By</strong>
												</div>
												<div className="col-12 col-md-8 ellipsis">
													{ this.props.state.nft.owners.length === 1 ?
													(
													<a className="owner-meta d-flex align-items-center" href={this.props.state.nft.owners[0].url}>
														<img className="avatar-sm rounded-circle" src={this.props.state.nft.owners[0].img.length ? this.props.config.apiServer + this.props.state.nft.owners[0].img : this.props.config.sizes.placeholders.profilePicture.medium } alt={this.props.state.nft.owners[0].title} title={this.props.state.nft.owners[0].address} />
														<h6 className="ms-2">{this.props.state.nft.owners[0].title ? this.props.state.nft.owners[0].title : this.props.state.nft.owners[0].address}</h6>
													</a>)
													: ( <p className="pt-3">Nobody</p>)}
												</div>
											</div>

										</div>




										<div className="d-flex justify-content-around my-3">
											<FacebookShareButton url={this.props.state.nft.URI} title={_shareTitle}>
												<i className="fab fa-facebook-f fa-fw" />
											</FacebookShareButton>

											<LinkedinShareButton url={this.props.state.nft.URI} title={_shareTitle}>
												<i className="fab fa-linkedin-in fa-fw" />
											</LinkedinShareButton>

											<TumblrShareButton url={this.props.state.nft.URI} title={_shareTitle}>
												<i className="fab fa-tumblr fa-fw" />
											</TumblrShareButton>


											<RedditShareButton url={this.props.state.nft.URI} title={_shareTitle}>
												<i className="fab fa-reddit fa-fw" />
											</RedditShareButton>


											<TwitterShareButton url={this.props.state.nft.URI} title={_shareTitle}>
												<i className="fab fa-twitter fa-fw" />
											</TwitterShareButton>

											<EmailShareButton url={this.props.state.nft.URI} title={_shareTitle}>
												<i className="far fa-envelope fa-fw" />
											</EmailShareButton>
										</div>

									</div>

									<div style={{"fontSize":"120%"}} dangerouslySetInnerHTML={{__html: this.props.state.nft.description.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />



								</div>

								<div className="tab-pane fade" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab">

									<ul className="list-unstyled mt-3">

										<li key={`hist_header`} className="single-tab-list row pb-2 m-0">
											<div className="col-6 col-md-2 font-weight-bold primary text-center"><strong>Date/Time</strong></div>
											<div className="col-6 col-md-4 font-weight-bold primary text-center"><strong>Description</strong></div>
											<div className="col-6 col-md-4 font-weight-bold primary text-center"><strong>By Who</strong></div>
											<div className="col-6 col-md-2 font-weight-bold primary text-center"><strong>Value</strong></div>
										</li>

										{/* Single Tab List */}
										{this.props.state.nft.history.map((item, idx) => {
											const tVal = Number(item.val) > 0 ? Number(item.val).toLocaleString() + " " +item.cc : 0;
											return (
												<li key={`history_${idx}`} className="single-tab-list row m-0 pb-2">
													<div className="col-6 col-md-2 text-center">{shortDate(item.ts)}</div>
													<div className="col-6 col-md-4 text-start">{item.tn}</div>
													<div className="col-6 col-md-4 text-start ellipsis"><img className="avatar-sm rounded-circle" src={item.pro.length ? this.props.config.apiServer + item.pro : this.props.config.sizes.placeholders.profilePicture.small} alt={item.who} /> {item.who}</div>
													<div className="col-6 col-md-2 text-end col-last">{Number(item.val) > 0 ? tVal  : '--'}</div>

												</li>
											);
										})}
									</ul>

									<p className="text-center mt-4" style={{fontSize:"80%"}}>The history shown above is based on activities on xMart and does not necessarily reflect all activities from other marketplaces.</p>
								</div>

								<div className="tab-pane fade" id="nav-offers" role="tabpanel" aria-labelledby="nav-offers-tab">

									<div id="buy-offers">
										<h4 className="bigdots pb-2">Sell Offers { this.props.state.nft.offers.sellAll.length ? ( <span className="badge">{this.props.state.nft.offers.sellAll.length}</span> ) : ( <></> ) }</h4>

										{ this.props.state.nft.mintedQty === 0 ? (
											<p className="p-5 text-center">Sell offers are only available once the NFT has been minted. </p>
										) : (
											<>

												{ this.props.state.nft.offers.sellAll.length === 0 ? (
												<p className="p-5 text-center">There are no sell offers at the moment.</p>
												) :
												(
													<div className="list-unstyled">
														{/* Single Tab List */}
														{this.props.state.nft.offers.sellAll.map((item, idx) => {

															return (

																<li key={`selloffer_${idx}`} className={ "m-0 single-tab-list border-bottom py-3" }>

																	<div className="row g-0">
																		<div className='col-10 col-md-10 d-flex pt-2 pb-2'>
																			<img className="avatar-sm rounded-circle me-3" src={this.props.config.apiServer + item.user.img} alt="" />
																			<div>
																				Sell offer { item.amount > 0 ? (<span> for <strong><CurrencyPrice price={item.amount} config={this.props.config} /></strong></span>) : (<></> )} was created on {formatDate(item.created, false)}
																				<br />
																				The offer was made {item.offerVia.length ? ( <>via {item.offerVia}</> ) : ( <> by <strong className="primary">{ this.props.state.nft.flagOwner ? ( <>You</> ) : ( <>{item.offerOwner}</> ) } </strong> </>) }

																				{ item.reserved === false && item.destination.length && item.offerVia.length === 0 ?
																				(
																					<>{" "}and can only be accepted by <strong>{item.destination}</strong></>
																				)
																				:
																				( <></> )
																				}

																				{ item.reserved ?
																				(
																					<>{" "}and is reserved for <strong>{item.reservedFor}</strong></>
																				)
																				:
																				(
																					<>
																						{ this.props.state.nft.flagOwner ?
																							(
																								<>
																									{ item.offerVia.toUpperCase() === 'ONXRP' ?
																									(<strong className="ms-3 external">This offer has a destination tag set against it. This means only the creator of the offer can accept it.</strong>) : (<></>)
																									}

																									{ item.offerVia.toUpperCase() === 'XMART' ?
																										(<strong className="ms-3">Cancelling this sale will remove it from the xMart marketplace.</strong>) : (<></>)
																									}
																								</>
																							)
																							:
																							( <></> )
																						}
																					</> )
																				}
																			</div>
																		</div>

																		<div className='col-12 col-md-2 pt-2'>
																			{ this.props.state.nft.flagOwner ?
																			( <button className="btn btn-bordered btn-sm w-100 px-0" disabled={this.props.state.modalVisible} onClick={this.offerSellCancelCheckProxyClick} data-offer={item.offerID} data-offernetwork={item.networkOfferID}>Cancel</button> )
																			:
																			( <></> )
																			}
																		</div>

																	</div>

																</li>
															);
														})}
													</div>
												)}

											</>	)}
									</div>

									<div id="sell-offers">
									{ this.props.state.nft.mintedQty === 0 ? (
												<p className="p-5 text-center">Buy offers are only available once the NFT has been minted. </p>
											) : (
												<>

													{ this.props.state.nft.offers.buy.length === 0 ? (
													<p className="p-5 text-center">There are no offers to buy this NFT.</p>
													) : (
															<div className="list-unstyled mt-3">
																{/* Single Tab List */}
																{this.props.state.nft.offers.buy.map((item, idx) => {

																	var _priceMessage = "We have no collection floor or asking price so cannot determine the offer percentage.";
																	var _priceClass = ''
																	if(this.props.state.nft.askingPrice > 0)
																	{
																		var difference = this.props.state.nft.askingPrice - item.amount;
																		var _pct = item.amount / this.props.state.nft.askingPrice * 100
																		if(difference === 0)
																		{
																			_priceMessage = "The offer matches your asking price";
																			_priceClass = "matching"

																		}
																		else if(difference < 0)
																		{
																			_priceMessage = `The offer of ${item.amount.toFixed(5)} XRP is ${Math.abs(difference).toFixed(5)} XRP more than your asking price`;
																			_priceClass = "higher"

																		}
																		else if(difference > 0)
																		{
																			_priceMessage = `The offer of ${item.amount.toFixed(5)} XRP is ${difference.toFixed(5)} XRP LOWER than your asking price`;
																			_priceClass = "lower"
																		}

																		if( item.external === true )
																		{
																			_priceMessage = `The offer was created on an external marketplace and only they are able to accept the offer on your behalf.`;
																			_priceClass = "external"
																		}


																	}
																	else if(this.props.state.nft.collection.floor > 0)
																	{
																		var difference = this.props.state.nft.collection.floor - item.amount;
																		var _pct = item.amount / this.props.state.nft.collection.floor * 100
																		if(difference === 0)
																		{
																			_priceMessage = "The offer matches the floor price of the collection";
																			_priceClass = "matching"

																		}
																		else if(difference < 0)
																		{
																			_priceMessage = `The offer of ${item.amount.toFixed(5)} XRP is ${Math.abs(difference).toFixed(5)} XRP more than the floor price of the collection`;
																			_priceClass = "higher"

																		}
																		else if(difference > 0)
																		{
																			_priceMessage = `The offer of ${item.amount.toFixed(5)} XRP is ${difference.toFixed(5)} XRP LOWER than the floor price of the collection`;
																			_priceClass = "lower"
																		}

																	}

																	return (
																		<div key={`offer_${idx}`} className={ "m-0 single-tab-list border-bottom pb-3" + ( item.declined ? " declined" : "" ) }>

																			<div className="row g-0" >
																				<div className='col-10 col-md-10 d-flex pt-2 pb-2'>

																					<img className="avatar-sm rounded-circle me-3" src={this.props.config.apiServer + item.user.img} alt="" />

																					<div>

																						Offer created for <strong><CurrencyPrice price={item.amount} config={this.props.config} /></strong>

																						{/* { ( item.user.id === this.props.config.userData.id && this.props.state.nft.offers.systemPending === false) || ( this.props.state.nft.flagOwner ) ? ( <>&nbsp;for <strong><CurrencyPrice price={item.amount} config={this.props.config} /></strong></> ) : ( <></> ) } */}

																						&nbsp;on {formatDate(item.created, false)}
																						<br />
																						by
																						{/* link or name */}
																						<span className="ms-1">
																							{ item.user.id === this.props.config.userData.id && this.props.state.nft.offers.systemPending === false ?
																								( <strong>You.</strong> )
																								:
																								( <a href={item.user.url}>{item.user.name}.</a> )
																							}
																						</span>


																						{ item.declined === true ?
																						( <span className={"ms-2 "}>The offer was declined.</span> )
																						:
																						(
																							<>
																								{ this.props.state.nft.flagOwner && this.props.state.nft.offers.systemPending === false ?
																								( <span className={"ms-2 " + _priceClass}>{_priceMessage}</span> )
																								:
																								(
																									<>

																									</> )
																								}

																							</> )
																						}
																					</div>

																				</div>
																				<div className='col-12 col-md-2 pt-2'>

																					{ this.props.state.nft.flagOwner && this.props.state.nft.offers.systemPending === false && item.declined === true ?
																					( <span className="badge bg-danger w-100  ms-4">Offer<br />has been<br />declined</span> )
																					:
																						( <></> )
																					}


																					{ this.props.state.nft.flagOwner && this.props.state.nft.offers.systemPending === false && item.external === true ?
																						( <span className="badge bg-danger w-100  ms-4">External<br />Offer</span> )
																						:
																						( <></> )
																					}





																					{ this.props.state.nft.flagOwner && this.props.state.nft.offers.systemPending === false  && item.declined === false && item.external === false ?
																						( <button className="btn py-2 btn-sm w-100 px-0" disabled={this.props.state.modalVisible} onClick={this.offerAcceptCheckProxyClick} data-offer={item.offerID}> Review</button> )
																						:
																						( <></> )
																					}

																					{ item.user.id === this.props.config.userData.id && this.props.state.nft.offers.systemPending === false ?
																						( <button className="btn btn-bordered btn-sm w-100 px-0 py-2" disabled={this.props.state.modalVisible} onClick={addXummPayload} data-type="NFTokenCancelOfferBulk" data-offers={item.networkOfferID} data-buysell="buy" data-nftokenids={this.props.state.nft.mintInfo.address}>Cancel</button> )
																						:
																						( <></> )
																					}
																				</div>
																			</div>

																		</div>
																	);
																})}
															</div>
													)}

												</>	)}
									</div>
								</div>

								<div className="tab-pane fade" id="nav-token" role="tabpanel" aria-labelledby="nav-token-tab">

									{ this.props.state.nft.mintedQty === 0 ?
									(
										<p className="p-5 text-center">This NFT hasn't yet been minted on the network.</p>
									)
									:
									(
									<ul className="list-unstyled mt-4">

										{/* Single Tab List */}
										<li key={`ledger_1`} className="single-tab-list row m-0 pb-2">
											<div className="col-12 col-md-3 fw-bold">Date Minted:</div>
											<div className="col-12 col-md-9">{formatDate(this.props.state.nft.mintInfo.when)}</div>
										</li>
										<li key={`ledger_2`} className="single-tab-list row m-0 pb-2">
											<div className="col-12 col-md-3 fw-bold">Token ID:</div>
											<div className="col-12 col-md-9 ellipsis">
												{ this.props.state.nft.mintInfo.address.length ?
													(
														<a href={this.props.config.links.explore + "/" + this.props.state.nft.mintInfo.address} target="_blank" rel="noreferrer"><i className="fas fa-link"></i> {this.props.state.nft.mintInfo.address}</a>
													) : ( <>Not yet minted</>)
													}
											</div>
										</li>
										<li key={`ledger_3`} className="single-tab-list row m-0 pb-2">
											<div className="col-12 col-md-3 fw-bold">Is NFT burnable:</div>
											<div className="col-12 col-md-9">{this.props.state.nft.mintInfo.burnable ? ( <><strong style={{"color":"#ff0000"}}>YES</strong> - WARNING: The token can be deleted at any time by the orginal issuer.</>) : ( <>No</>)}</div>
										</li>
										<li key={`ledger_4`} className="single-tab-list row m-0 pb-2">
											<div className="col-12 col-md-3 fw-bold">Creator Wallet:</div>
											<div className="col-12 col-md-9 ellipsis"><a href={this.props.config.links.explore + "/" + this.props.state.nft.mintInfo.creator} target="_blank" rel="noreferrer"><i className="fas fa-link"></i> {this.props.state.nft.mintInfo.creator}</a></div>
										</li>
										<li key={`ledger_5`} className="single-tab-list row m-0 pb-2">
											<div className="col-12 col-md-3 fw-bold">Owner Wallet:</div>
											<div className="col-12 col-md-9 ellipsis"><a href={this.props.config.links.explore + "/" + this.props.state.nft.mintInfo.owner} target="_blank" rel="noreferrer"><i className="fas fa-link"></i> {this.props.state.nft.mintInfo.owner}</a></div>
										</li>

										{ this.props.config.userData.display ? (
											<li key={`ledger_6`} className="single-tab-list row m-0 pb-2">
												<div className="col-12 col-md-3 fw-bold">Artwork:</div>
												<div className="col-12 col-md-9 ellipsis">
													<button onClick={this.refreshMedia} type="button" className="btn btn-sm">Refresh media</button>
												</div>
											</li>
										) : (<></>)}


										{ this.props.state.nft.flagOwner ?
										(
										<li key={`ledger_7`} className="single-tab-list row m-0 pb-2">
											<div className="col-12 col-md-3 fw-bold">Burn this token:</div>
											<div className="col-12 col-md-9">If you need to permanently remove this token from the ledger, <a href={'/'} target="_blank" onClick={this.props.burnToken} data-token={this.props.state.nft.mintInfo.address}>Click here.</a><br/><strong>NOTE: This action is PERMANENT</strong></div>
										</li>
										) : ( <></> )}

									</ul>



									)
									}
								</div>

							</div>

						</div>











					</div>
				) : (
						<div>
							{/* Modal tabs will display from "ModalViewNFTButtons" */}
						</div>
				)
				}

			</>

        );
    }
}

export default ModalTransferNFTTabcontent;

