import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';

import AuthorProfile from '../../components/AuthorProfile/AuthorProfile';
import Balances from '../../components/AuthorProfile/balances';



class pageContent extends Component {

	state = { profileData: this.props.config.userData }

    render() {

        return (
            <div className="main">
                <Header				config={ this.props.config } state={this.state} />
                <Breadcrumb			config={ this.props.config } state={this.state} title="Wallet Balances" background="null" subpage="Tools" page="Wallet balances" />

				<section className="author-area explore-area popular-collections-area">
					<div className="container p-0">

                    	<div className="row d-flex flex-row-reverse">
							<div className="col-12 col-md-9">
								<Balances		config={ this.props.config } />
							</div>
							<div className="col-12 col-md-3">
								{/* Author Profile */}
								<AuthorProfile	config={ this.props.config } state={this.state} />
							</div>
						</div>


					</div>
				</section>

                <Footer				config={ this.props.config }/>
                <ModalSearch		config={ this.props.config } />
                <ModalMenu			config={ this.props.config } />
                <Scrollup			config={ this.props.config } />
            </div>
        );
    }
}

export default pageContent;