import React, { Component } from 'react';
import { loginCheck, setupXmartDropzones, showWaitMessage, hideWaitMessage, displayErrorMessage, uuidv4 } from '../xutils';
import axios from 'axios';
import { VideoLink } from '../videoLink'

class ModalCRUDNFT extends Component
{
	resetPage = e =>
	{

	}

	handleChange = e => {
		this.props.callback({field:e.currentTarget.id, value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(e); }, 500)
	}

	removeProperty = e =>
	{
		e.preventDefault()
		var _currentAttributes = this.props.state.nft.attributeData;
		var dataPos = Number(e.currentTarget.dataset.position)-1;

		_currentAttributes.splice(dataPos, 1)

		this.props.callback({field:"attributeData", value:_currentAttributes})

	}

	changeProperty = e =>
	{
		var _currentAttributes = this.props.state.nft.attributeData;
		var dataPos;

		if( Number(e.currentTarget.dataset.position) === 0)
		{
			const tagNumber = Math.floor(Math.random() * 1000000) + 1000000;
			_currentAttributes.push( {"attribute_id":tagNumber, "attribute_name":"", "value":""} )

			dataPos = _currentAttributes.length-1;
			var dataType = e.currentTarget.dataset.type;

			window.setTimeout( (fieldType=dataType, pos = dataPos+1) => {
				var fld
				if( fieldType === 'name' )
				{
					fld = "tt_"+pos
				}
				else
				{
					fld = "tv_"+pos
				}
				document.getElementById(fld).focus(); }, 1)
		}
		else
		{
			dataPos = Number(e.currentTarget.dataset.position)-1
		}

		var element = _currentAttributes[ Number(dataPos) ]
		if(e.currentTarget.dataset.type === 'name' )
		{
			element.attribute_name = e.currentTarget.value;
		}
		else
		{
			element.value = e.currentTarget.value;
		}

		this.props.callback({field:"attributeData", value:_currentAttributes})

	}

	clearState = e =>
	{
		this.setState({selectedFile:null, errorMessage:"", uploading:false, uploadPercent:0, fileSize:0, fileName:""})
		window.$('#realcloser').trigger("click")
		//window.$('#theFile').val("")
	}

	validateForm = e => {

		var _invalidFields = [];

		if( this.props.state.nft.title.trim().length < 1 )
		{
			_invalidFields.push({"field":"title", "message":"NFT Title not provided"})
		}

		if( this.props.state.nft.collection.trim().length === 0 )
		{
			_invalidFields.push({"field":"collection", "message":"Choose a collection"})
		}

		if( this.props.state.nft.network.trim().length === 0 )
		{
			_invalidFields.push({"field":"network", "message":"Choose a minting newtwork"})
		}

		if( window.$('#nft-img').prop("src").indexOf('/place') > 0 )
		{
			_invalidFields.push({"field":"nft-img", "message":"Upload NFT artwork"})
		}

		if ( this.props.state.nft.link.trim().length && /((https|http):\/\/)(.*)*\/?/is.test( this.props.state.nft.link.trim() ) === false )
		{
			_invalidFields.push({"field":"link", "message":"Deep link URL is invalid - Must begin with HTTP://, HTTPS://"})
		}

		window.$('#nft-crud').find("input,select,textarea").removeClass("fieldInvalid");
		window.$.each(_invalidFields, (idx, fld) => {
			window.$('#'+fld.field).addClass("fieldInvalid");
		})

		this.props.callback({field:"saveDisabled", value:_invalidFields.length ? true : false})


	}

	handleSubmit = e => {

		e.preventDefault();
		showWaitMessage("Saving NFT");

		const BASE_URL = this.props.config.apiServer + "/com/post.cfc?method=crudNFT";
		var formData = new FormData( document.getElementById("nft-crud"));

		if(this.props.state.nft.hasAttributes === false)
		{
			formData.append("attributes_json", JSON.stringify(this.props.state.nft.attributeData) );
		}

		axios.post(BASE_URL, formData, { withCredentials: true })
			.then(res => {

				if( ! res.data.success)
				{
					hideWaitMessage()
					throw(res.data.errorMessage);
				}
				else
				{
					window.hideWaitMessage();

					if(this.props.state.nft.temp)
					{

						/* Close the modal */
						this.clearState();

						window.showWaitMessage("Loading your new NFT");

						document.location.href = res.data.redirect;

					}
					else
					{

						/* Try and click the reload button on cBrowse to reload changes */
						window.setTimeout( () => {

							/* If we're in cbrowse mode */
							try{
								var myObject = window.$('.nft-popper[data-nft="'+this.props.state.nft.id+'"]')
								if(myObject.length)
								{
									myObject.find("h5").html(this.props.state.nft.title);
								}
							} catch(e) {}

							/* If we're in plain view mode */
							try{
								var myObject = window.$('#viewing-nft')
								if(myObject.length)
								{
									document.getElementById('reloadNFT').click()
								}
							} catch(e) {}

						}, 500)

						/* Close the modal */
						this.clearState();

					}


				}
			})
		.catch(err =>
				{ window.hideWaitMessage(); displayErrorMessage("ERROR - " + err, { icon: "error" }); }
		)


	}


	handleChangeCollection = e => {

		window.changeValue = e.target.value;

		if(this.props.state.nft.collection.length === 0)
		{
			/* No collection set, allow without asking */
			if( window.changeValue === 'null')
			{
				//this.setState({ collection: e.target.value, network:this.props.config.fees.defaultNetwork })
				this.props.callback({field:"network", value:this.props.config.fees.defaultNetwork})
			}
			else
			{
				//this.setState({ collection: e.target.value, network:"collection" })
				this.props.callback({field:"network", value:"collection"})
			}

			this.props.callback({field:"collection", value:window.changeValue})

			/* Update attributes etc */

			window.setTimeout(() => { this.getCollectionInfo(window.changeEvent); }, 100)
			window.setTimeout(() => { this.validateForm(window.changeEvent); }, 500)
		}
		else
		{
			window.Swal.fire({
				title: 'Changing the collection will remove any metadata from this NFT.. Are you sure?',
				showDenyButton: true,
				showCancelButton: false,
				confirmButtonText: 'Yes',
				denyButtonText: 'No'
				}).then((result) => {

				if (result.isConfirmed) {

					if( window.changeValue === 'null')
					{
						//this.setState({ collection: e.target.value, network:this.props.config.fees.defaultNetwork })
						this.props.callback({field:"network", value:this.props.config.fees.defaultNetwork})
					}
					else
					{
						//this.setState({ collection: e.target.value, network:"collection" })
						this.props.callback({field:"network", value:"collection"})
					}

					this.props.callback({field:"collection", value:window.changeValue})

					/* Update attributes etc */

					window.setTimeout(() => { this.getCollectionInfo(window.changeEvent); }, 100)
					window.setTimeout(() => { this.validateForm(window.changeEvent); }, 500)

				} else if (result.isDenied) {
					// Do nothing
				}
				})
		}


	}

	getCollectionInfo = e =>
	{

		if( this.props.state.nft.collection.length > 10)
		{

			showWaitMessage("Getting collection metadata properties");

			var BASE_URL = this.props.config.apiServer + "/com/external/collections.cfc?method=getCollection&collection="+this.props.state.nft.collection;

			axios.get(`${BASE_URL}`, {withCredentials: true})
				.then(res => {
					if(!res.data.success)
					{
						throw(res.data.errorMessage)
					}
					else
					{

						var _currentAttributes = [];

						for (const [key, trait] of Object.entries(res.data.info.attributes)) {
							var tVal = ""

							this.props.state.nft.attributeData.forEach(function (item, index) {
								// console.log(item, index);
							});

							_currentAttributes.push( {"attribute_id":key, "attribute_name":trait.name, "value":""} )
						}

						this.props.callback({field:"attributeData", value:_currentAttributes})

						hideWaitMessage();

					}
				})
			.catch(err => {
					window.logger(err)
					hideWaitMessage();
				})
		}
		else
		{
			this.props.callback({field:"attributeData", value:[]})
		}

	}



    render()
	{
		const _collectionsLink = this.props.config.userData.url + "/";
		const _createLink = this.props.config.userData.url + "/collections/add";
		const _disabled = Number(this.props.state.nft.mintedQty) > 0 ? true : false;

        return (

			<div id="edit-nft-modal" className="modal fade p-0" data-keyboard="false" data-backdrop="static">
				<div className="modal-dialog modal-xl modal-fullheight modal-fullscreen-lg-down">
					<div className="modal-content h-100">

						<div className="modal-header modal-header--sticky">
							{this.props.state.nft.windowTitle}
							<span  onClick={this.clearState}>
								<i className="fas fa-times-circle icon-close" />
							</span>
							<button id="realcloser" className="hid" data-bs-dismiss="modal" >X</button>
						</div>
						<div className="show-grid modal-body p-3 pb-4">
							<div className="container row">

								{this.props.state.loading ?
								(
									<div className="text-center col-12 mt-5" id="loading">
										<h3>Loading NFT Data</h3>
										<img src={this.props.config.sizes.placeholders.preloader} alt="Loading.." />
									</div>
								) :
								(
									<form action="/nft/create/post/" method="post" id="nft-crud" className="item-form" onSubmit={this.handleSubmit}>

										<input type="hidden" id="uuid" name="uuid" value={this.props.state.nft.id} />
										<input type="hidden" id="temp" name="temp" value={this.props.state.nft.temp} />
										<button type="button" id="validate-form-button" className="hid" onClick={this.validateForm}>Check</button>
										<button type="button" id="get-collection-info" className="hid" onClick={this.getCollectionInfo}>CI</button>


										<div className="row justify-content-between">


											<div className="col-12 col-md-4 mb-4 nft-upload-preview item-form no-hover p-3 drop-zone" id='nft-dropzone'>

												{/* This is here to get called by xutils.setupXmartDropzones() after upload */}
												<button type="button" className="hid" onClick={this.validateForm} id="form-check">Check form</button>

												<img id="nft-img" src={this.props.state.nft.previewImage} key={this.props.state.nft.previewImage} data-key={this.props.state.nft.previewImage} alt="" />

												{ _disabled === false ?
												(
													<div id="nft-img-icon" title="Click to update your NFT">

														<div className="noInteraction">
															<span className="fa-stack">
																<i className="fas fa-cloud fa-stack-1x dropshadow"></i>
																<i className="fas fa-cloud-upload-alt fa-stack-1x"></i>
															</span>
														</div>

														<div className="text-center text-muted noInteraction">
															<p><strong>Tap the cloud <span className="d-none d-xl-block"> or drag an image onto it</span> to begin the media upload process for this NFT.</strong></p>
															<p><strong>(Maximum file size is 100mb)</strong></p>
														</div>

													</div>
												) : (<></>)
												}

											</div>


											<div className="col-12 col-md-8">

												{/* Item Form */}

													<div className="row">

														{ _disabled === true ?
															(
																<div className="alert alert-danger d-flex align-items-center" role="alert">
																	<i className="fas fa-exclamation-triangle ms-3 me-3" style={{"fontSize":"140%"}}></i>
																	<div style={{"fontSize":"130%"}}>
																		This NFT has already been minted and is now locked
																	</div>
																</div>
															)
														: (<></>)
														}

														<div className="col-12">
															<div className="form-group mt-3">
																<label htmlFor="title" className="pb-2 heading">NFT Name/Title <span className="req">*</span></label>
																<input type="text" className="form-control" name="title" id="title" placeholder="Descriptive title for your new NFT" required="required" disabled={_disabled}  value={this.props.state.nft.title}  onKeyUp={this.validateForm} onChange={this.handleChange} />
															</div>
														</div>


														<div className="col-12">
															<div className="form-group mt-3">
																<label htmlFor="collection" className="pb-2 heading">Collection <span className="req">*</span></label>
																<select id="collection" className="form-select" name="collection" required="required" disabled={_disabled || this.props.state.nft.hasAttributes === true ? true : false}  onChange={this.handleChangeCollection} value={this.props.state.nft.collection}>

																	{ this.props.config.userData.collections.length > 0  &&  this.props.state.nft.collection.length === 0 ? ( <option value="">-- Choose a collection --</option> ) : ( <></> ) }

																	<option value="null">Don't put this NFT into a collection</option>

																	{this.props.config.userData.collections.map((item, idx) => {
																		const _key = `coll_${idx}`;
																		const _network = this.props.config.fees.networks.length > 1 ? ` - Network: ${item.network}` : ``
																		return (
																			<option key={_key} value={item.id}>&bull; {item.name} {_network}</option>
																		);
																	})}

																</select>
															</div>

															{ this.props.state.nft.hasAttributes === false ?
															(

																<blockquote>
																Collections group together similar NFTs and dictate which category they appear in. To amend an existing collection, <a href={_collectionsLink} target="_blank" rel="noreferrer">Click Here</a> or to create a new collection <a href={_createLink} target="_blank" rel="noreferrer">Click Here</a>
																{ this.props.config.userData.spin === "Yes" ?
																(
																	<>
																		<br/><br/>
																		NOTE: Spinner collections will not appear in the list above.
																	</>
																)
																: (<></>)
																}

																</blockquote>
															)
															:
															(
																<blockquote>
																	This NFT was created from a bulk import. You are unable to change the collection.
																</blockquote>
															)}

															{/* Only display network dropdown on new collections */}
															{ ( this.props.state.collection === 'null' && this.props.config.fees.networks.length > 1 ) ?
																(
																	<div className="col-12 mb-3 ps-0">
																		<div className="form-group">
																			<label htmlFor="network" className="pb-2 heading">Network <span className="req">*</span></label>
																			<select id="network" className="form-select" name="network" required="required" disabled={_disabled}  onChange={this.handleChange} value={this.props.state.nft.network}>
																				<option value="collection">-- Choose a minting network --</option>
																				{this.props.config.fees.networks.map((item, idx) => {
																					const _key = `network_${idx}`;
																					return (
																						<option key={_key} value={item.id}>{item.network} - {item.currency}</option>
																					);
																				})}
																			</select>
																		</div>

																	</div>
																)
																:
																(
																	<input type="hidden" id="network" name="network" value={this.props.state.nft.network} />
																)
															}

														</div>





														<div className="col-12">
															<div className="form-group mt-3">
																<label htmlFor="description" className="pb-2 heading">Description</label>
																<textarea className="form-control" name="description" id="description" disabled={_disabled} placeholder="Provide an awesome description to entice buyers" cols={30} rows={3} value={this.props.state.nft.description} onChange={this.handleChange}  />
															</div>
														</div>


														<div className={ this.props.state.nft.collection.length === 0 ? "col-12 hid" : "col-12"}>
															<div className="form-group mt-3 attribute-editor">
																<label htmlFor="price" className="pb-2 heading">Properties</label>

																<div className="list-group">
																	<div className="row text-center mb-3 fw-bold">
																		<div className="col-6">
																			Property Name
																		</div>
																		<div className="col-6">
																			Property value
																		</div>
																	</div>

																	{ this.props.state.nft.collection.length > 10 ?
																	(
																		<>
																			{this.props.state.nft.attributeData.map((item, idx) => {
																			return (
																				<div className="mb-2" key={"prop_"+idx}>
																					<div className="row">

																						<div className="col-5">
																							<input type="text" className="w-100 form-control" readOnly={true} tabIndex={idx+1000} value={item.attribute_name} />
																						</div>

																						<div className="col-1 text-center pt-2">
																						=
																						</div>

																						<div className="col-6">
																							{ this.props.state.nft.hasAttributes === false ?
																							(
																								<input type="text" className="w-100 form-control" value={item.value} data-type="value" id={"tv_" + (idx+1)} data-position={idx+1} onChange={this.changeProperty}  />
																							)
																							:
																							(
																								<input type="text" className="w-100 form-control" readOnly={true} value={item.value} />
																							)
																							}
																						</div>
																					</div>
																				</div>

																			);
																			})}

																		</>
																	)
																	: ( <></>)}

																	{ this.props.state.nft.collection === 'null' ?
																	(
																		<>

																			{this.props.state.nft.attributeData.map((item, idx) => {
																			return (
																				<div className="mb-2" key={"prop2_"+idx}>
																					<div className="row">
																						<div className="col-2">
																							<input type="text" className="w-100 form-control" readOnly={true} value={item.attribute_id} />
																						</div>
																						<div className="col-3">
																							<input type="text" className="w-100 form-control" value={item.attribute_name} data-type="name" id={"tt_" + (idx+1)} data-position={idx+1} onChange={this.changeProperty}  />
																						</div>
																						<div className="col-1 text-center pt-2">
																						=
																						</div>
																						<div className="col-5">
																							<input type="text" className="w-100 form-control" value={item.value} data-type="value" id={"tv_" + (idx+1)} data-position={idx+1} onChange={this.changeProperty}  />
																						</div>
																						<div className="col-1 text-center pt-2">
																							<button className="btn w-100 btn-del" onClick={this.removeProperty} data-position={idx+1} tabIndex={idx+1000}><i className="fas fa-times-circle fa-lg" /></button>
																						</div>
																					</div>
																				</div>

																			);
																			})}

																			<div key={"prop_0"}>
																				<div className="row">
																					<div className="col-5">
																						<input type="text" className="w-100 form-control" value="" placeholder="Property Name" data-type="name" data-position={0} onChange={this.changeProperty} />
																					</div>
																					<div className="col-1 text-center pt-2">
																					=
																					</div>
																					<div className="col-5">
																						<input type="text" className="w-100 form-control" value="" placeholder="Property Value" data-type="value" data-position={0} onChange={this.changeProperty} />
																					</div>
																					<div className="col-1 text-center pt-2">

																					</div>
																				</div>
																			</div>

																			{ this.props.state.nft.temp  ?
																			(
																				<>
																					<blockquote>
																						Attributes/Properties are used to add characteristics to your NFT. For example, if you had an NFT of a man with brown hair wearing a certain colored sweater you could add attributes for each of those traits. For example:-
																						<ol>
																							<li><strong>Hair:</strong> Brown</li>
																							<li><strong>Gender:</strong> Male</li>
																							<li><strong>Sweater:</strong> Red</li>
																						</ol>
																						<span>
																							For a more detailed explanation, refer to our <VideoLink label={"How to add traits to an NFT"} tag={"editing-traits-nft"} config={this.props.config} /> training video.
																						</span>
																					</blockquote>

																				</>
																			)
																			: (<></>)
																			}

																		</>

																	): ( <></>)}

																	{ this.props.state.nft.hasAttributes === true ?
																	(
																		<blockquote>
																			This NFT was created from a bulk import. You are unable to change the attributes.
																		</blockquote>
																	)
																	:
																	(
																		<></>
																	)
																	}

																</div>




															</div>
														</div>


														<div className="col-12">
															<div className="form-group mt-3">
																<label htmlFor="price" className="pb-2 heading">Deep Link (Not mandatory)</label>
																<input type="text" className="form-control" name="link" id="link" placeholder="e.g. https://myDomain.com/item/1"  disabled={_disabled}  onChange={this.handleChange} value={this.props.state.nft.link} />
															</div>

															<blockquote>
																Deep links are used to allow xMart visitors to go directly to a page on your own website. This page may have further information about this particular NFT.
															</blockquote>

														</div>

													</div>

											</div>


										</div>
									</form>
								)}

							</div>
						</div>
						<div className="modal-footer modal-footer--sticky">
							<div className="row  w-100">
								<div className="col-12">
									<button type="button" disabled={ this.props.state.nft.saveDisabled } onClick={this.handleSubmit} className="btn w-100">{this.props.state.nft.saveButton}</button>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
        );
    }
}

export default ModalCRUDNFT;

