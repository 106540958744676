import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { formatDate } from '../../components/xutils';
import { addXummPayload } from '../../js/xumm';
import {
	faPaperPlane,
	faComment,
	faChevronCircleRight,
	faArrowLeft,
	faMagnifyingGlass,
	faAngleDoubleUp,
	faAngleDoubleDown,
	faListUl,
	faExclamationTriangle


} from "@fortawesome/free-solid-svg-icons";
import "../../css/xChat.css";

const bo = window.$("body,html");

class ChatComponent extends Component {


	scrollToBottom = () => {

		window.setTimeout( () => {

			try
			{
				const chatMessages = document.getElementById("chat-messages");
				chatMessages.scrollTop = chatMessages.scrollHeight;
			}
			catch(e)
			{}

		 }, 600 )

	}


	state = {
			chatEnabled:typeof(window.socket) === 'undefined' ? false : true,
			chatExpanded: false,
			chatChannel:0,
			chatChannelName:"",
			chatMessage:"",
			channels:this.props.config.userData.chatChannels,
			showSearch:false,
			searchString:"",
			searchResults:{},
			searchResultsCount:0,
			selectedAccordian:"collection"

		};


	componentDidMount() {

		if(typeof(window.socket) != 'undefined')
		{

			this.socket = window.socket

			this.socket.on("message", (data) => {
				// console.log("Received message:", data)
				this.appendMessage(data, data.channel);
			});

			this.socket.on("history", (data) => {
				//this.setState({messages:data.message});
			});
		}

	}

	componentDidUpdate(prevProps, prevState) {

	}

	handleAccordianClick = e =>
	{
		this.setState({selectedAccordian:e.currentTarget.dataset.type})
	}



	expandChat = e => {
		var _showSearch = this.state.showSearch ? true : false;
		if(this.state.chatExpanded)
		{
			_showSearch= false;
		}

		if( Number(this.state.chatChannel) > 0)
		{
			this.scrollToBottom()
		}

		this.setState({ chatExpanded: ! this.state.chatExpanded, showSearch:_showSearch });
	}


	joinChannel = e =>
	{
		if(typeof(this.socket) === 'undefined')
		{
			window.displayErrorMessage("You must be logged in to view chat")
			return false;
		}
		else
		{

			/* The getData API function auto joins the user */
			this.switchMessageArray(e, true)

		}


	}


	clickTitle = e =>
	{

		if(Number(this.state.chatChannel))
		{
			/* Switch back to channel list */
			this.openChannels()
		}
		else
		{
			/* Hide window and ensure we've changed from search box */
			this.setState({ chatExpanded: ! this.state.chatExpanded, showSearch:false });
		}
	}

	handleSearchChange = (e) => {
		this.setState({ searchString: e.target.value });
	}
	handleSearchPress = (e) => {
		if(e.code==='Enter')
		{
			document.getElementById("search-go").click();
		}
	}

	handleSearch = (e) => {

		try
		{

			/* Get message history and prepend to existing history*/
			const formData = new FormData( );
			formData.append("method", "search")
			formData.append("auth", JSON.stringify(this.socket.auth))
			formData.append("query", this.state.searchString)

			const BASE_URL = this.props.config.apiServer + "/com/external/chatroom.cfc";

			axios.post(`${BASE_URL}`, formData, {withCredentials: true})
				.then(res => {


					if(res.data.success)
					{

						this.setState({searchResults:res.data.info, searchResultsCount:Number(res.data.results)})

						if(Number(res.data.results)===0)
						{
							window.toast("<p>No search results found.</p><p>Maybe it's already been added to your channels.</p>", "center", 5000)
						}

					}
					else
					{
						throw new Error(res.data.errorMessage)
					}

				})
			.catch(err =>{
				window.displayErrorMessage(err.message);
				window.logger(err.message)
			})


		} catch(e) {
			console.log("Error", e)
			debugger
		}

	};

	searchChat = e =>
	{
		document.getElementById("joinChannel").dataset.id = e.currentTarget.dataset.id;
		document.getElementById("joinChannel").dataset.type = 'dm';
		document.getElementById("joinChannel").click();
		this.setState({ showSearch:false })
	}



	handleMessageChange = (e) => {
		this.setState({ chatMessage: e.target.value });
	}
	handleMessagePress = (e) => {
		if(e.code==='Enter' && e.ctrlKey)
		{
			document.getElementById("messages-send").click();
		}
	}

	handleSendMessage = (e) => {

		if (this.state.chatMessage.trim().length) {

			const currentTimelocal = new Date()
			const currentUTC = currentTimelocal.getTime()
			const _message = {	channel:	this.state.chatChannel.toString(),
								env:		this.props.config.serverMode.toLowerCase(),
								message: 	{
												text: this.state.chatMessage,
												from: this.socket.auth,
												ts:currentUTC
											}
								}

			this.socket.emit("message", _message);
			this.appendMessage(_message, this.state.chatChannel.toString())
			this.setState({ chatMessage: '', notConnected: false }); // Clear the text box after sending

		}

	};





	appendMessage = ( message, channelID ) =>

	{

		console.log("Append message", [message, channelID])

		try
		{

			/* Get message object for this channel */
			var messageHistory = this.locateChannelData(channelID);

			/* Increment counter if message is not from self and channel isn't open */
			if(Number(channelID) !== Number(this.state.chatChannel) && message.message.from.userID !== this.socket.auth.userID )
			{
				messageHistory.new++;
			}
			else
			{
				/* In the right channel */
				this.scrollToBottom();
			}

			messageHistory.messages.push(message.message);

			this.updateChannelData(channelID, messageHistory)

		}
		catch(e)
		{
			console.log("Error", e)
			debugger
		}

	}


	prependMessages = (channelID, messageData) =>
	{

		/* This will only ever modify the ACTIVE channel the user is looking at */
		/* This will only ever modify the ACTIVE channel the user is looking at */
		/* This will only ever modify the ACTIVE channel the user is looking at */

		//console.log("Prepend messages", [channelID, messageData])

		try
		{

			var messageHistory = this.locateChannelData(channelID);
			if(messageHistory == null)
			{
				debugger
				alert("gah")
			}

			if(Number(messageData.oldest))
			{
				messageHistory.messages = messageData.messages.concat(messageHistory.messages);
				messageHistory.oldest = Number(messageData.oldest);

				this.setState({chatChannel:channelID.toString(), chatChannelName:messageHistory.name, chatExpanded:true})

			}
			else
			{
				this.setState({chatChannel:channelID.toString(), chatChannelName:messageHistory.name, chatExpanded:true})
			}

			/* Update chat channel with new messages */
			messageHistory.new = 0;
			this.updateChannelData(channelID, messageHistory)

			this.scrollToBottom();

		}
		catch(e)
		{
			console.log("Error", e)
			debugger
		}



	}

	locateChannelData = channelID =>
	{
		//console.log("Locate channel data", channelID)


		try
		{
			var returnData = null;

			if(Number(channelID) === 0)
			{
				returnData = {"oldest":0, "new":0, "name":"newchannel", "messages":[]}
			}

			else
			{

				/* Loop across all channel types to get title, chats etc */
				Object.keys(this.state.channels).map((channelType, idxCT) =>
				{
					const theChannels = this.state.channels[channelType].ch;

					Object.keys(theChannels).map((item, idx) =>
					{
						if(Number(item) === Number(channelID) )
						{
							returnData = this.state.channels[channelType].ch[item];
							returnData.channelType = channelType;
						}
					})
				})

				if(returnData === null && 0 === 0)
				{
					returnData = {"oldest":0, "new":0, "name":"newchannel", "messages":[]}
				}

			}

			/* Return a deep copy */
			returnData = JSON.parse(JSON.stringify(returnData))
			//console.log("--- Returned ", returnData)

			return returnData

		}
		catch(e)
		{
			console.log("Error", e)
			debugger
		}

	}

	updateChannelData = ( channelID, channelData ) =>
	{

		//console.log("Update channel data", [channelID, channelData])

		try
		{
			var temp = this.state.channels

			/* Loop across all channel types to get title, chats etc */
			Object.keys(this.state.channels).map((channelType, idxCT) =>
			{
				const theChannels = this.state.channels[channelType].ch;

				Object.keys(theChannels).map((item, idx) =>
				{
					if(Number(item) === Number(channelID) )
					{
						temp[channelType].ch[item].messages = channelData.messages;
						temp[channelType].ch[item].new = channelData.new;
						temp[channelType].ch[item].oldest = channelData.oldest;
						temp[channelType].ch[item].last = channelData.last;
						//returnData.channelType = channelType;
					}
				})
			})


			/* Push new messages back into scope */
			this.setState({channels:temp})

		}
		catch(e)
		{
			console.log("Error", e)
			debugger
		}
	}

	getMessageHistory = channel =>
	{

		//console.log("Get message history", channel)

		try
		{
			var messageHistory = this.locateChannelData(channel);

			/* If not defined, create empty holder */
			if( typeof(messageHistory) === 'undefined')
			{
				messageHistory = {"oldest":0, "new":0, "name":"?????", "messages":[]};
			}
			else if( typeof(messageHistory.messages) === 'undefined')
			{
				alert("Messages not defined");
				this.histories[channel] = {"new":0, messages:[]};
				messageHistory = this.histories[channel];
			}
			else
			{
				/* Already have the history */
				// Update new count
			}

			return messageHistory;


		} catch(e) {
			console.log("Error", e)
			debugger
		}

	}

	setMessageCount = (channel, count) =>
	{

		var messageHistory = this.locateChannelData(channel);
		//debugger
		//var temp = this.state.channels;
		//temp[channel].new = count;
		//this.setState({channels:temp})

		return true

	}

	switchMessageArray = ( e, openChannel ) =>
	{

		try
		{

			/* Are we joining a brand new chat? */
			var _theID
			if(typeof(e) === 'number')
			{
				_theID = e
			}
			else
			{
				_theID = e.currentTarget.dataset.id
			}

			/* Get history data so we know the oldest */
			document.messageHistory = this.getMessageHistory(_theID);

			/* Get message history and prepend to existing history*/
			const formData = new FormData( );
			formData.append("method", "getChatData")
			formData.append("chatroomID", _theID)
			formData.append("auth", JSON.stringify(this.socket.auth))
			formData.append("timestamp", document.messageHistory.oldest)
			formData.append("type", e.currentTarget.dataset.type) // Required to open a channel

			if(openChannel)
			{
				formData.append("openChannel", openChannel) // Indicates opening a new channel
			}

			const BASE_URL = this.props.config.apiServer + "/com/external/chatroom.cfc";

			axios.post(`${BASE_URL}`, formData, {withCredentials: true})
				.then(res => {


					if(res.data.success)
					{

						_theID = res.data.info.idPK;

						/* Add the channel to our state */
						if(document.messageHistory.name === 'newchannel')
						{
							/* New channel subscription */
							var temp = this.state.channels
							res.data.info.role = res.data.role;
							temp[res.data.info.type].ch[_theID] = res.data.info
							this.setState({channels:temp, chatChannel:_theID, chatChannelName:res.data.info.name, chatExpanded:true, selectedAccordian:res.data.info.type})

						}
						else
						{
							/* Just get history messages */
							this.prependMessages(_theID, res.data.info)
							this.setState({selectedAccordian:res.data.info.type})

						}

						/* Join the channel */
						this.socket.emit("join", {"channel":_theID} )

						/* Already have the history */
						window.setTimeout( () => {
								this.setMessageCount(_theID, 0);
								this.chatFocus()
							}, 400 )

					}
					else
					{
						throw new Error(res.data.errorMessage)
					}

				})
			.catch(err =>{
				window.displayErrorMessage(err.message);
				window.logger(err.message)
			})


		} catch(e) {
			console.log("Error switching message array", e)
			debugger
		}

	}

	chatFocus = e => {
		if( ! this.props.config.isMobile)
		{
			window.$('#chat-input').focus();
		}
	}

	openSearch = e =>
	{
		this.setState({"showSearch":true, searchResults:{}, searchResultsCount:0, chatExpanded:true, chatChannel:0, chatChannelName:""})
		window.setTimeout( () => { this.chatFocus() }, 400 )
	}

	openChannels = e =>
	{
		this.setState({"showSearch":false, chatExpanded:true, chatChannel:0, chatChannelName:""})
	}

	enumerateRole = tag =>
	{
		switch (tag) {
			case "guest":
				return "Guest/Viewer";
			case "moderator":
				return "Moderator";
			case "owner":
				return "Channel Owner";
			case "member":
				return "Contributor";
			default:
				return "??";
		}
	}


	render() {


		try
		{

			const { isMobile } = this.props.config;
			const { chatExpanded } = this.state;

			var containerClass, title, chatChannelSearch, chatInfoMessage, channelList, chatPane, theChannelName, theChannels, theMessages;
			containerClass = `${ isMobile ? "chat-container mobile" : "chat-container" }${chatExpanded ? " fullscreen" : ""}`;

			var viewingWhat = "";
			if(this.state.showSearch)
			{
				viewingWhat = <><FontAwesomeIcon className="me-1" icon={faMagnifyingGlass} /> Search xChat</>
			}
			else if( Number(this.state.chatChannel) === 0)
			{
				viewingWhat = <><FontAwesomeIcon className="me-1" icon={faListUl} /> My Channels</>
			}
			else
			{
				viewingWhat = <><FontAwesomeIcon className="me-1" icon={faArrowLeft} /> {this.state.chatChannelName}</>
			};

			title = chatExpanded ? viewingWhat : ( <><FontAwesomeIcon className="fa-flip-horizontal me-1" icon={faComment} /> xChat</> );

			/* Should we show a chat start button? This will open searchable channel/user list */
			/* Should we show a chat start button? This will open searchable channel/user list */
			/* Should we show a chat start button? This will open searchable channel/user list */
			var chatButtons = null
			if( ! this.state.showSearch && ( isMobile === false || chatExpanded ) )
			{
				chatButtons = (
								<button className="start-btn roundbutton" title="Search" onClick={this.openSearch}>
									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</button>
				)
			}
			if(this.state.showSearch && chatExpanded)
			{
				chatButtons = (
								<button className="start-btn roundbutton" title="My Channels" onClick={this.openChannels}>
									<FontAwesomeIcon icon={faListUl} />
								</button>
				)
			}



			/* Ensure body can't scroll if chat is visible */
			/* Ensure body can't scroll if chat is visible */
			/* Ensure body can't scroll if chat is visible */
			if(isMobile )
			{
				if(this.state.chatExpanded)
				{
					bo.addClass("chatExpanded");
				}
				else
				{
					bo.removeClass("chatExpanded");
				}
			}

			/*
			BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ...
			BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ...
			BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ... BUILD CHANNEL LIST ...
			*/
			channelList = Number(this.state.chatChannel) === 0 && this.state.showSearch === false && this.state.chatExpanded ?

				(
					<div className="accordion" id="channelTypes">
						{

							Object.keys(this.state.channels).map((channelType, idxCT) =>
							{


								try
								{

									theChannelName = this.state.channels[channelType].lb;
									theChannels = this.state.channels[channelType].ch;

									const accHeading = "channelHeading_" + channelType
									const accGroup = "channelgroup_" + channelType

									const showClass = this.state.selectedAccordian === channelType ? "show" : ""
									const expanded = this.state.selectedAccordian === channelType ? "true" : "false"
									const collapsed = this.state.selectedAccordian === channelType ? "" : "collapsed"

									return (


										<div className="accordion-item m-0 p-0" key={`channelType_${channelType}`}>

											<h2 className="accordion-header m-0 p-0" id={accHeading} data-type={channelType} onClick={this.handleAccordianClick}>
												<button className={`accordion-button ${collapsed}`} type="button" data-bs-toggle="collapse" data-bs-target={"#" + accGroup} aria-expanded={expanded} aria-controls={accGroup}>
													{theChannelName} ({Object.keys(theChannels).length})
												</button>
											</h2>
											<div id={accGroup} className={"accordion-collapse collapse  " + showClass} aria-labelledby={accHeading} data-bs-parent="#channelTypes">
												<div className="accordion-body m-0 p-0">

													<ul className="chat-list m-0 p-0">
													{
														Object.keys(theChannels).map((item, idx) =>
														{

															const channel = theChannels[item];

															var theImage
															if(channel.img.length)
															{
																theImage = this.props.config.apiServer + channel.img
															}
															else
															{
																theImage = this.props.config.sizes.placeholders.profilePicture.small
															}


															return (
															<li key={`channel_${channelType}_${idx}`} className="chat-row d-flex" data-id={item} data-name={channel.name} data-type={channelType} onClick={this.switchMessageArray}>

																<div className='flex-grow-1 d-flex'>
																	<div className="me-3 align-self-center">
																		<img className="avatar" src={theImage} />
																	</div>
																	<div className="name align-self-center">
																		<div className="channel mb-1">{channel.name}</div>
																		{ channelType !== 'dm' ? ( <div className="channel-subtext">{this.enumerateRole(channel.role)}</div> ) : null}

																	</div>
																</div>

																{ channel.new ?
																(
																	<span className="badge bg-danger ms-auto">
																		{channel.new}
																		<span className="visually-hidden">unread messages</span>
																	</span>
																)
																: ( <></> )}

																<button className="ms-auto  align-self-center"><FontAwesomeIcon className="" icon={faChevronCircleRight} /></button>
															</li>
														)}
													)}
													{ Object.keys(theChannels).length === 0 ?
													( <div className="text-muted text-center p-4">Not subscribed to any "{theChannelName}" channels.</div>) : ( <></> )

														}
													</ul>

												</div>
											</div>
										</div>
									)

								}
								catch(e)
								{
									console.log("Channel list error", e)
								}

							})
						}
					</div>

				) : null;


			/*
			BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ...
			BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ...
			BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ... BUILD SEARCH BOX ...
			*/
			chatChannelSearch = Number(this.state.chatChannel) === 0 && this.state.showSearch === true  && this.state.chatExpanded?
			(
				<>
					<div className="chat-messages chat-panel" id='chat-messages'>
						{ this.state.searchResultsCount === 0 ?
						(
							<div  id="chat-search-splash" className='d-flex h-100 justify-content-center'>
								<img src="/img/chat-search-splash.png" className="align-self-center" />
							</div>
							) :
						(
							<div className="accordion" id="searchTypes">
							{
								Object.keys(this.state.searchResults).map((resultsType, idxCT) =>
								{


									try
									{

										const accHeading = "searchHeading_" + resultsType
										const accGroup = "searchgroup_" + resultsType
										const resultData = this.state.searchResults[resultsType];

										/*
										const showClass = this.state.selectedAccordian === resultsType ? "show" : ""
										const expanded = this.state.selectedAccordian === resultsType ? "true" : "false"
										const collapsed = this.state.selectedAccordian === resultsType ? "" : "collapsed"
										*/

										const showClass = "show"
										const expanded = "true"
										const collapsed = ""


										return (


											<div className="accordion-item m-0 p-0" key={`searchType_${resultsType}`}>

												<h2 className="accordion-header m-0 p-0" id={accHeading}>
												<button className={`accordion-button ${collapsed}`} type="button" data-bs-toggle="collapse" data-bs-target={"#" + accGroup} aria-expanded={expanded} aria-controls={accGroup}>
													{resultsType} ({resultData.length})
												</button>
												</h2>

												<div id={accGroup} className={"accordion-collapse collapse  " + showClass} aria-labelledby={accHeading} data-bs-parent="#searchTypes">
													<div className="accordion-body m-0 p-0">

														<ul className="chat-list m-0 p-0">
														{
															resultData.map((result, idx) =>
															{

																var theImage
																if(result.img.length)
																{
																	theImage = this.props.config.apiServer + result.img
																}
																else
																{
																	theImage = this.props.config.sizes.placeholders.profilePicture.small
																}

																return (
																<li key={`channel_${resultsType}_${idx}`} className="chat-row d-flex" data-id={result.id} onClick={this.searchChat}>

																	<div className='flex-grow-1 d-flex'>
																		<div className="me-3 align-self-center">
																			<img className="avatar" src={theImage} />
																		</div>
																		<div className="name align-self-center">
																			<div className="channel mb-1">{result.label}</div>
																			<div className="channel-subtext">{result.info}</div>
																		</div>
																	</div>

																	<button className="ms-auto  align-self-center"><FontAwesomeIcon className="" icon={faChevronCircleRight} /></button>
																</li>
															)}
														)}
														{ resultData.length === 0 ?
														( <div className="text-muted text-center p-4">No results found.</div>) : ( <></> )

															}
														</ul>

													</div>
												</div>


											</div>
										)

									}
									catch(e)
									{
										console.log("Search results error", e)
									}

								})
							}
							</div>
						)}
					</div>
					<div className="chat-input-container">
						<input
							type="text"
							className="chat-input flex-grow-1"
							id="chat-input"
							placeholder="Collection, Member name or Wallet"
							value={this.state.searchString}
							onChange={this.handleSearchChange}
							onKeyUp={this.handleSearchPress}
						/>
						<button onClick={this.handleSearch} disabled={this.state.searchString.length < 2 } id="search-go" className="chat-send-button no-style">
							<FontAwesomeIcon icon={faMagnifyingGlass} className='me-1' /> Find <div className='d-none d-md-block'>or Enter</div>
						</button>
					</div>
				</>
			) : null;


			//console.log("Chat info for ", this.state.chatChannel)
			chatInfoMessage = ! this.props.config.userData.loggedIn && this.state.chatExpanded ?
				(
					<div className="text-center">
						<h5 className="mt-5">You must be signed in to use chat.</h5>
						<button type="button" id="sign-in-button" onClick={addXummPayload} data-type="SignIn" className="btn btn-primary w-50 py-1 px-2 mt-4">
							SIGN IN
						</button>
					</div>
				)
				: null


			/* Create chat bubbles */
			/* Create chat bubbles */
			/* Create chat bubbles */

			//console.log("Messages for ", this.state)
			theMessages = this.locateChannelData(this.state.chatChannel).messages;
			chatPane = this.props.config.userData.loggedIn && Number(this.state.chatChannel) > 0 && this.state.chatExpanded ?
			(
				<>
					{ ! this.socket.connected ? ( <div id="chat-not-connected"><FontAwesomeIcon icon={faExclamationTriangle} className='me-1' /> Not connected to chat server</div> ) : null}

					<div className="chat-messages chat-panel" id='chat-messages'>
					{ theMessages.map((message, idx) =>
						{

						const whoBy = message.from.userName === "*" ? "System" : message.from.userLabel;
						const theID = message.ts < 0 ? "lastmessage" : "xChatMsg_"+idx;

						return (
							<>

								{ message.from.userName === 'rXMART8usFd5kABXCayoP6ZfB35b4v43t' ?
								(
									<div className="message__xmart">
										{message.text}
									</div>

								) : (

									<>
										{ message.from.userName === this.socket.auth.userName ?
											(
												<>
													<div className="sender__name text-end me-1 py-1">You &bull; <span className="ts">{formatDate(message.ts, false, true)}</span></div>
													<div className="message__sender">
														{message.text}
													</div>
												</>

											) : (

												<>
													<div className="recipient__name py-1 ms-1 text-start">{whoBy} &bull; <span className="ts">{formatDate(message.ts, false, true)}</span></div>
													<div className="message__recipient">
														{message.text}
													</div>
												</>

											)
										}
									</>

								)
								}

							</>
						)})
					}
					</div>
					<div className="chat-input-container">
						<input
							type="text"
							className="chat-input flex-grow-1"
							id="chat-input"
							placeholder={this.socket.connected === false ? "Chat is currently offline" : "Type a message..."}
							value={this.state.chatMessage}
							onChange={this.handleMessageChange}
							onKeyUp={this.handleMessagePress}
							disabled={this.socket.connected === false}
						/>
						<button onClick={this.handleSendMessage} id="messages-send" disabled={this.state.chatMessage.length < 1 || this.socket.connected === false} className="chat-send-button no-style">
							<FontAwesomeIcon icon={faPaperPlane} className='me-1' /> Send <div className='d-none d-md-block'>or Ctrl+Enter</div>
						</button>
					</div>
				</>
			) : null

			return (
				<div className={containerClass}>
					<div className={`chat-window ${this.state.chatExpanded ? "expanded" : ""}`}>

						<div id="chat-header">

							<div className="chat-title ellipsis" onClick={this.clickTitle}><h4>{title}</h4></div>

							{/* This button is used by slideout.js */}
							<button type='button' onClick={this.joinChannel} id="joinChannel" data-id="" data-type="" className="hid">Join</button>

							<div className="chat-button-group">

								{chatButtons}

								<button className="expand-btn roundbutton" onClick={this.expandChat}>
									<FontAwesomeIcon
										icon={
											this.state.chatExpanded
												? faAngleDoubleDown
												: ( isMobile ? faComment : faAngleDoubleUp )
										}
									/>
								</button>
							</div>
						</div>

						{chatChannelSearch}
						{chatInfoMessage}
						{channelList}
						{chatPane}
					</div>
				</div>
			);

		}
		catch(e)
		{
			console.log("Error in render", e)
			alert("Error in render")
		}



	}
}

export default ChatComponent;
