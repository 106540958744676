import React, { Component } from 'react';
import LoadingMessage from './loading-message';

class CollectionSpinnerLockedComponent extends Component {

	render() {

		return (


			<div className="row mt-5">

				<div className="col-12">
					<h2 className="mt-5 text-center">The collection is locked while import is in progress.</h2>
					{
						this.props.config.userData.display || this.props.state.isMyCollection ?
						(
							<>
								<p className="text-center">The download process hasn't yet started.</p>
								<LoadingMessage config={this.props.config} state={this.props.state} />
							</>
						)
						:
						( <><p className="text-center mt-5">If you are the owner of this collection, please log in to view any errors.</p></> )
					}
					{
						this.props.config.userData.display   ?
						(
							<>
								<p className="text-center">ADMIN MESSAGE: Once uploaded or if you suspect it's broken, <a href={this.props.config.apiServer + "/com/cron/tasks/imports.cfm"} rel="noreferrer" target="_blank">click here to check the import progress for errors</a></p>
								{
									this.props.state.collectionData.bulkInfo.importType === 3 ?
									( <p className="text-center fw-bold">You need to drop the ZIP file into import/{this.props.state.collectionData.recordID}/</p>)
									:
									( <></> )
								}
							</>
						)
						:
						( <></> )
					}
				</div>
			</div>

        );
    }
}

export default CollectionSpinnerLockedComponent;