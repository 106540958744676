import React, { Component } from 'react';
import Checkout 			from '../components/Store/Checkout';
import '../css/store.css'

class Store extends Component {

	render() {

        return (
            <div className="main">
				<div className="container-fluid p-0">

					<Checkout config={this.props.config} />

				</div>
            </div>
        );
    }
}

export default Store;