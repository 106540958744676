import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import OffersAndNFTs from '../../components/AuthorProfile/OffersAndNFTs';



class pageContent extends Component {

	state = { profileData: this.props.config.userData }

    render() {

        return (
            <div className="main">
                <Header				config={ this.props.config } state={this.state} />
                <Breadcrumb			config={ this.props.config } state={this.state} title="Wallet Offers" background="null" subpage="Tools" page="Wallet Offers" />

				<section className="author-area explore-area popular-collections-area">
					<div className="container p-0">
						<OffersAndNFTs		config={ this.props.config } />
					</div>
				</section>

                <Footer				config={ this.props.config }/>
                <ModalSearch		config={ this.props.config } />
                <ModalMenu			config={ this.props.config } />
                <Scrollup			config={ this.props.config } />
            </div>
        );
    }
}

export default pageContent;