import React, { Component } from 'react';


import axios from 'axios';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import AuthorComponent from '../../components/Author/Author';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';

class Author extends Component {

	state = {
        nftData: [],
		function: this.props.config.urlParts.length <=3 ? "" : ( this.props.config.urlParts.length >= 4 & this.props.config.urlParts[3].length ? this.props.config.urlParts[3] : "" ),
		profileData:{title:"", urlPath:"", url:"", social:[], profileImageM:"", profileImageL:""},
		dataType:"null",
		categories:[],
		noDataMessage:"",
		breadCrumb:"",
		profile: this.props.config.urlParts[2]
    }

    componentDidMount(){

		const BASE_URL = this.props.config.apiServer + "/com/external/profile.cfc?method=getProfile&profile=" + this.props.config.urlParts[2];

        axios.get(`${BASE_URL}`, {withCredentials: true})
            .then(res =>
			{
				if(res.data.success)
				{

					this.setState({
						profileData:res.data.info
					})

						document.title = res.data.info.title + this.props.config.meta.title;



					/* Inistialse jQuery components */
					window.initialiseJQ()

				}
				else
				{
					window.Swal.fire( { html:res.data.errorMessage } )

					this.setState({
						profileData:{url:"", urlPath:"", title:"Error"},
						nftData:[],
						noDataMessage:`<h3>There was an error loading the NFTs</h3><p>${res.data.errorMessage}</p>`
					})

				}

            })
        .catch(err => window.Swal.fire( { html:err } ) )
    }

    render() {

		return (

            <div className="main">
                <Header				config={ this.props.config } />
                <Breadcrumb			config={ this.props.config } state={this.state} title={this.state.profileData.title} subpage={this.state.profileData.urlPath} subPageLink={this.state.profileData.url} page={this.state.breadCrumb} background={this.props.config.apiServer + this.state.profileData.headerImage} shallow="true" />
                <AuthorComponent	config={ this.props.config } state={this.state} />
                <Footer 			config={ this.props.config } />
                <ModalSearch 		config={ this.props.config } />
                <ModalMenu 			config={ this.props.config } />
                <Scrollup 			config={ this.props.config } />
            </div>
        );
    }
}


export default Author;