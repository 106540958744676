import React, { Component } from 'react';
import reloadConfig from '../configLoader';
import SystemMessage from './SystemMessage';
import { checkXummLoggedIn, addXummPayload } from '../../js/xumm';

class Header extends Component {

	state = {
					"darkModeScheme":window.getCookie("darkModeScheme") === '' ? "gold" : "",
					"darkMode":window.getCookie("darkMode") === '' || window.getCookie("darkMode") === 'ON' ? true : false}

	launchCRUD = e =>
	{
		if(this.props.config.userData.id.length)
		{
			e.preventDefault()
			document.getElementById("crud-nft-loader-button").dataset.nft = "";
			document.getElementById("crud-nft-loader-button").dataset.collection = "";
			document.getElementById("crud-nft-loader-button").click()
		}
	}

	componentDidMount()
	{
		//alert(this.state.darkMode)
	}

	reloadConfigButton = e=> {
		reloadConfig()
	}

	/*
	goBasket = e =>
	{
		window.$('#storeGoBasket').trigger('click')
	}
	*/

	switchWidescreen = e =>
	{
		const _state = e.currentTarget.dataset.state;
		window.setCookie("screenMode", _state, 14);
		window.$('#reloader').trigger("click");

		window.toast(`NFT lists will now display and ${_state.length ? "Wide Screen" : "Standard Width"} Grids`, "center")

		window.dispatchEvent(new Event('resize'));

	}

	switchDarkMode = e =>
	{
		const _scheme = e.currentTarget.dataset.scheme;

		var _state;

		if(_scheme === '' )
		{
			_state = "OFF";
		}
		else
		{
			_state = "";
		}

		window.setCookie("darkMode", _state, 14);
		window.setCookie("darkModeScheme", _scheme, 14);
		window.darkModeSet(_state, _scheme)

	}

    render() {

		const _profileImage = this.props.config.userData.profileImageS && this.props.config.userData.profileImageS.length ? `${this.props.config.apiServer}${this.props.config.userData.profileImageS}` : this.props.config.sizes.placeholders.profilePicture.small;

		const _loggedIn = Number(this.props.config.userData.sid) > 0 ? true : false

		const _walletClass = _loggedIn ? "nav-link" : "nav-link wallet-disconnected";
		const _urlPrefix = _loggedIn ? this.props.config.userData.url : "/profile";

		const _iconClass = this.props.config.isMobile ? "fas fa-palette fa-lg" : "fas fa-desktop fa-lg"

		return (
			<>

				<SystemMessage config={this.props.config} />

				<header id="header">


					{/* Navbar */}
					<nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand icons">

						<div className="container header">

							{/* Navbar Brand*/}
							<a className="navbar-brand" href="/">
								<div id="header-logo">
									{/* <img className="navbar-brand-sticky" src="/img/logo.png" alt="sticky brand-logo" /> */}
								</div>
							</a>

							<div className="ms-auto" />
							{/* Navbar */}
							<ul className="navbar-nav items mx-auto">

								<li className="nav-item d-md-none">
									{ ! _loggedIn ?
									(
										<button type="button" id="sign-in-button-mobile" data-type="SignIn" className="btn w-100 my-2 wallet-disconnected py-2">
											<i className="fas fa-wallet me-2 fa-lg" /> Connect Wallet
										</button>
									) : (
										<a href="/" className="btn btn-smaller ms-lg-auto btn-bordered no-wrap px-2 wallet-connected w-100">
											<span className="me-md-2">Sign Out <i className="fas fa-sign-out-alt fa-lg ms-2 me-2"></i></span>
										</a>
									)}
								</li>



								{/* Categories dropdown */}
								<li className="nav-item dropdown">
									<a className="nav-link no-click text-shadow" href="/">Explore <i className="fas fa-angle-down ms-1" /></a>
									<ul className="dropdown-menu">
										<li className="nav-item" key={`navexplore_995`}><a href={`/nfts/for-sale`} className="nav-link">Recently Listed For Sale</a></li>
										<li className="nav-item" key={`navexplore_998`}><a href={`/nfts/latest-sales`} className="nav-link">Recent Sales</a></li>
										<li className="nav-item" key={`navexplore_997`}><a href={`/nfts/latest-mints`} className="nav-link">Latest Mints</a></li>
										<li className="nav-item" key={`navexplore_996`}><a href={`/nfts/latest-mints-xrpl`} className="nav-link">Latest Mints on the XRPL</a></li>
										<hr />
										<li className="nav-item" key={`navexplore_999`}><a href={`/category/all`} className="nav-link">View All Collections</a></li>
										{this.props.config.categories.data.map((item, idx) => {
											return (
												<li className="nav-item" key={`navexplore_${idx}`}><a href={`/category/${item.tag}`} className="nav-link">{item.name}</a></li>
											);
										})}
									</ul>
								</li>

								{/* Tools dropdown */}

								<li className="nav-item dropdown">
									<a className="nav-link no-click text-shadow" href="/">Tools <i className="fas fa-angle-down ms-1" /></a>
									<ul className="dropdown-menu">

										<li key={`navtools_1`} className="nav-item needs-login" id="nft-create">
											{ _loggedIn ?
												( <a href="/" onClick={this.launchCRUD} className="nav-link" >Create a new NFT</a> )
												:
												( <a href="/" onClick={checkXummLoggedIn} className="nav-link" >Create a new NFT</a> )
											}

										</li>
										<li key={`navtools_2`} className="nav-item needs-login" id="collection-create"><a href={_urlPrefix + "/collections/add"} className="nav-link" onClick={checkXummLoggedIn}>Create a new collection</a></li>
										<li key={`navtools_52`} className="nav-item"><a href={`/tools/minter-check/`} className="nav-link">Set minter status</a></li>

									</ul>
								</li>

								{/* My Account dropdown */}
								<li className="nav-item dropdown">

									<a className="nav-link no-click text-shadow" href="/">Account <i className="fas fa-angle-down ms-1" /></a>

									<ul className="dropdown-menu">


										<li key={`myaccount_3`} className="nav-item" id="profile-own">
											<a href={_urlPrefix + "/"} className="nav-link" onClick={checkXummLoggedIn}>
												My NFTs
												{ _loggedIn ? ( <span className="badge bg-secondary ms-3">{this.props.config.userData.counts.purchased}</span>) : ( <></> )}
											</a>
										</li>

										<li key={`myaccount_1`} className="nav-item" id="profile-collections">
											<a href={_urlPrefix + "/collections/"} className="nav-link" onClick={checkXummLoggedIn}>
											My Collections
											{ _loggedIn ? ( <span className="badge bg-secondary ms-3">{this.props.config.userData.counts.collections}</span>) : ( <></> )}
											</a>
										</li>


										<li key={`myaccount_4`} className="nav-item" id="profile-created">
											<a href={_urlPrefix + "/created/"} className="nav-link" onClick={checkXummLoggedIn}>
												Items Created
												{ _loggedIn ? ( <span className="badge bg-secondary ms-3">{this.props.config.userData.counts.created}</span>) : ( <></> )}
											</a>
										</li>


										<li key={`myaccount_5`} className="nav-item" id="profile-selling">
											<a href={_urlPrefix + "/selling/"} className="nav-link" onClick={checkXummLoggedIn}>
											Items Selling
											{ _loggedIn ? ( <span className="badge bg-secondary ms-3">{this.props.config.userData.counts.selling}</span>) : ( <></> )}
											</a>
										</li>


										<li key={`myaccount_9`} className="nav-item" id="profile-nfts-offers">
											<a href={_urlPrefix + "/wallet-offers/"} className="nav-link" onClick={checkXummLoggedIn}>Offers & Approvals  { _loggedIn ? ( <span className="badge bg-secondary ms-3">{this.props.config.userData.counts.offersAll}</span>) : ( <></> )}</a>
										</li>


										<li key={`myaccount_8`} className="nav-item" id="profile-balances"><a href={_urlPrefix + "/balances/"} className="nav-link" onClick={checkXummLoggedIn}>Wallet Balances</a></li>



										<li key={`myaccount_99`} className="nav-item" id="profile-edit"><a href={_urlPrefix + "/edit/"} className="nav-link" onClick={checkXummLoggedIn}>Edit Profile</a></li>

									</ul>

								</li>


							</ul>

							{/* Navbar Search */}
							<ul className="navbar-nav ">

								{/*

								<li className="nav-item dropdown">
									<a className={"nav-link text-shadow px-0 pe-3" + (this.props.config.userData.cart.basketQty ? " basketfull" : "")} >
										<i className="fas fa-shopping-basket"></i> <i className="fas fa-angle-down ms-2" />
									</a>
									<ul className="dropdown-menu">
										<li key={`store_1`} className="nav-item">
											<a href="/store/" className="nav-link" onClick={checkXummLoggedIn}>Browse All Products</a>
										</li>
										<li key={`store_2`} className="nav-item">
											<a href="/store/?basket" className="nav-link" onClick={checkXummLoggedIn}>My Cart {this.props.config.userData.cart.basketQty ? `(${this.props.config.userData.cart.basketQty} Items)` : ``}</a>
										</li>
										<li key={`store_3`} className="nav-item">
											<a href="/store/?orders" className="nav-link" onClick={checkXummLoggedIn}>Previous Orders</a>
										</li>
									</ul>


								</li>
								*/}

								<li className="nav-item dropdown">
									<a className="nav-link text-shadow px-0 pe-3" key="dark-on" id="darkmode" >
										<i className={_iconClass}></i> <i className="fas fa-angle-down ms-2" />
									</a>
									<ul className="dropdown-menu justify-content-start">
										<li key={`dm_2`} className="nav-item">
											<a href="/" className="no-click nav-link justify-content-start" data-scheme="gold" onClick={this.switchDarkMode}><div className="menu-color-blob gold me-3"></div> The Gold Standard</a>
										</li>
										<li key={`dm_1`} className="nav-item">
											<a href="/" className="no-click nav-link justify-content-start" data-scheme="" onClick={this.switchDarkMode}><div className="menu-color-blob original me-3"></div> Original Light Mode</a>
										</li>
										<li key={`dm_3`} className="nav-item">
											<a href="/" className="no-click nav-link justify-content-start" data-scheme="red" onClick={this.switchDarkMode}><div className="menu-color-blob red me-3"></div> Fire Red</a>
										</li>
										<li key={`dm_6`} className="nav-item">
											<a href="/" className="no-click nav-link justify-content-start" data-scheme="green" onClick={this.switchDarkMode}><div className="menu-color-blob green me-3"></div> Apple Green</a>
										</li>
										<li key={`dm_4`} className="nav-item">
											<a href="/" className="no-click nav-link justify-content-start" data-scheme="blue" onClick={this.switchDarkMode}><div className="menu-color-blob blue me-3"></div> Icy Blue</a>
										</li>
										<li key={`dm_5`} className="nav-item">
											<a href="/" className="no-click nav-link justify-content-start" data-scheme="purple" onClick={this.switchDarkMode}><div className="menu-color-blob purple me-3"></div> Neon Purple</a>
										</li>
										{ window.screen.width > 1280 ?
										(<>
											<li key={`dm_blank`} className="nav-item my-1">
												<hr/>
											</li>
											<li key={`dm_6`} className="nav-item">
												<a href="/" className="no-click nav-link justify-content-start" data-state="wide" onClick={this.switchWidescreen}>
													<i className="fas fa-th me-3 menu-color-white"></i>
													{/*
													<span className={this.props.config.wideScreen ? "" : "hid"}>
														<i className="fas fa-check-circle me-4 mt-1"></i>
													</span>
													<span className={this.props.config.wideScreen ? "hid" : ""}>
														<i className="fas fa-circle me-4 mt-1"></i>
													</span>
													*/}
													Wide Screen Layout
													<span className={this.props.config.wideScreen ? "" : "hid"}>
														<i className="fas fa-check color-green ms-2 mt-1"></i>
													</span>
												 </a>
											</li>
											<li key={`dm_7`} className="nav-item">
												<a href="/" className="no-click nav-link justify-content-start" data-state="" onClick={this.switchWidescreen}>
													<i className="fas fa-th-large me-3 menu-color-white"></i>

													{/*
													<span className={this.props.config.wideScreen ? "hid" : ""}>
														<i className="fas fa-check-circle ms-2 mt-1"></i>
													</span>
													<span className={this.props.config.wideScreen ? "" : "hid"}>
														<i className="fas fa-circle ms-2 mt-1"></i>
													</span>
													*/}

													Standard layout

													<span className={this.props.config.wideScreen ? "hid" : ""}>
														<i className="fas fa-check color-green ms-2 mt-1"></i>
													</span>

												 </a>
											</li>
										</>) : (<></>)}

									</ul>

								</li>

								<li className="nav-item ">
									<a href="/" className="nav-link  px-0 pe-3 text-shadow" id="search-link" data-bs-toggle="modal" data-bs-target="#search">
										<i className="fas fa-search" />
									</a>
								</li>

								{/* sign out button */}


							</ul>

							{/* Navbar Toggler (Hamburger) */}
							<ul className="navbar-nav toggle ">

								<li className="nav-item">

										<a href="/" className="nav-link" id="menu-link" data-bs-toggle="modal" data-bs-target="#menu">
										{ ! _loggedIn ?
										(
											<i className="fas fa-user-circle mt-1" id="profile-img-small-2" alt="Profile" />
										)
										:
										(
											<img src={_profileImage} id="profile-img-small-1" alt="Profile" className="round-icon" />
										)}

										<i className="fas fa-bars toggle-icon m-0 ms-1 mt-1" />

										</a>

								</li>


							</ul>
							{/* Navbar Action Button */}



							{/* DESKTOP LOGIN AND TASKS */}
							{/* DESKTOP LOGIN AND TASKS */}
							{/* DESKTOP LOGIN AND TASKS */}
							<ul className="navbar-nav action d-none d-xl-block">
								<li className="nav-item ms-3" id="wallet-button">
								{ _loggedIn ?
								(
									<>
										<a href="/" className="btn btn-smaller ms-lg-auto btn-bordered no-wrap py-1 px-2 wallet-connected" data-bs-toggle="tooltip"  data-bs-html="true" data-bs-placement="bottom" title={"Logged in as '" + this.props.config.userData.title + "'<br />" +  this.props.config.userData.walletAddress} >
											<img src={_profileImage} id="profile-img-small-2" alt="Profile" className="me-md-2 round-icon" />
											<span className="me-md-2">Sign Out <i className="fas fa-sign-out-alt fa-lg ms-2 me-2"></i></span>
										</a>
									</> )
								:
								(		<button type="button" id="sign-in-button" onClick={addXummPayload} data-type="SignIn" className="btn btn-smaller ms-lg-auto btn-bordered no-wrap py-1 px-2">
											<p>
												<i className="fas fa-user-circle mt-1" id="profile-img-small-2" alt="Profile" />
												<span className="ms-md-2 mt-0 me-2">SIGN IN</span>
											</p>
										</button>)
									}

								</li>
							</ul>

						</div>
					</nav>

				</header>
			</>
        );
    }
}

export default Header;
