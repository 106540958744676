import React, { Component } from 'react';
import Swal from 'sweetalert2';

export class VideoLink extends Component {

	state = {
				"linkType":this.props.linkType,
				"tag":this.props.tag,
				"label":this.props.label,
				"videos":[
							{tag:"package-spinner-collection", id:""},
							{tag:"package-standard-collection", id:""},
							{tag:"connect-to-testnet", id:"tMnQY8ETcYk"},
							{tag:"signing-in", id:""},
							{tag:"explaining-royalties", id:""},
							{tag:"how-to-create-and-mint", id:""},
							{tag:"how-to-create-a-collection", id:""},
							{tag:"how-to-list-for-sale", id:""},
							{tag:"accepting-offers", id:""},
							{tag:"buying-an-nft", id:""},
							{tag:"edit-my-profile", id:"-LiD7SN55xY"},
							{tag:"spin-to-win", id:"TJuznCiAssg"},
							{tag:"editing-traits-nft", id:""},
							{tag:"editing-traits-collection", id:""},
							{tag:"", id:""},

						]

			}

	message = e =>
	{
		e.preventDefault()
		Swal.fire( { html:"<p>This video is temporarily unavailable.</p>" } )
	}

	render() {

		var videoID = "";

		for(var i=1; i <= this.state.videos.length; i++)
		{
			if(this.state.videos[i-1].tag === this.state.tag)
			{
				videoID = this.state.videos[i-1].id;
			}
		}

		const _link = "https://www.youtube.com/watch?v=" + videoID;

		return  (

					<>
						{ this.state.linkType === "youtube" ?
							(
								<>
									{ videoID.length ? (
										<a href={_link} className="btn btn-youtube w-100" target="_blank">
										<div className="w-100 text-center">
											<i className="fa-lg fab fa-youtube me-3"></i>
											{this.state.label}
										</div>
										</a>
									) : (
										<a className="btn btn-youtube w-100 d-flex" onClick={this.message}><i className="fab fa-youtube me-2"></i> {this.state.label}</a>
									) }
								</>

							)
							:
							(
								<>
									{ videoID.length ? (
										<a className="video-link" href={_link} target="_blank"><i className="fa-lg fab fa-youtube me-1"></i> {this.state.label}</a>
									) : (
										<a className="video-link" href="/" onClick={this.message}><i className="fa-lg fab fa-youtube me-1"></i> {this.state.label}</a>
									) }
								</>

							)
						}
					</>
				)


	}
}

export default VideoLink;
