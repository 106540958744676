import { Component } from 'react';
import {load} from "./config";

export default class ConfigLoader extends Component {
    constructor(props){
        super(props);
        this.state = {isLoaded: false, config:{}};
    }

	reloadConfig = e => {
		load().then(config =>
			{
				this.setState( {isLoaded: true, config} )
	 		});
	}

  componentDidMount() {

     // Once the configuration is loaded set `isLoaded` to true so we know to render our component
	 this.reloadConfig()

  }

  render() {

	// If we haven't yet loaded the config, show either a "splash" component provided via a `loading` props or return nothing.
    if( ! this.state.isLoaded) {
        return this.props.loading ? this.props.loading() : null;
    }

	if( this.state.isLoaded) {
		window.config = this.state.config;

    }

    // The config is loaded so show the component set on the `ready()` props
    return (
		<>
			{/* <button id="reloader" style={{"zIndex":9999, "position":"fixed"}} onClick={this.reloadConfig}>Reload user data</button> */}
			{ this.props.ready(this.state.config) }
		</>

		)

  }

}