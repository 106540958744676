import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import NFTBrowser from '../../components/NFT/cBrowse';


class featuredProjects extends Component {

	state = {
		profile:"",
		isFeaturedCollections:true,
		isMyCollection:false,
		cardClass:"col-sm-1 col-xs-1 col-md-4 col-lg-3"
	}

    render() {

		document.title = `${this.props.title} | ${this.props.config.meta.title}`;
        return (
            <div className="nft-latest">
                <Header 		config={ this.props.config } />

                <Breadcrumb 	config={ this.props.config } state={this.state} title={this.props.title} subpage={"NFTS"} shallow="true" page={this.props.title} background="null" />

				<NFTBrowser
								config={ this.props.config }
								results="0"
								dataType={this.props.dataType}
								title={this.props.title}
								showDefaultSortOption={this.props.showDefaultSortOption ? this.props.showDefaultSortOption : false}
								showSweep={false}
								state={this.state}
								collectionID=""
								infiniteScroll={true}
								breakPoints={this.props.config.masonryBreakpoints} />

                <Footer 		config={ this.props.config } />
                <ModalSearch 	config={ this.props.config } />
                <ModalMenu 		config={ this.props.config } />
                <Scrollup 		config={ this.props.config } />
            </div>
        );
    }
}

export default featuredProjects;