import React, { Component } from 'react';

class SpinnerBuyButtonComponent extends Component {

    render() {

		return (

			<div className="row">


				{ this.props.showPromoButton ?
					(
						<>
						{ this.props.spinInfo.promoCost === 0 ?
								(
									<>
										<button type="button" className="btn btn-bordered p-0 py-3 w-100" disabled={this.props.spinInfo.mySpins.promo > 0 || this.props.spinInfo.promoRunning === false || this.props.parentState.animating ? true : false} onClick={this.props.getFreeSpin}>Get Spin</button>
									</>
								)
								:
								(
									<>
										<button type="button" className="btn btn-bordered p-0 py-3 w-100" disabled={this.props.spinInfo.promoAllowed === false || this.props.qtyLeft === 0 || this.props.parentState.animating ? true: false} onClick={this.props.checkTotals} data-type="xrp" >Buy a Spin</button>
									</>

								)
								}
						</>
					)
					: (<></>)
				}

				{ this.props.showTokenButton ?
					( <button type="input" className="btn btn-bordered  px-0 py-3 w-100 mb-3" onClick={this.props.checkTotals} data-type="custom" disabled={this.props.qtyLeft === 0 || this.props.parentState.animating ? true : false}>Swap Tokens <span className="d-none d-md-inline-block">For Spins</span></button> )
				: (<></>)}

				{ this.props.showXRPButton ?
					(
						<>
							{ this.props.config.rs[4] ?
							(
								<>
									<div className="alert alert-warning text-center">
										As a temporary restriction, the ability to buy new spins with XRP is disabled.
									</div>
									<button type="input" className="btn  btn-bordered w-100 px-0"  disabled={true}>Buy Spins With XRP</button>
								</>
							)
							:
							(
								<button type="input" className="btn btn-bordered px-0 py-3 w-100"  onClick={this.props.checkTotals}  data-type="xrp" disabled={ ( this.props.qtyLeft === 0 || this.props.disabled || this.props.state.animating || this.props.state.collectionData.launched === false || this.props.parentState.animating ) ? true : false}>Buy a spin</button>
							)
							}
						</>
					)
					:
				(<></>)}

				{ this.props.showTotalMessage ?
				(
					<>
						{ this.props.showPromoButton ?
						(
							<p>aaa</p>
						) :
						(
							<>
								{ this.props.showTokenButton && this.props.showXRPButton ?
								(
									<p className="fw-bold text-center mt-3 mb-2" style={{fontSize:"12px"}}>There are {this.props.state.collectionData.spinnerInfo.spinsLeftCustom > 0 ? this.props.state.collectionData.spinnerInfo.spinsLeftCustom : 0} spins available to buy with {this.props.state.collectionData.trustLineCurrency} and {this.props.state.collectionData.spinnerInfo.spinsLeftXRP > 0 ? this.props.state.collectionData.spinnerInfo.spinsLeftXRP : 0} spins available to buy with XRP.</p>
								) :
								(
									<>
										{ this.props.showTokenButton ? (<p className="fw-bold text-center mt-3 mb-2">You have {this.props.parentState.spins} unused spins. <br className="d-md-none"/>{this.props.state.collectionData.spinnerInfo.spinsLeftCustom > 0 ? this.props.state.collectionData.spinnerInfo.spinsLeftCustom : 0} spins available.</p>) : ( <></>) }
										{ this.props.showXRPButton ? (<p className="fw-bold text-center mt-3 mb-2">You have {this.props.parentState.spins} unused spins. <br className="d-md-none"/>{this.props.state.collectionData.spinnerInfo.spinsLeftXRP > 0 ? this.props.state.collectionData.spinnerInfo.spinsLeftXRP : 0} spins available.</p>) : ( <></>) }
									</>
								) }
							</>
						)}


					</>
				)
				:
				( <></> )
				}


			</div>

		);
    }
}

export default SpinnerBuyButtonComponent;