import React, { Component } from 'react';
import { numberRounder } from '../xutils'
import CollectionDescription from './description';
import ModalCollectionStats from '../Modal/ModalCollectionStats';
import axios from 'axios';

import '../../css/collectionSlideOut.css'

class CollectionSlideOut extends Component {

	state = { loading:true, infoVisible: false, analysisData:{"sales":[], "description":"", "charts":[]}}

	joinChat = e =>
	{
		/* Trigger chat.js in footer */
		document.getElementById("joinChannel").dataset.id = this.props.state.collectionData.chatroom.idPK;
		document.getElementById("joinChannel").dataset.type = 'collection';
		document.getElementById("joinChannel").click();
	}


	showStats = e =>
	{

		window.showWaitMessage("Loading Stats..");

		this.setState({
			loading:true
		})

		const formData = new FormData( );
		formData.append("method", "getCollectionAnalysis")
		formData.append("collection", this.props.state.collectionData.id)
		formData.append("scheme", window.getCookie("darkModeScheme") )
		formData.append("darkmode", window.getCookie("darkMode") )

		const BASE_URL = this.props.config.apiServer + "/com/external/homePageData.cfc";

		axios.post(`${BASE_URL}`, formData, {withCredentials: true})
			.then(res => {

				if(res.data.success)
				{
					this.setState({
						analysisData: res.data.info,
						loading:false
					})

					document.getElementById('showStatsModal').click();
					window.setTimeout( () =>
					{
						window.dispatchEvent(new Event('resize'));
					}, 1000);

				}
				else
				{
					throw new Error(res.data.errorMessage)
				}

				window.hideWaitMessage()

			})
		.catch(err =>{ window.displayErrorMessage(err.message); window.logger(err.message) })
		//this.setState( { infoVisible: ! this.state.infoVisible } );

	}

    render() {

		const graphClass = this.props.config.chatEnabled || this.props.config.userData.display ? "col-2 col-md-1" : "col-4 col-md-2"
		const progressClass = this.props.state.collectionData.mintProgress >= 100 ? "progress-bar bg-success" : "progress-bar progress-bar-striped progress-bar-animated bg-warning"

		return (
			<>
				<div className="container-fluid" id="collectionSlider">

					<div className="container p-0">

						<div className="row" id="collectionRow">

							<div className="col-3 col-md-3 text-center">
								<div>
									{ this.props.state.collectionData.previewImage.indexOf("_pr.mp4") > -1 ?
										(
											<video loop="loop" muted="muted" autoPlay="autoplay" controls="controls" style={{width:"100%"}} className="collectionImage preload-image" poster={this.props.config.sizes.placeholders.loader} data-load={this.props.config.apiServer + this.props.state.collectionData.previewImage} id={"preload_cbrowse_slideout"}>
											</video>
										) : ( <a href={this.props.state.collectionData.fullURL}><img className="collectionImage" src={this.props.config.apiServer + this.props.state.collectionData.previewImage.replace("_440", "_320")}></img></a> )
									}
								</div>

								{/*
								{ window.location.href.indexOf("browse-all") > -1 ?
								(
									<a href={this.props.state.collectionData.fullURL}  className="btn btn-sm mt-4 py-2 w-75">
										<span className="d-none d-md-inline-block"><i className="fas fa-arrow-left"></i> Spin To Win</span>
									</a>
								)
								: (
									<>
									{ this.props.state.collectionData.spinner === 'Yes' ? ( <>Spinner</> ) : ( <></> )}
									</> )}
									*/}

							</div>

							<div className="col-9 col-md-9 text-center mt-2">




								<div className='row text-center w-100 g-2'>

									{ this.props.state.collectionData.og && this.props.state.collectionData.spinner === 'Yes' ?
									(
									<div className="col-12 m-2 mb-2 d-flex align-self-center pe-2" style={{"font-size":".9rem"}}>
										<span className='me-2 primary'>Mint Progress:</span>
										<div className="progress flex-grow-1 mt-1">
											<div className={progressClass} role="progressbar" aria-label="Mint Progress" aria-valuenow={this.props.state.collectionData.mintProgress} aria-valuemin="0" aria-valuemax="100" style={{"minWidth":"50px", "width":`${this.props.state.collectionData.mintProgress}%`}}>{`${this.props.state.collectionData.mintProgress}%`}</div>
										</div>
									</div>) : null}

									<div className="col-4 col-md-2 m-0">
										<div className="statBox">
										Sales <span className="fw-bold">{numberRounder(this.props.state.collectionData.sales)}</span>
										</div>
									</div>
									<div className="col-4 col-md-2 m-0">
										<div className="statBox">
										Volume <span className="fw-bold">{numberRounder(this.props.state.collectionData.volume)}</span>
										</div>
									</div>
									<div className="col-4 col-md-2 m-0">
										<div className="statBox">
										For Sale <span className="fw-bold">{numberRounder(this.props.state.collectionData.totalForSale)}</span>
										</div>
									</div>
									<div className="col-4 col-md-2 m-0">
										<div className="statBox">
										Items <span className="fw-bold">{numberRounder(this.props.state.collectionData.totalNfts - this.props.state.collectionData.totalBurned)}</span>
										</div>
									</div>
									<div className="col-4 col-md-2 m-0">
										<div className="statBox">
										Floor <span className="fw-bold">{numberRounder(this.props.state.collectionData.floorPrice)} XRP</span>
										</div>
									</div>
									<div className={`${graphClass} m-0`}>
										<div className="statBox statBoxIcon">
											<button type='button' className="nostyle m-0 p-0 infobutton" onClick={this.showStats}><i className="fas fa-chart-bar"></i></button>
										</div>
									</div>

									{ this.props.config.chatEnabled || this.props.config.userData.display ?
									(
										<div className="col-2 col-md-1 m-0 ">
											<div className="statBox statBoxIcon">
												<button type='button' className="nostyle m-0 p-0 infobutton" onClick={this.joinChat}><i className="fas fa-comments"></i></button>
											</div>
										</div>
									) : (<></>)}

									<div className="col-12 m-0 pt-2 d-none d-md-block">
									{ window.location.href.indexOf("browse-all") === -1 ?
										(
											<a className="text-center primary" style={{"fontSize":"90%"}} href={this.props.state.collectionData.fullURL + 'browse-all'}>View Collection Items <i className="fas fa-arrow-right" style={{"fontSize":"90%"}}></i></a>
										)
										: (
											<>
											{ this.props.state.collectionData.spinner === 'Yes' && this.props.state.collectionData.mintProgress < 100 ? ( <a className="text-center primary" style={{"fontSize":"90%"}} href={this.props.state.collectionData.fullURL}><i className="fas fa-arrow-left" style={{"fontSize":"90%"}}></i> Go to Collection Spinner to Win </a> ) : ( <></> )}
											</>
											)
									}
									</div>

								</div>


							</div>

							<div className="col-12 m-0 pt-2 text-center d-md-none">
							{ window.location.href.indexOf("browse-all") === -1 ?
								(
									<a className="text-center primary" style={{"fontSize":"90%"}} href={this.props.state.collectionData.fullURL + 'browse-all'}>View Collection Items <i className="fas fa-arrow-right" style={{"fontSize":"90%"}}></i></a>
								)
								: (
									<>
									{ this.props.state.collectionData.spinner === 'Yes' && this.props.state.collectionData.spinner === false ? ( <a className="text-center primary" style={{"fontSize":"90%"}} href={this.props.state.collectionData.fullURL}><i className="fas fa-arrow-left" style={{"fontSize":"90%"}}></i> Go to Collection Spinner to Win </a> ) : ( <></> )}
									</>
									)
							}
							</div>


						</div>
					</div>

					{ this.state.infoVisible === false ?
						(
							<></>
						)
						:
						(
							<>
								<div className="container g-0 p-0 collectionInfo">
									<CollectionDescription config={ this.props.config } state={this.props.state} />
								</div>
								<div className="row py-2 m-0 mb-3">
								<p className="text-center small">NOTE: Prices, volumes and counts are updated every 30 minutes.</p>

									<div className="col-12 text-center">
										<button type='button' className="btn btn-sm btn-bordered" onClick={this.toggleInfo}><i className="fas fa-chevron-up me-3 fa-lg"></i>Hide information<i className="fas fa-chevron-up ms-3 fa-lg"></i></button>
									</div>
								</div>
							</>
						)}
				</div>

				{/* The actual viewing section */}
				<ModalCollectionStats config={this.props.config} state={this.state}  />


			</>
		);
    }
}

export default CollectionSlideOut;