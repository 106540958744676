import React, { Component } from 'react';
import axios from 'axios';

class CollectionEditButtons extends Component {

	state = { keys:{"secret":"", "public":"", "xAddress":"", "explore":""} }

	launchCRUD = e =>
	{
		e.preventDefault()
		var _cid = typeof e.currentTarget.dataset.collection !== 'undefined' ? e.currentTarget.dataset.collection : "";
		document.getElementById("crud-nft-loader-button").dataset.collection = _cid;
		document.getElementById("crud-nft-loader-button").dataset.nft = "";
		document.getElementById("crud-nft-loader-button").click()
	}

    render() {

		return (

			<>
				{/* Admin and owner buttons */}
				{ ( this.props.config.userData.display || this.props.state.isMyCollection )
						&& this.props.config.urlParts[this.props.config.urlParts.length-1] !== 'edit'
						&& this.props.config.urlParts[this.props.config.urlParts.length-1] !== 'add'
						&& ( this.props.state.collectionData.id && this.props.state.collectionData.id.length > 20 ) ?
					(
						<div className="col-12 text-center py-3 tools">

							<a className="btn btn-bordered btn-sm py-1 me-3" href={`${this.props.config.userData.url}/collections/${this.props.state.collectionData.id}/edit`}>
								<span className="d-block d-md-none"><i className="fas fa-edit me-2"></i> Edit</span>
								<span className="d-none d-md-block"><i className="fas fa-edit me-2"></i> Edit Collection</span>
							</a>

							{ this.props.state.collectionData.bulk === 'Yes' ?
								(
									<>
									{/* <button className="btn btn-bordered btn-smaller me-3" onClick={this.showKeys} >
										<span className=""><i className="fas fa-key me-2"></i> View Keys</span>
									</button>
									*/}
									</>
								)
								:
								(
									<>
									{/* Only show create NFT to collection owner */}
									{	this.props.state.isMyCollection && this.props.state.collectionData.spinner !== "Yes" && this.props.state.collectionData.external !== "Yes" ?
										(
											<button className="btn btn-bordered btn-sm py-1" onClick={this.launchCRUD} data-collection={this.props.state.collectionData.id}>
												<span><i className="fas fa-plus-circle me-2"></i> New NFT</span>
											</button>
										) : ( <></> )}

									</>

								)
							}

						</div>
					)
					:
					( <></> )
				}
			</>
		);
    }
}

export default CollectionEditButtons;