import React, { Component } from 'react';
import axios from 'axios';
import ModalArchiveCollection from '../Modal/ModalArchiveCollection'
import 'axios-progress-bar/dist/nprogress.css'
import { VideoLink } from '../videoLink'
import { numberCheck, numberCheckInteger } from '../xutils';

import DateTimeField from '@1stquad/react-bootstrap-datetimepicker';

// import DatePicker from 'react-date-picker';
// import DatePicker from 'react-datepicker';
//import "react-datepicker/dist/react-datepicker.css";

class CollectionCrud extends Component {

	state = {
				buttonDisabled:this.props.state.disabled ? true : false,

				UTCStartTime:			0,
				UTCEndTime:				0,

				ss:			false,

				positions:[
					{"value":"middle", 		"text":"Central (Middle of image)"},
					{"value":"top", 		"text":"Top Edge (North)"},
					{"value":"topright", 	"text":"Top Right (North East)"},
					{"value":"right", 		"text":"Right Edge (East)"},
					{"value":"bottomright", "text":"Bottom Right (South East)"},
					{"value":"bottom", 		"text":"Bottom Edge (South)"},
					{"value":"bottomleft", 	"text":"Bottom Left (South West)"},
					{"value":"left", 		"text":"Left Edge (West)"},
					{"value":"topleft", 	"text":"Top Left (North West)"},
					{"value":"none", 		"text":"No watermark"}
				],

				spinnerTypes:[
					{"value":1, 		"text":"Standard Spinner", "disabled":false},
					{"value":2, 		"text":"Card Shuffle (Coming soon)", "disabled":true},
					{"value":3, 		"text":"Wheel of Fortune (Coming soon)", "disabled":true}
				],

				launchDurations:[
					{"value":0, 		"text":"No Presale - Just launch"},
					{"value":1, 		"text":"1 Hour"},
					{"value":2, 		"text":"2 Hours"},
					{"value":4, 		"text":"4 Hours"},
					{"value":6, 		"text":"6 Hours"},
					{"value":8, 		"text":"8 Hours"},
					{"value":10, 		"text":"10 Hours"},
					{"value":12, 		"text":"12 Hours"},

					{"value":24, 		"text":"1 Day"},
					{"value":48, 		"text":"2 Days"},
					{"value":72, 		"text":"3 Days"},
					{"value":96, 		"text":"4 Days"},
					{"value":120, 		"text":"5 Days"},
					{"value":144, 		"text":"6 Days"},
					{"value":168, 		"text":"7 Days"}
				],

				launchTimes:[
					{"value":0, 		"text":"12 Midnight"},
					{"value":1, 		"text":"1 AM"},
					{"value":2, 		"text":"2 AM"},
					{"value":3, 		"text":"3 AM"},
					{"value":4, 		"text":"4 AM"},
					{"value":5, 		"text":"5 AM"},
					{"value":6, 		"text":"6 AM"},
					{"value":7, 		"text":"7 AM"},
					{"value":8, 		"text":"8 AM"},
					{"value":9, 		"text":"9 AM"},
					{"value":10, 		"text":"10 AM"},
					{"value":11, 		"text":"11 AM"},
					{"value":12, 		"text":"12 Midday"},
					{"value":13, 		"text":"1 PM"},
					{"value":14, 		"text":"2 PM"},
					{"value":15, 		"text":"3 PM"},
					{"value":16, 		"text":"4 PM"},
					{"value":17, 		"text":"5 PM"},
					{"value":18, 		"text":"6 PM"},
					{"value":19, 		"text":"7 PM"},
					{"value":20, 		"text":"8 PM"},
					{"value":21, 		"text":"9 PM"},
					{"value":22, 		"text":"10 PM"},
					{"value":23, 		"text":"11 PM"}



				],
				mediaTypes:[
					{"value":"image", 		"text":"Images/Photos [e.g. GIF, JPG, PNG]"},
					{"value":"audio", 		"text":"Audio [e.g, MP3, OGG, WAV]"},
					{"value":"video", 		"text":"Video [e.g. MP4]"}
				],

				format: 'DD-MM-YY',
				inputFormat: 'DD MMMM YYYY HH:mm:ss a z',
				mode: 'datetime',
				startOfWeek: 'isoWeek',
				locale:"RU"

			};

	removeProperty = e =>
	{
		e.preventDefault()
		var _currentAttributes = this.props.state.collectionData.attributeData;
		var dataPos = Number(e.currentTarget.dataset.position)-1;

		_currentAttributes.splice(dataPos, 1)

		this.props.callback({field:"attributeData", value:_currentAttributes})

	}

	changeProperty = e =>
	{
		var _currentAttributes = this.props.state.collectionData.attributeData;
		var dataPos;

		if( Number(e.currentTarget.dataset.position) === 0)
		{

			//const now = new Date()
			//const tagNumber = Math.round(now.getTime() / 1000)

			const tagNumber = Math.floor(Math.random() * 1000000) + 1000000;


			_currentAttributes.push( {"tag":tagNumber, "value":""} )
			dataPos = _currentAttributes.length-1;

			window.setTimeout( ( pos = dataPos+1 ) => {
				var fld = "att_"+pos
				document.getElementById(fld).focus(); }, 1)
		}
		else
		{
			dataPos = Number(e.currentTarget.dataset.position)-1
		}

		var element = _currentAttributes[ Number(dataPos) ]
		if(e.currentTarget.dataset.type === 'trait' )
		{
			element.trait_type = "a";
		}

		element.value = e.currentTarget.value;

		this.props.callback({field:"attributeData", value:_currentAttributes})

	}

	archiveCollection = e =>
	{
		const BASE_URL = this.props.config.apiServer + "/com/external/collections.cfc?method=getDeletionStatus&uuid="+this.props.state.collectionData.id;

		axios.get(BASE_URL, { withCredentials: true })
            .then(res => {
				if( ! res.data.success)
				{
					throw(res.data.errorMessage);
				}
				else
				{
					if( ! res.data.info.deleteable)
					{
						window.displayErrorMessage(res.data.info.message);
					}
					else
					{
						/* Display the modal */
						window.$('#archiveModal').trigger("click")
					}

				}
            })
        .catch(err =>
				{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)
	}

	showSuper = e =>
	{
		this.setState({ss:true})
	}

	clearCloseDate = e =>
	{
		this.props.callback({subKey:"spinnerInfo", field:"closeDate", value:0})
	}

	handleChangeDisbursementAddress = e =>
	{
		this.props.callback({subKey:"", field:"disbursementAddress", value:e.currentTarget.value})
	}

	handleChangeCloseDate = theDate =>
	{

		/* Swap the button state */

		var theDateNew
		try
		{
			theDateNew = Number(theDate.getTime().toString().substring(0, 10))
		} catch(e)
		{
			alert("Err")
		}

		this.props.callback({subKey:"spinnerInfo", field:"closeDate", value:theDateNew})
		window.setTimeout(() => { this.validateForm(); }, 500)


	}

	handleChangeLaunchpadStart = theDate =>
	{

		/* Swap the button state */

		var theDateNew
		try
		{
			theDateNew = Number(theDate.getTime().toString().substring(0, 10))
		} catch(e)
		{
			alert("Err")
		}

		this.props.callback({subKey:"launchpadInfo", field:"startDate", value:theDateNew})
		window.setTimeout(() => { this.validateForm(); }, 500)


	}

	handleChangeLaunchpadDuration = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"launchpadInfo", field:"duration", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(); }, 500)
	}

	handleChangePostTokens = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"launchpadInfo", field:"postTokens", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(); }, 500)
	}

	handleChangePostXRP = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"launchpadInfo", field:"postXRP", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(); }, 500)
	}

	handleChangeGiveawayIncrements = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"", field:"gasMI", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(); }, 500)
	}

	handleChangeGiveawayMinPrice = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"", field:"gasMP", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(); }, 500)
	}

	handleChangeGiveawayMaxPurchases = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"", field:"gasPPW", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(); }, 500)
	}

	handleChangeGiveawaySpins = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"", field:"gas", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(); }, 500)
	}

	handleChangeReservedXRPSpins = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"launchpadInfo", field:"reservedXRPSpins", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(); }, 500)
	}


	handleChangeLaunchpadGuestList = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"launchpadInfo", field:"guestList", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(); }, 500)
	}

	handleChangeHasGuestList = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"launchpadInfo", field:"hasGuestList", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(); }, 500)
	}



	handleChangeNetwork = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"", field:"network", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(e); }, 500)
	}

	handleChangeHasJSON = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"bulkInfo", field:"bulkImportableHasJSON", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(e); }, 500)
	}


	handleChangeOffers = e => {
		this.props.callback({subKey:"", field:"offersEnabled", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(e); }, 500)

	}

	handleChangeHasRarity = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"bulkInfo", field:"bulkImportableHasRarity", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(e); }, 500)
	}

	handleChangeMediaType = e =>
	{
		/* Swap the button state */
		this.props.callback({subKey:"bulkInfo", field:"mediaType", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(e); }, 500)
	}

	changeIsSpinner = e => {
		this.props.callback({subKey:"", field:"spinner", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(e); }, 500)

	}


	changeIsLaunchpad = e => {
		this.props.callback({subKey:"", field:"launchpad", value:e.currentTarget.value})
		this.props.callback({subKey:"", field:"spinner", value:e.currentTarget.value === 'Yes' ? "Yes" : "No"})
		window.setTimeout(() => { this.validateForm(e); }, 500)

	}

	changeIsLaunchpad = e => {
		this.props.callback({subKey:"", field:"launchpad", value:e.currentTarget.value})
		this.props.callback({subKey:"", field:"spinner", value:e.currentTarget.value === 'Yes' ? "Yes" : "No"})
		window.setTimeout(() => { this.validateForm(e); }, 500)

	}

	handleTaxon = e => {
		this.props.callback({subKey:"", field:"taxon", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(e); }, 500)
	}


	setDemoCurrency = e =>
	{
		e.preventDefault()
		this.props.callback({subKey:"spinnerInfo", field:"customCurrency", value:"Yes"})
		this.props.callback({subKey:"spinnerInfo", field:"trustLine", value:"rshTb1stPwzaB9754K7p4zFPp3ySvyv1Re"})
		window.setTimeout(() => { this.checkTrustline() }, 200)
		window.setTimeout(() => { this.validateForm(e); }, 500)
	}

	handleCustomCurrency = e =>
	{
		if(this.props.state.collectionData.spinnerInfo.trustLineReturnAddress.length === 0)
		{
			this.props.callback({subKey:"spinnerInfo", field:"trustLineReturnAddress", value:this.props.state.collectionData.disbursementAddress})
		}
		this.props.callback({subKey:"spinnerInfo", field:"customCurrency", value:e.currentTarget.value})
		this.props.callback({subKey:"spinnerInfo", field:"trustLineTokensPerSpin", value:""})
		window.setTimeout(() => { this.validateForm(e); }, 500)
	}


	handleChangeSpins = e => {
		this.props.callback({subKey:"spinnerInfo", field:"trustLineTokensPerSpin", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(e); }, 500)

	}

	handleFormChange = e => {
		this.props.callback({subKey:e.currentTarget.dataset.scopesubkey, field:e.currentTarget.name, value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(e); }, 500)

	}

	handleChangeTrustline = e => {
		this.props.callback({subKey:"spinnerInfo", field:"trustLine", value:e.currentTarget.value})
		window.setTimeout(() => { this.validateForm(e); }, 500)

	}

	checkTrustline = e =>
	{

		var tVal = this.props.state.collectionData.spinnerInfo.trustLine;
		var _isValid;

		if(tVal.length)
		{

			_isValid = window.isValidAddress(tVal);

			if(!_isValid)
			{
				this.props.callback({subKey:"spinnerInfo", field:"trustLineStatus", value:"invalidaddress"})
			}
			else
			{

				window.showWaitMessage(`Checking trustline is configured`, false, true);

				/* Good valid address - Do a lookup to get its configuration */
				const BASE_URL = this.props.config.apiServer + "/com/external/wallet.cfc";

				const formData = new FormData();
				formData.append("walletAddress", this.props.state.collectionData.spinnerInfo.trustLineReturnAddress);
				formData.append("trustlineAddress", tVal);
				formData.append("method", "accountHasTrustlineSet");

				axios.post(BASE_URL, formData, { withCredentials: true })
					.then(res => {

						if( ! res.data.success)
						{
							throw(res.data.errorMessage);
						}
						else
						{
							if(res.data.trustlineSet)
							{
								this.props.callback({subKey:"spinnerInfo", field:"trustLineStatus", value:"verified"})
							}
							else
							{
								this.props.callback({subKey:"spinnerInfo", field:"trustLineStatus", value:"invalid"})
							}

							window.setTimeout(() => { this.validateForm(e); }, 500)
							window.hideWaitMessage();

						}
					})
				.catch(err =>
						{ window.hideWaitMessage(); window.displayErrorMessage(err); }
				)

			}

		}
		else
		{
			this.props.callback({subKey:"spinnerInfo", field:"trustLineStatus", value:""})
		}

	}

	validateForm = e => {

		/* Form validation */
		var _invalidFields = [];

		/* Work out times for launchpad */
		var UTCEnd = 0
		var UTCStart = 0

		var _errors = {general:0, spinner:0, launch:0, fpm:0, super:0};

		/* Handle any changes to dropdowns */
		try
		{
			if(e.currentTarget.tagName === 'SELECT')
			{
				if(e.currentTarget.id === 'category')				{ this.props.state.categoryID = e.currentTarget.value; }
				if(e.currentTarget.id === 'featured')				{ this.props.callback({subKey:"", field:"featured", value:e.currentTarget.value}) }
				if(e.currentTarget.id === 'burnable')				{ this.props.callback({subKey:"", field:"burnable", value:e.currentTarget.value}) }
				if(e.currentTarget.id === 'ga')						{ this.props.callback({subKey:"", field:"ga", value:e.currentTarget.value}) }
				if(e.currentTarget.id === 'cropPosition')			{ this.props.callback({subKey:"", field:"cropPosition", value:e.currentTarget.value}) }
				if(e.currentTarget.id === 'watermarkPosition')		{ this.props.callback({subKey:"", field:"watermarkPosition", value:e.currentTarget.value}) }
				if(e.currentTarget.id === 'private')				{ this.props.callback({subKey:"", field:"private", value:e.currentTarget.value}) }
				if(e.currentTarget.id === 'browseable')				{ this.props.callback({subKey:"", field:"br", value:e.currentTarget.value}) }
				if(e.currentTarget.id === 'hideunminted')			{ this.props.callback({subKey:"", field:"hu", value:e.currentTarget.value}) }
				if(e.currentTarget.id === 'importAllowDupeTitles')	{ this.props.callback({subKey:"bulkInfo", field:"importAllowDupeTitles", value:e.currentTarget.value}) }
				if(e.currentTarget.id === 'spinnerType')			{ this.props.callback({subKey:"spinnerInfo", field:"type", value:e.currentTarget.value}) }

			}
		}
		catch(err) {}

		/* These are the only always editable fields */
		if( window.$('#title').val().length < 3 )
		{
			_invalidFields.push({"field":"name", "message":"Collection name is required"})
			_errors.general++
		}
		if( window.$('#nft-img').prop("src").indexOf("placeHolders") > -1 )
		{
			_invalidFields.push({"field":"nft-img", "message":"You need to upload a collection image"})
			_errors.general++
		}

		if( window.$('#mediaType').val().length === 0 )
		{
			_invalidFields.push({"field":"mediaType", "message":"Choose which type of media is contained in this collection"})
			_errors.general++
		}

		/* These are only checked if collection is editable */
		if(this.props.state.bulkDisabled === false) {

			if( window.$('#category').val().length === 0 )
			{
				_invalidFields.push({"field":"category", "message":"Choose a category"})
				_errors.general++
			}

			if( Number(this.props.state.collectionData.defaultRoyalty) < 0 || Number(this.props.state.collectionData.defaultRoyalty) > this.props.config.fees.maximumRoyaltyPercent )
			{
				_invalidFields.push({"field":"defaultRoyalty", "message":`Enter a valid royalty (0-${this.props.config.fees.maximumRoyaltyPercent}%)`})
				_errors.general++
			}

			if( window.$('#urlPath').hasClass("invalid-url") || window.$('#urlPath').val().length === 0)
			{
				_invalidFields.push({"field":"urlPath", "message":"The vanity URL provided is invalid or not available"})
				_errors.general++
			}

			/* User can do spins - Enforce it */
			if(this.props.config.userData.spin)
			{
				if( window.$('#spinner').length && window.$('#spinner').val().length === 0 )
				{
					_invalidFields.push({"field":"spinner", "message":"Decide if this will be a spinner collection or not"})
					_errors.general++
				}

			}

			if( this.props.state.collectionData.spinner === 'Yes' && this.props.state.collectionData.ga !== 'Yes' )
			{
				/* Tokens per spin */
				var tokenSpins = Number( this.props.state.collectionData.spinnerInfo.trustLineTokensPerSpin )
				if( tokenSpins <= 0 || isNaN(tokenSpins) )
				{
					_invalidFields.push({"field":"trustLineTokensPerSpin", "message":"Cost per spin must be greater than zero"})
				}
			}

			if( this.props.state.collectionData.spinner === 'Yes' && Number(this.props.state.collectionData.spinnerInfo.type) === 0)
			{
				_invalidFields.push({"field":"type", "message":"Choose a spinner type"})
			}




			if( this.props.state.collectionData.spinner === 'Yes' && this.props.state.collectionData.totalMints === 0 && this.props.state.collectionData.ga !== 'Yes' )
			{

				/* If custom currency, ensure wallet address is correct */
				if( this.props.state.collectionData.spinnerInfo.customCurrency === 'Yes' )
					{
						if( this.props.state.collectionData.spinnerInfo.trustLine.trim().length === 0)
						{
							_invalidFields.push({"field":"trustLine", "message":"Trustline/Cold Wallet is required"})
						}
						else if( this.props.state.collectionData.spinnerInfo.trustLineStatus !== 'verified' )
						{
							_invalidFields.push({"field":"trustLine", "message":"Check trustline section for errors"})
						}

					}


				if( this.props.state.collectionData.launchpad === 'Yes' && this.props.state.collectionData.totalMints === 0 && Number(this.props.state.collectionData.launchpadInfo.duration)> 0 && this.props.state.collectionData.spinnerInfo.customCurrency !== 'Yes')
				{

					// var launchpadSpins = Number( window.$('#launchpadPriceAfterLaunch').val() ) // Kludge for controlled input not working
					var launchpadSpins = Number( this.props.state.collectionData.launchpadInfo.costAfterLaunch )

					if( launchpadSpins <= tokenSpins )
					{
						_invalidFields.push({"field":"launchpadPriceAfterLaunch", "message":"Post launchpad spin cost should be greater than the pre-sale cost"})
					}

					if( this.props.state.collectionData.launchpadInfo.hasGuestList === "Yes" && this.props.state.collectionData.launchpadInfo.guestList.length < 20)
					{
						_invalidFields.push({"field":"launchpadGuestList", "message":"Guest list needs providing"})
					}

				}


			}



		}


		if (this.props.state.collectionData.spinnerInfo.allowReShuffle === 'Yes')
		{
			if( Number(this.props.state.collectionData.spinnerInfo.extraSpinCost) <= 0 )
			{
				_invalidFields.push({"field":"mediaType", "message":"Need a reShuffle spin cost"})
			}
		}



		if (this.props.state.collectionData.launchpad === 'Yes')
		{
			if( Number(this.props.state.collectionData.launchpadInfo.startDate) === 0 )
			{
				_invalidFields.push({"field":"mediaType", "message":"We need a launch date for the launchpad/collection"})
			}
		}


		this.setState( { UTCStartTime:UTCStart, UTCEndTime:UTCEnd, buttonDisabled:_invalidFields.length ? true : false } )


		window.$('#collection-crud *').removeClass("fieldInvalid")

		var _html = "";
		window.$.each(_invalidFields, (idx, fld) => {
			_html += `<li><span>${fld.message}</span></li>`
			window.$('#'+fld.field).addClass("fieldInvalid").parent().find("span.error-message").remove().append(`<span className="error-message">Poo</span>`);
			window.$('#'+fld.field).append(`<span className="error-message">Poo</span>`);
		})

		if(_html.length)
		{
			window.$('.errorMessages').html(`<div class="card-header alert alert-danger"><strong>There are errors in the form. Please correct them before saving:-</strong></div><div class="card-body p-0"><ol class="mx-4 mb-4">${_html}</ol></div>`).removeClass("hid")
		}
		else
		{
			window.$('.errorMessages').html("").addClass("hid")
		}

		//this.setState({errors:_errors})


	}

	handleSubmit = e => {

		e.preventDefault();
		window.showWaitMessage(`<p>Saving Changes to Collection</p>${Number(this.props.state.collectionData.bulkInfo.importType) === 1 ? "<p><strong>This could take some time as you are uploading collection media. Do not navigate away until the upload has finished fully.</strong></p><p id='upload-progress'></p>" : ""}<p><img src="${this.props.config.sizes.placeholders.preloader}" /></p>`, false, true);

		const formData = new FormData( document.getElementById("collection-crud") );
		formData.append("attributes_json", JSON.stringify(this.props.state.collectionData.attributeData) );

		if( Number(this.props.state.collectionData.bulkInfo.importType) === 1)
		{
			/* Append upload fields to form data */
			if(this.state.selectedFileMetadata) { formData.append("bulkuploadfilemetadata", this.state.selectedFileMetadata, this.state.selectedFileMetadata.name ); }
			if(this.state.selectedFileImages) { formData.append("bulkuploadfileimages", this.state.selectedFileImages, this.state.selectedFileImages.name ); }
		}

		if( this.props.state.collectionData.launchpad === 'Yes')
		{
			formData.append("launchpadStart", this.props.state.collectionData.launchpadInfo.startDate);
			formData.append("launchpadStartDateUTC", this.state.UTCStartTime);
			formData.append("launchpadEndDateUTC", this.state.UTCEndTime);
		}

		if( this.props.state.collectionData.spinner === 'Yes')
		{
			formData.append("spinnerCloseDate", this.props.state.collectionData.spinnerInfo.closeDate);
		}

		const BASE_URL = this.props.config.apiServer + "/com/post.cfc?method=crudCollection";
		axios.post(BASE_URL, formData, {
										withCredentials: true,
										onUploadProgress: (progressEvent) => {
											const percentComplete = parseInt( (progressEvent.loaded / progressEvent.total ) * 100)
											const msg = percentComplete < 100 ? `Uploading ${percentComplete}%` : "Extracting data on server";
											window.$('#upload-progress').html( msg );
										 }
										})
            .then(res => {

				if( ! res.data.success)
				{
					throw(res.data.errorMessage);
				}
				else
				{
					window.showWaitMessage(`<p>Completed.. Reloading collections</p>`, false, true);
					document.location.href = res.data.redirect;
				}
            })
        .catch(err =>
				{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)

	}



	componentDidMount()
	{

		window.setTimeout( () => { window.$('#name').focus(); }, 600);

	}

	addMinutes(date, minutes) {
		return new Date(date.getTime() + minutes*60000);
	}

    render() {

		var dateValue, tDate, closeDate, closeDateMessage;
		var UTCStartTime = ""
		var UTCEndTime = ""
		var UTCLocale = ""

		/* Collection start */
		if(this.props.state.collectionData.launchpadInfo.startDate )
		{
			var startDate = new Date(0);
			startDate.setUTCSeconds(this.props.state.collectionData.launchpadInfo.startDate);
			dateValue = new Date(startDate)
			tDate = new Date()
		}
		else
		{
			tDate = new Date();
			dateValue = "";
		}


		/* Collection end */
		if(this.props.state.collectionData.spinnerInfo.closeDate)
		{
			var closeDateObj = new Date(0);

			closeDateObj.setUTCSeconds(this.props.state.collectionData.spinnerInfo.closeDate);
			closeDate = new Date(closeDateObj)

			UTCStartTime = closeDate.toLocaleString()
			UTCLocale = " (" + closeDate.toString().split("(")[1]

			closeDateMessage = `The collection will automatically be closed on ${UTCStartTime} ${UTCLocale}`
		}
		else
		{
			closeDate = ''
			closeDateMessage = `Select a date above if you want to automatically close this collection on certain date, calculate rarities & remove unminted NFTS`
		}


		var availableDates = []
		/*	for(var days = 0; days < 14; days++)
		{
			availableDates.push( new Date(tDate)  )
			tDate.addDays(1)
		}
		*/


		/* Work out messaging for the launch date */
		/* Work out messaging for the launch date */
		/* Work out messaging for the launch date */
		var launchMessage = "Choose a date from the calendar above & a pre-sale duration (If applicable). NOTE: Dates are chosen in UTC/GMT but will be displayed in your timezone."

		/* Convert start date to date object */
		if(this.props.state.collectionData.launchpadInfo.startDate > 0)
		{
			var startDate = new Date(0);
			startDate.setUTCSeconds(this.props.state.collectionData.launchpadInfo.startDate);

			if(this.props.state.collectionData.launchpadInfo.duration === 0)
			{
				endDate = startDate
			}
			else
			{
				var endDate = new Date(0);
				endDate.setUTCSeconds(this.props.state.collectionData.launchpadInfo.startDate);
				endDate = this.addMinutes(endDate,60 * this.props.state.collectionData.launchpadInfo.duration);
			}

			UTCStartTime = startDate.toLocaleString()
			UTCEndTime = endDate.toLocaleString()
			UTCLocale = " (" + endDate.toString().split("(")[1]

			if(this.props.state.collectionData.launchpadInfo.duration > 0)
			{
				launchMessage = `The collection pre-sale will start on ${UTCStartTime} and run for ${this.props.state.collectionData.launchpadInfo.duration} hour${ Number(this.props.state.collectionData.launchpadInfo.duration) > 1 ? "s" : ""} until ${UTCEndTime} ${UTCLocale}`
			}
			else
			{
				/* No pre sale - Get on with it */
				launchMessage = `The collection will launch on ${UTCStartTime} ${UTCLocale}`
			}

		}

		/*
		if(UTCStartTime.length)
		{
			if(this.props.state.collectionData.launchpadInfo.duration > 0)
			{
				launchMessage = `The collection pre-sale will start on ${UTCStartTime} and run for ${this.props.state.collectionData.launchpadInfo.duration} hour${ Number(this.props.state.collectionData.launchpadInfo.duration) > 1 ? "s" : ""} until ${UTCEndTime} ${UTCLocale}`
			}
			else
			{
				// No pre sale - Get on with it
				launchMessage = `The collection will launch on ${UTCStartTime} ${UTCLocale}`;
			}

		}*/


		const _saveMessage = this.props.state.temp ? "Save New Collection" : "Save Changes to Collection";
		const _title = this.props.state.temp ? "Create a New Collection" : "Edit Collection";

		var LPUsers = ['r9T5BNMLTVSCLzfZqW3x4Xc9msWRMSQvZh',
							'rKBBqQwtCqHnq85FeGNxQb9aSnZx2imaAy',
							'rPazt17sjE58uYs9tUp9zugFwYj2xkPjSZ',
							'rKi2rLyUw2hkUzbHi1C6NdijiZEFpQuPP8',
							'rD93sdNLMdt5htutQ6QztJs59cNE8vZn1c',
							'rTANGkuio1ufqmdrk2ghwLxffyz7SKU13',
							'rhdmdf3qMJGoYrZHtTFSDTawWCH6cLhQhr'
						];

		var isLPUser = LPUsers.indexOf(this.props.config.userData.walletAddress) > -1 || this.props.config.userData.display ? true : false;


        return (

            <section className="popular-collections-area">
                <div className="container p-0">

					<div className="text-center col-12" id="loading">
						<h3>Loading form</h3>
						<img src={this.props.config.sizes.placeholders.preloader} alt="Loading.." />
					</div>

					<form action="/profile/post/" method="post" id="collection-crud" className="item-form hid p-0 mt-4" onSubmit={this.handleSubmit} noValidate="novalidate">

						<input type="hidden" id="uuid" name="uuid" defaultValue={this.props.state.collectionData.id} />
						<input type="button" id="validate-form-button" className="hid" onClick={this.validateForm} />  {/* Used by pages/crud.js to force a validation */}

						<div className="row mb-3 pb-2 bigdots">
							<div className="col-12">
								<div className="intro d-flex justify-content-between align-items-end m-0">
									<div className="intro-content">
										<h3 className="m-0">{_title}</h3>
									</div>
								</div>
							</div>
						</div>

						<div className="row ">

							<div className="col-12 col-md-4 nft-upload-preview item-form drop-zone mb-4" id='collection-preview'>
								<p className="text-muted text-center">This image will be displayed on pages such as the home page, featured collections and your "My collections" page.</p>
								{ this.props.state.previewImage && this.props.state.previewImage.indexOf("_pr.mp4") > -1 ?
								(
									<>
										<video id="nft-img" loop="loop" autoPlay="autoplay" style={{width:"100%", borderRadius:"10px"}}>
											<source src={this.props.state.previewImage} type="video/mp4" loop="loop" autoPlay="autoplay" />
										</video>
									</> ) : ( <></> ) }

								{ this.props.state.previewImage && this.props.state.previewImage.indexOf("_pr.mp4") === -1 ?
								( <>
									<img id="nft-img" className="" src={this.props.state.previewImage} alt="" style={{borderRadius:"10px"}} />
									</> ) : ( <></> ) }

								<div id="nft-img-icon" title="Click to update your NFT">
								<div className="noInteraction">
									<span className="fa-stack">
										<i className="fas fa-cloud fa-stack-1x dropshadow"></i>
										<i className="fas fa-cloud-upload-alt fa-stack-1x"></i>
										</span>
									</div>
									<div className="text-center text-muted noInteraction">
										<strong>Tap the cloud <span className="d-none d-xl-block"> or drag an image onto it</span> to begin the media upload process for this NFT.</strong>
									</div>
								</div>
							</div>


							<div className="col-12 col-md-8 collection-details mb-4">

								<nav className="siteTabs">
									<div className="nav nav-tabs justify-content-center mt-4" id="nav-tab" role="tablist">
										<button className="nav-link no-width active" id="nav-general-tab" data-bs-toggle="tab" data-bs-target="#nav-general" type="button" role="tab" aria-controls="nav-general" aria-selected="false">General {this.props.state.errors.general ? ( <span><i className="fas fa-exclamation-triangle ms-2"></i> {this.props.state.errors.general}</span> ) : (<></>)}</button>
										<button className="nav-link no-width" disabled={this.props.state.collectionData.spinner === 'No'} id="nav-spinner-tab" data-bs-toggle="tab" data-bs-target="#nav-spinner" type="button" role="tab" aria-controls="nav-spinner" aria-selected="true">Spinner {this.props.state.errors.spinner ? ( <span><i className="fas fa-exclamation-triangle ms-2"></i> {this.props.state.errors.spinner}</span> ) : (<></>)}</button>
										<button className="nav-link no-width" id="nav-launchpad-tab" disabled={this.props.state.collectionData.launchpad === 'No'} data-bs-toggle="tab" data-bs-target="#nav-launchpad" type="button" role="tab" aria-controls="nav-launchpad" aria-selected="true">Launch {this.props.state.errors.launch ? ( <span><i className="fas fa-exclamation-triangle ms-2"></i> {this.props.state.errors.launch}</span> ) : (<></>)}</button>
										<button className="nav-link no-width" id="nav-promo-tab" disabled={this.props.state.collectionData.ga === 'No' || this.props.state.collectionData.spinner === 'No' } data-bs-toggle="tab" data-bs-target="#nav-promo" type="button" role="tab" aria-controls="nav-promo" aria-selected="true">FPM {this.props.state.errors.fpm ? ( <span><i className="fas fa-exclamation-triangle ms-2"></i> {this.props.state.errors.fpm}</span> ) : (<></>)}</button>
										<button className="nav-link no-width" id="nav-traits-tab" data-bs-toggle="tab" data-bs-target="#nav-traits" type="button" role="tab" aria-controls="nav-traits" aria-selected="true">Traits</button>
										{ this.props.config.userData.display ? (
											<button className="nav-link no-width" id="nav-superuser-tab" data-bs-toggle="tab" data-bs-target="#nav-superuser" type="button" role="tab" aria-controls="nav-superuser" aria-selected="true">Superuser {this.props.state.errors.super ? ( <span><i className="fas fa-exclamation-triangle ms-2"></i> {this.props.state.errors.super}</span> ) : (<></>)}</button>
										) : null }


									</div>
								</nav>


								<div className="tab-content container p-0 pt-3" id="nav-tabContent">

									{ /* General ... General ... General ... General ... General ... General ... */ }
									<div className="tab-pane fade show active" id="nav-general">

										<div className="row">

											<div className="col-12 mb-3">
												<div className="form-group">
													<label htmlFor="title" className="heading">Collection Name/Title <span className="req">*</span></label>
													<input type="text" className="form-control" name="title" id="title" autoFocus={true} autoComplete="off" placeholder="Descriptive title for your collection" onKeyUp={this.validateForm} required="required" defaultValue={this.props.state.collectionData.title}  data-scopesubkey="" onChange={this.handleFormChange} onBlur={this.blurredName} />
												</div>
											</div>

											<div className="col-12 mb-3">
												<div className="form-group">

													<label htmlFor="urlPath" className="form-label heading">Your Vanity URL <span className="req">*</span> <span className="text-muted" id="urlPath_check"></span></label>
													<div className="input-group">
														<span className="input-group-text" id="urlPath_prefix" style={{fontSize:"80%"}}>/collections/{this.props.config.userData.urlPath}/</span>
														<input type="text" className="form-control vanity-url valid-url" data-original={this.props.state.vanityOriginal} data-type="collections" name="urlPath" id="urlPath" disabled={ ( this.props.state.collectionData.totalMints && this.props.state.bulkDisabled === false ) && this.props.config.userData.display === false} aria-describedby="urlPath_prefix" autoComplete="off" maxLength={40} placeholder="" required="required" defaultValue={this.props.state.collectionData.urlPath} data-scopesubkey="" onKeyUp={this.handleFormChange} onChange={this.handleFormChange} />
													</div>

													{ ( this.props.state.collectionData.totalMints && this.props.state.bulkDisabled === false ) && this.props.config.userData.display === false ?
														( <p className="whyDisabled m-0 p-0 py-3 text-center">The ability to change the vanity URL is disabled as you have minted {this.props.state.collectionData.totalMints} NFT{this.props.state.collectionData.totalMints === 1 ? "" :"s"}.</p>)
													:
														(<></>)
													}

												</div>
											</div>


											<div className="col-12 mb-3">
												<div className="form-group">
													<label htmlFor="description" className="heading">Description</label>
													<textarea className="form-control" name="description" id="description" placeholder="Provide an awesome description to entice buyers to view the collection." cols={30} rows={3} defaultValue={this.props.state.collectionData.description} data-scopesubkey="" onChange={this.handleFormChange} />
												</div>
											</div>

											{ isLPUser || ( this.props.state.temp === false && this.props.state.collectionData.launchpad === 'Yes' )?
											(
												<div className="col-12 mb-3">
													<div className="form-group">
													<label htmlFor="bulk" className="heading">Do you want to run this collection through the LaunchPad?</label>

														<select id="launchpad" name="launchpad" className="form-select" disabled={this.props.state.bulkDisabled} value={this.props.state.collectionData.launchpad}  onChange={this.changeIsLaunchpad}>
															{ this.props.state.collectionData.launchpad === '' ? ( <option key="lpblank" value="">-- Choose --</option> ) : ( <></> ) }
															<option value="Yes" key="spYes">Yes</option>
															<option value="No" key="spNo">No</option>
														</select>

													</div>
												</div>
											) : ( <></>)
											}

											{ this.props.state.collectionData.launchpad === 'No' || isLPUser === false ?
												(
												<>
													<div className="col-12 mb-3">
														<div className="form-group">
														<label htmlFor="bulk" className="heading">Will this be a gamified collection? (Requires metadata and images)</label>

															<select id="spinner" name="spinner" className="form-select" disabled={this.props.state.bulkDisabled} value={this.props.state.collectionData.spinner}  onChange={this.changeIsSpinner}>
																{ this.props.state.collectionData.spinner === '' ? ( <option key="spblank" value="">-- Choose --</option> ) : ( <></> ) }
																<option value="Yes">Yes</option>
																<option value="No">No</option>
															</select>

														</div>
													</div>

												</>

												) : (
												<>
													<input type="hidden" id="spinner" name="spinner" value={this.props.state.collectionData.spinner} />
												</>
												)}

											{ this.props.state.collectionData.spinner === 'No' ?
											(
											<div className="col-12 form-group mb-3 hid ">
												<label htmlFor="offersEnabled" className="heading">Allow buyers to make unsolicited offers?</label>

												<select id="offersEnabled" name="offersEnabled" className="form-select" value={this.props.state.collectionData.offersEnabled} onChange={this.handleChangeOffers}>
													<option value="Yes">Yes</option>
													<option value="No">No</option>
												</select>
												<blockquote>
														By default, all buyers are able to make an offer to buy any "minted" NFT in this collection. If you don't want offers to be made, change this value to "No". Although xMart will not allow offers, this doesn't stop them being made elsewhere. Secondary sales are not restricted by this setting.
												</blockquote>
											</div>
											)
											:
											(
												<input type="hidden" id="offersEnabled" name="offersEnabled" value={this.props.state.collectionData.offersEnabled} />
											)
											}

											{/* Only display network dropdown on new collections */}
											{ this.props.state.temp ? (
												<>
												{ this.props.config.fees.networks.length > 1 ?
													(
														<div className="col-12 mb-3">
																							<div className="form-group">
																								<label htmlFor="mediaType" className="heading">Network <span className="req">*</span></label>
																								<select id="network" name="network" className="form-select" required="required" disabled={this.props.state.bulkDisabled || this.props.state.collectionData.totalNfts} onChange={this.handleChangeNetwork} defaultValue={this.props.state.collectionData.network}  value={this.props.state.collectionData.network}>
																									{this.props.config.fees.networks.map((item, idx) => {
																										const _key = `network_${idx}`;
																										return (
																											<option key={_key} value={item.id}>{item.network} - {item.currency}</option>
																										);
																									})}
																								</select>

																								{this.props.state.collectionData.totalNfts && this.props.state.bulkDisabled === false ?
																									( <p className="whyDisabled m-0 p-0 py-3 text-center">The ability to change network is disabled as you already have NFTs in the collection.</p>)
																								:
																									(<></>)
																								}

																							</div>

																						</div>
													)
													:
													(
														<input type="hidden" id="network" name="network" value={this.props.state.collectionData.network} />
													)
													}

												</>
												) : ( <></> )}


											<div className="col-12 col-md-7 mb-3">
												<div className="form-group">
													<label htmlFor="category" className="heading">Category <span className="req">*</span></label>
													<select id="category" name="category" className="form-select" required="required" disabled={this.props.state.bulkDisabled} onChange={this.validateForm} value={this.props.state.categoryID}>
														<option value="">- Choose a category -</option>
															{this.props.config.categories.data.map((item, idx) => {
																const _key = `cat_${idx}`;
																return (
																	<option key={_key} value={item.id}>{item.name}</option>
																);
															})}
													</select>
												</div>

											</div>


											<div className="col-12 col-md-5 mb-3 ps-0">
												<div className="form-group">
													<label htmlFor="defaultRoyalty" className="heading">Default Royalty (0 - {this.props.config.fees.maximumRoyaltyPercent}%) <span className="req">*</span></label>
													<div className="input-group mt-1">
														<input type="text" className="form-control numeric" name="defaultRoyalty" id="defaultRoyalty" disabled={this.props.state.bulkDisabled || this.props.state.collectionData.totalMints > 0} aria-describedby="defaultRoyalty_suffix" autoComplete="off"  min="0" max={this.props.config.fees.maximumRoyaltyPercent} placeholder={"0-" + this.props.config.fees.maximumRoyaltyPercent} onKeyPress={numberCheck} required="required" defaultValue={this.props.state.collectionData.defaultRoyalty} data-scopesubkey="" onChange={this.handleFormChange} />
														<span className="input-group-text" id="defaultRoyalty_suffix">%</span>
													</div>
													{this.props.state.collectionData.totalMints > 0 && this.props.state.bulkDisabled === false ?
													( <p className="whyDisabled m-0 p-0 py-3 text-center">Royalty cannot be changed as you have minted {this.props.state.collectionData.totalMints} NFT{this.props.state.collectionData.totalMints === 1 ? "" :"s"}.</p>)
												:
													(<></>)
												}

												</div>
											</div>


											<div className="col-12 mb-3">
												<div className="form-group">
													<label htmlFor="mediaType" className="heading">Collection Media Type <span className="req">*</span></label>
													<select id="mediaType" name="mediaType" className="form-select" required="required" disabled={this.props.state.bulkDisabled || this.props.state.collectionData.totalMints} onChange={this.handleChangeMediaType} value={this.props.state.collectionData.bulkInfo.mediaType}>
														{ this.props.state.temp ? ( <option key="mt_blank" value="">-- Choose a media type --</option> ) : (<></>) }
														{this.state.mediaTypes.map((item, idx) => {
															const _key = `mt_${idx}`;
															return (
																<option key={_key} value={item.value}>{item.text}</option>
															);
														})}
													</select>

													{this.props.state.collectionData.totalNfts && this.props.state.bulkDisabled === false ?
														( <p className="whyDisabled m-0 p-0 py-3 text-center">The ability to change media type is disabled as you already have NFTs in the collection.</p>)
													:
														(<></>)
													}

												</div>

											</div>

										</div>

									</div>



									{ /* Traits ... Traits ... Traits ... Traits ... Traits ... Traits ... */ }
									<div className="tab-pane fade mb-3" id="nav-traits">

										{/* Simple attributes editor */}
										{ this.props.state.collectionData.spinner.length ?
										(
											<div className="row">

												<div className="form-group mb-2 attribute-editor">
													<label htmlFor="price" className="heading">Attributes</label>

													{ this.props.state.collectionData.spinner == "No" || ( this.props.state.collectionData.spinner == "Yes" && this.props.state.collectionData.totalNfts > 0 ) ?
													(

														<>
															<div className="list-group">

																<div className="row text-center mb-2 fw-bold">
																	<div className="col-11">
																		Attribute Name
																	</div>
																	<div className="col-1">
																	</div>
																</div>

																{this.props.state.collectionData.attributeData.map((item, idx) => {
																	const _key = `coll_${idx}`;
																	return (
																		<div className="mb-2" key={"prop_"+idx}>
																			<div className="row">
																				<div className="col-11">
																					<input type="text" className="w-100 form-control" defaultValue={item.value} data-type="value" id={"att_" + (idx+1)} data-position={idx+1} onChange={this.changeProperty}  />
																				</div>
																				<div className="col-1 text-center pt-2">
																					{ this.props.state.attributesEditable ?
																					( <button className="btn w-100 btn-del" onClick={this.removeProperty} data-position={idx+1} tabIndex={idx+1000}><i className="fas fa-times-circle fa-lg" /></button> )
																					:
																					( <></> )
																					}
																				</div>
																			</div>

																		</div>
																	);
																})}

															</div>

															{ this.props.state.attributesEditable ?
															(
																<div key={"prop_0"}>
																	<div className="row">
																		<div className="col-11">
																			<input type="text" className="w-100 form-control" defaultValue="" placeholder="Property Value" data-type="name" data-position={0} onChange={this.changeProperty} />
																		</div>
																		<div className="col-1 text-center pt-2">
																			<button className="btn w-100 btn-del" disabled={true}><i className="fas fa-times-circle fa-lg" /></button>
																		</div>
																	</div>
																</div>
															)
															:
															(
																<blockquote className="mt-3"><strong>Attributes can no longer be added or removed, <span className="primary-text">only renamed</span> as the collection has more than one NFT.</strong></blockquote>
															)
															}
														</>
														) : ( <></>	)
													}

												</div>

												{/* Complex attributes editor */}
												{ 0 === 1 ?

													(
														<div className="form-group mt-3 attribute-editor">
															<label htmlFor="price" className="pb-2 heading">Attributes/Properties</label>

															<div className="list-group">
																<div className="row text-center mb-3 fw-bold">
																	<div className="col-6">
																		Tag
																	</div>
																	<div className="col-6">
																		Friendly Name
																	</div>
																</div>

																{this.props.state.collectionData.attributeData.map((item, idx) => {
																	const _key = `coll_${idx}`;
																	return (
																		<div className="mb-4" key={"prop_"+idx}>
																			<div className="row trait-row p-3">
																				<div className="col-2 text-center pt-2">
																					Trait
																				</div>
																				<div className="col-5">
																					<input type="text" className="w-100 form-control" value={item.tag}  readOnly={true} />
																				</div>
																				<div className="col-5">
																					<input type="text" className="w-100 form-control" defaultValue={item.value} data-type="value" id={"tv_" + (idx+1)} data-position={idx+1} onChange={this.changeProperty}  />
																				</div>
																			</div>

																			{item.attributes.map((subitem, idx) => {
																			const _key = `coll_${idx}`;
																			return (
																				<div className="mt-2" key={"prop_"+idx}>
																					<div className="row">
																						<div className="col-2 text-center pt-2">
																							<i className="fas fa-arrow-right"></i>
																						</div>
																						<div className="col-5">
																							<input type="text" className="w-100 form-control" value={subitem.tag} readOnly={true} />
																						</div>
																						<div className="col-5">
																							<input type="text" className="w-100 form-control" defaultValue={subitem.value} data-type="value" id={"tv_" + (idx+1)} data-position={idx+1} onChange={this.changeProperty}  />
																						</div>
																					</div>
																				</div>

																			);
																		})}
																		</div>

																	);
																})}

																<div key={"prop_0"}>
																	<div className="row">
																		<div className="col-5">
																			<input type="text" className="w-100 form-control" defaultValue="" placeholder="Property Name" data-type="trait" data-position={0} onChange={this.changeProperty} />
																		</div>
																		<div className="col-1 text-center pt-2">
																		=
																		</div>
																		<div className="col-5">
																			<input type="text" className="w-100 form-control" defaultValue="" placeholder="Property Value" data-type="value" data-position={0} onChange={this.changeProperty} />
																		</div>
																		<div className="col-1 text-center pt-2">

																		</div>
																	</div>
																</div>

															</div>

														</div>
													) : (<></>)
												}

												{ this.props.state.attributesEditable && this.props.state.collectionData.spinner === "No" ?
												(
													<>
														<blockquote>
															Attributes/Properties are used to add characteristics to your NFTs. For example, if you wanted to describe the attributes of a person you may want to add the following attributes:-
															<ol>
																<li><strong>Age</strong></li>
																<li><strong>Gender</strong></li>
																<li><strong>Hair</strong></li>
																<li><strong>Height</strong></li>
																<li><strong>Weight</strong></li>
															</ol>
															<span>
																For a more detailed explanation, refer to our <VideoLink label={"How to add traits to an collection"} tag={"editing-traits-collection"} config={this.props.config} /> training video.
															</span>
														</blockquote>

													</>
												)
												: (<></>)
												}

												{ this.props.state.collectionData.spinner === "Yes" && this.props.state.collectionData.totalNfts === 0 ?
												(
													<>
														<blockquote className="mt-0">
															Attributes/Properties for spinner collections must be provided in JSON format as part of the main bulk upload. For a more detailed explanation, refer to our <VideoLink label={"How to package a spinner collection"} tag={"package-spinner-collection"} config={this.props.config} /> training video.
														</blockquote>

													</>
												)
												: (<></>)
												}
											</div>
										) : ( <></> )}

									</div>



									{ /* Spinner ... Spinner ... Spinner ... Spinner ... Spinner ... Spinner ... */ }
									{ this.props.config.userData.spin === 'Yes'  ?
										(
											<div className="tab-pane fade mb-3" id="nav-spinner">

												{ this.props.state.collectionData.spinner === 'Yes' ?
													(

														<div className="row">

															<div className="col-12 col-md-6  mb-3">
																<div className="form-group">
																	<label htmlFor="spinnerType" className="heading">Display Type</label>
																	<select id="spinnerType" name="spinnerType" className="form-select" required="required" disabled={this.props.state.bulkDisabled} onChange={this.validateForm} value={this.props.state.collectionData.spinnerInfo.type}>
																		{ this.props.state.temp || this.props.state.collectionData.spinnerInfo.type === 0 ? ( <option key="dtblank" value="">-- Choose --</option> ) : ( <></> ) }
																		{this.state.spinnerTypes.map((item, idx) => {
																		const _key = `spinnerType_${idx}`;
																		return (
																		<option key={_key} value={item.value} disabled={item.disabled}>{item.text}</option>
																		);
																		})}
																	</select>
																</div>
															</div>

															<div className="col-12  col-md-6 mb-3">
																<div className="form-group">
																	<label htmlFor="spinnerType" className="heading">Allow ReShuffle</label>
																	<select id="allowReShuffle" name="allowReShuffle" className="form-select" required="required"  data-scopesubkey="spinnerInfo" onChange={this.handleFormChange} value={this.props.state.collectionData.spinnerInfo.allowReShuffle}>
																		{ this.props.state.collectionData.spinnerInfo.allowReShuffle === '' ? ( <option>-</option> ) : ( <></> )}
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																	</select>
																</div>
															</div>



															{ this.props.state.collectionData.spinnerInfo.allowReShuffle === 'Yes' ?
																(
																	<>
																	<div className="col-6 col-md-8 mb-3">
																		<div className="form-group">
																			<label htmlFor="customCurrency" className="heading">Re-Shuffle Options</label>
																			<div className="d-flex justify-content-evenly mt-1">
																			Cost per re-spin after the initial spin purchase (XRP Only)
																			</div>
																		</div>
																	</div>
																	<div className="col-6 col-md-4 mb-3">
																		<div className="form-group">
																			<label htmlFor="trustLineTokensPerSpin" className="text-center heading">Re-Shuffle Cost Per Spin <span className="req">*</span></label>
																			<div className="d-flex justify-content-evenly mt-1">

																				<input type="text"
																							className="form-control w-50 text-center"
																							name="extraSpinCost"
																							id="extraSpinCost"
																							autoComplete="off"
																							placeholder=""

																							onKeyDown={this.props.state.collectionData.spinnerInfo.customCurrency === 'Yes'  ? numberCheckInteger : null}
																							defaultValue={this.props.state.collectionData.spinnerInfo.extraSpinCost}

																							data-scopesubkey="spinnerInfo"
																							onChange={this.handleFormChange}


																							/>

																				<span className='mt-2 fw-bold'>XRP</span>

																			</div>
																		</div>
																	</div>
																	</>
																) : (<></>) }

															<div className="col-12 mb-3">
																<div className="form-group">
																	<label htmlFor="browseable" className="heading">Is The Collection Browseable?</label>
																	<select id="browseable" name="browseable" className="form-select" required="required" onChange={this.validateForm} value={this.props.state.collectionData.br}>
																		{ this.props.state.collectionData.br === '' ? ( <option>-</option> ) : ( <></> )}
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																	</select>
																	<p className="whyDisabled m-0 p-0 py-3 text-center">If you choose "No", the collection contents and the ability to browse the collection will be disabled.</p>

																</div>

															</div>

															{ this.props.state.collectionData.br === 'Yes' ?
															(
															<div className="col-12 mb-3">
																<div className="form-group">
																	<label htmlFor="hideunminted" className="heading">Hide unminted NFTs</label>
																	<select id="hideunminted" name="hideunminted" className="form-select" required="required" onChange={this.validateForm} value={this.props.state.collectionData.hu} disabled={this.props.state.bulkDisabled}>
																		{ this.props.state.collectionData.hu === '' ? ( <option>-</option> ) : ( <></> )}
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																	</select>
																	<p className="whyDisabled m-0 p-0 py-3 text-center">If you choose "Yes", only minted NFT's will be shown when browsing the collection.<br/>You will be able to see ALL NFTs though - Use a different wallet to browse the collection to test it.</p>

																</div>

															</div>
															) : ( <></>)
															}

															<div className="row mx-0 g-0">

																{/* this.props.config.serverMode === 'LOCAL' && this.props.state.bulkDisabled === false ?
																(
																	<div className="col-12 mb-3">
																		<button className="btn btn-bordered p-2 ms-2 w-100" onClick={this.setDemoCurrency}>Use XLD - Dev only</button>
																	</div>
																)
																: ( <></> )
																}*/}

																<div className="col-6 col-md-8 mb-3">
																	<div className="form-group">
																		<label htmlFor="customCurrency" className="heading">Spin Currency <span className="req">*</span></label>
																		<select id="customCurrency" name="customCurrency" className="form-select" disabled={this.props.state.bulkDisabled} value={this.props.state.collectionData.spinnerInfo.customCurrency} onChange={this.handleCustomCurrency}>
																			<option value="No">XRP</option>
																			<option value="Yes">Custom Currency (Trustline)</option>
																		</select>
																	</div>
																</div>

																<div className="col-6 col-md-4 mb-3">
																	<div className="form-group">
																		<label htmlFor="trustLineTokensPerSpin" className="text-center heading">Cost Per Spin <span className="req">*</span></label>
																		<div className="d-flex justify-content-evenly mt-1">


																			<input type="text"
																						className="form-control w-50 text-center"
																						name="trustLineTokensPerSpin"
																						id="trustLineTokensPerSpin"
																						disabled={this.props.state.bulkDisabled}
																						autoComplete="off"
																						placeholder=""
																						onKeyDown={this.props.state.collectionData.spinnerInfo.customCurrency === 'Yes'  ? numberCheckInteger : null}
																						defaultValue={this.props.state.collectionData.spinnerInfo.trustLineTokensPerSpin}
																						onChange={this.handleChangeSpins} />

																			<span className='mt-2 fw-bold'>{this.props.state.collectionData.spinnerInfo.customCurrency === 'Yes' ? "Tokens" : "XRP"}</span>

																		</div>
																	</div>
																</div>



																{ this.props.state.collectionData.spinnerInfo.customCurrency === 'Yes' ?
																(
																	<>
																		<div className="col-12 col-md-8 mb-3">
																			<div className="form-group">
																				<label htmlFor="trustLine" className="heading">Trustline Address (Cold Wallet) <span className="req">*</span></label>
																				<input type="text" className="form-control" name="trustLine" id="trustLine" disabled={this.props.state.bulkDisabled}  autoComplete="off" placeholder="" onKeyUp={this.validateForm} defaultValue={this.props.state.collectionData.spinnerInfo.trustLine} onChange={this.handleChangeTrustline} />

																				<div className="mt-3">
																					{ this.props.state.collectionData.spinnerInfo.trustLineStatus === "verified" 			? ( <span className="validText"><i className="fas fa-check fa-lg me-2"></i> Trustline is set</span>) : ( <></>)}
																					{ this.props.state.collectionData.spinnerInfo.trustLineStatus === "invalidaddress"		? ( <span className="errorText"><i className="fas fa-exclamation-triangle fa-lg me-2"></i> Invalid Address</span>) : ( <></>)}
																					{ this.props.state.collectionData.spinnerInfo.trustLineStatus === "invalid" 			? ( <span className="errorText"><i className="fas fa-exclamation-triangle fa-lg me-2"></i> INVALID</span>) : ( <></>)}
																					{ this.props.state.collectionData.spinnerInfo.trustLineStatus === "" 					? ( <span className="errorText">Trustline is required</span>) : ( <></>)}
																				</div>
																			</div>
																		</div>
																		<div className="col-12 col-md-4 mb-3">
																			<div className="form-group">
																				<label className="heading">Validity Check</label>
																				<div className="text-center mt-2">
																					<button type="button" className="btn btn-bordered ms-2 w-75 p-0 py-2 px-3" onClick={this.checkTrustline}>Verify</button>
																				</div>
																			</div>
																		</div>

																		{ this.props.state.collectionData.spinnerInfo.trustLineStatus === "invalid" ?
																		(
																			<div className="col-12 errorText mb-4">
																				A trustline for the custom currency must be set up on the return address
																			</div>
																		) : ( <></>)}

																		<div className="col-12 mb-3">
																			<div className="form-group">
																				<label htmlFor="trustLineReturnAddress" className="heading">Return Address</label>
																				<input type="text" className="form-control" name="trustLineReturnAddress" id="trustLineReturnAddress" defaultValue={this.props.state.collectionData.spinnerInfo.trustLineReturnAddress} readOnly={true} disabled={true} />
																			</div>
																			<blockquote>
																				This is the wallet address that tokens will be returned to after tokens are swapped for spins. You must have the trustline set on this wallet to allow the return of the tokens.
																			</blockquote>
																		</div>
																	</>
																) : (<></>) }

																{ Number(this.props.state.collectionData.spinnerInfo.type) > 0 ?
																(
																	<div className="col-12 mb-3">
																		<div className="form-group">
																			<label htmlFor="ga" className="heading">Run the collection in "Fair Price Minting" or FREEBIE mode?</label>
																			<select id="ga" name="ga" className="form-select" value={this.props.state.collectionData.ga} data-scopesubkey="" onChange={this.handleFormChange} disabled={this.props.state.bulkDisabled}>
																				{ this.props.state.collectionData.ga === '' ? ( <option>-</option> ) : ( <></> )}
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>
																		<p className="whyDisabled m-0 p-0 py-3 text-center">"Fair Price Minting" mode allows you to distribute limited amounts of NFTs for free or a minimum price. Buyers will then be able to buy at your minimum price or pay a higher "fair mint" price.</p>
																	</div>
																)
																:(<></>)}





															</div>

														</div>
													) : ( <></> )
												}


											</div>
										) : ( <></> )}

									{ /* Launchpad ... Launchpad ... Launchpad ... Launchpad ... Launchpad ... Launchpad ... */ }
									<div className="tab-pane fade mb-3" id="nav-launchpad">

										<div className="row">

											<div className="col-12 col-md-6 mb-3">
												<div className="form-group">
												<label htmlFor="bulk" className="heading">Collection Launch Date</label>
												<DateTimeField
														onChange={this.handleChangeLaunchpadStart}
														dateTime={dateValue}
														format={this.state.format}
														minDate={this.state.minimalDate}
														inputFormat={this.state.inputFormat}
														viewMode={this.state.mode}
														startOfWeek={this.state.startOfWeek}
														availableDates={availableDates}
														disabled={this.props.state.bulkDisabled}
														inputProps={{"className":"form-control mt-1"}}
													/>
												</div>
											</div>

											<div className="col-6 col-md-6 mb-3">
												<div className="form-group">
												<label htmlFor="launchpadDuration" className="heading">Pre-Sale Duration</label>

												<select id="launchpadDuration" name="launchpadDuration" className="form-select" required="required" disabled={this.props.state.bulkDisabled} onChange={this.handleChangeLaunchpadDuration} value={this.props.state.collectionData.launchpadInfo.duration}>
													{ this.props.state.temp  ? ( <option key="dublank" value="">-- Choose --</option> ) : ( <></> ) }
													{this.state.launchDurations.map((item, idx) => {
													const _key = `launchduration_${idx}`;
													return (
													<option key={_key} value={item.value}>{item.text}</option>
													);
													})}
												</select>

												</div>

											</div>

											<div className="col-12 mb-3">
												<blockquote className="m-0">{launchMessage}</blockquote>
											</div>

											{ this.props.state.collectionData.launchpadInfo.duration > 0 ?
												 (
													<>
														<div className="col-6 col-md-8 mb-3">
															<div className="form-group">
																<label htmlFor="customCurrency" className="heading">Post pre-sale</label>
																<p className="mt-3">What price will be set for XRP purchases after pre-sale?</p>
															</div>
														</div>

														<div className="col-6 col-md-4 mb-3">
															<div className="form-group">
																<label htmlFor="costAfterLaunch" className="text-center heading">Cost Per Spin <span className="req">*</span></label>
																<div className="d-flex justify-content-evenly mt-1">
																		<input type="text"
																					className="form-control w-50 text-center"
																					name="costAfterLaunch"
																					id="costAfterLaunch"
																					autoComplete="off"
																					placeholder=""


																					defaultValue={this.props.state.collectionData.launchpadInfo.costAfterLaunch}
																					data-scopesubkey="launchpadInfo"
																					onChange={this.handleFormChange}

																					/>

																	<span className='mt-2 fw-bold'>XRP</span>
																</div>
															</div>
														</div>

														{ this.props.state.collectionData.spinnerInfo.customCurrency === 'Yes' ?
														(
															<>
																<div className="col-6 col-md-8 mb-3">
																	<div className="form-group">
																		After the pre-sale has ended, will you allow spin purchase using project tokens?
																	</div>
																</div>

																<div className="col-6 col-md-4 mb-3">
																	<div className="form-group">
																		<select id="launchpadPostSaleUseTokens" name="launchpadPostSaleUseTokens" className="form-select" value={this.props.state.collectionData.launchpadInfo.postTokens} onChange={this.handleChangePostTokens}>
																			{ this.props.state.collectionData.launchpadInfo.postTokens === '' ? ( <option>-</option> ) : ( <></> )}
																			<option value="Yes">Yes</option>
																			<option value="No">No</option>
																		</select>
																	</div>
																</div>
															</>
														)
														: ( <></> )
														}


														{ this.props.state.collectionData.launchpadInfo.postTokens === 'Yes' &&  this.props.state.collectionData.spinnerInfo.customCurrency === 'Yes' ?
														(
															<>
																<div className="col-6 col-md-8 mb-3">
																	<div className="form-group">
																		How many spins will be reserved for people using XRP?
																	</div>
																</div>

																<div className="col-6 col-md-4 mb-3">
																	<div className="form-group">
																		<div className="d-flex justify-content-evenly mt-1">
																			<input 	type="text"
																					className="form-control w-100 text-center"
																					onKeyDown={numberCheckInteger}
																					name="launchpadReservedXRPSpins"
																					id="launchpadReservedXRPSpins"
																					autoComplete="off"
																					placeholder=""
																					defaultValue={this.props.state.collectionData.launchpadInfo.reservedXRPSpins}
																					onChange={this.handleChangeReservedXRPSpins} />
																		</div>
																	</div>
																</div>

																<div className="col-12 mb-3"><blockquote className="m-0">Once the collection is uploaded, the number of spins available for XRP will be deducted from the total number of NFTs leaving those spins available for purchase using the project token.</blockquote></div>

															</>)
														: (<></>)}


														<div className="col-6 col-md-8 mb-3">
															<div className="form-group">
																<label className="heading">Guest List</label>
																<p className="mt-3">Will you be restricting the pre-sale to a list of wallets?</p>
															</div>
														</div>

														<div className="col-6 col-md-4 mb-3">
															<div className="form-group">
																<label htmlFor="launchpadHasGuestList" className="heading">&nbsp;</label>
																<select id="launchpadHasGuestList" name="launchpadHasGuestList" className="form-select" disabled={this.props.state.bulkDisabled} value={this.props.state.collectionData.launchpadInfo.hasGuestList} onChange={this.handleChangeHasGuestList}>
																{ this.props.state.temp  ? ( <option key="glblank" value="">-- Choose --</option> ) : ( <></> ) }
																	<option value="No">No</option>
																	<option value="Yes">Yes</option>
																</select>
															</div>
														</div>


														{ this.props.state.collectionData.launchpadInfo.hasGuestList === 'Yes' ?
														(
															<div className="col-12 mb-3">
																<div className="form-group">
																	<label htmlFor="launchpadGuestList" className="heading">Guest List (One wallet per line)</label>
																	<textarea id="launchpadGuestList" name="launchpadGuestList" className="form-control" disabled={this.props.state.bulkDisabled} value={this.props.state.collectionData.launchpadInfo.guestList} onChange={this.handleChangeLaunchpadGuestList} />
																</div>
															</div>
														) : ( <></> )}

													</>

												 ) : (
													<></>
												 )}

										</div>

									</div>



									{ /* FPM ... FPM ... FPM ... FPM ... FPM ... FPM ... */ }
									<div className="tab-pane fade mb-3" id="nav-promo">

										<div className="row">

											<div className="col-12 col-md-6 mb-3">
												<div className="form-group">
													<label className="form-label heading">Maximum NFTs allocated to giveaway</label>
													<input 	type="number"
																	className="form-control text-center"
																	onKeyDown={numberCheckInteger}
																	name="gaSpins"
																	id="gaSpins"
																	autoComplete="off"
																	placeholder=""
																	defaultValue={this.props.state.collectionData.gas}
																	onChange={this.handleChangeGiveawaySpins} />

													<p className="whyDisabled m-0 p-0 py-3 text-center">This cannot exceed the number of unminted NFTs</p>

												</div>
											</div>

											<div className="col-12 col-md-6 mb-3">
												<div className="form-group">
													<label className="form-label heading">Minimum XRP per NFT</label>
													<input 	type="number"
																	className="form-control w-100 text-center"
																	name="gaMinimumPrice"
																	id="gaMinimumPrice"
																	autoComplete="off"
																	placeholder=""
																	defaultValue={this.props.state.collectionData.gasMP}
																	onChange={this.handleChangeGiveawayMinPrice} />

													<p className="whyDisabled m-0 p-0 py-3 text-center">Zero will enable FREEBIE mode</p>

												</div>
											</div>

											<div className="col-12 col-md-6 mb-3">
												<div className="form-group">
													<label className="form-label heading">Minimum price increment</label>
													<input 	type="number"
																	className="form-control w-100 text-center"
																	name="gaMinimumIncrement"
																	id="gaMinimumIncrement"
																	autoComplete="off"
																	placeholder=""
																	disabled={Number(this.props.state.collectionData.gasMP) === 0 ? true : false}
																	defaultValue={this.props.state.collectionData.gasMI}
																	onChange={this.handleChangeGiveawayIncrements} />

													<p className="whyDisabled m-0 p-0 py-3 text-center"> e.g. 1 XRP, 0.5 XRP</p>

												</div>
											</div>

											<div className="col-12 col-md-6 mb-3">
												<div className="form-group">
													<label className="form-label heading">Maximum purchases per wallet</label>
													<input 	type="number"
																	className="form-control w-100 text-center"
																	onKeyDown={numberCheckInteger}
																	name="gaMaxPurchasesPerWallet"
																	id="gaMaxPurchasesPerWallet"
																	autoComplete="off"
																	placeholder=""
																	disabled={Number(this.props.state.collectionData.gasMP) === 0 ? true : false}
																	defaultValue={this.props.state.collectionData.gasPPW}
																	onChange={this.handleChangeGiveawayMaxPurchases} />

													<p className="whyDisabled m-0 p-0 py-3 text-center">&nbsp;</p>

												</div>
											</div>


										</div>

									</div>



									{ /* SuperUser ... SuperUser ... SuperUser ... SuperUser ... SuperUser ... SuperUser ... */ }
									{ this.props.config.userData.display ?
										(

											<div className="tab-pane fade mb-3" id="nav-superuser">

												<div className="row">

													<div className="col-12 col-md-6 mb-3">
														<div className="form-group">
															<label htmlFor="xx" className="form-label heading">Collection taxon</label>
															<input type="text" className="form-control" disabled={true} defaultValue={this.props.state.collectionData.xTaxon} />
														</div>
													</div>

													<div className="col-12 col-md-6 mb-3">
														<div className="form-group">
															<label className="form-label heading">Custom Taxon</label>
															<input type="text" className="form-control" name="taxon" id="taxon" disabled={this.props.state.collectionData.totalMints ? true : false} autoComplete="off" placeholder="" defaultValue={ Number(this.props.state.collectionData.taxon) > 0 ? this.props.state.collectionData.taxon : ""} onChange={this.handleTaxon} />
														</div>
													</div>


													<div className="col-12 col-md-6 mb-3">
														<div className="form-group">
															<label className="form-label heading">Feature project on the home page?</label>
															{/* <YesNo value={this.props.state.collectionData.featured} dataObject={this.props.state.collectionData} id="featured" /> */}
															<select id="featured" name="featured" className="form-select" value={this.props.state.collectionData.featured} onChange={this.validateForm}>
																{ this.props.state.collectionData.featured === '' ? ( <option>-</option> ) : ( <></> )}
																<option value="Yes">Yes</option>
																<option value="No">No</option>
															</select>
														</div>
													</div>

													<div className="col-12 col-md-6 mb-3">
														<div className="form-group">
															<label className="form-label heading">Allow NFTs to be burnable?</label>
															<select id="burnable" name="burnable" className="form-select" disabled={this.props.state.bulkDisabled || this.props.state.collectionData.totalMints} value={this.props.state.collectionData.burnable} onChange={this.validateForm}>
																{ this.props.state.collectionData.burnable === '' ? ( <option>-</option> ) : ( <></> )}
																<option value="Yes">Yes</option>
																<option value="No">No</option>
															</select>
														</div>
													</div>

													{ this.props.state.temp === false ?
													(
														<div className="col-12 mb-3">
															<div className="form-group">
																<label className="form-label heading">Disbursement Address</label>
																<input type="text" className="form-control" name="disbursementaddress" id="disbursementaddress" autoComplete="off" placeholder="r Address to receive funds" required="required" defaultValue={this.props.state.collectionData.disbursementAddress} onChange={this.handleChangeDisbursementAddress} />
															</div>
														</div>
													) : (<></>)}


													<div className="col-12 col-md-6 mb-3">
														<div className="form-group">
															<label className="form-label heading">Private/Hidden Collection?</label>
															<select id="private" name="private" className="form-select" value={this.props.state.collectionData.private} onChange={this.validateForm}>
																{ this.props.state.collectionData.private === '' ? ( <option>-</option> ) : ( <></> )}
																<option value="Yes">Yes</option>
																<option value="No">No</option>
															</select>
														</div>
													</div>

													<div className="col-12 col-md-6 mb-3">
														<div className="form-group">
															<label className="form-label heading">Allow duplicate titles in import?</label>
															<select id="importAllowDupeTitles" name="importAllowDupeTitles" className="form-select" value={this.props.state.collectionData.bulkInfo.importAllowDupeTitles} onChange={this.validateForm}>
																{ this.props.state.collectionData.bulkInfo.importAllowDupeTitles === '' ? ( <option>-</option> ) : ( <></> )}
																<option value="Yes">Yes</option>
																<option value="No">No</option>
															</select>
														</div>
													</div>


													<div className="col-12 col-md-6 mb-3">
														<div className="form-group">
															<label className="form-label heading">Spin Share Percent</label>
															{/*
															<select id="spinShare" name="spinShare" className="form-select" required="required" data-scopesubkey="spinnerInfo" onChange={this.handleFormChange}  value={this.props.state.collectionData.spinnerInfo.spinShare}>
																{[...Array(51)].map((x, i) =>
																	<option key={"spinShare_"+i} value={i}>{i}%</option>
																)}

															</select>
															*/}

															<input type="text"
																							className="form-control text-center"
																							name="spinShare"
																							id="spinShare"
																							autoComplete="off"
																							placeholder=""

																							onKeyDown={numberCheck}
																							defaultValue={this.props.state.collectionData.spinnerInfo.spinShare}

																							data-scopesubkey="spinnerInfo"
																							onChange={this.handleFormChange}


																							/>

														</div>
													</div>

													<div className="col-12 col-md-6 mb-3">
														<div className="form-group">
															<label className="form-label heading">Reshuffle Share Percent</label>
															{/*
															<select id="shuffleShare" name="shuffleShare" className="form-select" required="required" data-scopesubkey="spinnerInfo" onChange={this.handleFormChange}  value={this.props.state.collectionData.spinnerInfo.shuffleShare}>
																{[...Array(51)].map((x, i) =>
																	<option key={"shuffleShare"+i} value={i}>{i}%</option>
																)}

															</select>
															*/}

															<input type="text"
																							className="form-control text-center"
																							name="shuffleShare"
																							id="shuffleShare"
																							autoComplete="off"
																							placeholder=""

																							onKeyDown={numberCheck}
																							defaultValue={this.props.state.collectionData.spinnerInfo.shuffleShare}

																							data-scopesubkey="spinnerInfo"
																							onChange={this.handleFormChange}


																							/>

														</div>
													</div>

													<div className="col-6 mb-3">
														<div className="form-group">
															<label htmlFor="bulk" className="heading">Collection Auto-Close Date</label>
															<DateTimeField
																	onChange={this.handleChangeCloseDate}
																	dateTime={closeDate}
																	format={this.state.format}
																	minDate={this.state.minimalDate}
																	inputFormat={this.state.inputFormat}
																	viewMode={this.state.mode}
																	startOfWeek={this.state.startOfWeek}
																	availableDates={availableDates}
																	inputProps={{"className":"form-control mt-1"}}
																/>

														</div>
													</div>




													<div className="col-2 mb-3">
														<div className="form-group">
															<label htmlFor="bulk" className="heading"></label>
															{ closeDate > 0 ? ( <button type="button" onClick={this.clearCloseDate} className="no-style primary mt-4"><i className="fas fa-times fa-lg"></i></button> ) : ( <></> )  }


														</div>

													</div>

													<div className="col-12 mb-3">
														<blockquote className="m-0">{closeDateMessage}</blockquote>
													</div>

												</div>

											</div>
										)
										: ( <></> )
									}


								</div>

							</div>













							<div className="errorMessages card col-12 p-0 border hid nohover"></div>

							<div className="col-12">
								<button className="btn w-100 mt-3 mt-sm-4" id="save-button" disabled={this.state.buttonDisabled} type="submit">{ _saveMessage }</button>
							</div>

							{ this.props.state.temp ? (<></>) : (
								<div className="col-12 text-center">
									<button className="btn btn-muted w-50 mt-3 mt-sm-4" id="save-button" type="button" onClick={this.archiveCollection}>Delete and archive collection</button>
								</div>
							)}



						</div>
					</form>

				</div>

				{ this.props.state.temp ? (<></>) : (
					<>
						<ModalArchiveCollection 	config={ this.props.config } state={this.props.state} />
						<button type="button" className="hid" id="archiveModal" data-bs-toggle="modal" data-bs-target="#archive-modal">Show modal</button>
					</>
				)}

			</section>
        );
    }
}


export default CollectionCrud;