import React, { Component } from 'react';

class comingSoon extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
    }
    render() {
        return (
            <section className="single featured post-details">
                <div className="container">
                    <div className="row">

							<div className="col-12 align-self-center">
								<h1 className="primary text-center">Coming Soon</h1>
							</div>

                    </div>
                </div>
            </section>
        );
    }
}

export default comingSoon;