import React, { Component } from 'react';

import Swal from 'sweetalert2';
import { setupXmartDropzones, loginCheck, uuidv4 } from '../../components/xutils';
import axios from 'axios';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import CollectionCrud from '../../components/Collections/crud';

var currentDate = new Date();
class editCollection extends Component {



	state = {
			saveButton:"Create collection",
			editMessage:"Create New Collection",
			pageName:"Create",
			saveDisabled:true,
			errors:{general:0, spinner:0, launch:0, fpm:0, super:0},

			categoryID:"",
			vanityOriginal:"",
			previewImage:'/img/placeHolders/upload.png',
			backgroundImage: this.props.config.sizes.placeholders.collectionBanner,
			temp: this.props.collectionID.length ? false : true,
			bulkDisabled:false,
			attributesEditable:true,

			collectionData:{
						id: this.props.collectionID.length ? this.props.collectionID : uuidv4(),
						attributes:[],
						attributeData:[],
						description:"",
						urlPath:"",
						title:"",
						bulk:"No",
						br:"Yes",
						spin:"No",
						spinner:"No",
						launchpad:"No",
						watermarkPosition:"",
						lfe:"Yes",
						network:this.props.config.fees.defaultNetwork,
						bulkInfo: {
							importingFailed: "No",
							importURLMeta: "",
							importURLMedia: "",
							importing: "No",
							importErrors: {},
							importType: null,
							bulkImportableHasJSON: null,
							cropPosition:"",
							trustLine:"",
							trustLineTokensPerSpin:0,
							customCurrency:"XXX",
							mediaType:""
						},
						spinnerInfo:{
							customCurrency:"",
							trustLineTokensPerSpin:0,
							trustLine:"",
							trustLineReturnAddress:this.props.config.userData.walletAddress,
							trustlineStatus:"",
							extraSpinCost:"",
							closeDate:0,
							type:0,
							allowReShuffle:"No",
							spinnerType:0
						},
						launchpadInfo:{

							startDate:				0,
							endDate:				0,
							startHour:				-1,
							duration:				-1,
							costAfterLaunch:		0,
							hasGuestList:			"",
							guestList:				"",
							reservedXRPSpins:		"",
							postTokens:				"",
							postXRP:				""
						},
						featured:"No",
						private:"No",
						purchase:"No",
						burnable:"No",
						defaultRoyalty:'',
						headerImage:"",
						importing:"No",
						totalNfts:0,
						totalMints:0,
						previewImage :"",
						ga:"No",
						hu:"No",
						taxon:0,
						category:{"tag":"", "id":"", "name":""}
					}
	}


	/* Populate state data from child components */
	callback = statedata => {

		var _collectionData = this.state.collectionData;
		if(statedata.subKey && statedata.subKey.length)
		{
			_collectionData[statedata.subKey][statedata.field] = statedata.value;
		}
		else
		{
			_collectionData[statedata.field] = statedata.value;
		}

		this.setState({collectionData:_collectionData})

	}


	componentDidMount() {

		/* Ensure we're allowed to view this page */
		loginCheck( this.props.config );

		/* Load collection data */
		if( ! this.state.temp )
		{
			//$('h2,#breadcrumb-page').html("Edit an existing NFT");

			document.title = "Edit collection: " + this.state.collectionData.id;


			this.setState({
				saveButton:"Save Collection",
				editMessage:"Edit Collection",
				pageName:"",
				temp:false,
				})

			var BASE_URL = this.props.config.apiServer + "/com/external/collections.cfc?method=getCollection&includeNFTS=false&collection="+this.state.collectionData.id;

			axios.get(`${BASE_URL}`, {withCredentials: true})
				.then(res => {

					document.title = "Edit collection: " + res.data.info.title;

					if( res.data.info.creator.id !== this.props.config.userData.id && this.props.config.userData.display === false)
					{
						window.$('#collection-crud').hide();
						window.showWaitMessage("Unable to edit another users collection. Redirecting to your profile.", false, true)
						document.location.href = this.props.config.userData.url;

					}

					if(!res.data.success)
					{
						Swal.fire( { html:res.data.errorMessage } )
						/* Hide form */
						window.$('#collection-crud').hide();
					}
					else
					{

						if( ! res.data.info.bulkInfo)
						{
							res.data.info.bulkInfo = {
								importingFailed: "No",
								importURLMeta: "",
								importURLMedia: "",
								importing: "No",
								importErrors: {},
								importType: 0,
								cropPosition:"",
								trustLine:"",
								trustLineTokensPerSpin:0
							}
						}

						/* Copy attributes into simpler, useable fomat */
						var theseTraits = [];
						for (const [key, trait] of Object.entries(res.data.info.attributes)) {

							/* Get attributes */
							var theseAttributes = [];
							for (const [subkey, attribute] of Object.entries(trait.attributes)) {
								theseAttributes.push( {"tag":subkey, "value":attribute.name} )
							}

							theseTraits.push( {"tag":key, "value":trait.name, "attributes":theseAttributes} )
						}

						res.data.info.attributeData = theseTraits

						var attributesEditable = false;
						if(res.data.info.totalNfts === 0)
						{
							attributesEditable = true;
						}





						this.setState({
							previewImage: 		res.data.info.previewImage.length ? this.props.config.apiServer + res.data.info.previewImage : this.props.config.sizes.placeholders.collectionPreview,
							headerImage : 		res.data.info.headerImage.length ? this.props.config.apiServer + res.data.info.headerImage : this.props.config.sizes.placeholders.collectionBanner,
							pageName:			res.data.info.title,
							attributesEditable:	attributesEditable,
							collectionData:		res.data.info,
							categoryID:			res.data.info.category.id,
							bulk:				res.data.info.bulk,
							featured:			res.data.info.featured,
							bulkDisabled:		res.data.info.lfe === "Yes" ? true : false,
							vanityOriginal:		res.data.info.urlPath
							})

						setTimeout(() => {
							//$('#uuid').val( this.props.config.urlParts[4] )

							setupXmartDropzones("collectionBanner", this.props.config); /* jQuery in main.js */
							setupXmartDropzones("collectionPreview", this.props.config); /* jQuery in main.js */

							window.setTimeout(() => {
								window.$('#validate-form-button').trigger("click") /* Ensure button clickble or not */
								window.$('#loading').addClass("hid");
								window.$('#collection-crud').removeClass("hid");
							}, 800)




						}, 0)

					}
				})
			.catch(err => window.logger(err))
		}
		else
		{

			document.title = "Create New collection";

			/* Just set up dropzone - New Collection */
			setTimeout(() =>
			{
				//setupXmartDropzones("profile", config); /* jQuery in main.js */

					window.$('#validate-form-button').trigger("click");
					window.$('#loading').addClass("hid");
					window.$('#collection-crud').removeClass("hid");

					setupXmartDropzones("collectionBanner", this.props.config); /* jQuery in main.js */
					setupXmartDropzones("collectionPreview", this.props.config); /* jQuery in main.js */

				}, 500)

		}

	}


    render() {

		return (
            <div className="main">
                <Header					config={ this.props.config }/>
                <Breadcrumb				config={ this.props.config } state={this.state} title={this.state.editMessage} subpage="" page="" background="null" />
				<CollectionCrud			config={ this.props.config } state={this.state} callback={this.callback} />
                <Footer					config={ this.props.config } />
                <ModalSearch			config={ this.props.config } />
                <ModalMenu				config={ this.props.config } />
                <Scrollup				config={ this.props.config } />
            </div>
        );
    }
}

export default editCollection;

Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + parseInt(days));
    return this;
};
