import React, { Component } from 'react';

class CollectionSpinnerFailedComponent extends Component {

	render() {

		return (


			<div className="row mt-5">

				<div className="col-12">
					<h2 className="mt-5 text-center">The media for the collection has errors</h2>
					{
						this.props.config.userData.display || this.props.state.isMyCollection ?
						(
							<>
								<p>The following errors have occurred while importing the ZIP file:-</p>
								<ol style={{"listStyle":"decimal"}}>
									{
										this.props.state.collectionData.bulkInfo.importErrors.map((item, idx) => {
											return (
												<li key={`tdo_${idx}`} className="single-tab-list align-items-center">
													{ typeof item.file !== 'undefined' ?
													(
														<div dangerouslySetInnerHTML={{ __html:`<strong>${item.file}</strong> - ${item.error}` }}></div>
													) : (
														<div dangerouslySetInnerHTML={{ __html:`<strong>${item}</strong>`} }></div>
													)}

												</li>
											);
										})
									}
								</ol>
							</>
						)
						:
						( <><p className="text-center mt-5">If you are the owner of this collection, please log in to view any errors.</p></> )
					}
					{
						this.props.config.userData.display ?
						(
							<>
								<p className="text-center">ADMIN MESSAGE: Once uploaded or if you suspect it's broken, <a href={this.props.config.apiServer + "/com/cron/tasks/imports.cfm?id=" + this.props.state.collectionData.xTaxon} rel="noreferrer" target="_blank">click here to check the import progress for errors</a></p>
							</>
						)
						:
						( <></> )
					}
				</div>
			</div>

        );
    }
}

export default CollectionSpinnerFailedComponent;