import React, { Component } from 'react';
import axios from 'axios';

class CollectionSpinnerWalletAuthorise extends Component {

	state = {
        qrCode: ""
    }

	authorise = e =>
	{
		window.showWaitMessage("Redirecting to Authorisation Page");

		const _request = {
			component:"post",
			method:"authoriseMinter",
			redirect:document.location.href
		}

		window.callAPI(
			_request,
			function (_jsonData)
			{
				if(_jsonData.success)
				{
					document.location.href = _jsonData.info.redirect;
				}
				else
				{
					window.displayErrorMessage("Authorise error - " + _jsonData.errorMessage)
				}
			}
		)

	}


	render() {

		return (

			<div className="row mt-5">

				<div className="col-12">
					<h2 className="mt-5 text-center">Wallet needs configuring</h2>
					<p>To enable xMart to mint NFTs on your behalf you need to approve us to do this. Simply click the button below and approve the transaction in your wallet. once this is complete, you will be able to continue populating the collection.</p>

					<div className="text-center">
						<button type="button" className="btn" onClick={this.authorise}>Authorize in Xumm</button>
					</div>


						{/*
							this.state.qrCode.length ?
							(
								<p className="text-center">
									<img src={this.state.qrCode} style={{"maxWidth":"300px", "border":"3px solid #444", "borderRadius":"8px"}} width="300" height="300" alt="Xumm QR Code" />
								</p>
							) :
							(
								<><p>Loading QR Code</p></>
							)
						*/}


				</div>
			</div>

        );
    }
}

export default CollectionSpinnerWalletAuthorise;