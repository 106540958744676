import React from "react";
import '../../css/termsAndConditions.css'

const PrivacyTerms = () => {
	return (
		<div className="container termsAndConditions">

			<div className="row mb-3 mt-4 pb-2 bigdots">
				<div className="col-12">
					<div className="intro d-flex justify-content-between align-items-end m-0">
						<div className="intro-content">
							<h3 className="m-0">Privacy Policy</h3>
						</div>
					</div>
				</div>
			</div>

			{/*

			https://www.textfixer.com/html/convert-word-to-html.php
			https://www.textfixer.com/html/convert-word-to-html.php
			https://www.textfixer.com/html/convert-word-to-html.php
			https://www.textfixer.com/html/convert-word-to-html.php
			https://www.textfixer.com/html/convert-word-to-html.php
			https://www.textfixer.com/html/convert-word-to-html.php

			*/}


			<p>
				xMart Marketplace website is operated by xMart, (hereinafter,
				“xMart”,“We”, or “Us”). These terms and conditions (the “Terms”) govern
				your access to the xMart marketplace website https://xmart.art any
				mobile applications, and other services owned, controlled, or offered by
				xMart, now or in the future (all collectively referred to as, the “
				<strong>Services</strong>“). The term “<strong>You</strong>” or “
				<strong>User</strong>” shall refer to any individual that views, uses,
				accesses, browses or submits any content or material to the Services.{" "}
			</p>

			<p>
				Since we may gather certain types of information about our users, we
				feel you should fully understand our policy and the terms and conditions
				surrounding the capture and use of that information. This Privacy Policy
				discloses what information we gather and how we use it. The private
				information you provide on the Services will be used only for its
				intended purpose.
			</p>

			<p>
				<strong>
					BY VISITING, SIGNING UP, USING, BROWSING, OR ACCESSING THE SERVICES,
					YOU CONSENT TO THE DATA PRACTICES DESCRIBED IN THIS STATEMENT. IF YOU
					DO NOT AGREE WITH OUR PRIVACY PRACTICES, DO NOT USE THE SERVICES.
				</strong>
			</p>

			<h2>
				<strong>INFORMATION WE COLLECT</strong>
			</h2>

			<p>
				We may collect information, including:{" "}
				<strong>Personal Information </strong>and{" "}
				<strong>Non-Personal Information</strong>, when you interact with us and
				the Services, for example when you:
			</p>

			<p>· Access or use the Services;</p>

			<p>· Open or respond to our e-mails;</p>

			<p>· Contact customer service;</p>

			<p>· Visit any page online that displays our ads or content;</p>

			<p>· Make a purchase on or through the Services (if applicable);</p>

			<h2>
				<strong>PERSONAL INFORMATION</strong>
			</h2>

			<p>
				“<strong>Personal Information</strong>” means information about you that
				specifically identifies you or, when combined with other information we
				have, can be used to identify you. As a general rule, we do not collect
				Personal Information about you when you visit and/or use the Services,
				unless you choose to provide such information to us. Submitting Personal
				Information through the Services is voluntary. By doing so, you are
				giving us your permission to use the information for the stated purpose.{" "}
			</p>

			<p>
				· <strong>LEGAL BASIS FOR COLLECTING YOUR PERSONAL INFORMATION</strong>
			</p>

			<p>
				We collect, process and use your Personal Information for the purposes
				described in this Privacy Policy, based at least on one of the following
				legal grounds:
			</p>

			<p>
				· <strong>With your consent: </strong>We ask for your agreement to
				process your information for specific purposes and you have the right to
				withdraw your consent at any time.{" "}
			</p>

			<p>
				· <strong>When Performing this Agreement: </strong>We collect and
				process your Personal Information in order to provide you with the
				Services, following your acceptance of this Privacy Policy; to maintain
				and improve our Services; to develop new services and features for our
				Users; and to personalise the Services in order for you to get a better
				user experience.{" "}
			</p>

			<p>
				· <strong>Legitimate interests: </strong>We process your information for
				our legitimate interests while applying appropriate safeguards that
				protect your privacy. This means that we process your information for
				things like detecting, preventing, or otherwise addressing fraud, abuse,
				security, usability, functionality or technical issues with, protecting
				against harm to the rights, property or safety of our properties, or our
				users, or the public as required or permitted by law; enforcing legal
				claims, including investigation of potential violations of this Privacy
				Policy; in order to comply and/or fulfil our obligation under applicable
				laws, regulation, guidelines, industry standards and contractual
				requirements, legal process, subpoena or governmental request, as well
				as our Terms and our billing process.
			</p>

			<p>
				· <strong>INFORMATION WE COLLECT AND HOW WE COLLECT INFORMATION</strong>
			</p>

			<p>
				Through the Services, we may collect information that can identify you
				when you voluntarily submit it to us. Your Personal Information may
				include:
			</p>

			<p>· Name;</p>

			<p>· Mailing Address;</p>

			<p>· Email address;</p>

			<p>· Phone number;</p>

			<p>· Demographic Data (such as age, gender, zip code)</p>

			<p>· Other similar information</p>

			<p>
				· <strong>STORAGE OF PERSONAL INFORMATION</strong>
			</p>

			<p>
				We will take reasonable precautions, as well as physical, technical, and
				organisational measures in accordance with industry standards, as
				described herein, to protect your Personal Information from loss,
				misuse, unauthorised access, disclosure, alteration, or destruction.
				Computer safeguards, such as firewalls and data encryption may be used
				to protect your information. We authorise access to your information
				only for those employees or agents who require it to fulfil their job
				responsibilities and these individuals are required to treat this
				information as confidential.
			</p>

			<p>
				However, the security of information on or transmitted via the Internet
				cannot be guaranteed. Unauthorised entry of use, hardware or software
				failures, and other factors may compromise the security of your Personal
				Information. All information you send to us electronically or through
				email is not secure. Any transmission is at your own risk as the
				transmission of information via the Internet is not completely secure.{" "}
			</p>

			<h2>
				<strong>NON-PERSONAL INFORMATION</strong>
			</h2>

			<p>
				We collect and temporarily store certain information about your usage of
				the Services. Non-Personal Information means information that alone
				cannot identify you, including data such as cookies, pixel tags, web
				beacons and device information. The information includes, without
				limitation:
			</p>

			<p>
				· <strong>Device Data</strong>: We may collect information such as: the
				type of computer and/or mobile device you use; the unique device ID of
				your computer and/or mobile device; the IP address of your computer
				and/or mobile device; the operating system of your computer and/or
				mobile device; and the type of mobile internet browsers of your computer
				and/or mobile device.
			</p>

			<p>
				· <strong>Usage details</strong>: When you access and use the Services,
				we may automatically collect certain details of your access to and use
				of the Services, including traffic data, location data, logs and other
				communication data and the resources that you access and use on or
				through the Services
			</p>

			<p>
				· <strong>Location information</strong>: When you first visit or use the
				Services, we may request permission to collect and use your device’s
				precise geolocation. You can opt not to permit the collection of this
				information, but you may not be able to use the Services provided by
				xMart without access to your location. We need your location information
				in order to provide our Services. You can control how and whether we
				collect your precise geolocation information through your device’s
				settings.
			</p>

			<p>
				We may use information that has been de-identified and/or aggregated to
				better understand who uses our Services and how we can deliver a better
				experience, or otherwise at our discretion.
			</p>

			<p>
				<strong>
					If you do not want us to collect this information, you may either
					change your device’s privacy settings or DO NOT ACCESS OR USE THE
					SERVICES.
				</strong>
			</p>

			<h2>
				<strong>INFORMATION COLLECTION TECHNOLOGIES</strong>
			</h2>

			<p>
				The technologies we use for automatic information collection may
				include:
			</p>

			<p>
				· <strong>Cookies (or mobile cookies)</strong>: We may use “cookies” to
				help you personalise your online experience. A cookie is a text file
				that is placed on your hard disk by a web page server. Cookies cannot be
				used to run programs or deliver viruses to your computer. Cookies are
				uniquely assigned to you and can only be read by a web server in the
				domain that issued the cookie to you. The purpose of a cookie is to tell
				the web server that you have returned to a specific page. You have the
				ability to accept or decline cookies. Most web browsers automatically
				accept cookies, but you can usually modify your browser setting to
				decline cookies if you prefer. If you choose to decline cookies, you may
				not be able to fully experience the interactive features of the
				Services.{" "}
			</p>

			<p>
				· <strong>Retargeting/Advertising Cookies</strong>:
				Retargeting/Advertising Cookies collect data about your online activity
				and identify your interests so that we can provide advertising that we
				believe is relevant to you.{" "}
			</p>

			<p>
				· <strong>Web Beacons</strong>: The Services and our e-mails may contain
				small electronic files known as web beacons (also referred to as clear
				gifs, pixel tags and single-pixel gifs) that permit us, for example, to
				count users who have visited those pages or opened an e-mail and for
				other related Services statistics.
			</p>

			<p>
				· <strong>Social Ad Platforms</strong>: We may also use Google
				Analytics, Facebook, Instagram, and Twitter’s functionality of
				re-marketing through their tracking cookies and pixel-based retargeting
				services. This means that if you provided your consent to Google,
				Facebook, Instagram or Twitter (the “
				<strong>Social Ad Platforms</strong>”) to be provided with personalised
				commercial offers, you may be served with ads (including advertisements
				of third parties) based on your access to the Services, outside of the
				Services and across the internet. In such event, the Social Ad
				Platforms, will place cookies on your web browser and use such cookies
				to serve you ads based on past visits to our Services.
			</p>

			<p>
				Please visit the Social Ad Platforms Privacy policy to find out how they
				use such information:
			</p>

			<p>
				· Google:{" "}
				<a href="https://policies.google.com/technologies/ads%25C2%25A0">
					https://policies.google.com/technologies/ads{" "}
				</a>
			</p>

			<p>
				· Twitter:{" "}
				<a href="https://business.twitter.com/en/help/ads-policies/other-policy-requirements/policies-for-conversion-tracking-and-tailored-audiences.html%25C2%25A0">
					https://business.twitter.com/en/help/ads-policies/other-policy-requirements/policies-for-conversion-tracking-and-tailored-audiences.html{" "}
				</a>
			</p>

			<p>
				· Facebook:{" "}
				<a href="https://www.facebook.com/about/privacy/">
					https://www.facebook.com/about/privacy/
				</a>
			</p>

			<p>
				· Instagram:{" "}
				<a href="https://help.instagram.com/478880589321969/">
					https://help.instagram.com/478880589321969/
				</a>
			</p>



			<p>
				· <strong>Mobile Device Identifiers</strong>. Mobile device identifiers
				help xMart learn more about our users’ demographics and internet
				behaviours. Mobile device identifiers are data stored on mobile devices
				that may track mobile device and data and activities occurring on and
				through it, as well as the applications installed on it. Mobile device
				identifiers enable collection of Personal Information (such as media
				access control, address and location, and tracking data, including
				without limitation IP address, domain server, type of device(s) used to
				access the Services, web browser(s) used to access the Services,
				referring webpage or other source through which you accessed the
				Services, other statistics and information associated with the
				interaction between your browser or device and the Services).
			</p>

			<p>
				· <strong>Cross Device Matching</strong>. To determine if users have
				interacted with content across multiple devices and to match such
				devices, we may work with partners who analyse device activity data
				and/or rely on your information (including demographic, geographic and
				interest-based data). To supplement this analysis, we may also provide
				de-identified data to these partners. Based on this data, we may then
				display targeted advertisements across devices that we believe are
				associated or use this data to further analyse usage of Services across
				devices.
			</p>

			<h2>
				<strong>CHANGING YOUR DATA COLLECTION SETTINGS</strong>
			</h2>

			<p>
				We strive to provide you with choices regarding the Personal Information
				you provide to us. You may need to adjust the settings on your computer
				and/or device to restrict the collection of information by the Services,
				but this may prevent you from accessing all of the features of the
				Services.
			</p>

			<p>
				· <strong>Tracking Technologies: </strong>You may be able to set your
				browser or device to refuse all or some browser cookies, or to alert you
				when cookies are being sent. If you disable or refuse cookies or block
				the use of other tracking technologies, some parts of the Services may
				then be inaccessible or not function properly.
			</p>

			<p>
				· <strong>Location Information:</strong> You can choose whether or not
				to allow the Services to collect and use real-time information about
				your device’s location through the device’s privacy settings. If you
				block the use of location information, some parts or all of the Services
				may be inaccessible or not function properly.{" "}
			</p>

			<p>
				· <strong>Social Ad Platforms: </strong>If you wish to opt-out of
				re-targeting and tracking functionality of the Social Ad Platforms, you
				may do so through each platform directly. <strong>PLEASE NOTE</strong>{" "}
				that these tracking and targeting by Social Ad Platforms, is provided
				pursuant to your engagement with the Social Ad Platforms and the actual
				nature and scope of Personal Information collection and processing
				performed by such Social Ad Platforms may not be fully known to us. If
				you would like to learn more or make further inquiries with respect to
				such nature or scope, please refer to each Social Ad Platforms directly.
			</p>

			<p>
				You can decide whether or not to accept Cookies through your internet
				browser’s settings. Most browsers have an option for turning off the
				Cookie feature, which will prevent your browser from accepting new
				Cookies, as well as (depending on your browser software) allow you to
				decide on acceptance of each new Cookie in a variety of ways. You may
				also be able to reject mobile device identifiers by activating the
				appropriate setting on your mobile device. You can also delete all
				Cookies that are already on your computer. Although you are not required
				to accept xMart Cookies, if you block, reject, or delete them, you may
				have to manually adjust some preferences every time you visit a site and
				some of the Services and functionalities may not work.
			</p>

			<p>
				To explore what Cookie settings are available to you, look in the
				“preferences” or “options” section of your browser’s menu. To find out
				more information about Cookies, including information about how to
				manage and delete Cookies, please visit{" "}
				<a href="http://www.allaboutcookies.org/">
					http://www.allaboutcookies.org/.
				</a>
			</p>

			<p>
				In addition, if you wish not to receive ads from us based on information
				we obtain from use of cookies and pixel tags, please send us an e-mail
				to <a href="mailto:info@Xmart.art">info@xMart.art</a> and we will
				respond within a reasonable timeframe and in accordance with applicable
				laws.
			</p>

			<h2>
				<strong>THIRD-PARTY AUTOMATIC INFORMATION COLLECTION</strong>
			</h2>

			<p>
				When you use the Services or its contents, certain third parties may use
				automatic information collection technologies to collect information
				about you or your device. These third parties may include:
			</p>

			<p>· Advertisers, ad networks and ad servers;</p>

			<p>· Analytics companies;</p>

			<p>· Your mobile device manufacturer;</p>

			<p>· Your mobile service provider; and/or</p>

			<p>· Your Internet provider.</p>

			<p>
				These third parties may use tracking technologies to collect information
				about you when you use the Services. The information they collect may be
				associated with your Personal Information or they may collect
				information about your online activities over time and across different
				websites, mobile applications and other online sites. They may use this
				information to provide you with interest-based (behavioural) advertising
				or other targeted content. We do not control these third parties’
				tracking technologies or how they may be used. If you have any questions
				about an advertisement or other targeted content, you should contact the
				responsible provider directly.
			</p>

			<h2>
				<strong>HOW WE USE YOUR INFORMATION</strong>
			</h2>

			<p>
				We use information collected as described in this Privacy Policy for the
				following:
			</p>

			<p>
				· <strong>Operation</strong>
			</p>

			<p>· Operate, maintain and improve the Services;</p>

			<p>
				· Provide you with the Services and its contents, and any other
				information, products or services that you request from us;
			</p>

			<p>
				· Send you reminders, technical notices, updates, security alerts,
				support and administrative messages and marketing messages;
			</p>

			<p>· Request customer feedback; </p>

			<p>· Answer your questions and respond to your requests;</p>

			<p>
				· Ensure that content from our Services is presented in the most
				effective manner for you and for your computer or device for accessing
				the Services;
			</p>

			<p>
				· Carry out our obligations and enforce our rights arising from any
				contracts entered into between you and us, including the Terms and
				Conditions; and
			</p>

			<p>
				· Notify you when there are changes to any services we offer or provide
				though the Services.
			</p>

			<p>
				· <strong>Usage and Analytics</strong>
			</p>

			<p>· Estimate our audience size and usage patterns;</p>

			<p>· Perform analytics and conduct customer research;</p>

			<p>· Analyse advertising effectiveness; and</p>

			<p>
				· Communicate and provide additional information that may be of interest
				to you about us and our business partners.
			</p>

			<p>
				· <strong>Location:</strong> We will use location data for the following
				reasons:{" "}
			</p>

			<p>· To provide our Services targeted to your geographical area; </p>

			<p>
				· To display information that is relevant to your particular location.
			</p>

			<h2>
				<strong>HOW WE DISCLOSE YOUR PERSONAL INFORMATION </strong>
			</h2>

			<p>
				The Personal Information you provide to us whether voluntarily or
				automatically, may be used and disclosed without limitations, in the
				following instances:{" "}
			</p>

			<p>
				· With vendors who provide services for us (who are required to protect
				the Personal Information);
			</p>

			<p>· To report or collect on debts owed to us;</p>

			<p>
				· To a prospective buyer or buyer of xMart or other successor in the
				event of a merger, divestiture, restructuring, reorganisation,
				dissolution or other sale or transfer of some or all our assets;
			</p>

			<p>· As part of bankruptcy, liquidation or similar proceeding;</p>

			<p>
				· To comply with legal requirements for the enforcement of law,
				regulations, court orders, subpoena, a warrant during the course of a
				legal proceedings or otherwise;{" "}
			</p>

			<p>
				· To protect and safeguard our copyright, trademarks, legal rights,
				intellectual property rights or safety; or
			</p>

			<p>
				· In response to a subpoena, or similar legal process, including to law
				enforcement agencies, regulators, and courts in the United States.
			</p>

			<h2>
				<strong>HOW WE DISCLOSE YOUR NON-PERSONAL INFORMATION</strong>
			</h2>

			<p>We may also disclose Non-Personal Information:</p>

			<p>· For the same reasons, we might share Personal Information;</p>

			<p>· With our advertisers for their own analysis and research;</p>

			<p>· To facilitate targeted content and ads; or</p>

			<p>
				· With Third-Party Ad Servers to place our ads on the Services and on
				third-party websites or mobile applications.
			</p>

			<h2>
				<strong>
					HOW TO ACCESS, UPDATE, WITHDRAW CONSENT OR DELETE YOUR INFORMATION
				</strong>
			</h2>

			<p>
				If the laws applicable to you grant you such rights, you may ask to
				access, correct, or delete your Personal Information that is stored in
				our systems or that we otherwise control. You may also ask for our
				confirmation as to whether or not we process your Personal Information
				or ask to withdraw any consent you have previously provided to xMart in
				connection with our use and processing of your Personal Information.
				Subject to the limitations in law, you may request that we update,
				correct, or delete inaccurate or outdated information. You may also
				request that we suspend the use of any Personal Information the accuracy
				of which you contest while we verify the status of that data.
			</p>

			<p>
				If you wish to exercise any of these rights or withdraw your consent,
				please contact us at:{" "}
				<a href="mailto:info@Xmart.cart">info@xMart.cart</a> When handling these
				requests, we may ask for additional information to confirm your identity
				and your request. Please note, upon request to delete your Personal
				Information, we may retain such data in whole or in part to comply with
				any applicable rule or regulation and/or response or defend against
				legal proceedings versus us or our affiliates, or as we are otherwise
				permitted under such law applicable to you. While we strive to honour
				all reasonable requests, please be aware that we backup the data to
				prevent accidental or malicious destruction of your information.
				Information on backup servers may take some time to be completely
				deleted from the system. If you request that we remove you from our
				system and delete all your information, please note that you may still
				receive materials while your request is being processed.
			</p>

			<p>
				If you request that your information be deleted, we will keep your
				information for a period of time in order to deter fraud. However, if
				you request to delete your information, your Personal Information will
				not be used by us or our third- party providers for any further
				purposes, nor sold or shared with third parties, except as necessary to
				prevent fraud and assist law enforcement, as required by law, or in
				accordance with this Privacy Policy.
			</p>

			<h2>
				<strong>SECURITY AND DATA RETENTION</strong>
			</h2>

			<p>
				The security of your Personal Information is important to us. We seek to
				protect your Personal Information from unauthorised access, use and
				disclosure using appropriate physical, technical, organisational and
				administrative security measures based on the type of Personal
				Information and how we are processing that data. We endeavour to follow
				generally accepted industry standards to protect the Personal
				Information submitted to us, both during transmission and in storage.
				For example, the Services use industry standard Secure Sockets Layer
				(SSL) technology to allow for the encryption of Personal Information. We
				store and process your information on our servers in the United States
				and abroad. We maintain what we consider industry standard backup and
				archival systems. Although we work to protect the security of the data
				that we hold in our records, for example, by making good faith efforts
				to store Personal Information in a secure operating environment that is
				not open to the public, please be aware that no method of transmitting
				data over the Internet or storing data is completely secure. We cannot
				and do not guarantee the complete security of any data you share with
				us, and except as expressly required by law, we are not responsible for
				the theft, destruction, loss or inadvertent disclosure of your
				information or content.
			</p>

			<p>
				If at any time during or after our relationship we believe that the
				security of your Personal Information may have been compromised, we may
				seek to notify you of that development. If a notification is
				appropriate, we will endeavour to notify you as promptly as possible
				under the circumstances. If we have your e-mail address, we may notify
				you by e-mail to the most recent e-mail address you have provided us. If
				you receive a notice from us, you can print it to retain a copy of it.
				To receive these notices, you must check your e-mail account using your
				computer or mobile device and email application software. You consent to
				our use of e-mail as a means of such notification. If you prefer for us
				to use the U.S. Postal Service to notify you in this situation, please
				e-mail us at <a href="mailto:info@Xmart.art">info@xMart.art</a> . Please
				include your address when you submit your request. You can make this
				election any time, and it will apply to notifications we make after a
				reasonable time thereafter for us to process your request. You may also
				use this e-mail address to request a print copy, at no charge, of an
				electronic notice we have sent to you regarding a compromise of your
				Personal Information.
			</p>

			<p>
				We retain Personal Information about you consistent with all internal
				policies and procedures. We may retain Personal Information to comply
				with our legal obligations, resolve disputes or collect fees owed, or as
				is otherwise permitted or required by our data retention policies and
				procedures.
			</p>

			<p>
				<strong>CHILDREN’S PRIVACY</strong>
			</p>

			<p>
				The Services are not directed to or intended for use by children under
				18 years of age. If you are a child under the age of 18, please do not
				attempt to register for or otherwise use the Services or send us any
				Personal Information. By accessing, using and/or submitting information
				to or through the Services, you represent that you are not under the age
				of 18. As noted in the Terms and Conditions, we do not knowingly collect
				or solicit Personal Information from children under the age of 18. If we
				learn that we have received any Personal Information directly from a
				child under age 18 without first receiving his or her parent’s verified
				consent, we will use that Personal Information only to respond directly
				to that child (or his or her parent or legal guardian) to inform the
				child that he or she cannot use the Services. We will then subsequently
				delete that child’s Personal Information. If you believe that a child
				under 18 may have provided us with Personal Information, please contact
				us at <a href="mailto:info@Xmart.art">info@xMart.art</a>{" "}
			</p>

			<h2>
				<strong>UPDATES TO THIS POLICY</strong>
			</h2>

			<p>
				We reserve the right to change, modify, update, add, or remove portions
				of this Privacy Policy at any time. Any changes or updates will be
				effective immediately upon posting to this page. When we do update it,
				for your convenience, we will make the updated policy available on this
				page. If you opt out of receiving communications, you may not receive
				certain notifications, however, they will still govern your use of the
				Services, and you are responsible for proactively checking for any
				changes. We encourage you to check this Privacy Policy frequently to
				stay up to date on any changes. You can determine if changes have been
				made by checking the effective date on the Privacy Policy. Continued use
				of the Services after the effective date of any change in this Privacy
				Policy will constitute acceptance of such changes and it will signify
				that you agree to abide by and be bound by the modified Privacy Policy.
			</p>

			<h2>
				<strong>CONTACT US</strong>
			</h2>

			<p>
				If you have questions, comments, or concerns regarding this Privacy
				Policy, please contact us and we will respond to your request within a
				reasonable timeframe. Please include the following information in your
				request (a) Your name; (b) Your contact information, including phone
				number and email address; and (c) The precise nature of your request,
				inquiry, or complaint. You may contact us at:{" "}
				<a href="mailto:info@Xmart.art">info@xMart.art</a>{" "}
			</p>
		</div>
	);
};

export default PrivacyTerms;
