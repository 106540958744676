import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ModalViewNFT  from '../../components/Modal/ModalViewNFT';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';

import axios from 'axios';

class viewNFTComponent extends Component {

	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	state = {
		tab:"assets",
		useModalViewNFTs:true,
		modalVisible:false,
		prevY: 0,
		loading:true,
		loadingNFT:true,
		homePageData:{"featured":[], forSale:[], minted:[], recentSales:[]},
		nfts:[],
		nft:{
			creator:{"headerImage":"", "id":"", "url":"", "profileImageM": this.props.config.sizes.placeholders.profilePicture.medium, "title":""},
			collection: {url:"", "name":"", title:"", "preview":""},
			auction:{end:0, isAuction:false, bids:[]},
			mintedQty:false,
			mintPending:false,
			mintTransferPending:false,
			flagOwner:false,
			flagEditable:false,
			flagCreator:false,
			flagForSale:false,
			flagMadeAnOffer:false,
			flagBurned:false,
			flagDupe:false,
			floorPrice:0,
			ceilingPrice:0,
			description:"",
			burnable:false,
			attributes:{},
			attributeData:[],
			owners:[],
			media:[],
			history:[],
			offers:{ buy:[], sell:{}, forSale:false, forSalePrice:0, offersMade:0, buyOfferMade:false },
			mintAddresses:[],
			metaData:{mediaType:"", width:0, height:0},
			supply:"?",
			size:"?",
			title:"",
			preview: this.props.config.sizes.placeholders.square,
			previewMedia:"",
			previewAudio:""
		},
		collection:this.props.collection,
		dataType:this.props.dataType,
		infiniteScroll:this.props.infiniteScroll,
		bulkCollection:this.props.bulkCollection,
		maxResults:this.props.maxResults,
		offset:0,
		itemsPerPage:30,
		totalFilteredNFTs:-1,
		totalLoaded:0,
		collectionFilters:[],
		sortOrder:"",
		showSearchStuff:false,
		searchCriteria:"",

		filterType:"",
		showFilterHTML:false,
		filterHTML:"",
		mediaPlaying:false,
		disableTransfers:false,

		selectedForSale:[],

	};

	myCallbackModal = (visible) => {


		/* This enables grandchild component to change the state of modal tab visibility */
		this.setState({modalVisible:visible})

		/* Scroll to top */
		window.setTimeout( () => {
			//alert(2)
			//document.getElementById("view-nft-modal").scrollTop = document.documentElement.scrollTop = 0;
		}, 500);

	}

	loadNFT = e =>
	{

		const _id = e.currentTarget ? e.currentTarget.dataset.nft : e;

		this.setState({loadingNFT:true});

		/* Change to preloader image */
		window.$('#viewing-nft-image').prop( "src", this.props.config.sizes.placeholders.loader);

		const BASE_URL = this.props.config.apiServer + "/com/external/nfts.cfc?method=getNFT&uuid="+_id;
		axios.get(`${BASE_URL}`, {withCredentials: true})
			.then(res => {

				if( ! res.data.success)
				{
					window.displayErrorMessage("Invalid NFT - Reloading collection", false, false);
				}
				else{
					window.$('#breadcrumb-2 a').prop("href", res.data.nft.collection.url).html(res.data.nft.collection.name)

					/* Ensure images and URL's point to API server */
					var cachebuster = Math.round(new Date().getTime() / 1000);
					//res.data.nft.preview = res.data.nft.preview.length ? this.props.config.apiServer + res.data.nft.preview + "?cb="+cachebuster: "";
					res.data.nft.creator.headerImage = res.data.nft.creator.headerImage.length ? this.props.config.apiServer + res.data.nft.creator.headerImage : "";

					document.title = `${res.data.nft.title} NFT ${this.props.config.meta.title}`;

					//res.data.nft.media = res.data.nft.media ? this.props.config.apiServer + res.data.nft.media : "";

					/* Copy to window for jQuery sharing */
					window.nftData = res.data.nft;
					var _tab = "assets"
					if( res.data.nft.offers.offersMade && res.data.nft.flagOwner > 0 ) { _tab = "offers" }		/* We have offers and are the owner */
					if( res.data.nft.offers.buyOfferMade ) { _tab = "offers" }									/* Buyer has made an offer */

					this.setState({
						isMine:res.data.nft.flagOwner,
						buyDisabled:res.data.nft.flagOwner ? true : false,
						nft: res.data.nft,
						loadingNFT:true,
						tab:_tab,
						playMedia:false,
						modalVisible:false,
						mediaPlaying:false
					})

					/* Reveal page parts */
					this.setState({loadingNFT:false});
					window.setTimeout( () => { window.loadPreloadImages(); }, 500)

				}
			})
		.catch(err => window.logger(err))

	}

	myCallbackPlaybutton = e => {
		/* Enable play of preview video */
		this.setState({mediaPlaying:! this.state.mediaPlaying})
	}

	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */
	/* ENSURE STATE AND CALLBACKS ARE KEPT IN SYNCH - PAGES/NFT/VIEW.JS AND COMPONENTS/NFT/CBROWSE */


	componentDidMount(){

		const id = this.props.config.urlParts.length > 4 ? this.props.config.urlParts[4] : this.props.config.urlParts[2];
		this.loadNFT(id)

	}

    render() {
        return (
            <div className="nft-view" id="view-full-screen">
                <Header				config={ this.props.config } />
                <Breadcrumb			config={ this.props.config } state={this.state} title={this.state.nft.title} subpage="NFTs" subPageLink="/category/all/" shallow="true" page="Item Details" />
                <ModalViewNFT 		config={ this.props.config } state={this.state} fullPage={true} modalCallBack={this.myCallbackModal} playButtonCallBack={this.myCallbackPlaybutton} loadNFT={this.loadNFT} componentCalledFrom="view.js" />
                {/* <LiveAuctions /> */}
                <Footer				config={ this.props.config } />
                <ModalSearch		config={ this.props.config } />
                <ModalMenu			config={ this.props.config } />
                <Scrollup			config={ this.props.config } />
            </div>
        );
    }
}

export default viewNFTComponent;