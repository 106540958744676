import React, { Component } from 'react';
import Masonry from 'react-masonry-css';
import axios from 'axios';
class ModalFilters extends Component {

	state = {
		sortedObject:this.props.state.collectionData.attributes,
		matches:this.props.state.collectionData.totalNfts,
		showMatches:true,
		filterType:"and",
		showCalculating:false,
		chosenFilters:[],
		possibleFilters:[],
		reactFilterData:[],
		showRefresh:false,
		filterButton:"Show Collection Filters",
		clearButtonDisabled:true,
		applyButtonDisabled:true
	 }

	checkButtonState = e =>
	{
		this.setState({
			clearButtonDisabled:this.state.chosenFilters.length ? false : true,
			applyButtonDisabled:this.state.chosenFilters.length ? false : true,
		})
	}

	typeChange = e =>
	{
		this.setState({filterType:e.target.value})

		if(e.target.value === 'and')
		{
			/* Clear any existing filters */
			this.setState({
				matches:this.props.state.collectionData.totalNfts,
				showRefresh:false,
				chosenFilters:[],
				filterButton:"Show Collection Filters"
			})

			window.$('#filters input[type=checkbox]').prop("checked", null)

			/* Pretend we've clicked a checkbox and recalculate disbaled buttons etc. */
			this.changeFilterSelection()

		}
		window.setTimeout( () => { this.recalculate(); this.checkButtonState() }, 500 )
	}

	changeFilterSelection = e  =>
	{

		var modifiedFilters = [];
		window.$.each( window.$('#filters input[type=checkbox]:checked'), function (_idx, _obj)
		{
			modifiedFilters.push(window.$(_obj).prop("id"))
		})

		this.setState({
							chosenFilters:modifiedFilters,
							showCalculating:true,
							showMatches:false
						})

		/* Allow time for state to be set */

		window.setTimeout( () => { this.recalculate(); this.checkButtonState() }, 500 )

	}

	componentDidMount()
	{

		/* Force a redraw */
		window.$('#filters .form-check-input').removeClass('blocked');
		this.setState({"ready":true})

	}

	showingModal = e =>
	{
		/*Scroll to the top of the filters */
		window.$('.modal-content').animate({
			scrollTop: window.$(".modal-header").offset().top
		}, 0);

	}

	clearFilters = e =>
	{

		window.Swal.fire({
			title: 'Do you want to clear all filters and display all NFT\'s in the collection again?',
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: 'Yes',
			denyButtonText: 'No'
			}).then((result) => {
			if (result.isConfirmed) {

				this.setState({
					matches:this.props.state.collectionData.totalNfts,
					showRefresh:false,
					chosenFilters:[],
					filterButton:"Show Collection Filters"
				})

				window.$('#close-filter-modal').trigger('click')
				window.$('#filters input[type=checkbox]').prop("checked", null)

				/* Pretend we've clicked a checkbox and recalculate disbaled buttons etc. */
				this.changeFilterSelection()

				/* Clear filters on parent component */
				this.props.callbackFromParent( {NFTCount:this.state.matches, appliedFilters:[]} );

				window.toast("Filters cleared", "bottom")



			} else if (result.isDenied) {
				// Do nothing
			}
			})

	}

	applyFilters = e =>
	{
		var arrApplied = []

		if(this.state.chosenFilters.length)
		{
			for (const topKey in this.state.sortedObject) {
				if( Object.keys(this.state.sortedObject[topKey].attributes).length > 0 )
				{

					var traitObject = this.state.sortedObject[topKey]

					for (const subKey in traitObject.attributes) {
						var attributeObject = traitObject.attributes[subKey];

						if(this.state.chosenFilters.indexOf(attributeObject.filterHash) > -1)
						{
							arrApplied.push({"label":`${traitObject.name}|${attributeObject.name}`, "filterHash":attributeObject.filterHash})
						}

					}
				}
			}
			this.setState({filterButton:`Edit Filters - ${this.state.chosenFilters.length} Selected`})
			window.toast(`${this.state.chosenFilters.length} Filters Applied`, "bottom")
		}
		else
		{
			this.setState({filterButton:"Show Collection Filters"})
		}

		this.props.callbackFromParent( {NFTCount:this.state.matches, appliedFilters:arrApplied, filterType:this.state.filterType} );


	}

	recalculate = e =>
	{

		if(this.state.chosenFilters.length)
		{
			const BASE_URL = this.props.config.apiServer + "/com/external/collections.cfc?method=getFilterEstimate";
			const formData = new FormData();

			formData.append("collection", this.props.state.collectionData.id );
			formData.append("filters", this.state.chosenFilters );
			formData.append("filterType", this.state.filterType );

			axios.post(BASE_URL, formData, { withCredentials: true })
				.then(res => {

					if( ! res.data.success)
					{
						this.setState({ showCalculating:false, showMatches:true})
						throw(res.data.errorMessage);
					}
					else
					{
						this.setState({matches:res.data.matches, possibleFilters:res.data.possibleFilters, showCalculating:false, showMatches:true})

						window.$('#filters .filterdiv').removeClass('blocked');

						if(res.data.possibleFilters.length)
						{
							window.$('#filters .filterdiv').addClass('blocked')
							for (const key in this.state.possibleFilters) {
								const filterID = this.state.possibleFilters[key]
								window.$('#'+filterID).parent().parent().removeClass('blocked')
							}
						}


					}
				})
			.catch(err =>
					{ window.hideWaitMessage(); window.displayErrorMessage(err); }
			)
		}
		else
		{
			window.$('#filters .filterdiv').removeClass('blocked');
			this.setState({matches:this.props.state.collectionData.totalNfts, showCalculating:false, showMatches:true})
		}



	}

	render() {

		var childElements;

		if( ! this.state.sortedObject)
		{
			childElements = '';
		}
		else
		{

			/* Work out which traits should show - Those with > 1 attributes */
			var traits = [];
			for (const topKey in this.state.sortedObject) {
				if( Object.keys(this.state.sortedObject[topKey].attributes).length > 0 )
				{
					traits.push(topKey);
				}
			}

			childElements = traits.sort().map((item, idx) =>
			{
				const traitItem = this.state.sortedObject[item]
				return (

						<div key={traitItem.name} className="card p-0 grid-item mb-4">
							<div className="card-header">
								{traitItem.name}
							</div>
							<div className="card-body py-0">
								{ Object.keys(this.state.sortedObject[item].attributes).sort().map((subitem, subidx) =>
								{
									const attributeItem = this.state.sortedObject[item].attributes[subitem];
									const tooltip = `There are ${attributeItem.itemsWithThisAttribute} NFTs in the collection with this attribute which equates to ${attributeItem.percentWithThisAttribute}%`
									return (

											<div key={attributeItem.filterHash} className="row py-1 g-0 filterdiv">
												<div className='col-2 c1'><input type="checkbox" id={attributeItem.filterHash} className="form-check-input" onChange={this.changeFilterSelection} /></div>
												<div className="col-8 c2"><label htmlFor={attributeItem.filterHash}>{attributeItem.name}</label></div>
												<div className='col-2 c3 pe-2' title={tooltip} data-bs-toggle="tooltip" data-bs-placement="left">{attributeItem.percentWithThisAttribute}%</div>
											</div>

									);
								})}
							</div>
						</div>


			 );
			})

		}

        return (

			<>



				<div className="hid" id="btnFilters">
					<button id="showFilterModal" className="btn btn-bordered" data-bs-toggle="modal" data-bs-target="#filters" onClick={this.showingModal}>{this.state.filterButton}</button>
				</div>

				<div id="filters" className="modal fade p-0">
					<div className="modal-dialog modal-xl dialog-animatedx modal-fullheight modal-fullscreen-lg-down">
						<div className="modal-content h-100">

							{ this.state.showCalculating ? (
								<div id="blocker">
									<div className="message">
										<p className="pulsate">Recalculating</p>
										<img src={this.props.config.sizes.placeholders.preloader} alt="Loading" />
									</div>
								</div> ) : ( <></> )}


							<div className="modal-header modal-header--sticky">
								<span>Filters</span>
								<div  style={{"fontSize":"110%"}}>
										{ this.state.showMatches ? ( <strong>Matches: <span id="maching-result">{this.state.matches}</span></strong> ) : ( <></> )}
										{ this.state.showCalculating ? ( <strong className="pulsate">Calculating</strong> ) : ( <></> )}
								</div>
								<i className="fas fa-times-circle icon-close" data-bs-dismiss="modal" />
								<button id="close-filter-modal" data-bs-dismiss="modal" className="hid">X</button>

							</div>

							<div className="modal-body">

								<Masonry breakpointCols={{ default: 3, 1100: 3, 700: 2, 500: 1 }} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
									{childElements}
								</Masonry>

							</div>

							<div className="modal-footer modal-footer--sticky">

								<div className="row w-100">

									<div className='col-12 col-lg-4 mb-3'>
										<select id="filterType" name="filterType" className="form-select" defaultValue={this.state.filterType} onChange={this.typeChange}>
											<option value="and">Match JUST selected attributes</option>
											<option value="or">Match ANY selected attribute</option>
										</select>
									</div>

									<div className='col-6 col-xl-5 mb-3'>
										<button className="btn btn-smaller mx-2 my-2 w-100" onClick={this.applyFilters} data-bs-dismiss="modal" disabled={this.state.applyButtonDisabled} >Apply Filters</button>
									</div>
									<div className='col-6 col-xl-3'>
										<button className="btn btn-bordered btn-smaller mx-2 my-2 w-100" onClick={this.clearFilters} disabled={this.state.clearButtonDisabled}>Clear Filters</button>
									</div>
								</div>

							</div>

						</div>
					</div>
				</div>
			</>
    	)
	}
};

export default ModalFilters;

