import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';

class Activity extends Component {

	state = { soft404:true };

    render() {

        return (
			<>
			{ ! this.state.soft404 ?

			( <></> )
			: (
				<div className="main">
					<Header				config={ this.props.config } state={this.state} />
					<Breadcrumb			config={ this.props.config } state={this.state} title="" subpage="" page="" background="null" />

					<div className="container text-center mt-5">
						<h1>404</h1>
						<p>This page has been forgotten :-(</p>
					</div>

					<Footer				config={ this.props.config } />
					<ModalSearch		config={ this.props.config } />
					<ModalMenu			config={ this.props.config } />
					<Scrollup			config={ this.props.config } />
				</div>
				) }

			</>

        );
    }
}

export default Activity;