import React, { Component } from 'react';
import { VideoLink } from '../videoLink'
import SpinnerBuyButton from './spinner-buy-button'

class SpinnerButtonsComponent extends Component {

    render() {

		return (

			<>

				{/* Spacer div */}
				<div className="mt-5 d-none d-md-block"></div>

				<div id="output1" className="mb-4 text-center">

					{ this.props.state.collectionData.spinnerInfo.spinsLeft === 0 && this.props.state.collectionData.br ?
					(
						<>
							<h3 className="primary text-center">This collection has no spins left</h3>
							<p>Unfortunately, all spins have been purchased for this collection. Keep an eye on the collection to see what comes up for sale.</p>
							{ this.props.state.collectionData.br === 'Yes' ?
							(
							<p>Click the "View Collection Items" link to browse the collection.</p>
							) : ( <></>)}
						</>
					) :
					(
						<>

							<div className="m-2 d-none d-md-block text-center">
								<VideoLink label={"How Does The Spinner Work?"} tag={"spin-to-win"} linkType="link" config={this.props.config} />
							</div>

							{ this.props.state.collectionData.trustLineCurrency === 'XRP' ?
								(<>
									<p><strong className="primary">Hurry!!</strong> First-come, first-served basis. Buy spins to mint a random NFT. Each spin costs <strong className="primary">{this.props.state.collectionData.trustLinePerSpin} XRP</strong>.</p>
								</>) :
								(<>
									<p><strong className="primary">Hurry!!</strong> First-come, first-served basis. You need to swap <strong className="primary">{this.props.state.collectionData.trustLineCurrency} tokens</strong> for spins. Each spin costs <strong className="primary">{this.props.state.collectionData.trustLinePerSpin} {this.props.state.collectionData.trustLineCurrency}</strong>.</p>
								</>)
							}

							{ this.props.parentState.shuffleCollection ?
								(<>
									<p className="mt-3">
										<strong className="primary">Shuffle Mode is Enabled!!</strong> Don't like the NFT you have just spun? Reshuffle it for just <strong className="primary">{this.props.parentState.tokensPerRespin} XRP</strong>.
									</p>
								</>) :
								(<></>)
							}



						</>
					)
					}

				</div>

				<div className='d-none d-md-block'>

					<SpinnerBuyButton
																			state={this.props.state}
																			parentState={this.props.parentState}
																			config={this.props.config}
																			spinInfo={this.props.state.spinInfo}
																			qtyLeft={this.props.qtyLeft}
																			getFreeSpin={this.getFreeSpin}
																			showTokenButton={this.props.showTokenButton}
																			showXRPButton={this.props.showXRPButton}
																			showPromoButton={this.props.showPromoButton}
																			checkTotals={this.props.checkTotals}
																			showTotalMessage={true}
																			disabled={this.props.state.showShuffleDiv  || this.props.state.shufflePendingPayment} />

				</div>

			</>

		);
    }
}

export default SpinnerButtonsComponent;