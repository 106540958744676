import React, { Component } from 'react';
import AuthorProfile from '../AuthorProfile/AuthorProfile';
import ExploreCategory from '../../components/Explore/ExploreCategory';

class AuthorComponent extends Component {

	state = {
		profile: this.props.config.urlParts[2],
		isFeaturedCollections:false,
		isMyCollection:this.props.config.urlParts[2] === this.props.config.userData.urlPath ? true : false,
		showHeading:true,
		cardClass:"col-md-6 col-lg-4",
		"description":"",
		"tag":this.props.config.urlParts[2] === this.props.config.userData.urlPath ? "myCollections" : "theirCollections"
	}

	launchCRUD = e =>
	{
		e.preventDefault()
		document.getElementById("crud-nft-loader-button").dataset.collection = "";
		document.getElementById("crud-nft-loader-button").dataset.nft = "";
		document.getElementById("crud-nft-loader-button").click()
	}

    render() {

		var col1Class = "col-12 col-md-9 mt-2 ";
		var col2Class = "col-12 col-md-3 mt-2 ";

		if(this.props.config.wideScreen)
		{
			col1Class += "col-lg-9 col-xl-9 col-xxl-10";
			col2Class += "col-lg-3 col-xl-3 col-xxl-2";
		}

        return (
            <div className="author-area explore-area popular-collections-area">

                <div className={this.props.config.wideScreenClass}>
                    <div className="row d-flex flex-row-reverse g-0">

                        <div className={col1Class}>
							<ExploreCategory		config={ this.props.config } state={this.state} hasSideNav={true} />
                        </div>

						<div className={col2Class} style={{"maxWidth":"400px"}}>
                            {/* Author Profile */}
                            <AuthorProfile 			config={ this.props.config } state={this.props.state} isMyCollection={this.state.isMyCollection} />
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

export default AuthorComponent;