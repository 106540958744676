import React, { Component } from 'react';
import { toDaysMinutesSeconds,numberRounder, shortDate  } from '../xutils'

class HomeTabs extends Component {

state = {"tab":"minting"};


formatRows = section =>
{
	const data = this.props.data[section]
	var _html

	var childElements = data.map((item, idx) => {

		const _prefix = item.image.indexOf("http") < 0 ? this.props.config.apiServer : "";
		const _img = item.image.length ? _prefix + item.image : this.props.config.sizes.placeholders.square;

		return (
			<a href={item.link} target="_blank" key={"link_" + section + "_" + idx}>
				<div className="row g-0 mb-2 pb-2">
					<div className="deets">

						<span className="c1 me-2">#{idx+1}</span>
						<span className="c1 me-2"><img src={_img} /></span>
						<span className="c3">
							{item.title}
							{ item.subTitle && item.subTitle.length ?
							(
								<span>{item.subTitle ? ( <> <br/> {item.subTitle} </> ) : (<></>)}</span>
							) : ( <></> )}
						</span>
					</div>
					<div className="values">
						<div>
							{
								section == 'trending' ?
								(
								<>{parseInt(item.v1)}</>
								)
								:
								(
								<>{numberRounder(item.v1, true)}</>
								)
							}
						</div>
						{
							section == 'sales' || section === 'recentmints' ?
							(
							<div>{toDaysMinutesSeconds(item.v2, true)}</div>
							)
							:
							(
							<div>{numberRounder(item.v2, true)}</div>
							)
						}

					</div>
				</div>
			</a>
		);
	})

	return childElements

}


    render() {

		var preloadKey = 1;

		return (
            <section className="popular-collections-area home-tabs">

                <div className="container p-2">

					{ this.props.state.loading ?
					(
						<div id="loading" className="text-center">
							<h3 className="m-3 mt-5"><strong>Loading...</strong></h3>
							<img src={this.props.config.sizes.placeholders.preloader} alt="Loading" />
						</div>
					) :
					(
						<>
							<nav className="siteTabs">
								<div className="nav nav-tabs justify-content-center mt-4" id="nav-tab" role="tablist">
									<button className="nav-link active" id="nav-trending-tab" data-bs-toggle="tab" data-bs-target="#nav-trending" type="button" role="tab" aria-controls="nav-trending" aria-selected="false">Trending</button>
									<button className="nav-link" id="nav-top24-tab" data-bs-toggle="tab" data-bs-target="#nav-top24" type="button" role="tab" aria-controls="nav-top24" aria-selected="true">24hr</button>
									<button className="nav-link" id="nav-sales-tab" data-bs-toggle="tab" data-bs-target="#nav-sales" type="button" role="tab" aria-controls="nav-sales" aria-selected="false">Sales</button>
									<button className="nav-link" id="nav-minting-tab" data-bs-toggle="tab" data-bs-target="#nav-minting" type="button" role="tab" aria-controls="nav-minting" aria-selected="false">Minting</button>
									<button className="nav-link" id="nav-recent-mints-tab" data-bs-toggle="tab" data-bs-target="#nav-recent-mints" type="button" role="tab" aria-controls="nav-recent-mints" aria-selected="false">Mints</button>
								</div>
							</nav>
							<div className="tab-content container pt-3" id="nav-tabContent">

								<div className="tab-pane fade show active" id="nav-trending" role="tabpanel" aria-labelledby="nav-trending-tab">
									<p className='text-center header m-0 mb-2'>Top 10 trending. 24 hours by sales with floor.</p>
									{ this.formatRows("trending") }
								</div>

								<div className="tab-pane fade" id="nav-top24" role="tabpanel" aria-labelledby="nav-top24-tab">
									<p className='text-center header m-0 mb-2'>Top 10 collections. Past 24 hours by volume + floor.</p>
									{ this.formatRows("top24") }
								</div>

								<div className="tab-pane fade" id="nav-sales" role="tabpanel" aria-labelledby="nav-sales-tab">
									<p className='text-center header m-0 mb-2'>Latest 10 sales on the XRPL with price &amp; age.</p>
									{ this.formatRows("sales") }
									<div className="text-center">
										<a href="/nfts/latest-sales" className='mt-3 m-0 mb-2 btn btn-sm btn-bordered py-1 w-75'>View All <i className="fas fa-arrow-right"></i></a>
									</div>
								</div>

								<div className="tab-pane fade" id="nav-minting" role="tabpanel" aria-labelledby="nav-minting-tab">
									<p className='text-center header m-0 mb-2'>Minting collections on xMart with volume &amp; floor.</p>
									{ this.formatRows("minting") }
									<div className="text-center">
										<a href="/collections/minting-now" className='mt-3 m-0 mb-2 btn btn-sm btn-bordered py-1 w-75'>View All <i className="fas fa-arrow-right"></i></a>
									</div>
								</div>

								<div className="tab-pane fade" id="nav-recent-mints" role="tabpanel" aria-labelledby="nav-recent-mints">
									<p className='text-center header m-0 mb-2'>Recent mints on xMart with price &amp; age.</p>
									{ this.formatRows("recentmints") }
									<div className="text-center">
										<a href="/nfts/latest-mints" className='mt-3 m-0 mb-2 btn btn-sm btn-bordered py-1 w-75'>View All <i className="fas fa-arrow-right"></i></a>
									</div>
								</div>

							</div>

						</>

					) }

                </div>

            </section>

        );
    }
}

export default HomeTabs;