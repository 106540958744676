import React, { Component } from 'react';
import axios from 'axios';

import ModalSwapTokens from '../Modal/ModalSwapTokens'
import ModalSpinResult from '../Modal/ModalSpinResult'

import LaunchPad from './launchPad'

import SpinnerBuyButton from './spinner-buy-button'
import SpinnerButtons from './spinner-buttons'
import SpinnerGiveaway from './spinner-giveaway'
import PendingPayments from './spinner-pending-payments'

import SpinnerType1 from './spinner-type-1'

import { addXummPayload, checkXummLoggedIn } from '../../js/xumm';
import { toDaysMinutesSeconds } from '../xutils';
import _ from 'lodash';

var shuffleDuration; // In milliseconds
var animationType;
var animations = [];
var images = [];
var spinTimeCheck = null;
var pendingShufflePaymentTimer = null;
var pendingExpiryPaymentTimer = null;
var shuffleTimerInterval = null;


class CollectionSpinnerWheelComponent extends Component {

	state = {
		examples: [],
		examplesCopy: [],
		spinInfo: {
						"purchased":	{"custom":0, "xrp":0, "promo":0},
						"available":	{"custom":-1, "xrp":-1, "promo":-1},
						"mySpins":		{"custom":0, "xrp":0, "promo":0, "total":0}, shuffle:false },
		spins:20,
		balances:{spins:0, shuffle:0},
		btnText:"Spin & Win!",
		btnDisabled: true,
		testMode:false,
		testModeRS:false,
		testModeGetShuffleSlot:false,
		tokens: -1,
		nftsRemaining:0,
		showLoading:true,
		spinError: false,
		spinErrorMessage: "",
		awardedNFT: 			{"id":"", "title":"foo", "img":"", "expiry":0, "allowShuffle":false},
		allocatedNFT: 			{"id":"", "title":"", "img":"", "expiry":0, "allowShuffle":false},
		allocatedNFTPrevious: 	{"id":"", "title":"", "img":"", "expiry":0, "allowShuffle":false},
		swapsPending:0,
		tokenCurrency:'FOO',
		tokensPerSpin:0,
		address:"",
		collectionID:"",
		apiServer:this.props.config.apiServer,
		preview:{"width":"440px", "height":"440px"},
		spinnerBeenSpun:false,
		initialisedSpinner:false,
		shuffling:false,
		shufflePendingPayment: false,
		shuffleAllowed:false,
		showShufflePanel:true,
		NFTFromShuffle:false,
		animating:false,
		animateMin:0,
		animateMax:0,
		animateAllocate:0,
		animateAllocateRequested:false,
		timeTilAutoMint:0,
		timeTilAutoMintTime:'',
		timeTilAutoExpire:0,
		timeTilAutoExpireTime:'',
		launched:this.props.state.collectionData.launched,

		animations:[],
		shuffleEndTime:3687444027375,
		shuffleMinimumEndTime:3687444027375

	}

	/* SHUFFLER... SHUFFLER... SHUFFLER... SHUFFLER... SHUFFLER... SHUFFLER... */
	/* SHUFFLER... SHUFFLER... SHUFFLER... SHUFFLER... SHUFFLER... SHUFFLER... */
	/* SHUFFLER... SHUFFLER... SHUFFLER... SHUFFLER... SHUFFLER... SHUFFLER... */

	initialiseShuffle = e => {

		images = [...this.state.examples.reverse()];
		let _zIndex = this.state.examples.length;

		window.$('#ShuffleContainer').empty()

		images.map((item, idx) => {

			window.$('<img>')
				.attr('src', item.img)
				.attr('id', item.id)
				.attr('data-pos', idx)
				.css({
					position: 'absolute',
					left: 0,
					top: 0,
					opacity: 1,
					scale:"100%",
					zIndex:_zIndex--
				})
				.appendTo('#ShuffleContainer');
		})

		var animationType = 'corners';

		switch (animationType) {
			case 'corners':

				shuffleDuration = 190; // In milliseconds
				animations = [

					{top: "-=440px", opacity: 0, scale:"200%"},
					{left: "+=440px", top: "-=440px", opacity: .3, scale:"200%", rotate:"20deg"},

					{left: "+=440px", opacity: 0, scale:"200%"},
					{left: "+=440px", top: "+=440px", opacity: .3, scale:"200%", rotate:"20deg"},

					{top: "+=440px", opacity: 0, scale:"200%"},
					{left: "-=440px", top: "+=440px", opacity: .3, scale:"200%", rotate:"-20deg"},

					{left: "-=440px", opacity: 0, scale:"200%"},
					{left: "-=440px", top: "-=440px", opacity: .3, scale:"200%", rotate:"-20deg"},

				];

				break;

			case 'trippyZoom':

				shuffleDuration = 220; // In milliseconds
				animations = [

					{opacity: 0, scale:"200%"},
					{opacity: 0, scale:"200%"},
					{opacity: 0, scale:"200%"},
					{opacity: 0, scale:"200%"}

				];

				break;

			case 'sideOut':

				shuffleDuration = 200; // In milliseconds
				animations = [

					{left: "-=440px", opacity: 0, scale:"120%"},
					{left: "+=440px", opacity: 0, scale:"120%"},
				];

				break;


			default:
				shuffleDuration = 100; // In milliseconds
				animations = [

					{top: "-=440px", opacity: 0, scale:"120%"},
					{bottom: "+=440px", opacity: 0, scale:"120%"},
				];
		}

		this.setState({shuffleCount:0, animations:animations, shuffleDuration:shuffleDuration})

		window.jQuery('#spinner-wheel').removeClass("notVisible");
		window.jQuery('#loading').hide();

	}

	doShuffle = e =>
	{
		this.setState({shuffling:true, NFTFromShuffle:true})

		/* Give the form enough time for shuffler to appear */
		window.setTimeout( () => {

			this.initialiseShuffle()

			/* Claim a slot after a short period */
			window.showWaitMessage("Starting Reshuffle.. Hold onto your hat!")
			this.claimShuffleSlot();

		}, 500 )

	}

	/* After shuffler has been started, get the actual claim */
	claimShuffleSlot() {

		const formData = new FormData();
		formData.append("method", "allocateSlotSpin");
		formData.append("collection", this.props.state.collectionData.id);
		if(this.state.testModeGetShuffleSlot) { formData.append("spinTestMode", "true"); }

		const BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc";
		axios.post(BASE_URL, formData, { withCredentials: true })
			.then(res => {
				if (res.data.success) {

					window.hideWaitMessage()

					/* Change the URL to our selected image */
					let _examples = [...this.state.examplesCopy]
					if( ! this.state.testModeGetShuffleSlot)
					{
						/* If not in test mode, change the image to one returned by CFC */
						_examples[_examples.length-1] = res.data.item;
					}

					var shuffleEndTime = Date.now() + 50000; /* Run for a long time until claimSlot() starts it stopping */
					var shuffleMinimumEndTime = Date.now() + 4500; /* Run for AT LEAST 10 seconds */

					/* Prepend URL */
					res.data.item.img = this.props.config.apiServer + res.data.item.img

					/* Update state with new NFT allocation and modified exmaple value */
					this.setState({
										allocatedNFT: 			{"id":"", "title":"", "img":"", "expiry":0, "allowShuffle":false},
										allocatedNFTPrevious:	this.state.allocatedNFT,
										awardedNFT:				res.data.item,
										spinErrorMessage: "",
										spinError: false,
										examples: _examples,
										spins: Number(res.data.spins),
										balances: res.data.balances,
										spinnerBeenSpun:true,
										shuffleAllowed:true,
										shufflePendingPayment:false,
										shuffling:true,
										animating:true,
										examples:_examples,

										shuffleCount:0,
										shuffleDuration:200,

										shuffleMinimumEndTime:shuffleMinimumEndTime,
										shuffleEndTime:shuffleEndTime

									})

				/* Init the shuffler and preff start */
				this.initialiseShuffle()
				shuffleTimerInterval = window.setInterval(() => { window.$('#shufflePresser').trigger("click") }, this.state.shuffleDuration);

				/* Start shuffle sound */
				var ffObj = document.getElementById("spinner-shuffle");
				ffObj.play()

				}
				else {
					throw({message:res.data.errorMessage});
				}

			})
			.catch(err => {

				this.setState({ allocatedNFT: {"id":"", "title":"", "img":"", "expiry":0}, spinErrorMessage: "", spinError: false })

				window.$('#spinnerAnimationStop').trigger("click")
				window.displayErrorMessage("Error shuffling an NFT - " + err.message);
				this.resetSpinButton()

				return false;

			})



	}

	/* This handles the actual frame animations */
	shuffleImages = e =>
	{

		let imgs = window.$('#ShuffleContainer img');
		let currentImage = window.$(imgs[0])

		// Show the current image
		currentImage.css('zIndex', 99).css('scale', "100%");

		if (Date.now() >= this.state.shuffleEndTime && Date.now() >= this.state.shuffleMinimumEndTime  && Number(currentImage.data("pos")) === imgs.length-1) {
			alert("Spinner didn't work. Try reloading the page.")
			console.log("Over maximum time " + this.state.shuffleEndTime)

			var ffObj = document.getElementById("spinner-shuffle");
			ffObj.pause()

			window.clearInterval(shuffleTimerInterval)

			window.$('#shuffleStopped').trigger("click");
			return;
		}

		if (Date.now() >= this.state.shuffleMinimumEndTime && Number(currentImage.data("pos")) === imgs.length-1) {

			/* We need to stop the shuffler and change the position to the last image */
			console.log("Over minimum time")

			var ffObj = document.getElementById("spinner-shuffle");
			ffObj.pause()

			window.clearInterval(shuffleTimerInterval)

			this.setState({
							shuffling:false,
							animating:false,
							shuffleAllowed:false,
							NFTFromShuffle:true
						})


			/* Pop up the modal */
			window.$('#spin-result-link').trigger("click")
			window.setTimeout( () => {
										window.$('#congratulate').trigger("click");

										/* Reload collection data */
										window.$('#reload-collection').trigger("click");

										// this.reloadSpinner()
									}, 600 )

			return;
		}



		// Shuffle the image
		let animConfig = animations[this.state.shuffleCount % this.state.animations.length];
		let _zIndex = this.state.examples.length*2;

		/* Set next interval to animation diruation less 10% */
		currentImage.animate(animConfig, this.state.shuffleDuration - ( this.state.shuffleDuration * 10 / 100), function() {

			// Reset the position of the image and move it to the bottom of the deck
			currentImage
				.css({
					zIndex: 1,
					top: 0,
					left: 0,
					opacity: 1,
					scale:"100%",
					rotate:"0deg"
				})
				.appendTo('#ShuffleContainer');


			// Shuffle the images array to match the DOM order
			//images.push(images.shift());

			let imgs = window.$('#ShuffleContainer img');

			// Redo z-Index
			imgs.map((idx, item) => {
								window.$(item).css({ zIndex:_zIndex-- })
							})

		});

		this.setState({shuffleCount:this.state.shuffleCount+1});

	}

	/* This extends an allocation while we process the payment */
	extendShuffleXummTimer = e => {

		/* Ensure result dialog closes */
		document.getElementById("modal-spinresult-close").click()

		const formData = new FormData();

		formData.append("method", "extendShuffle");
		formData.append("id", this.state.allocatedNFT.id);
		if(this.state.testModeRS) { formData.append("spinTestMode", "true"); }

		let BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc?method=extendShuffle";

		axios.post(BASE_URL, formData, { withCredentials: true })
			.then(res => {

				if (res.data.success)
				{

					/* Update copy of state objects */
					/* Update copy of state objects */

					var _item = this.state.allocatedNFT;
						_item.expiry = res.data.expiry;

					var _item2 = this.state.awardedNFT;
						_item2.expiry = res.data.expiry;

					this.setState({allocatedNFT:_item, awardedNFT:_item2})

					/* Ensure new sections of the page show */
					window.setTimeout( () => { this.reloadSpinner() }, 1500)

					addXummPayload(e);

				}
				else {
					throw({message:res.data.errorMessage});
				}

			})
			.catch(err => {

				window.hideWaitMessage();
				window.displayErrorMessage("Error extending shuffle - " + err.message);
				return false;

			})



	}

	getFreeSpin = e =>
	{
		window.showWaitMessage(`Claiming FREE spin`, false, true);

		/* Good valid address - Do a lookup to get its configuration */
		const BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc";

		const formData = new FormData();
		formData.append("method", "claimFreeSpin");
		formData.append("collection", this.props.state.collectionData.id);

		axios.post(BASE_URL, formData, { withCredentials: true })
			.then(res => {

				if( ! res.data.success)
				{
					throw(res.data.errorMessage);
				}
				else
				{
					this.updatePromoInfo()
					window.hideWaitMessage();

				}
			})
		.catch(err =>
				{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)
	}


	shuffleStopped = e =>
	{
		this.setState({shuffling:false})

	}

	doShufflePay = e =>
	{

		var offerIDs = [];
		var NFTokenIDs = [];
		var offerTypes = [];
		var offerIDsNew = [];
		var ledgerType;
		var offerType;

		for (var i = 0; i < offerIDs.length; i++)
		{
			var item = offerIDs[i].split("|");
			offerTypes.push(offerType)
			NFTokenIDs.push(item[0])
			offerIDsNew.push(item[1])
		}

		addXummPayload( { "type":ledgerType, "which":"NFTokenAcceptOffer", "OfferIDs":offerIDsNew, "NFTokenIDs":NFTokenIDs, "OfferTypes":offerTypes, "values":{} } )

	}

	doNoShuffle = e =>
	{

		/* Quietly terminate shuffle */
		this.terminateShufflePayment(false, '')

		/* Reload collection data after DB has done it's stuff */
		window.setTimeout( () => { window.toast("Your NFT will now begin the mint process.", "center", 3500) }, 400 )
		window.setTimeout( () => { window.$('#reload-collection').trigger("click"); }, 1200 )

	}

	terminateShufflePayment = (_reloadPage=false, _msg="Terminating Shuffle") =>
	{

		_reloadPage = false;

		if(_msg.length)
		{
			window.showWaitMessage(_msg)
		}

		const formData = new FormData();
		formData.append("method", "terminateShuffle");
		formData.append("id", this.state.allocatedNFT.id);
		formData.append("collection", this.props.state.collectionData.id);
		if(this.state.testModeRS) { formData.append("spinTestMode", "true"); }

		let BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc?method=terminateShuffle";

		axios.post(BASE_URL, formData, { withCredentials: true })
			.then(res => {

				if (res.data.success)
				{
					if(_reloadPage)
					{
						document.location.reload()
					}
					else
					{
						if(_msg.length)
						{
							window.hideWaitMessage();
						}

						this.setState({shufflePendingPayment:false})

						//if ( ! this.state.testModeRS ) { this.reloadSpinner() }
						this.reloadSpinner()
					}

				}
				else {
					throw({message:res.data.errorMessage});
				}

			})
			.catch(err => {

				window.hideWaitMessage();
				window.displayErrorMessage("Error cancelling shuffle - " + err.message);

				window.$('#spinnerAnimationStop').trigger("click")

				return false;

			})


	}

	updateAutoMint = e =>
	{

		if(this.state.allocatedNFT.id === 0)
		{
			window.clearInterval(pendingShufflePaymentTimer)
			return true
		}

		const currentTimelocal = new Date()
		const currentUTC = Number(currentTimelocal.getTime().toString().substring(0,10))
		const tm = this.state.allocatedNFT.expiry - currentUTC

		if(tm <= 0 && this.state.allocatedNFT.expiry > 0)
		{
			window.clearInterval(pendingShufflePaymentTimer)
			this.terminateShufflePayment(true, "<p>Reloading Spinner.</p><p>Reshuffle offer has expired.</p>")
		}

		this.setState({timeTilAutoMintTime:toDaysMinutesSeconds( tm > 0 ? tm : 0, true, true, false )})
		if(this.state.balances.shuffle)
		{
			this.checkBalances(tm)
		}

	}


	updateAutoExpire = e =>
	{

		const currentTimelocal = new Date()
		const currentUTC = Number(currentTimelocal.getTime().toString().substring(0,10))
		const tm = this.state.timeTilAutoExpire - currentUTC

		if(tm <= 0 && this.state.timeTilAutoExpire > 0)
		{
			window.clearInterval(pendingExpiryPaymentTimer)
			window.showWaitMessage("<p>Reloading Spinner as the spins order has expired.</p>")
			window.setTimeout( () => { this.reloadSpinner(); window.hideWaitMessage() }, 4500)
		}

		this.setState({timeTilAutoExpireTime:toDaysMinutesSeconds( tm > 0 ? tm : 0, true, true, false )})
		this.checkBalances(tm)

	}

	checkBalances = tm =>
	{

		/* Every xx seconds, test if payment received */
		if(tm % 10 === 0)
		{
			const formData = new FormData();
			formData.append("method", "getBalances");
			formData.append("collection", this.props.state.collectionData.id);

			const BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc";
			axios.post(BASE_URL, formData, { withCredentials: true })
				.then(res => {
					if (res.data.success) {

						if(res.data.balances.spins === 0 && res.data.balances.shuffle === 0 )
						{
							/* All paid up */
							window.clearInterval(pendingShufflePaymentTimer)
							window.clearInterval(pendingExpiryPaymentTimer)
							this.reloadSpinner();
							window.hideWaitMessage()
						}

					}
					else {
						throw({message:res.data.errorMessage});
					}

				})
				.catch(err => {

					window.displayErrorMessage("Error checking payment status - " + err.message);

					return false;

				})
		}

	}

	terminateSpinsPayment = e =>
	{

		/* Stop timer from kicking in */
		window.clearInterval(pendingExpiryPaymentTimer)

		const formData = new FormData();
		formData.append("method", "terminateSpinsPayment");
		formData.append("collection", this.props.state.collectionData.id);

		const BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc";
		axios.post(BASE_URL, formData, { withCredentials: true })
			.then(res => {
				if (res.data.success) {

					/* All paid up */
					this.reloadSpinner(); window.hideWaitMessage()

				}
				else {
					throw({message:res.data.errorMessage});
				}

			})
			.catch(err => {

				window.displayErrorMessage("Error checking payment status - " + err.message);

				return false;

			})
	}

	/* SPINNER... SPINNER... SPINNER... SPINNER... SPINNER... SPINNER... SPINNER... */
	/* SPINNER... SPINNER... SPINNER... SPINNER... SPINNER... SPINNER... SPINNER... */
	/* SPINNER... SPINNER... SPINNER... SPINNER... SPINNER... SPINNER... SPINNER... */

	reloadSpinner = e =>
	{
		window.clearInterval(pendingShufflePaymentTimer)
		window.clearInterval(pendingExpiryPaymentTimer)
		this.setState({shufflePendingPayment:false, shuffleAllowed:false, NFTFromShuffle:false, animating:false})
		this.loadSamples()

		/* Reload collection data after DB has done it's stuff */
		window.setTimeout( () => { window.$('#reload-collection').trigger("click"); }, 1200 )

	}

	spinTheWheel = e => {

		/* Reset all spinner elements */
		window.$('#spinnerAnimationReset').click();
		/* Hide modal */
		window.$('#spin-result-modal').modal('hide');

		/* Get spinning */
		window.setTimeout( () => { this.checkAndSpin(true) }, 350)
	}

	spinTimer = e =>
	{
		if( ! this.state.animating )
		{
			/* Start the spinner */
			console.log("Start Animation " + ( Date.now() - this.state.animateStart) );
			window.$('#spinnerAnimationStart').trigger("click")
			this.setState({animating:true})
			return true;
		}

		if(Date.now() >= this.state.animateAllocate)
		{
			if( Date.now() >= this.state.animateAllocate && this.state.animateAllocateRequested == false)
			{

				/* Replace image with placeholder in case of error claiming */
				var _examples = [...this.state.examplesCopy]
				_examples[window.slotPosition - 1] = { id: "null", img: this.props.config.sizes.placeholders.error };
				this.setState({examples:_examples})

				/* Claim a slot after a few seconds */
				console.log("Claim after " + ( Date.now() - this.state.animateStart) )
				this.setState({animateAllocateRequested:true})
			 	this.claimSlot();

				return true;
			}

			/* We are at least xx seconds into the animation */
			if(this.state.allocatedNFT.id.length && Date.now() >= this.state.animateMin)
			{

				console.log("Congratulate after " + ( Date.now() - this.state.animateStart) )

				window.clearInterval(spinTimeCheck)
				window.slotMachine.stop(1);

				/* Start the fanfare is shuffling not allowed */
				window.$('#congratulate').trigger("click");
				window.$('#spin-result-link').trigger("click");


				this.resetSpinButton()
				this.setState({animating:false})

				/* If shuffle is allowed, diplayu pament options */
				if(this.state.allocatedNFT.allowShuffle)
				{
					/* Ensure correct elements display on page */
					this.setState({shuffleAllowed:false, shufflePendingPayment:true})

					/* Create shuffle cards */
					window.setTimeout( () => { this.initialiseShuffle() }, 250 )

					/* Start the countdown */
					pendingShufflePaymentTimer = window.setInterval( () => { this.updateAutoMint() }, 1000)
				}
				else
				{
					if(this.props.state.collectionData.spinnerInfo.type === 2)
					{
						/* This is the result of a shuffle being made */

					}
				}

				return true;
			}

			if(Date.now() > this.state.animateMax)
			{
				/* We are at least xx seconds into the animation */
				console.log("Timeout after " + ( Date.now() - this.state.animateStart) )
				window.slotMachine.stop(1);
				window.clearInterval(spinTimeCheck)
				window.displayErrorMessage("<p>The xMart server returned an error.</p><p>If your number of available spins has decreased, you can find the NFT in the 'Account \ My NFTs' menu</p><p>If they haven't decreased, please retry the spin. Sorry for any inconvenience!</p>");

				this.setState({animating:false})

				return true;
			}

		}

	}

	checkTotals = e =>
	{

		var xummCheck = checkXummLoggedIn(e)

		if(xummCheck.continue)
		{

			window.showWaitMessage("Checking remaining spins")

			let BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc?method=getSlotSpins&collection=" + this.props.state.collectionData.id + "&includeTokens=false&currency="+e.currentTarget.dataset.type;
			if(this.state.testMode) { BASE_URL += '&spinTestMode=true' }

			axios.get(BASE_URL, { withCredentials: true })
				.then(res => {

					if (res.data.success) {

						const remaining = res.data.tokenCurrency === 'XRP' ? res.data.spinsAvailableXRP : res.data.spinsAvailableCustom;
						if(remaining <= 0)
						{
							throw({message:"There are no spins remaining sorry."});
						}

						this.setState({"swapCurrency":res.data.tokenCurrency, "swapRemaining":remaining})

						/* If in restricted mode, limit to 1 */
						if(this.props.config.rs[2] && this.props.config.userData.display === false )
						{
							this.setState({nftsRemaining:1, tokenCurrency:res.data.tokenCurrency, tokensPerSpin:res.data.tokensPerSpin});
						}
						else
						{
							this.setState({nftsRemaining:remaining, tokenCurrency:res.data.tokenCurrency, tokensPerSpin:res.data.tokensPerSpin});
						}

						window.setTimeout( () => { document.getElementById("swap-tokens-link").click(); window.hideWaitMessage(); }, 1350)
						window.setTimeout( () => { window.$('#refreshState').trigger("click"); window.$('#swap-modal #offer-amount').focus(); window.$('#swap-modal #spinCost').focus() }, 600)

					}
					else {
						throw({message:res.data.errorMessage});
					}

				})
				.catch(err => {

					window.hideWaitMessage();
					window.displayErrorMessage("Error getting spin info - " + err.message);

					window.$('#spinnerAnimationStop').trigger("click")

					return false;

				})
		}



	}

	loadSamples = e =>
	{
		var BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc?method=getCollectionSpinnerSamples&collection=" + this.props.state.collectionData.id;
		if(this.state.testMode) { BASE_URL += '&spinTestMode=true' }

		axios.get(BASE_URL, { withCredentials: true })
			.then(res => {

				this.setState({

						examples: 				res.data.items,
						examplesCopy: 			[...res.data.items],

						btnDisabled: 			Number(res.data.spins) === 0 && this.props.state.testMode === false ? true : false,
						spinInfo: 				res.data.spinInfo,
						spins:					res.data.spinInfo.mySpins.total,
						balances: 				res.data.balances,
						swapsPending:			Number(res.data.swapsPending),
						preview:				res.data.preview,

						tokens: 				Number(res.data.collectionInfo.tokens).toFixed(4),
						tokenCurrency:			res.data.collectionInfo.token,
						tokensPerSpin:			Number(res.data.collectionInfo.tokensPerSpin),
						tokensPerRespin:		Number(res.data.collectionInfo.tokensPerRespin),
						collectionID:			res.data.collectionInfo.id,
						allocatedNFT:			res.data.allocatedNFT,
						allocatedNFTPrevious:	res.data.allocatedNFT,
						//awardedNFT:				res.data.allocatedNFT,
						address: 				this.props.config.userData.walletAddress,

						timeTilAutoExpire:		res.data.balances.spinsExpires,

						shuffleAllowed:			res.data.shuffleAllowed,
						shufflePendingPayment:	res.data.shufflePending,
						shuffleLocked:			res.data.shuffleLocked,
						shuffleCollection:		res.data.shuffleCollection && res.data.spinInfo.available.xrp > 0 ? true : false

				})

				if(res.data.shufflePending === true && res.data.shuffleLocked === false)
				{
					pendingShufflePaymentTimer = window.setInterval( () => { this.updateAutoMint() }, 1000)
				}

				if(res.data.balances.spinsExpires)
				{
					pendingExpiryPaymentTimer = window.setInterval( () => { this.updateAutoExpire() }, 1000)
				}

				if(res.data.shuffleAllowed)
				{

					/* Use exmaple images in shuffler */
					this.setState({initialisedShuffle:true, showLoading:false})
					window.setTimeout( () => { this.initialiseShuffle() }, 250 )

				}
				else
				{

					/* Call the spinner initialise button - This is done within the spinner as animation may be different etc */
					window.setTimeout( () => { window.$('#spinner-init').trigger("click"); }, 850 )

				}
/*
				else
				{
				 alert("Unhandled spinner type")
				}
				*/

			})
			.catch(err => {
				window.displayErrorMessage("Error getting sample images for spinner - " + err.message);
				return false;

			})
	}

	/* Ensure we have enough spins and start the animation */
	checkAndSpin(_startWheel) {

		window.showWaitMessage("Checking remaining number of spins");

		this.setState({
			btnDisabled: true,
			btnText: "Checking Spins",
			NFTFromShuffle:false
		});

		const formData = new FormData();
		formData.append("method", "getSlotSpins");
		formData.append("collection", this.props.state.collectionData.id);
		formData.append("includeTokens", "false");
		if(this.state.testMode) { formData.append("spinTestMode", "true"); }

		const BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc";

		axios.post(BASE_URL, formData, { withCredentials: true })
			.then(res => {

				window.hideWaitMessage();

				if (res.data.success) {

					this.setState({
						spins: Number(res.data.spins),
						btnDisabled: true
					});

					if ( this.state.spins <= 0 ) {
						if (_startWheel) {
							window.displayErrorMessage("Sorry, you have no spins left for this collection");
						}
					}
					else {

						/* How long to spin for */
						var _startTime = Date.now()
						var _minTime = this.props.state.collectionData.spinnerInfo.type === 1 ? _startTime + (3000 ) : _startTime + (3000 );
						var _allocateTime = this.props.state.collectionData.spinnerInfo.type === 1 ? _startTime + (500 ) : _startTime + (500 );
						var _maxTime = this.props.state.collectionData.spinnerInfo.type === 1 ? _startTime + (30000 ) : _startTime + (30000 );

						this.setState({
							btnText: "Good luck!!",
							btnDisabled:true,
							animating:false,
							animateStart:_startTime,
							animateMin:_minTime,
							animateMax:_maxTime,
							animateAllocate:_allocateTime,
							animateAllocateRequested:false
						});

						/* Timer to check if we should stop the spinner */
						spinTimeCheck = window.setInterval(() => { this.spinTimer() }, shuffleDuration);



					}
				}
				else {

					window.clearInterval(spinTimeCheck)
					window.displayErrorMessage("Error getting spins available - " + res.data.errorMessage);
					window.$('#spinnerAnimationStop').trigger("click")

				}

			})
			.catch(err => {

				window.displayErrorMessage("Error spinning - " + err.message);
				return false;

			})

	}

	/* After spinner has been started, get the actual claim */
	claimSlot() {

		this.setState({ allocatedNFT: {"id":"", "title":"", "img":"", "expiry":0}, spinErrorMessage: "", spinError: false })

		const formData = new FormData();
		formData.append("method", "allocateSlotSpin");
		formData.append("collection", this.props.state.collectionData.id);
		if(this.state.testMode) { formData.append("spinTestMode", "true"); }

		const BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc";
		axios.post(BASE_URL, formData, { withCredentials: true })
			.then(res => {

				if (res.data.success) {

					/* Change the URL to our selected image */
					var _examples = this.state.examples
					_examples[window.slotPosition - 1] = res.data.item;
					_examples[0] = res.data.item;
					_examples[1] = res.data.item;
					this.setState({examples:_examples})

					/* Update state with new NFT allocation and modified exmaple value */
					this.setState({
										allocatedNFT: 			res.data.item,
										allocatedNFTPrevious:	res.data.item,
										awardedNFT:				res.data.item,
										spinErrorMessage: "",
										spinError: false,
										examples: this.state.examples,
										spins: Number(res.data.spins),
										spinnerBeenSpun:true
									})

					window.allocatedNFT = res.data.item;

				}
				else {
					throw({message:res.data.errorMessage});
				}

			})
			.catch(err => {

				window.$('#spinnerAnimationStop').trigger("click")
				window.displayErrorMessage("Error allocating an NFT - " + err.message);
				this.resetSpinButton()

				return false;

			})



	}

	resetSpinButton()
	{
		window.clearInterval(spinTimeCheck)

		this.setState({
			btnText: "Spin & Win!",
			btnDisabled: Number(this.props.state.spins) <= 0 ? true : false,
			animating:false,
			NFTFromShuffle:false
		});
	}

	buyTokens(obj) {
		window.open(obj.target.dataset.link, "_blank")
	}

	updatePromoInfo = e => {

		var BASE_URL = this.props.config.apiServer + "/com/external/spins.cfc?method=getCollectionSpinnerSamples&collection=" + this.props.state.collectionData.id;
		if(this.state.testMode) { BASE_URL += '&spinTestMode=true' }

		axios.get(BASE_URL, { withCredentials: true })
			.then(res => {

				this.setState({
						btnDisabled: 			Number(res.data.spins) === 0 && this.state.testMode === false ? true : false,
						spinInfo: 				res.data.spinInfo,
						spins:					res.data.spinInfo.mySpins.total,
						balances: 				res.data.balances,
						swapsPending:			Number(res.data.swapsPending),
						address: 				this.props.config.userData.walletAddress

				})

				window.toast("Free spin granted - Good luck!", "center")

				this.resetSpinButton();

			})

			.catch(err => {
				window.displayErrorMessage("Error fresh data for spinner - " + err.message);
				return false;

			})

	}

	componentDidMount() {

		this.loadSamples()

	}

	callbackFromParent = (dataFromChild) =>
	{
		var _newState = this.state;
		{ Object.keys(dataFromChild).map((key, idx) => {
			var value = dataFromChild[key];
			_newState[key] = value
		})}
		this.setState(_newState)
	}

    render() {

		var showSpinButton = true
		var showTokenButton = false;
		var qtyLeft = 0
		var promoCanClaim = false
		var showXRPButton = false;

		if( this.props.state.collectionData.trustLineCurrency !== 'XRP')
		{
			showTokenButton = true
			qtyLeft = this.props.state.collectionData.spinnerInfo.spinsLeftCustom

		}

		/* Launchpad */
		if(this.props.state.collectionData.launchpad === 'Yes')
		{

			/* If ended and we allow XRP */
			if(this.props.state.collectionData.launched && this.props.state.collectionData.launchpadInfo.postXRP == 'Yes')
			{
				showXRPButton = true
			}

		}


		if( this.props.state.collectionData.trustLineCurrency === 'XRP')
		{
			showXRPButton = true
			qtyLeft = this.props.state.collectionData.spinnerInfo.spinsLeftXRP
		}

		var showPromoButton = this.props.state.collectionData.ga === 'Yes';
		if(showPromoButton)
		{
			showTokenButton = false;
			showXRPButton = false;
			qtyLeft = this.props.state.collectionData.spinnerInfo.spinsLeftPromo
			promoCanClaim = this.state.spinInfo.mySpins.promo ? true : false

		}

		if(this.state.shuffleAllowed)
		{
			/* Hide all other buttons */
			showTokenButton = false;
			showXRPButton = false;
			showSpinButton = false;
		}

		var owesMoney = this.state.balances.spins || this.state.balances.shuffle ? true : false;
		var decisionRequired = this.state.shuffleCollection && this.state.allocatedNFT.id.length && owesMoney === false && this.state.animating === false && this.state.shuffleAllowed === false ? true : false

		return (

			<div className="container">
				<div className="text-center col-12" id="loading">
					<h3>Loading Spinner</h3>
					<img src={this.props.config.sizes.placeholders.preloader} alt="Loading.." />
				</div>

				<div id="spinner-wheel" className="row notVisible">

					<div className="row mb-4 hid">
						<div className="col-12">
							{/* Intro */}
							<div className="intro d-flex justify-content-between align-items-end m-0">

								<div className="intro-content">
									<h3 className="mt-3 mb-0">Collection Spinner</h3>
								</div>

								{ this.props.state.collectionData.br === 'Yes' ?
								(
								<div className="intro-btn">
									<a className="btn content-btn" href={this.props.state.collectionData.fullURL + 'browse-all'}>View Collection Items</a>
								</div>
								) : ( <></> )}

							</div>
						</div>
					</div>

					{ /* Standard spinner */ }
					<div className="col-12 col-lg-6 align-items-center spinner-hidden">
						<div>
							{ this.props.state.collectionData.spinnerInfo.type === 1 || 0 === 0 ?

								<SpinnerType1
													config={ this.props.config }
													state={this.state}
													parentState={this.props.state}
													showKeys={this.showKeys}
													who={this.props.who}
													collectionID={this.props.collectionID}
													spinnerType={this.props.state.collectionData.spinnerInfo.type}
													checkTotals={this.checkTotals}
													spinTheWheel={this.spinTheWheel}
													doShuffle={this.doShuffle}
													callbackFromParent={this.callbackFromParent}
													showTokenButton={showTokenButton}
													showXRPButton={showXRPButton}
													showPromoButton={showPromoButton}
													showSpinButton={showSpinButton}
													/> : <></> }



								{ ( this.props.config.rs[1] && this.props.config.userData.display === false ) ?
									(
										<>
											<div className="alert alert-warning text-center mt-3">
												As a temporary restriction, we have disabled the spinner.
											</div>
										</>
									) :
									(

										<>

											{ this.props.state.collectionData.launched === false || showSpinButton === false || ( this.state.balances.spins + this.state.balances.shuffle ) > 0  || decisionRequired?
											(
												<></>
											) :
											(
												<>
													{/* Spin and buy buttons - If no spins owned, show buy button first */ }

													{/* DESKTOP CAN SEE BUTTON AND MESSAGE */}
													{/* DESKTOP CAN SEE BUTTON AND MESSAGE */}
													{/* DESKTOP CAN SEE BUTTON AND MESSAGE */}
													<div className="d-none d-md-block">
														<button id="slots-spin" className="btn  w-100 mb-4 py-3 mt-4" style={{"fontSize":"120%"}} onClick={this.spinTheWheel} disabled={ ( this.state.spins <= 0 || this.state.btnDisabled || this.state.showShuffleDiv  || this.state.shufflePendingPayment || this.state.animating || owesMoney  || decisionRequired) }>{this.state.btnText}</button>
														<div className="d-md-none">
															<SpinnerBuyButton
																				state={this.props.state}
																				parentState={this.props.state}
																				config={this.props.config}
																				spinInfo={this.state.spinInfo}
																				qtyLeft={qtyLeft}
																				getFreeSpin={this.getFreeSpin}
																				showTokenButton={showTokenButton}
																				showXRPButton={showXRPButton}
																				showPromoButton={showPromoButton}
																				showSpinButton={showSpinButton}
																				checkTotals={this.checkTotals}
																				showTotalMessage={true}
																				disabled={this.state.showShuffleDiv  || this.state.shufflePendingPayment} />
														</div>
													</div>

													{/* MOBILE CAN ONLY SEE THE 2 BUTTONS */}
													{/* MOBILE CAN ONLY SEE THE 2 BUTTONS */}
													{/* MOBILE CAN ONLY SEE THE 2 BUTTONS */}
													<div className="row g-0 mt-3 d-md-none">

														<div className="col-6 p-1">
															<SpinnerBuyButton
																				state={this.props.state}
																				parentState={this.props.state}
																				config={this.props.config}
																				spinInfo={this.state.spinInfo}
																				qtyLeft={qtyLeft}
																				getFreeSpin={this.getFreeSpin}
																				showTokenButton={showTokenButton}
																				showXRPButton={showXRPButton}
																				showPromoButton={showPromoButton}
																				showSpinButton={false}
																				checkTotals={this.checkTotals}
																				showTotalMessage={false}
																				disabled={this.state.showShuffleDiv  || this.state.shufflePendingPayment} />
														</div>

														<div className="col-6 p-1">
															<button id="slots-spin" className="btn px-0 py-3 w-100" onClick={this.spinTheWheel} disabled={ ( this.state.spins <= 0 || this.state.btnDisabled || this.state.showShuffleDiv  || this.state.shufflePendingPayment || this.state.animating || owesMoney  || decisionRequired ) }>{this.state.btnText}</button>
														</div>

														<div className="col-12">

															{ showPromoButton ?
															(
																<>
																	{ this.state.spinInfo.promoCost === 0 ?
																	(
																		<>
																			<p className="fw-bold text-center mt-3">{qtyLeft} promo spins available.</p>
																		</>
																	)
																	:
																	(
																		<>
																			<p className="fw-bold text-center mt-3">{qtyLeft} promo spins available.</p>
																		</>

																	)
																	}
																</>

															) :
															(
																<>
																	{ showTokenButton && showXRPButton ?
																	(
																		<p className="fw-bold text-center mt-3 mb-2" style={{fontSize:"12px"}}>You have {this.state.spins} spins & there are {this.props.state.collectionData.spinnerInfo.spinsLeftCustom > 0 ? this.props.state.collectionData.spinnerInfo.spinsLeftCustom : 0} spins available to buy with {this.props.state.collectionData.trustLineCurrency} and {this.props.state.collectionData.spinnerInfo.spinsLeftXRP > 0 ? this.props.state.collectionData.spinnerInfo.spinsLeftXRP : 0} spins available to buy with XRP.</p>
																	) :
																	(
																		<>
																			{ showTokenButton ? (<p className="fw-bold text-center mt-3 mb-2">You have {this.state.spins} unused spins. <br className="d-md-none"/> {this.props.state.collectionData.spinnerInfo.spinsLeftCustom > 0 ? this.props.state.collectionData.spinnerInfo.spinsLeftCustom : 0} spins available.</p>) : ( <></>) }
																			{ showXRPButton ? (<p className="fw-bold text-center mt-3 mb-2">You have {this.state.spins} unused spins. <br className="d-md-none"/>{this.props.state.collectionData.spinnerInfo.spinsLeftXRP > 0 ? this.props.state.collectionData.spinnerInfo.spinsLeftXRP : 0} spins available.</p>) : ( <></>) }
																		</>
																	) }
																</>
															)}

														</div>
													</div>
												</>
											)}



										</>


									)}




								<button id="spinnerAnimationStart" className='hid'>Start Animation</button>
								<button id="spinnerAnimationStop" className='hid'>Stop animation</button>




						</div>
					</div>

					<div className="col-12 col-lg-6 spinner-hidden">

						{ this.state.shuffleAllowed === true ?
						(
							<div className="alert alert-success p-3 fw-bold text-center m-0 mt-3">
								<p className="fw-bold text-center">TIME TO SHUFFLE!</p>
								<p>Payment for a reshuffle of NFT "{this.state.allocatedNFTPrevious.title}" <strong>has been received.</strong></p>
								<p>Click <strong>"Begin Shuffle"</strong> to find out which NFT you're going to win this time.</p>

							</div>
						) : ( <></> )}


						{ decisionRequired ?
						(
							<div className="alert alert-info p-3 fw-bold text-center m-0 mt-3">
								<p className="fw-bold text-center">TO SHUFFLE OR NOT TO SHUFFLE?</p>
								<p>Happy with this NFT? Do you want to <strong>keep it</strong> or <strong>reshuffle it</strong> and win a different one?</p>
								<p>If you do nothing, "{this.state.allocatedNFT.title}" will be <strong>automatically minted</strong> in {this.state.timeTilAutoMintTime}.</p>
								<div className='row'>
									<div className='col-12 col-md-6 mb-2'>
										<button type="button" className="btn w-100 btn-sm" onClick={this.extendShuffleXummTimer} data-type="payForShuffle" data-offers={'n/a'} data-buysell="buy" data-nftokenids={this.state.allocatedNFT.id} disabled={false} >Shuffle for {this.state.tokensPerRespin} XRP</button>
									</div>
									<div className='col-12 col-md-6 mb-2'>
										<button type="button" className="btn w-100 btn-sm btn-bordered" onClick={this.doNoShuffle}>Keep it</button>
									</div>
								</div>

							</div>
						) :
						(
							<>
								{ owesMoney ?
								( 	<div className='pendingPayments'>
										<PendingPayments
													state={this.props.state}
													parentState={this.state}
													checkTotals={this.checkTotals}
													config={this.props.config}
													spinInfo={this.state.spinInfo}
													balances={this.state.balances}
													qtyLeft={qtyLeft}
													extendShuffleXummTimer={this.extendShuffleXummTimer}
													terminateShufflePayment={this.terminateShufflePayment}
													terminateSpinsPayment={this.terminateSpinsPayment}
													/>
									</div>)
								:
								(
									<>
									{ this.state.shuffleAllowed === false ?
									(
										<>

											{ this.state.allocatedNFT.id.length && 0 === 1 ?
											(
												<div className="alert alert-info mb-4 d-none">
													Spun items can be found by going to <a href={this.props.config.userData.url + '#' + this.props.state.collectionData.category.id} target="_blank">"Account\My NFTs"</a>. They will show as reserved until they have been minted on the network and accepted by you using your wallet.
												</div>
											) : ( <></> )}

											{ this.props.state.shuffleAllowed ?
											(
												<p>
												Do your shuffle
												</p>
											)
											:
											( <>
												{ this.state.swapsPending > 0 ?
												(
													<>
														<div className="alert alert-info text-center mb-4">
															You have {this.state.swapsPending} swap {this.state.swapsPending > 1 ? 's' : '' } pending authorization. Refer to your account balances which can be found on the "Accounts menu". Please reload this page after 30 seconds to refresh your spin total.
														</div>
													</>

												) : ( <></> )}
												</>)}

											{ ( this.props.config.rs[3] && this.props.config.userData.display === false) ?
											(
												<>
													<div className="alert alert-warning text-center">
														As a temporary restriction, the ability to buy new spins is disabled.
													</div>

													<button type="input" className="btn  btn-bordered w-100 mt-3" disabled={true}>Buy Spins</button>
												</>

											) :
											(
												<div id="">

													<button className="hid" id="launchSwap" data-type="" onClick={this.checkTotals}>Get balance</button>





													{ owesMoney === false && this.props.state.collectionData.launchpad === 'Yes' && this.props.state.collectionData.ga === 'No' && this.props.state.collectionData.launchpadInfo.trueLP ?
													( 	<div className='launchpad'>
															<LaunchPad
																		state={this.props.state}
																		parentState={this.state}
																		checkTotals={this.checkTotals}
																		config={this.props.config}
																		getFreeSpin={this.getFreeSpin}
																		spinInfo={this.state.spinInfo}
																		qtyLeft={qtyLeft}
																		showTokenButton={showTokenButton}
																		showXRPButton={showXRPButton}
																		showPromoButton={showPromoButton}
																		showSpinButton={showSpinButton}
																		/>
														</div>)
														: (<></>)}

													{ owesMoney === false && this.props.state.collectionData.ga === 'Yes' ?
													( 	<div className='giveaway'>
															<SpinnerGiveaway
																			state={this.props.state}
																			parentState={this.state}
																			updatePromoInfo={this.updatePromoInfo}
																			checkTotals={this.checkTotals}
																			config={this.props.config}
																			getFreeSpin={this.getFreeSpin}
																			spinInfo={this.state.spinInfo}
																			qtyLeft={qtyLeft}
																			showTokenButton={showTokenButton}
																			showXRPButton={showXRPButton}
																			showPromoButton={showPromoButton}
																			showSpinButton={showSpinButton}
																			/>
														</div>)
														: (<></>)}

													{  owesMoney === false && ( ( this.props.state.collectionData.launchpad === 'No' && this.props.state.collectionData.ga === 'No' ) || ( this.props.state.collectionData.launchpad === 'Yes' && this.props.state.collectionData.launchpadInfo.trueLP === false ) ) ?
													( 	<div className="spinnerbuttons">
															<SpinnerButtons
																			state={this.props.state}
																			parentState={this.state}
																			checkTotals={this.checkTotals}
																			config={this.props.config}
																			spinInfo={this.state.spinInfo}
																			qtyLeft={qtyLeft}
																			showTokenButton={showTokenButton}
																			showXRPButton={showXRPButton}
																			showPromoButton={showPromoButton}
																			showSpinButton={showSpinButton}
																			/>
														</div>
													)
													: (<></>)}


												</div>

											)}

										</>
									) : ( <></> )}
								</>
								)}
							</>

						)}

						<button type="button" className="btn  btn-bordered w-100 mt-3 hid" id="swap-tokens-link" data-bs-toggle="modal" data-bs-target="#swap-modal">Swap</button>
						<button type="button" className="btn  btn-bordered w-100 mt-3 hid" id="spin-result-link" data-bs-toggle="modal" data-bs-target="#spin-result-modal">Result</button>
						<button type="button" className="btn  btn-bordered w-100 mt-3 hid" id="reloadSpinner" onClick={this.reloadSpinner}>Reload spinner</button>
						<button type="button" className="btn  btn-bordered w-100 mt-3 hid" id="shufflePresser" onClick={this.shuffleImages}>Shuffle 1</button>

					</div>


					{/* The modal for showing the swap tokens inputs */}
					<ModalSwapTokens	config={ this.props.config } state={this.state} reloadSpinner={this.reloadSpinner} />

					{ this.state.allocatedNFT !== null ?
						(
							<ModalSpinResult	config={ this.props.config }
												state={this.state}
												spinTheWheel={this.spinTheWheel}
												doShuffle={this.doShuffle}
												doShufflePay={this.doShufflePay}
												doNoShuffle={this.doNoShuffle}
												extendShuffleXummTimer={this.extendShuffleXummTimer}
												callbackFromParent={this.callbackFromParent}
												reloadSpinner={this.reloadSpinner}
												terminateShufflePayment={this.terminateShufflePayment}/>
						) : ( <></> ) }

				</div>
			</div>
        );
    }
}

export default CollectionSpinnerWheelComponent;