import React, { Component } from 'react';

class ViewItems extends Component {

	render() {

		return (
			<div className="col-12">
				<div className="row items">
					{ this.props.nfts.map((item, idx) => {

						const _img = item.error ? '/img/placeHolders/ipfs-error.png' : ( item.pending === false ? this.props.config.apiServer + item.preview : '/src/img/ipfs-downloading.png');
						const _title = item.error ? item.errorMessage : ( item.pending === true ? "Pending" : item.metaData.name);
						const _description = item.error ? "" : ( item.pending === true ? "" : item.metaData.description);
						const _shopLink = `https://makeitphysical.xmart.art/products?image=${escape(item.URIMedia)}|${item.metaData.width}x${item.metaData.height}`

						return (
									<div key={`nft_${idx}`} className="col-12 col-sm-6 col-lg-4 p-0">
										<div className="card text-center m-2 p-0">

											<div className="image-over">

												<h5 className="mb-2">{_title}</h5>

												{item.pending ?
												(
													<img className="card-img-top" src={_img} alt={"Pending IPFS download"} />

												) : (
													<a href={item.URI_media} target="_blank" rel="noreferrer">
														<img className="card-img-top" src={_img} alt={_title} />
													</a>
												)}

											</div>

											<div className="card-caption col-12 px-4 mb-3">

												{/* <div className="card-body p-0 pt-2">
													{_description.length ? ( <p className="p-0 m-0">{_description}</p> ) : ( <></> )}
												</div>
												*/}

												<div className="">
													{item.error ?
													(
														<></>

													) : (
															<a className="btn btn-bordered btn-smaller py-2 mt-3 w-100" target="_blank" href={item.URI}><i className="icon-magnifier me-2" />View MetaData</a>
														)}


														<a className="btn btn-bordered btn-smaller mt-3 py-2 w-100" target="_blank" href={this.props.config.links.explore + "/" + item.NFTokenID}><i className="icon-magnifier me-2" />View On Explorer</a>

														{ this.props.config.serverMode === 'LOCAL' ?
														(
															<a className="btn btn-bordered btn-smaller mt-3 py-2 w-100" target="_blank" href={_shopLink}><i className="icon-magnifier me-2" />Make it Physical</a>
														)
														:
														( <></> )
														}



												</div>


											</div>

										</div>
									</div>
							);
					})}
				</div>
			</div>
		)
	}

}

export default ViewItems;