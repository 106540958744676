import React, { Component } from 'react';
import ModalViewNFTButtons from './ModalViewNFTButtons';
import ModalTransferNFTTabcontent from './ModalTransferNFTTabcontent';

import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class ModalViewNFT extends Component
{

	state = {
				modalTag:"",
				modalButton:"",
				modalTitle:"",
				secretWord:"sss",
				secretWordConfirmation:""
	}

	triggerReload = e => {
		window.$('.xummReloader').trigger("click");
	}

	cancelBuyOffer = e =>
	{

		const _offerID = e.currentTarget.dataset.offer;

		window.Swal.fire(
			{
				title: 'Decline Offer',
				html: '<p className="text-center">Are you sure you want to cancel this buy offer?<p><p>You can always add another one in the future.</p>',
				showCancelButton: true,
				confirmButtonText:"Cancel Offer",
			}).then((result) => {
				if (! result.isDismissed)
				{
					window.callAPI(
						{
										component: "post",
										method: "cancelBuyOffer",
										offerID:_offerID,
										nftID:this.props.state.nft.id,
										redirect:this.props.state.nft.URI
									},
						function (_jsonData)
						{
							if(_jsonData.success)
							{
								/* Navigate to wallet auth */
								document.location.replace( _jsonData.info.redirect )
							}
							else
							{
								window.displayErrorMessage("Unable to cancel offer - " + _jsonData.errorMessage)
							}
						}
					)
				}
			})
	}

	burnToken = e =>
	{

		e.preventDefault();

		const _NFTokenID = e.currentTarget.dataset.token;

		window.Swal.fire(
			{
				title: 'Burn Token',
				html: '<p className="text-center">You will be redirected to Xumm to confirm the burn.</p><p className="text-center">Please ensure you double check the correct token ID in the transaction.</p>',
				showCancelButton: true,
				confirmButtonText:"Authorize in Xumm",
			}).then((result) => {
				if (! result.isDismissed)
				{
					window.callAPI(
						{
										component: "post",
										method: "NFTokenBurn",
										NFTokenID:_NFTokenID,
										redirect:this.props.state.nft.URI
									},
						function (_jsonData)
						{
							if(_jsonData.success)
							{
								/* Navigate to wallet auth */
								document.location.replace( _jsonData.info.redirect )
							}
							else
							{
								window.displayErrorMessage("Unable to cancel offer - " + _jsonData.errorMessage)
							}
						}
					)
				}
			})
	}

	myCallback = (dataFromChild) => {

		if(dataFromChild.length)
		{

			/* Update secret word and which tab to show */
			this.setState({ modalTag:dataFromChild, secretWord:window.generateString(7), secretWordConfirmation:"" });

			/* Update parent so we know to show the tab */
			this.props.modalCallBack(true)

		}
		else
		{
			/* Clear out data */
			this.setState({ modalTag:"", modalButton:"", modalTitle:"" })

			/* Update parent so we know to show the tab */
			this.props.modalCallBack(false)
		}

		/* Scroll to top */
		window.setTimeout( () => {
			//alert(3)
			//document.getElementById("view-nft-modal").scrollTop = document.documentElement.scrollTop = 0;
		}, 500);

	}

	getMediaURL = e =>
	{

		const BASE_URL = this.props.config.apiServer + "/com/external/medias.cfc?method=getMediaURL&uuid=" + e.currentTarget.dataset.uuid;
		axios.get(BASE_URL, { withCredentials: true })
			.then(res => {

				if (res.data.success) {

					window.open(res.data.redirect)

				}
				else {
					throw({message:res.data.errorMessage});
				}

			})
			.catch(err => {

				window.displayErrorMessage("Error getting media URL - " + err.message);
				return false;

			})

	}

	componentDidMount()
	{
		/* Ensure video preview gets paused when closing the modal */
		window.$('#view-nft-modal').on('hidden.bs.modal', function () {
			const _obj = document.getElementById("item-preview-video");
			if(_obj) { _obj.pause() }
		});

	}

	playPreview = e =>
	{

		this.props.playButtonCallBack();

		/* Allow state time to re-render */
		window.setTimeout(() => {
			document.getElementById("item-preview-video").play()
		}, 300)

	}

	stopPreview = e =>
	{

		this.props.playButtonCallBack()

		/* Allow state time to re-render */
		window.setTimeout(() => {
			//document.getElementById("item-preview-video").stop()
		}, 300)

	}

    render()
	{

console.log(this.props.state.nft)
		const _previewMedia = this.props.state.nft.previewMedia.indexOf("://") < 0 ? this.props.config.apiServer + this.props.state.nft.previewMedia : ( this.props.state.nft.previewMedia.length ? this.props.state.nft.previewMedia : "");
		const _previewImage = this.props.state.nft.preview.indexOf("://") < 0 ? this.props.config.apiServer + this.props.state.nft.preview : ( this.props.state.nft.preview.length ? this.props.state.nft.preview : "");

		const showMainPage = this.props.state.nft.flagDupe || this.props.state.nft.flagBurned ? false : true;
		const _useBithomp = true;

		const isVideo = this.props.state.nft.previewMedia.indexOf(".mp4") > -1 ? true : false;
		const isAudio = this.props.state.nft.previewAudio.indexOf(".mp3") > -1 ? true : false;

		return (
			<div className="container p-0">

				{ this.props.fullPage === false ?
					(
					<div className="modal-header modal-header--sticky">
						<span><span className="d-none d-md-inline-block">{this.props.state.nft.collection.name} \ </span> {this.props.state.nft.title}</span> <i className="fas fa-times-circle icon-close" data-bs-dismiss="modal" />
					</div>
					) : ( <></> )
				}

				<section className="show-grid modal-body p-3 pb-4 item-details-area">

					<div className="container">

					{ this.props.state.loadingNFT ?
						(
						<div id="loading-nft" className="text-center col-12">
							<h3 className="p-5"><strong>Loading NFT Data</strong></h3>
							<img src={this.props.config.sizes.placeholders.preloader} alt="Loading NFT" />
						</div>
						)
						:
						(

						<div id="viewing-nft" className="row justify-content-between g-3">

							<button className="xummReloader hid" onClick={this.props.loadNFT} data-nft={this.props.state.nft.id}>Reload</button>

							{/* NFT Image and attributes*/}
							<div className="col-12 col-md-5 m-0">
								<div className="item-info">
									<div className="item-thumb text-center card no-bg no-border p-2 mt-3" id="item-preview">

											{ this.props.state.nft.flagReserved
												&& this.props.state.nft.flagBurned === false
												&& this.props.state.nft.flagDupe === false  ?
											(	<span className="collection-feature-large">
													<img src="/img/sashes/large-reserved.png" alt="Reserved" />
												</span>
											)
											: ( <></> ) }

											{ this.props.state.nft.flagForSale
												&& this.props.state.nft.flagBurned === false
												&& this.props.state.nft.flagDupe === false  ?
											(	<span className="collection-feature-large">
													<img src="/img/sashes/large-for-sale.png" alt="For sale" />
												</span>
											)
											: ( <></> ) }

											{ this.props.state.nft.mintedQty
												&& this.props.state.nft.flagReserved === false
												&& this.props.state.nft.flagForSale === false
												&& this.props.state.nft.flagAllowOffers === true
												&& this.props.state.nft.flagBurned === false
												&& this.props.state.nft.flagDupe === false ?
											(	<span className="collection-feature-large">
													<img src="/img/sashes/large-make-an-offer.png" alt="Make an offer" />
												</span>
											)
											:
											( <></> )}

											{ this.props.state.nft.mintedQty
												&& this.props.state.nft.flagReserved === false
												&& this.props.state.nft.flagForSale === false
												&& this.props.state.nft.flagAllowOffers === false
												&& this.props.state.nft.flagBurned === false
												&& this.props.state.nft.flagDupe === false ?
											(	<span className="collection-feature-large">
													<img src="/img/sashes/large-minted.png" alt="Minted" />
												</span>
											)
											:
											( <></> )}

										{/* <img id="viewing-nft-image" src={this.props.state.nft.preview} alt={`${this.props.state.nft.title} Preview`} /> */}

										{ this.props.state.nft.mediaType === 'image' || isAudio && isVideo === false ?
										(
											<>
												{_useBithomp === true ?
												(
													<>
														{ this.props.state.nft.mintInfo.address.length === 0 ?
														(
															<p>
																{/* No token address yet, just do image */}
																<img id="viewing-nft-image" src={_previewImage} alt={`${this.props.state.nft.title} Preview`} />
															</p>
														) :
														(
															<a href={this.props.config.links.explore + "/" + this.props.state.nft.mintInfo.address} target="_blank" rel="noreferrer">
																{ this.props.state.nft.previewMedia.length === 0 ?
																	( <img id="viewing-nft-image" src={_previewImage} alt={`${this.props.state.nft.title} Preview`} /> )
																	:
																	( <img id="viewing-nft-image" src={_previewMedia} alt={`${this.props.state.nft.title} Preview`} /> )
																}
															</a>
														 )}
													</>

												)
												:
												(
													<a href={this.props.config.apiServer + "/com/external/medias.cfc?method=viewMedia&type=image&nft=" + this.props.state.nft.id} target="_blank" rel="noreferrer">
														{ this.props.state.nft.previewMedia.length === 0 ?
															( <img id="viewing-nft-image" src={_previewImage} alt={`${this.props.state.nft.title} Preview`} /> )
															:
															( <img id="viewing-nft-image" src={_previewMedia} alt={`${this.props.state.nft.title} Preview`} /> )
														}
													</a>
												)
												}
											</>



										)
										:
										( <></> )}

										{ isVideo ?
										(
											<video id="item-preview-video" loop="loop" muted="muted" autoPlay="autoplay" className="preload-image" controls="controls" style={{width:"100%"}} poster={this.props.config.sizes.placeholders.loader} data-load={_previewMedia} >
											</video>
										)
										:
										( <></> )
										}

										{ isAudio ?
										(
											<audio id="item-preview-video" loop="loop" autoPlay="autoplay" src={this.props.state.nft.previewAudio} className="mt-3" controls="controls" style={{width:"100%"}} >
											</audio>
										)
										:
										( <></> )
										}


										{/* this.props.state.nft.mediaType === 'video' || isVideo ?
										(
											<>
												{ this.props.state.mediaPlaying === false ?
												(
													<>
														<div className="viewing-nft-play">

															{ _previewMedia.length ?
																(
																	<img  src="/img/placeHolders/play-button.png" alt={`Play ${this.props.state.nft.title} Preview`} onClick={this.playPreview} />
																) :
																(
																	<a href={this.props.config.apiServer + "/com/external/medias.cfc?method=viewMedia&type=video&nft=" + this.props.state.nft.id} target="_blank">
																		<img  src="/img/placeHolders/play-button.png" alt={`Play ${this.props.state.nft.title} Preview`} />
																	</a>
																)
															}

														</div>
														<img id="viewing-nft-image" src={_previewImage} alt={`${this.props.state.nft.title} Preview`} />
													</>
												)
												:
												(
													<>
														<div className="viewing-nft-play">
															<img  src="/img/placeHolders/stop-button.png" alt={`Play ${this.props.state.nft.title} Preview`} onClick={this.stopPreview} />
														</div>
														<video id="item-preview-video" poster={_previewImage} loop style={{width:"100%"}}>
															<source src={_previewMedia} type="video/mp4" />
														</video>
													</>
												)}
											</>)
										:
										( <></> )
										*/}







									</div>


								</div>


								<div id="item-buttons">
									{ this.props.state.nft.flagXummActivity ?
										(
											<blockquote className="w-100 mb-3 mt-2">
												<p><strong className="primary">You have a pending Xumm action on this NFT. Please click the "Reload NFT" in about 10 seconds.</strong></p>
												<p><button className="btn btn-primary btn-sm w-100 my-3" type='button' onClick={this.triggerReload}>Reload NFT</button></p>
											</blockquote>
										) : (
											<>
												{/* All NFT interaction buttons */}
												<ModalViewNFTButtons 	config={this.props.config}
																state={this.props.state}
																parentState={this.state}
																callbackFromParent={this.myCallback}
																/>
											</>
										) }
								</div>


							</div>

							{/* NFT Details */}
							<div className="col-12 col-md-7 m-0">

								{ this.props.state.nft.flagDupe === true ?
								(
									<>
										<h3 className="m-0 mt-3">{this.props.state.nft.title}</h3>

										<span style={{"fontSize":"120%"}}>{this.props.state.nft.description.replace(/(?:\r\n|\r|\n)/g, '<br>')}</span>




										{ this.props.state.nft.flagOwner ?
										(
											<>
												<p><strong>This NFT has been flagged as a duplicate. Holding duplicates of an NFT can have a NEGATIVE IMPACT on the value of the correct one.</strong></p>
												<p>Please check the 2 links below to verify that this is in fact a duplicate</p>

												<p className="mt-3">[1] This token (The duplicate <strong>to burn</strong>):-</p>
												<p><a className="btn btn-bordered btn-smaller py-2 w-100" target="_blank" href={this.props.config.links.explore + "/" + this.props.state.nft.dupeInfo.thisTokenAddress}><i className="icon-magnifier me-2" />View On Explorer</a></p>
												<p className="mt-3">[2] Token <strong className="primary">to keep</strong>:-</p>
												<p><a className="btn btn-bordered btn-smaller py-2 w-100" target="_blank" href={this.props.config.links.explore + "/" + this.props.state.nft.dupeInfo.duplicateOf}><i className="icon-magnifier me-2" />View On Explorer</a></p>

												<p>If you agree that the token in item 1 is in fact a duplicate of the one show in item 2, please use the button below to burn the token permanently.</p>

												<button className="btn w-100" onClick={this.burnToken} data-token={this.props.state.nft.dupeInfo.thisTokenAddress}>Confirm in wallet</button>
											</>
										)
										: (<>
											<p><strong>This NFT has been flagged as a duplicate. It is not available for sale or to accept offers.</strong></p>
										</>)
										}






									</>
								) : ( <></>)
								}

								{ this.props.state.nft.flagBurned === true ?
								(
									<>
										<h3 className="m-0 mt-3">{this.props.state.nft.title}</h3>
										<span style={{"fontSize":"120%"}}>{this.props.state.nft.description.replace(/(?:\r\n|\r|\n)/g, '<br>')}</span>
										<p><strong>This NFT has been burned on the ledger. It is not available for sale or to accept offers.</strong></p>
										<p><a href={this.props.config.links.explore + "/" + this.props.state.nft.mintInfo.address} target="_blank" rel="noreferrer"><i className="fas fa-link"></i> {this.props.state.nft.mintInfo.address}</a></p>

									</>
								) : ( <></>)
								}

								{ showMainPage === true ?
								(
									<ModalTransferNFTTabcontent state={this.props.state} config={this.props.config} burnToken={this.burnToken} />

								) : ( <></>)
								}



							</div>


							{/* Bottom info Tabs */}
							{ ( this.props.state.nft.flagDupe === true || this.props.state.nft.flagBurned ) || 0 === 0 ?
								(
									<>

									</>
								) :
								(

									<div id="nft-viewer-info" className="row">


										<div id="nav-assets" className="col-12">

											<h4 className="bigdots pb-2">NFT Assets</h4>

												{/* Single Tab List */}
												{this.props.state.nft.media.map((item, idx) => {
													return (
														<div key={`tdo_${idx}`} className="row">

															<div className="col-12 col-sm-2 text-center">
																<FontAwesomeIcon icon={item.icon} />
																{ this.props.state.nft.flagOwner || 0 === 0 ?
																(
																	<a href={this.props.config.apiServer + "/com/external/medias.cfc?method=viewMedia&uuid=" + item.uuid} target="_blank" rel="noreferrer" className="btn btn-bordered w-100 btn-smaller btn-small mt-4 py-1">View</a>
																)
																: (<></>)}
															</div>
															<div className="col-12 col-sm-10">
															{ item.mediaType === 'image' ?
																(
																	<ul>
																		<li><strong>File Type:</strong> {item.extension}</li>
																		<li><strong>Width:</strong> {item.width}px</li>
																		<li><strong>Height:</strong> {item.height}px</li>
																		<li><strong>Size:</strong> {formatBytes(Number(item.size))}</li>
																		<li><strong>Animated:</strong> {item.animationFrames ? "Yes" : "No" }</li>
																	</ul>
																) : ( <></>)
																}

																{ item.mediaType === 'video' ?
																(
																	<ul>
																		<li><strong>File Type:</strong> {item.extension}</li>
																		<li><strong>Width:</strong> {item.width}px</li>
																		<li><strong>Height:</strong> {item.height}px</li>
																		<li><strong>Size:</strong> {formatBytes(Number(item.size))}</li>
																		<li><strong>Duration:</strong> {secondsToHms(Number(item.duration))}</li>
																	</ul>
																) : ( <></>)
																}

																{ item.mediaType === 'audio' ?
																(
																	<ul>
																		<li><strong>File Type:</strong> {item.extension}</li>
																		<li><strong>Bit Rate:</strong> {item.bitRate}</li>
																		<li><strong>Size:</strong> {formatBytes(Number(item.size))}</li>
																		<li><strong>Duration:</strong> {secondsToHms(Number(item.duration))}</li>
																	</ul>
																) : ( <></>)
																}

																{ item.mediaType === 'file' ?
																(
																	<ul>
																		<li><strong>File Type:</strong> {item.extension}</li>
																		<li><strong>Size:</strong> {formatBytes(Number(item.size))}</li>
																	</ul>
																) : ( <></>)
																}

															</div>
														</div>
													);
												})}

										</div>

									</div>

								)}

						</div>

						)}

					</div>

				</section>
			</div>
		)

    }
}

const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) {
	  return '0 Bytes';
	}
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
}

export default ModalViewNFT;

