import React, { Component } from 'react';

class CollectionSpinnerType1 extends Component {

	init = e => {

		const element = document.getElementById('slots-reel');

		if(element === null)
		{
			/* No spinner - Probably in shuffle mode */
		}
		else
		{
			if(element.children.length)
			{
				window.slotPosition = element.children.length;
				window.slotMachine = new window.SlotMachine(element, {
					active: window.slotPosition - 1,
					delay: 500,
					randomize: () => { return window.slotPosition - 1 },
					onComplete: () => {

						if (window.spinError) {
							window.displayErrorMessage(`<p>An error occurred while spinning. Either you do not have sufficient spins or another system error occurred.</p><p><strong>${window.spinErrorMessage}</strong></p>`);
						}

						else {

							this.setState({
								btnText:"Spin and win!",
								btnDisabled: Number(this.props.state.spins) <= 0 && this.props.state.testMode === false ? true : false
							});

						}

					}

				})

				// Start the shuffling when the button is clicked
				window.$('#spinnerAnimationStart').click(() => {
					/* Start the spin */
					window.slotMachine.shuffle(Infinity);
				});

				window.$('#spinnerAnimationStop').click(() => {
					window.slotMachine.stop(1);
				});

				/* Append this filter to the page for the blur effect */
				window.jQuery("body").append(`<svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="filters" id="spinnerFilterDiv">
										<defs>
											<filter id="blurFast">
												<feGaussianBlur in="SourceGraphic" stdDeviation="0,70" />
											</filter>
											<filter id="blurMedium">
												<feGaussianBlur in="SourceGraphic" stdDeviation="0,40" />
											</filter>
											<filter id="blurSlow">
												<feGaussianBlur in="SourceGraphic" stdDeviation="0,20" />
											</filter>
											<filter id="blurTurtle">
												<feGaussianBlur in="SourceGraphic" stdDeviation="0,20" />
											</filter>
										</defs>
									</svg>`)
			}
		}

		window.jQuery('#spinner-wheel').removeClass("notVisible");
		window.jQuery('#loading').hide();

		this.props.callbackFromParent( {initialisedSpinner:true, loading:false} );

	}


	componentDidMount = e =>
	{



	}


    render() {

		return (

			<>

				{ this.props.state.shuffleAllowed ?
				(
					<>

						<div id="ShuffleContainer" className="mt-3 text-center">
							Shuffle images loading...
						</div>

						<div className="hid">
							<audio id="spinner-shuffle" controls src="/assets/mp3/shuffle.mp3" loop>
							</audio>
						</div>

						<button type="button" className="btn  w-100 mt-3" onClick={this.props.doShuffle} disabled={this.props.state.shuffling}>Begin Shuffle</button>

					</>
				)
				:
				(
					<>

						{ /* This button initialises any javascript for thew spinner */ }
						{ this.props.state.initialisedSpinner === false ? <button id="spinner-init" type="button" className="hid" onClick={this.init} /> : <></> }

						<div id="slots" className="mt-3" style={{ margin: "0 auto", "aspectRatio":"1 / 1" }}>
							<div id="slots-reel">
								{this.props.state.examples.map((item, idx) => {

									const _imgSrc = item.img.indexOf("://") < 0 ? this.props.config.apiServer + item.img : item.img;
									const _img = item.img.toLowerCase().indexOf("placeholder") === -1 ? ( _imgSrc ) : item.img;

									return (
										<div className="slot" id={`reel_${idx + 1}`} key={`spinimage_${idx + 1}`}><img src={_img} alt="Reel slot"></img></div>

									);
								})}
							</div>
						</div>




					</>

				)
				}

			</>


        );
    }
}

export default CollectionSpinnerType1;