import React, { Component } from 'react';

class SpinnerPendingPaymentsComponment extends Component {

	componentDidMount()
	{
	}


    render() {

		return (

			<div className='mt-4'>

					{ this.props.balances.spins ?
					(
						<>
							<div className="alert alert-info p-3 fw-bold text-center m-0">
								<p className="fw-bold text-center p-0">PENDING SPINNER PAYMENT</p>
								<p>You have already started the purchase of spins for this collection. You need to <strong>open your wallet</strong> and either <strong>approve or decline</strong> the transaction.</p>
								<p>Do nothing &amp; it will be <strong>automatically cancelled</strong> in {this.props.parentState.timeTilAutoExpireTime}.</p>
								<button type="button" className="btn btn-bordered btn-sm w-100 w-50-md mt-2" onClick={this.props.terminateSpinsPayment} data-id={this.props.parentState.allocatedNFT.id}>Cancel Purchase</button>
							</div>
						</>
					)
					: (
						<></> )}


					{ this.props.balances.shuffle ?
					(
						<>
							<div className="alert alert-info p-3 fw-bold text-center m-0">
								<p className="fw-bold text-center p-0">RESHUFFLE PENDING PAYMENT</p>
								<p>You said that you wanted to reshuffle this NFT but payment hasn't yet been received. If you do nothing, "{this.props.parentState.allocatedNFT.title}" will be <strong>automatically minted</strong> in {this.props.parentState.timeTilAutoMintTime}.</p>

								<button type="button" className="btn w-100 mb-3" onClick={this.props.extendShuffleXummTimer} data-type="payForShuffle" data-offers={'n/a'} data-buysell="buy" data-nftokenids={this.props.parentState.allocatedNFT.id}>Pay {this.props.parentState.tokensPerRespin} XRP to Reshuffle</button>
								<button type="button" className="btn btn-bordered btn-sm w-100 w-50-md mt-2" onClick={this.props.terminateShufflePayment} data-id={this.props.parentState.allocatedNFT.id}>Cancel shuffle & Accept NFT</button>

							</div>
						</>
					)
					: (
						<></> )}


			</div>
		)

    }
}

export default SpinnerPendingPaymentsComponment;

