import React, { Component } from 'react';
import axios from 'axios';
import { addXummPayload } from '../../js/xumm';

import '../../css/nfts-and-offers.css'
import { formatDate } from '../../components/xutils';

class OffersAndNFTs extends Component {
    state = {
        "data": 						{},
		"sell":							{},
		"buy":							{},
		"orphaned":						{},

		"selectedSellNoLongerOwned":	[],

		"selectedReservations":			[],
		"selectedSelling":				[],
		"selectedOffersToBuyOthers":	[],
		"selectedOffersToBuyMine":		[],
		"sellOffersNeeded":				[],

		"loading":						true,
		"cached":						true,
		"timestamp":					1676210522
    }

	componentDidMount(){

		this.loadData()
		document.title = "Wallet Offers"

    }

	loadData = e =>
	{
		/* Use API for the NFTs as they go into our discovery pot */
		this.setState({loading:true});
		const BASE_URL = this.props.config.apiServer + "/com/external/wallet.cfc?method=getWalletOffers";
		axios.post(BASE_URL, {}, { withCredentials: true })
            .then(res => {

				if( ! res.data.success)
				{
					throw(res.data.errorMessage);
				}
				else
				{
					/* Scroll to top if no # passed in url */
					if(window.location.hash.length < 10 || res.data.fallback)
					{
						window.$('.modal-content').animate({
							scrollTop: window.$("body").offset().top
						}, 0);
					}
					else
					{
						if(window.$('#'+window.location.hash.substring(1)).length)
						{
							window.setTimeout(() => {

								window.$([document.documentElement, document.body]).animate({
									scrollTop: window.$('#'+window.location.hash.substring(1)).offset().top - 90
								});

								}, 500)
						}
					}

					this.setState({loading:false, cached:res.data.fallback, timestamp:res.data.when, data:res.data.data});
				}
            })
        .catch(err =>
				{ window.hideWaitMessage()	; window.displayErrorMessage(err); }
		)

	}

	checkboxReservationsAll = e =>
	{
		window.$('#reservations .cb').not(":disabled").prop("checked", e.currentTarget.checked);
		this.checkboxReservations()
	}
	checkboxSellingAll = e =>
	{
		window.$('#selling .cb').not(":disabled").prop("checked", e.currentTarget.checked);
		this.checkboxSelling()
	}
	checkboxOffersToBuyOthersAll = e =>
	{
		window.$('#offersToBuyOthers .cb').not(":disabled").prop("checked", e.currentTarget.checked);
		this.checkboxOffersToBuyOthers()
	}
	checkboxOffersToBuyMineAll = e =>
	{
		window.$('#offersToBuyMine .cb').not(":disabled").prop("checked", e.currentTarget.checked);
		this.checkboxOffersToBuyMine()
	}



	checkboxReservations = e =>
	{
		var arrChosen = [];
		var checkedItems = window.$('#reservations .cb:checked').not("disabled")
		for (var i = 0; i < checkedItems.length; i++) {
			var tID = `${checkedItems[i].dataset.token}|${checkedItems[i].dataset.offer}`;
			arrChosen.push( tID );
		}
		this.setState({selectedReservations:arrChosen})
	}

	checkboxSelling = e =>
	{
		var arrChosen = [];
		var checkedItems = window.$('#selling .cb:checked').not("disabled")
		for (var i = 0; i < checkedItems.length; i++) {
			var tID = `${checkedItems[i].dataset.token}|${checkedItems[i].dataset.offer}`;
			arrChosen.push( tID );
		}
		this.setState({selectedSelling:arrChosen})
	}

	checkboxOffersToBuyOthers = e =>
	{
		var arrChosen = [];
		var checkedItems = window.$('#offersToBuyOthers .cb:checked').not("disabled")
		for (var i = 0; i < checkedItems.length; i++) {
			var tID = `${checkedItems[i].dataset.token}|${checkedItems[i].dataset.offer}`;
			arrChosen.push( tID );
		}
		this.setState({selectedOffersToBuyOthers:arrChosen})
	}

	checkboxOffersToBuyMine = e =>
	{
		var arrChosen = [];
		var checkedItems = window.$('#offersToBuyMine .cb:checked').not("disabled")
		for (var i = 0; i < checkedItems.length; i++) {
			var tID = `${checkedItems[i].dataset.token}|${checkedItems[i].dataset.offer}`;
			arrChosen.push( tID );
		}
		this.setState({selectedOffersToBuyMine:arrChosen})
	}

	resendSellOffers = e =>
	{

		window.showWaitMessage("Please wait..");

		const BASE_URL = this.props.config.apiServer + "/com/external/offers.cfc";

		const formData = new FormData();
			formData.append("method", "resendSellOffers" );

		axios.post(BASE_URL, formData, { withCredentials: true })
		.then(res => {

			window.hideWaitMessage();

			if( ! res.data.success)
			{
				throw(res.data.errorMessage)
			}
			else
			{
				window.toast("Creation of offers has been queued. They should take no more than 30 seconds to appear here. This page will reload automatically.", "center", 30000)
				window.setTimeout( () => { document.location.reload() }, 30000 )
			}
		})
		.catch(err =>
			{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)

	}



	acceptSellOffer = e =>
	{

		window.showWaitMessage("Checking offer values and buyers balance");

		const BASE_URL = this.props.config.apiServer + "/com/external/offers.cfc";

		const formData = new FormData();
			formData.append("method", "acceptReservedToken" );
			formData.append("NFTokenID", e.currentTarget.dataset.nft );
			formData.append("OfferID", e.currentTarget.dataset.offer );
			/* formData.append("redirect", this.props.config.apiServer  + "/nft/" + e.currentTarget.dataset.nft ); */
			formData.append("redirect", document.location.href);

		axios.post(BASE_URL, formData, { withCredentials: true })
		.then(res => {

			window.hideWaitMessage();

			if( ! res.data.success)
			{
				throw(res.data.errorMessage)
			}
			else
			{
				/* Approve in Xumm */
				document.location.href = res.data.redirect;

			}
		})
		.catch(err =>
			{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)

	}



	terminate = e =>
	{
		var offerIDs = [];
		var NFTokenIDs = [];
		var offerTypes = [];
		var offerIDsNew = [];
		var ledgerType;
		var offerType;

		var which = e.currentTarget.dataset.type;

		if(which==='SellNoLongerOwned')
		{
			ledgerType = "NFTokenCancelOfferBulk"
			offerType="null"
		}
		else if(which==='Reservations')
		{
			ledgerType = "NFTokenAcceptOffer"
			offerType="sell"
		}
		else if(which==='Selling')
		{
			ledgerType = "NFTokenCancelOfferBulk"
			offerType="sell"
		}
		else if(which==='OffersToBuyOthers')
		{
			ledgerType = "NFTokenCancelOfferBulk"
			offerType="buy"
		}
		else if(which==='OffersToBuyMine')
		{

			ledgerType = "NFTokenAcceptOffer"
			offerType="buy"
		}
		else if(which==='sellOffersNeeded')
		{
			this.resendSellOffers()
			return true
		}

		else
		{
			return false
			/* Get checked items for claim, sell etc. */
			offerIDs = this.state['selected'+which]
		}

		if(which==='SellNoLongerOwned')
		{
			/* Get ID's from the original data */
			this.state.data.sellNoLongerOwned.map((item, idx) => {
				offerIDs.push(item.NFTokenID + "|" + item.OfferID);
			})

		}
		else if(e.currentTarget.dataset.scope === 'item')
		{
			offerIDs = [e.currentTarget.dataset.token + '|' + e.currentTarget.dataset.offer];
		}
		else if(which==='Reservations')
		{
			offerIDs = this.state.selectedReservations
		}
		else if(which==='Selling')
		{
			offerIDs = this.state.selectedSelling
		}
		else if(which==='OffersToBuyOthers')
		{
			offerIDs = this.state.selectedOffersToBuyOthers
		}
		else if(which==='OffersToBuyMine')
		{

			offerIDs = this.state.selectedOffersToBuyMine
		}
		else
		{
			return false
			/* Get checked items for claim, sell etc. */
			offerIDs = this.state['selected'+which]
		}

		for (var i = 0; i < offerIDs.length; i++)
		{
			var item = offerIDs[i].split("|");
			offerTypes.push(offerType)
			NFTokenIDs.push(item[0])
			offerIDsNew.push(item[1])
		}

		addXummPayload( { "type":ledgerType, "which":which, "OfferIDs":offerIDsNew, "NFTokenIDs":NFTokenIDs, "OfferTypes":offerTypes, "values":{} } )

	}

    render() {

		var _mobileClass = ''
		//const _mobileClass = 'd-none d-lg-block'

        return (

			<>
				<div id="offers-and-nfts" className="container">

					<button onClick={this.loadData} className="xummReloader hid">Reload</button>

					{ this.state.loading ?
					(
						<div id="loading" className="text-center">
							<h3>Loading Data</h3>
							<img src={this.props.config.sizes.placeholders.preloader} alt="Loading" />
						</div>
					)
					:
					(
						<div id="output">

							{ 		this.state.data.sellNoLongerOwned.length === 0
									&& this.state.data.sellOffersNeeded.length === 0
									&& this.state.data.selling.length === 0
									&& this.state.data.offersToBuyOthers.length === 0
									&& this.state.data.offersToBuyMine.length === 0
									&& this.state.data.reservations.length === 0 ?
							(
								<>
								<h2 className="text-center">You have no offers to manage</h2>

								</>

							)
							: (
								<>

									{ this.state.cached ?
									(
										<div className='alert alert-danger'>The offers below are from {formatDate(this.state.timestamp, false)}. We were unable to connect to the 3rd party service to get the latest results.</div>
									)
									: ( <></> )}


									{/*

											"data":{"sellNoLongerOwned":[], "selling":[], "offersToBuyOthers":[], "offersToBuyMine":[], "reservations":[]} }

									*/}



									{ this.state.data.sellOffersNeeded.length ?
									(
										<>
											<div className="row mb-4 mt-5 bigdots">
												<div className="col-12 col-md-10 pb-3">
													<h3 className="m-0 primary">Spins Awaiting Claiming</h3>
												</div>
												<div className="col-12 col-md-2 pb-3">

												</div>
											</div>
											<div id="orhpanedItems">

												<div className="row">
													<div className="col-12 col-md-10 text-center mb-4">
														<p>After you win an item on the spinner a sell offer is created for you to accept it. These offers <strong>expire after 7 days</strong>. Click the "Create Offers" button to re-send the offers to your wallet.</p>

													</div>
													<div className="col-12 col-md-2 mb-4 text-center">
														<button type='button' className="btn py-2 btn-bordered w-100" onClick={this.terminate} data-type="sellOffersNeeded" data-offer="" data-token="" data-scope="item">Create Offers</button>
													</div>

													<div className="col-12 text-start">
														{ this.state.data.sellOffersNeeded.map((item, idx) => {

															var _img = item.preview;
															if (_img.indexOf("http") === -1 )
															{
																_img = this.props.config.apiServer + _img;
															}
															var _link = this.props.config.apiServer + "/nft/" + item.NFTokenID;

															return (
																<div className="row g-0" key={"orphan_"+idx}>

																	<div className="col-2 text-center mb-4">
																		<a href={_link} target="_blank">
																			<img className="nft" src={_img} alt={item.title} />
																		</a>
																	</div>

																	<div className="col-10">
																		<p className="title primary fw-bold m-0">
																			<a className="linkIcon" href={_link} target="_blank">
																			NFT: {item.title}
																			</a>
																		</p>
																		<p className="tokenAddress m-0"><a className="linkIcon" href={this.props.config.links.explore + "/" + item.NFTokenID} target="_blank">NFT: {item.NFTokenID}</a></p>
																	</div>

																</div>
															)
														})}
													</div>

												</div>
											</div>
										</>
										)
									: ( <></>)}

									{ this.state.data.sellNoLongerOwned.length ?
									(
										<>
											<div className="row mb-4 mt-5 bigdots">
												<div className="col-12 col-md-10 pb-3">
													<h3 className="m-0 primary">Orphaned offers</h3>
												</div>
												<div className="col-12 col-md-2 pb-3">

												</div>
											</div>
											<div id="orhpanedItems">

												<div className="row">
													<div className="col-12 col-md-10 text-center mb-4">
														<p>You have received <strong>{this.state.data.sellNoLongerOwned.length}</strong> offers to accept an NFT but you are already the owner. These can safely be deleted and will decrease your wallet reserve amount freeing up valuable XRP.</p>
														<div className="text-start">
															<ol>
																{ this.state.data.sellNoLongerOwned.map((item, idx) => {
																	return (
																		<li key={"nolonger_"+idx}>
																			<p className="title primary fw-bold m-0">
																				<a className="linkIcon" href={this.props.config.links.exploreXRPL + "nft/" + item.NFTokenID + "/sell-offers"} target="_blank">Offer: {item.OfferID}</a>
																			</p>
																			<p className="tokenAddress m-0"><a className="linkIcon" href={this.props.config.links.explore + "/" + item.NFTokenID} target="_blank">NFT: {item.NFTokenID}</a></p>
																		</li>
																	)
																})}
															</ol>
														</div>
													</div>
													<div className="col-12 col-md-2 mb-4 text-center">
														<button type='button' className="btn py-2 btn-bordered w-100" onClick={this.terminate} data-type="SellNoLongerOwned" data-offer="" data-token="" data-scope="item">Delete</button>
													</div>
												</div>
											</div>
										</>
										)
									: ( <></>)}

									{ this.state.data.reservations.length ?
									(
										<>
											<div className="row mb-4 mt-5 bigdots">
												<div className="col-12pb-3">
													<h3 className="m-0 primary">Reservation Offers</h3>
												</div>
											</div>

											<div id="reservations">
												<p>The items listed below have sell offers where you are the intended recipient. You simply need to authorize them to transfer them to your wallet.</p>
												<div id="items list-group">

													<span className={_mobileClass}>
														<div className='list-item row border-bottom mb-4'>
															<div className="col-2 col-md-1 pt-2 text-center mb-4">
																<input type="checkbox" className='mt-2' onChange={this.checkboxReservationsAll} />
															</div>
															<div className="col-10 col-md-11 mb-4 text-end">
																<button className='btn w-100' disabled={this.state.selectedReservations.length === 0} onClick={this.terminate} data-type="Reservations" data-scope="checked">Approve</button>
															</div>
														</div>
													</span>



													{ this.state.data.reservations.map((item, idx) => {

														var _img = item.existsInxMart === true ? item.preview : '/src/img/ipfs-downloading.png';
														if (_img.indexOf("http") === -1 )
														{
															_img = this.props.config.apiServer + _img
														}
														const _title = item.existsInxMart === true ? item.title : "Pending";

														var _instruction = "You need to authorize this transaction to receive the NFT from the owner.";
														var _class = "";
														var _rowDisabled = item.existsInxMart === false || item.locked;

														if(item.dupeOf > 0)
														{
															_instruction = "This offer is for the same NFT as #" + item.dupeOf + ". ";
															_class = "danger fw-bold";
															_rowDisabled = true;
														}


														return (
															<div className='list-item row mb-4' key={"claim" + idx} id={"approve-" + item.NFTokenID}>
																<div className="col-1 col-md-1 pt-2 text-center mb-4">
																	<span className={_mobileClass}>
																		#{idx+1}<br/>
																		<input type="checkbox" className='mt-2 cb' data-offer={item.OfferID} data-token={item.NFTokenID} onChange={this.checkboxReservations} disabled={_rowDisabled} />
																	</span>
																</div>
																<div className="col-11 col-md-2 text-center mb-4">
																	{item.pending ?
																	(
																		<img className="nft" src={_img} alt={"Pending IPFS download"} />

																	) : (
																		<a href={item.URI_media} target="_blank" rel="noreferrer">
																			<img className="nft" src={_img} alt={_title} />
																		</a>
																	)}
																</div>
																<div className="col-12 col-md-7 mb-4">
																	<p className="title primary fw-bold m-0">
																		{ item.existsInxMart ? ( <a className="linkIcon" href={this.props.config.apiServer + "/nft/" + item.NFTokenID} target="_blank">{item.title}</a> ) : ( <>{item.title}</> )}
																	</p>
																	<p className={"instruction mt-2 mb-2 " + _class}>{_instruction}</p>
																	<p className="tokenAddress m-0"><a className="linkIcon" href={this.props.config.links.explore + "/" + item.NFTokenID} target="_blank">NFT: {item.NFTokenID}</a></p>
																</div>
																<div className="col-12 col-md-2 mb-4 text-center">
																	<button type='button' className="btn py-2 mt-2 btn-bordered w-100" onClick={this.terminate} data-type="Reservations" data-offer={item.OfferID} data-token={item.NFTokenID} disabled={_rowDisabled} data-scope="item">Accept</button>
																</div>
															</div>
															);
													})}

												</div>
											</div>
										</>
									)
									: ( <></>)}





									{ this.state.data.selling.length ?
									(
										<>
											<div className="row mb-4 mt-5 bigdots">
												<div className="col-12 pb-3">
													<h3 className="m-0 primary">Items On Sale</h3>
												</div>
											</div>
											<div id="selling">
												<p>The items listed below are the NFTs you currently have up for sale. These can be from external marketplaces as well as xMart.</p>
												<div id="items list-group">

													<span className={_mobileClass}>
														<div className='list-item row border-bottom mb-4'>
															<div className="col-2 col-md-1 pt-2 text-center mb-4">
																<input type="checkbox" className='mt-2' onChange={this.checkboxSellingAll} />
															</div>
															<div className="col-10 col-md-11 mb-4 text-end">
																<button className='btn w-100' disabled={this.state.selectedSelling.length === 0} onClick={this.terminate} data-type="Selling" data-scope="checked">Cancel selected</button>
															</div>
														</div>
													</span>

													{ this.state.data.selling.map((item, idx) => {

														var _img = item.existsInxMart === true ? item.preview : '/src/img/ipfs-downloading.png';
														if (_img.indexOf("http") === -1 )
														{
															_img = this.props.config.apiServer + _img;
														}


														const _title = item.existsInxMart === true ? item.title : "Pending";

														var _instruction = "";
														var _class = "";
														var _rowDisabled = item.existsInxMart === false || item.locked;

														if(item.dupeOf > 0)
														{
															_instruction = "This offer is for the same NFT as #" + item.dupeOf + ". ";
															_class = "danger fw-bold";
															// _rowDisabled = true;
														}

														if(item.locked && item.destinationName.length )
														{
															//_instruction += `It was created on ${item.destinationName} and has seller fees unlike xMart which is free to list. `;
															//_class = "danger fw-bold";
														}

														return (
															<>
																{ item.dupeOf > 0 && 0 == 1 ?
																( null )
																:
																(
																	<div className='list-item row' key={"sell_" + idx} id={"approve-" + item.NFTokenID}>
																		<div className="col-1 col-md-1 pt-2 text-center mb-4">
																			#{idx+1}<br/>
																			<input type="checkbox" className='mt-2 cb' data-offer={item.OfferID} data-token={item.NFTokenID} onChange={this.checkboxSelling} disabled={_rowDisabled} />
																		</div>
																		<div className="col-11 col-md-2 text-center mb-4">
																			{item.pending ?
																			(
																				<img className="nft" src={_img} alt={"Pending IPFS download"} />

																			) : (
																				<a href={item.URI_media} target="_blank" rel="noreferrer">
																					<img className="nft" src={_img} alt={_title} />
																				</a>
																			)}
																		</div>
																		<div className="col-12 col-md-7 mb-4">
																			<p className="title primary fw-bold m-0">
																				{ item.existsInxMart ? ( <a className="linkIcon" href={this.props.config.apiServer + "/nft/" + item.NFTokenID} target="_blank">{item.title}</a> ) : ( <>{item.title}</> )}
																			</p>
																			<p className={"instruction mt-2 mb-2 " + _class}>{_instruction}</p>
																			<p className="tokenAddress m-0"><a className="linkIcon" href={this.props.config.links.explore + "/" + item.NFTokenID } target="_blank">NFT: {item.NFTokenID}</a></p>
																		</div>
																		<div className="col-12 col-md-2 mb-4 text-center">
																			<p className='xrp m-2 fw-bold'>{item.Amount} {item.currencyCode}</p>
																			<button type='button' className="btn py-2 btn-bordered w-100" onClick={this.terminate} data-type="Selling" data-offer={item.OfferID} data-token={item.NFTokenID} disabled={_rowDisabled} data-nft={item.NFTokenID} data-scope="item">Cancel</button>
																		</div>
																	</div>
																)}

															</>

															);
													})}



												</div>
											</div>
										</>
									)
									: ( <></>)}

									{ this.state.data.offersToBuyOthers.length ?
									(
										<>
											<div className="row mb-4 mt-5 bigdots">
												<div className="col-12 pb-3">
													<h3 className="m-0 primary">Buy Offers I have made</h3>
												</div>
											</div>
											<div id="offersToBuyOthers">
												<p>The items listed below are the NFTs you have created buy offers for. These can be from external marketplaces as well as xMart.</p>
												<div id="items list-group">

													<span className={_mobileClass}>
														<div className='list-item row border-bottom mb-4'>
															<div className="col-2 col-md-1 pt-2 text-center mb-4">
																<input type="checkbox" className='mt-2' onChange={this.checkboxOffersToBuyOthersAll} />
															</div>
															<div className="col-10 col-md-11 mb-4 text-end">
																<button className='btn w-100' disabled={this.state.selectedOffersToBuyOthers.length === 0} onClick={this.terminate} data-type="OffersToBuyOthers" data-scope="checked">Approve</button>
															</div>
														</div>
													</span>


													{ this.state.data.offersToBuyOthers.map((item, idx) => {

														var _img = item.existsInxMart === true ? item.preview : '/src/img/ipfs-downloading.png';
														if (_img.indexOf("http") === -1 )
														{
															_img = this.props.config.apiServer + _img;
														}

														const _title = item.existsInxMart === true ? item.title : "Pending";

														var _instruction = "";
														var _class = "";

														if(item.dupeOf > 0)
														{
															_instruction = "This offer is for the same NFT as #" + item.dupeOf;
															_class = "danger";
														}

														if (item.locked && 0 === 1)
														{
															_instruction = "Although this buy offer is intended for your NFT, it has a destination address set on the offer which means you cannot delete or accept it.";
															if(item.destinationName.length) { _instruction += ' The offer can only be accepted or cancelled on ' + item.destinationName }
															_class = "danger";
														}

														return (
															<div className='list-item row' key={"buy" + idx} id={"approve-" + item.NFTokenID}>
																<div className="col-1 col-md-1 pt-2 text-center mb-4">
																	#{idx+1}<br/>
																	<input type="checkbox" className='mt-2 cb'  data-offer={item.OfferID} data-token={item.NFTokenID} onChange={this.checkboxOffersToBuyOthers} disabled={item.existsInxMart === false || item.locked} />
																</div>
																<div className="col-11 col-md-2 text-center mb-4">
																	{item.pending ?
																	(
																		<img className="nft" src={_img} alt={"Pending IPFS download"} />

																	) : (
																		<a href={item.URI_media} target="_blank" rel="noreferrer">
																			<img className="nft" src={_img} alt={_title} />
																		</a>
																	)}
																</div>
																<div className="col-12 col-md-7 mb-4">
																	<p className="title primary fw-bold m-0">
																		{ item.existsInxMart ? ( <a className="linkIcon" href={this.props.config.apiServer + "/nft/" + item.NFTokenID} target="_blank">{item.title}</a> ) : ( <>{item.title}</> )}
																	</p>
																	<p className={"instruction mt-2 mb-2 " + _class}>{_instruction}</p>
																	<p className="tokenAddress m-0"><a className="linkIcon" href={this.props.config.links.explore + "/" + item.NFTokenID } target="_blank">NFT: {item.NFTokenID}</a></p>
																</div>
																<div className="col-12 col-md-2 mb-4 text-center">
																	<p className='xrp m-2 fw-bold'>{item.Amount} {item.currencyCode}</p>
																	<button type='button' className="btn py-2 btn-bordered w-100" onClick={this.terminate} data-type="OffersToBuyOthers" data-offer={item.OfferID} data-token={item.NFTokenID} disabled={item.existsInxMart === false } data-scope="item">Cancel</button>
																	{/* <button type='button' className="btn py-2 btn-bordered w-100 mt-3" data-offer={item.OfferID} onClick={this.terminate} disabled={item.locked} data-type="Buy" data-scope="item">Delete</button> */}
																</div>
															</div>
															);
														})
													}

												</div>
											</div>
										</>
									)
									: ( <></>)}


									{ this.state.data.offersToBuyMine.length ?
									(
										<>
											<div className="row mb-4 mt-5 bigdots">
												<div className="col-12 pb-3">
													<h3 className="m-0 primary">Buy Offers For My NFTs</h3>
												</div>
											</div>
											<div id="offersToBuyMine">
												<p>The items listed below are the buy offers received for NFTs you own. These can be from external marketplaces as well as xMart.</p>
												<div id="items list-group">

													<span className={_mobileClass}>
														<div className='list-item row border-bottom mb-4'>
															<div className="col-2 col-md-1 pt-2 text-center mb-4">
																<input type="checkbox" className='mt-2' onChange={this.checkboxOffersToBuyMineAll} />
															</div>
															<div className="col-10 col-md-11 mb-4 text-end">
																<button className='btn w-100' disabled={this.state.selectedOffersToBuyMine.length === 0} onClick={this.terminate} data-type="OffersToBuyMine" data-scope="checked">Approve</button>
															</div>
														</div>
													</span>


													{ this.state.data.offersToBuyMine.map((item, idx) => {

														var _img = item.existsInxMart === true ? item.preview : '/src/img/ipfs-downloading.png';
														if (_img.indexOf("http") === -1 )
															{
																_img = this.props.config.apiServer + _img;
															}

														const _title = item.existsInxMart === true ? item.title : "Pending";

														var _instruction = "";
														var _class = "";

														if(item.dupeOf > 0)
														{
															_instruction = "This offer is for the same NFT as #" + item.dupeOf;
															_class = "danger";
														}

														if (item.locked)
														{
															_instruction = "Although this buy offer is intended for your NFT, it has a destination address set on the offer which means you cannot delete or accept it.";
															if(item.destinationName.length) { _instruction += ' The offer can only be accepted or cancelled on ' + item.destinationName }
															_class = "danger";
														}

														return (
															<div className='list-item row' key={"buymine" + idx} id={"approve-" + item.NFTokenID}>
																<div className="col-1 col-md-1 pt-2 text-center mb-4">
																	#{idx+1}<br/>
																	<input type="checkbox" className='mt-2 cb' data-offer={item.OfferID} data-token={item.NFTokenID} onChange={this.checkboxOffersToBuyMine} disabled={item.existsInxMart === false || item.locked} />
																</div>
																<div className="col-11 col-md-2 text-center mb-4">
																	{item.pending ?
																	(
																		<img className="nft" src={_img} alt={"Pending IPFS download"} />

																	) : (
																		<a href={item.URI_media} target="_blank" rel="noreferrer">
																			<img className="nft" src={_img} alt={_title} />
																		</a>
																	)}
																</div>
																<div className="col-12 col-md-7 mb-4">
																	<p className="title primary fw-bold m-0">
																		{ item.existsInxMart ? ( <a className="linkIcon" href={this.props.config.apiServer + "/nft/" + item.NFTokenID} target="_blank">{item.title}</a> ) : ( <>{item.title}</> )}
																	</p>
																	<p className={"instruction mt-2 mb-2 " + _class}>{_instruction}</p>
																	<p className="tokenAddress m-0"><a className="linkIcon" href={this.props.config.links.explore + "/" + item.NFTokenID } target="_blank">NFT: {item.NFTokenID}</a></p>
																</div>
																<div className="col-12 col-md-2 mb-4 text-center">
																	<p className='xrp m-2 fw-bold'>{item.Amount} {item.currencyCode}</p>
																	<button type='button' className="btn py-2 btn-bordered w-100" onClick={this.terminate} data-type="OffersToBuyMine" data-offer={item.OfferID} data-token={item.NFTokenID}  disabled={item.existsInxMart === false || item.locked} data-scope="item">Accept</button>
																	{/* <button type='button' className="btn py-2 btn-bordered w-100 mt-3" data-offer={item.OfferID} onClick={this.terminate} disabled={item.locked} data-type="Buy" data-scope="item">Delete</button> */}
																</div>
															</div>
															);
														})
													}

												</div>
											</div>
										</>
									)
									: ( <></>)}

								</>
							)
							}

						</div>
					)}




				</div>
			</>

        );
    }
}

export default OffersAndNFTs;