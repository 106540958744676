import React, { Component } from 'react';
import '../Maintenance.css'

class CurtainsUp extends Component {

    render() {

        return (

			<>

				<div className="curtain">
					<div className="logo">
						<img src="/img/logo-white.png" alt="Logo" />
					</div>

					<div className='countdown-holder'>
						<div className="title">Sorry about this but we're doing a little maintenance on the site.</div>
						<div className="title mt-4">We're still here just busy.</div>
					</div>
				</div>


			</>

        );
    }
}

export default CurtainsUp;