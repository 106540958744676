import React, { Component } from 'react';

class SystemMessage extends Component {

	componentDidMount(){
        this.setState({
			showMessage: this.props.config.meta.message.length ? true : false
        })
    }

    render() {
		return (
			<>
				{ this.props.config.serverMode === "UAT" ? ( <img src="/img/sashes/server-uat.png" alt="UAT Server" style={{"position":"fixed", "left":"0", "top":"0", "zIndex":1000, "pointerEvents":"none"}} id="sash-server" /> ) : ( <></> ) }
				{ this.props.config.serverMode === "LOCAL" ? ( <img src="/img/sashes/server-dev.png" alt="Dev/local Server" style={{"position":"fixed", "left":"0", "top":"0", "zIndex":1000, "pointerEvents":"none"}} id="sash-server" /> ) : ( <></> ) }
				{ /* this.state.showMessage ? (<div className="system-alert alert alert-danger" role="alert"><span className="d-none d-md-block">{this.props.config.meta.message}</span><span className="d-block d-md-none">{this.props.config.meta.messageMobile}</span></div>) : ( <></> ) */ }
			</>
        );
    }
}

export default SystemMessage;