import React, { Component } from 'react';
import axios from 'axios';
import { CurrencyPrice } from '../currencyPrice';
import '../../css/modalSweep.css';
import { addXummPayload } from '../../js/xumm';

var loadTimeout = null;

class ModalSweep extends Component {

	state = {
		budget:					"",
		quantity:				"",
		totalSpend:				0,
		finding:				false,
		approveButtonDisabled: 	true,
		searched:				false,
		items:					[]

	}

	buyNow = e =>
	{

		var NFTokenIDs = [];
		var offerTypes = [];
		var offerIDsNew = [];


		for (var i = 0; i < this.state.items.length; i++)
		{
			var item = this.state.items[i];
			offerTypes.push("Buy")
			NFTokenIDs.push(item.NFTokenID)
			offerIDsNew.push(item.offerID)
		}

		addXummPayload( { "type":"NFTokenAcceptOffer", "which":"", "OfferIDs":offerIDsNew, "NFTokenIDs":NFTokenIDs, "OfferTypes":offerTypes, "values":{} } )
	}

	changeQty = e =>
	{
		window.clearTimeout(loadTimeout);
		this.setState({budget:"", quantity:e.currentTarget.value})
		loadTimeout = window.setTimeout( () => { this.recalculate() }, 1000 )
	}

	changeBudget = e =>
	{
		window.clearTimeout(loadTimeout);
		this.setState({budget:e.currentTarget.value, quantity:""})
		loadTimeout = window.setTimeout( () => { this.recalculate() }, 1000 )
	}

	resetModal = e =>
	{
		this.setState({budget:"", quantity:"", items:[], totalSpend:0})
		window.$('#close-sweep-modal').trigger("click");
	}

	recalculate = e =>
	{

		if(Number(this.state.quantity) === 0 && Number(this.state.budget) === 0)
		{
			this.setState({finding:false, searched:false, items:[], totalSpend:0})
			return false;
		}
		else
		{

			this.setState({finding:true, searched:true})

			const BASE_URL = this.props.config.apiServer + "/com/external/collections.cfc?method=getFloorItems";
			const formData = new FormData();

			formData.append("collection", this.props.state.collectionData.id );
			formData.append("quantity", this.state.quantity );
			formData.append("budget", this.state.budget );

			axios.post(BASE_URL, formData, { withCredentials: true })
				.then(res => {

					if( ! res.data.success)
					{
						this.setState({finding:false, items:[], totalSpend:0});
						throw(res.data.errorMessage);
					}
					else
					{
						this.setState({finding:false, items:res.data.items, totalSpend:res.data.amountSpent});
					}
				})
			.catch(err =>
					{ this.setState({finding:false}); window.displayErrorMessage(err); }
			)
		}



	}

	render() {

        return (

			<>



				<div className="hid" id="btnFilters">
					<button id="showSweepModal" className="btn btn-bordered" data-bs-toggle="modal" data-bs-target="#sweeping">Sweeper</button>
				</div>

				<div id="sweeping" className="modal fade p-0">
					<div className="modal-dialog modal-lg dialog-animatedx modal-fullheight modal-fullscreen-lg-down">
						<div className="modal-content h-100">

							{ this.state.finding ? (
								<div id="blocker">
									<div className="message">
										<p className="pulsate">Finding NFTs</p>
										<img src={this.props.config.sizes.placeholders.preloader} alt="Loading" />
									</div>
								</div> ) : ( <></> )}


							<div className="modal-header modal-header--sticky">

								<span>Floor Sweep</span>
								<button type='button' className="nostyle"  onClick={this.resetModal} ><i className="fas fa-times-circle icon-close"/></button>
								<button type='button' id="close-sweep-modal" data-bs-dismiss="modal" className="hid">X</button>
							</div>


							<div className="modal-body d-block">

								<div>

									{ this.state.items.length ?
									(
										<>

										<p className="primary text-center">{this.state.items.length} results found.</p>

										{/* Single Tab List */}
										{this.state.items.map((item, idx) => {
											var _img = item.previewURL;
											if (_img.indexOf("http") === -1 )
											{
												_img = this.props.config.apiServer + _img
											}
											return (
												<div key={"sweep_"+idx} className="row mb-2">
													<div className="col-2">
														<img src={_img} />
													</div>
													<div className="col-6 pt-2">
														{item.title}
													</div>
													<div className="col-4 text-end pt-2">
														<CurrencyPrice price={item.amount} formField={false} config={this.props.config} /> XRP
													</div>
												</div>
											);
										})}
										</>



									)
									:
									(
										<>
											{ this.state.searched ?
											(<p className='primary text-center mt-5'>No results found for your criteria.</p>)
											:
											(
												<div className="p-3">
													<p className="text-center">Floor sweeping allows you to specify an amount of XRP you would like to spend to buy as many NFTs from the collection as possible at the lowest price possible (floor price).</p>
													<p className="text-center">You can either use the <strong>Budget field</strong> to specify how much you are willing to spend. Or use the <strong># of NFTs</strong> field to specify a number of NFTs to buy.</p>
													<p className="text-center">Once your desired amount of NFTs are shown, use the <strong>Buy Now</strong> button to approve in your wallet.</p>
													<p className="text-center">Note: Items selected may be purchased by other buyers while you are in the process of approving them in Xumm.</p>
												</div>
											)}
										</>
									)
									}
								</div>

							</div>

							<div className="modal-footer modal-footer--sticky d-block p-0 m-0">

								<div className="row w-100 g-1">

									<div className='col-5 mb-2'>
										<input type="number" className="form-control py-2" id="quantity" onChange={this.changeQty} value={this.state.quantity} placeholder="# of NFTs" />
									</div>

									<div className='col-2 mb-2 text-center pt-2'>
										<strong>Or</strong>
									</div>

									<div className='col-5 mb-2'>
										<input type="number" className="form-control py-2" id="budget" onChange={this.changeBudget} value={this.state.budget} placeholder="Budget" />
									</div>

								</div>

								<div className="row w-100 g-0">
									<div className='col-5 text-center'>
										<p className="m-0 p-0 pt-2 spend">
											Total Spend:<br/><CurrencyPrice price={this.state.totalSpend} formField={false} config={this.props.config} /> XRP
										</p>
									</div>
									<div className='col-7 text-center'>
										<button className="btn btn-bordered mx-2 my-2 w-100" disabled={this.state.items.length === 0} onClick={this.buyNow}>Buy Now</button>
									</div>
								</div>

							</div>

						</div>
					</div>
				</div>
			</>
    	)
	}
};

export default ModalSweep;

