import React, { Component } from 'react';

class ModalMenu extends Component {

	componentDidMount()
	{
		/* Direty little hack to get the menu to appear - Bootstrap doesn't populate it after expanding the menu */
		window.$(window).resize();
	}

	render() {
        return (
				<div id="menu" className="modal fade p-0">
					<div className="modal-dialog modal-xl dialog-animatedx modal-fullheight modal-fullscreen-lg-down">
						<div className="modal-content h-100">
							<div className="modal-header" data-bs-dismiss="modal">
								Menu <i className="fas fa-times-circle icon-close" data-bs-dismiss="modal" />
							</div>
							<div className="menu modal-body">
								<div className="row w-100">
									<div className="items p-4 col-12 text-center"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
    	)
	}
};

export default ModalMenu;

