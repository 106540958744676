import React, { Component } from 'react';
import AuthorProfile from '../AuthorProfile/AuthorProfile';

class CollectionDescription extends Component {

    render() {

		return (

			<>

				{ this.props.state.collectionData.description.length > 50 ?

					(
						<div className="row p-0">


							{/*<div className="col-12 col-md-3 text-center p-3">
								<div className="mt-3">
									<img className="author-profile-image" src="https://api-xmart-devbox.eu.ngrok.io/src/img/profilepicture/b/d/9/bd9ca83cacf89984d90603445f208212/1662041101400_440.jpg?cb=1662041113470" alt="" style={{maxWidth:"200px"}} />
								</div>
								<div className="card-body p-0 pt-2">
									<h5 className="mb-3 author-profile-heading">BoomerangTang</h5>
									<p className="my-3 author-profile-description">Awesome music</p>
								</div>
							</div>



							<div className="col-12 col-md-4 text-center p-3">
								<AuthorProfile 			config={ this.props.config } state={this.props.state} />
							</div>

							*/}

							<div className="col-12 p-3">
								<h3 className="mb-3 primary">Collection Overview:-</h3>

								<div className="description" dangerouslySetInnerHTML={{__html: this.props.state.collectionData.description.replace(/[\n]/g, '<br/>') }}></div>

								{ this.props.state.collectionData.creator.social[3] && this.props.state.collectionData.creator.social[3].link.length ?
								(
									<div className="mt-4">
										Find out more at the project website: <a href={this.props.state.collectionData.creator.social[3].link} target="_blank">{this.props.state.collectionData.creator.social[3].link}</a>
									</div>
								)
								:
								( <></>)
								}

							</div>
						</div>
					) : ( <></> )}
			</>
		);
    }
}

export default CollectionDescription;