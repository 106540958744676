import React, { Component } from 'react';
import ModalActionTask from '../Modal/ModalActionTask'
import axios from 'axios';
import { displayErrorMessage, shortDate } from '../xutils';

class balancesComponent extends Component {
    state = {
        data: [],
		taskID:"",
		tasks:[],
		loading:true,
		task:{id:"", created:1, type:"n/a", "fields":[], "preview":"", walletAuthRequired:false, buttonText:"", allowRejection:false }
    }

	componentDidMount(){

		this.loadTasks()

		if(window.location.hash) {
			// Fragment exists
			window.setTimeout(() => {

				const elem = document.getElementById(window.location.hash.substring(1))
				elem.classList.add("flash");
				elem.scrollIntoView({ behavior: 'auto' /*or smooth*/, block: 'center' })

				}, 1500)
		  } else {
			// Fragment doesn't exist
		  }



    }

	loadTasks = e =>
	{

		this.setState({loading:true})

		const BASE_URL = this.props.config.apiServer + "/com/external/tasks.cfc?method=getTaskOverview";
		axios.get(BASE_URL, { withCredentials: true })
			.then(res => {

				if (res.data.success) {
					this.setState({tasks:res.data.tasks, loading:false})
				}
				else {
					displayErrorMessage("Error getting task - " + res.data.errorMessage);
				}

			})
			.catch(err => {

				displayErrorMessage("Error getting task - " + err.message);
				return false;

			})
	}

	action = e =>
	{

		//this.setState({taskID:e.currentTarget.dataset.uuid})

		const BASE_URL = this.props.config.apiServer + "/com/external/tasks.cfc?method=getTask&uuid=" + e.currentTarget.dataset.uuid;
		axios.get(BASE_URL, { withCredentials: true })
			.then(res => {

				if (res.data.success) {

					if(document.getElementById("modalTaskImage"))
					{
						/* Clear out the current task image */
						document.getElementById("modalTaskImage").src = this.props.config.sizes.placeholders.loader;
						document.getElementById("modalTaskImage").classList.add("preload-image")
					}

					if(res.data.task.msg.indexOf("tecOBJECT_NOT_FOUND") > -1)
					{
						res.data.task.dismissable = true;
						res.data.task.walletAuthRequired = false;
					}

					this.setState({task:res.data.task})

					window.setTimeout( () => { window.loadPreloadImages() }, 500);
					window.actionShowModal.click()

				}
				else {
					displayErrorMessage("Error getting task - " + res.data.errorMessage);
				}

			})
			.catch(err => {

				displayErrorMessage("Error getting task - " + err.message);
				return false;

			})


	}


    render() {
        return (

			<>
				<div id="taskList">




					{ this.state.loading ?
						(
						<div id="loading-nft" className="text-center col-12">
							<h3 className="p-5"><strong>Loading Task List</strong></h3>
							<img src={this.props.config.sizes.placeholders.preloader} alt="Loading Task List" />
						</div>
						)
						:
						(
							<>

								<div className="row mb-3 pb-2 bigdots">
									<div className="col-12">
										<div className="intro d-flex justify-content-between align-items-end m-0">
											<div className="intro-content">
												<h3 className="m-0">My Tasks</h3>
											</div>
										</div>
									</div>
								</div>

								<p>The list below shows your outstanding tasks. To action an item and remove it from the list, click the "Go" button.</p>

								{/* Single Tab List */}
								{ this.state.tasks.length === 0 ? (<h4 className="text-center mt-5 primary">Woot! You have no outstanding tasks</h4>) : ( <></> ) }

								{ this.state.tasks.map((item, idx) => {

									return (
										<div key={`tdo_${idx}`} className="card mb-3" id={item.id.split(":")[0]}>
											<div className="row">

												<div className="col-2 text-center">
													<i className={item.icon}></i>
													<div className="date" dangerouslySetInnerHTML={{__html: shortDate(item.date, "<br>")}}>
													</div>

												</div>

												<div className="col-11 col-md-7 py-2">
													<p><strong>{item.type}</strong></p>
													{item.title.length ? ( <p>{item.title}</p> ) : (<></>)}
													{item.subtitle.length ? ( <div className="d-flex align-content-between">
																				<div className="taskImage flex-shrink-1" style={{"backgroundImage":`url(${this.props.config.apiServer + item.thumbnail})`}}></div>
																				<div className="w-75 mt-3"><strong className="primary">{item.subtitle}</strong></div>
																				</div> ) : (<></>)}
													{item.err ? ( <p><strong className="errorText">{ item.msg.split("[")[0] }</strong></p> ) : (<></>)}

												</div>
												<div className="col-12 col-md-3 py-2 text-center">
													<button className="btn w-100" type="button" data-uuid={item.id} onClick={this.action}>{item.button}</button>
												</div>
											</div>
										</div>
									);
								})}

							</>
						)
					}

				</div>

				<ModalActionTask		config={this.props.config} state={this.state} />

				<button type="button" className="hid" id="actionReloader" onClick={this.loadTasks} >Reload list</button>
				<button type="button" className="hid" id="actionShowModal" data-bs-toggle="modal" data-bs-target="#offer-action">Show modal</button>

			</>

        );
    }
}

export default balancesComponent;