import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';


class pageContent extends Component {

	state = {status:"Need", wallet:{classicAddress:"", secret:"xMart", xAddress:"", balance:0}}

	mintCheck = e =>
	{
		window.showWaitMessage("Checking mint values");

		window.callAPI(
			{
							component: 		"external",
							method: 		"checkMinterStatus",
							address:		this.props.config.userData.walletAddress,
							spinnerCheck:	true,
							userRedirect:document.location.href
						},
			function (_jsonData)
			{
				window.hideWaitMessage();
				if(_jsonData.success)
				{
					if ( ! _jsonData.isMinterSet)
					{
						window.Swal.fire(
							{
								title: 'Wallet needs authorising',
								html: '<p className="text-center">To enable us to mint NFTs on your behalf, we must be approved to do so.</p><p>A small XRPL transaction cost will be incurred to set the "Minter" value on your account.<p>',
								showCancelButton: true,
								confirmButtonText:"Authorise in wallet",
							}).then((result) => {
								if (! result.isDismissed)
								{
									window.callAPI(
										{
														component: "external/wallet",
														method: "getMinterQRCode",
														force: true,
														spinnerCheck: true,
														address:window.config.userData.walletAddress,
														userRedirect:document.location.href
													},
										function (_jsonData)
										{

											if(_jsonData.success)
											{
												document.location.replace( _jsonData.redirect )
											}
											else
											{
												window.displayErrorMessage("Unable to set minter status - " + _jsonData.errorMessage)
											}
										}
									)
								}
							})
					}
					else
					{
						/* Display the modal */
						//window.$('#mintShowModal').trigger("click")

						window.displayMessage("Minter status is set", true, false);
						this.setState({status:"MINTER IS SET"});

						/* Set focus on input field */
						//window.setTimeout( () => { $('#mint-price').trigger("click").focus().select() }, 550)
					}
				}
				else
				{
					window.displayErrorMessage("Mint error - " + _jsonData.errorMessage)
				}
			}
		)
	}

    render() {

		const _title = "Set minter flag";

        return (
            <div className="main">
                <Header				config={ this.props.config } />
                <Breadcrumb			config={ this.props.config } state={this.state} title={_title} background="null" subpage="Tools" page={_title} />

				<section className="container mt-5">

					<button className="btn w-100" onClick={this.mintCheck}>Check Minter Status</button>

				</section>


                <Footer				config={ this.props.config } />
                <ModalSearch		config={ this.props.config } />
                <ModalMenu			config={ this.props.config } />
                <Scrollup			config={ this.props.config } />
            </div>
        );
    }
}

export default pageContent;