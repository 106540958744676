import React, { Component } from 'react';

export class CurrencyFees extends Component {

	state ={
				originalValue:this.props.formData[this.props.formGroup][this.props.formField]['default'],
				currentValue:this.props.formData[this.props.formGroup][this.props.formField]['default'],
				objRate:this.props.config.rates[ this.props.config.userData.currency ],
				objFee:this.props.config.fees[this.props.feeType]
			};

	passBack = e =>
	{

		const newPrice= e.currentTarget.value;
		this.setState({currentValue:newPrice})

		/* Timeout to allow setstate to work */
		window.setTimeout( () =>
		{
			// Colculate totals
			// const calcs = this.getCalculations();

			/* Update the calling page's form data  */
			this.props.formData[this.props.formGroup][this.props.formField]['value'] = newPrice;

			/* pass back */
			this.props.callback(this.props.formData);

		}, 500)

	}

	formatNumber = value =>
	{
		if(Number(value) === 0)
		{
			return 0
		}
		else
		{
			return Number(value).toLocaleString( undefined, { minimumFractionDigits: 5 } )
		}

	}


	getCalculations = e =>
	{
		var feeAmount, _minXRP, _maxXRP, _whichFee
		var buyerFees = "";

		if(typeof this.props.formData[this.props.formGroup]['externalFee'] !== "undefined")
		{
			_whichFee = {
				"maxXRP": 0,
				"xMartFee": false,
				"amt": this.props.formData[this.props.formGroup]['externalFee']['value'],
				"minXRP": 0,
				"type": "%",
				"maxPct": this.props.formData[this.props.formGroup]['externalFeeCap']['value'],
			}
		}
		else
		{
			_whichFee = this.state.objFee
		}

		if(_whichFee.type === 'xrp')
		{
			feeAmount = Number(_whichFee.amt);

			/* Convert the new amount to destination currency */
			feeAmount = (feeAmount / Number(this.state.objRate.rate))

			buyerFees = _whichFee.amt > 0 ? `A flat rate of ${feeAmount} ${this.state.objRate.name}` : "No fees are payable"
		}
		else
		{
			if(_whichFee.amt <= 0)
			{
				buyerFees = "No fees are payable";
				feeAmount = 0;
			}
			else
			{
				if(_whichFee.type === "%")
				{
					feeAmount = Number(this.state.currentValue) * Number(_whichFee.amt) / 100;
				}
				else
				{
					feeAmount = Number(_whichFee.amt);
				}

				buyerFees+= `${Number(_whichFee.amt)}% of the ${this.props.sourceLabel}.`


				if(_whichFee.minXRP !== 0) {
					_minXRP = (_whichFee.minXRP / this.state.objRate.rate);
					buyerFees+= ` Subject to a minimum of ${_minXRP} ${this.props.config.userData.currency}`
				}
				if(_whichFee.maxXRP !== 0) {
					_maxXRP = (_whichFee.maxXRP / this.state.objRate.rate);
					buyerFees+= ` & capped at ${this.formatNumber(_maxXRP)} ${this.props.config.userData.currency}`
				}
				else if(_whichFee.maxPct !== 0) {
					//if(_whichFee.amt = _whichFee.maxPct)
					var maxFee = Number(this.props.sourceValue) * Number(_whichFee.maxPct) / 100;

					buyerFees+= ` & capped at ${this.formatNumber(maxFee)} ${this.props.config.userData.currency}`
				}

				/* Convert the new amount to destination currency */
				feeAmount = Number((feeAmount / this.state.objRate.rate));

				if(feeAmount > 0 && feeAmount < _minXRP) { feeAmount = _minXRP }
				if(feeAmount > 0 && feeAmount > _maxXRP) { feeAmount = _maxXRP }
			}

			if( _whichFee.xMartFee === false) { buyerFees += '. This fee has been imposed by ' + this.props.formData[this.props.formGroup]['externalWho']['value'] }

			buyerFees+= `.`

		}

		var _total
		if(this.props.HasRoyalty)
		{
			var royalty = Number(this.state.currentValue) > 0 ? Number(this.state.currentValue) * this.props.royaltyPercent / 100 : 0;
			_total = Number(this.state.currentValue) - Number(royalty)

		}
		else
		{
			_total = this.props.includeSourceInTotal ? ( Number(this.state.currentValue) + Number(feeAmount) ) :  Number(feeAmount)
		}


		return {value:this.state.currentValue, total:this.formatNumber(_total), feeAmount:this.formatNumber(feeAmount), toolTip:buyerFees}

	}

	render() {

		var calcs = this.getCalculations();
		var _min = this.props.hasSourceField && this.props.formData[this.props.formGroup][this.props.formField].min ? this.props.formData[this.props.formGroup][this.props.formField].min : 0

		return (
					<>

						{/* Primary source input */}

						{ this.props.hasSourceField ?
						(
							<>
							<div className="col-12 col-lg-6 mt-3">
									<label htmlFor={this.props.formField} className="pt-2">{this.props.sourceLabel} { this.props.sourceEditable ? (<span className="req">*</span>) : (<></> )}</label>
								</div>
								<div className="col-12 col-lg-6 mt-3">
									<div className="form-group">
										<div className="d-flex align-items-center">
											{ this.props.sourceEditable ?
											(
												<input 		type="number"
															className="form-control value-field"
															id={this.props.formField}
															name={this.props.formField}
															onChange={this.passBack}
															placeholder=""
															min={_min}
															required="required"
															maxLength="12"
															value={calcs.value} />
											) :
											(
												<input 		type="number"
															className="form-control value-field"
															id={this.props.formField}
															name={this.props.formField}
															value={calcs.value}
															readOnly="readonly" />
											)}

											<span className="ms-2">{this.state.objRate.symbol}</span>
										</div>
									</div>
								</div>
							</>
						)
						:
						( <></> )
						}

						{ this.props.HasRoyalty ?
						(
							<>
							<div className="col-12 col-lg-6 mt-3">
									<label htmlFor={this.props.formField} className="pt-2">Creator Royalty</label>
								</div>
								<div className="col-12 col-lg-6 mt-3">
									<div className="form-group">
										<div className="d-flex align-items-center">
											<input 		type="number"
														className="form-control value-field"
														id=""
														name=""
														maxLength="12"
														value={Number(this.props.royaltyPercent).toFixed(2)}
														readOnly="readonly" />

											<span className="ms-2">%</span>
										</div>
									</div>
								</div>
							</>
						)
						:
						( <></> )
						}


						{/* Transaction fees */}
						<div className="col-12 col-lg-6 mt-3">
							<label className="pt-2">{this.props.feeLabel}</label>
						</div>
						<div className="col-12 col-lg-6 mt-3">
							<div className="form-group">
								<div className="d-flex align-items-center">
									<input type="number" className="form-control value-field" readOnly value={ calcs.feeAmount } maxLength="12" />
									<span className="ms-2">{this.state.objRate.symbol}</span>
									<span data-bs-toggle="tooltip" id="ttt" title={calcs.toolTip}><i className="fas fa-question-circle fa-lg ms-2 tip"></i></span>
								</div>
							</div>
						</div>



						{/* Amount received total or amount less royalty */}
						<div className="col-12 col-lg-6 mt-3">
							<label className="pt-2">{ this.props.HasRoyalty ? ( "Received After Sale" ) :  ( "Total Transaction Cost" ) }</label>
						</div>
						<div className="col-12 col-lg-6 mt-3">
							<div className="form-group">
								<div className="d-flex align-items-center">
									<input type="number" className="form-control value-field" readOnly value={ calcs.total } />
									<span className="ms-2">{this.state.objRate.symbol}</span>
								</div>
							</div>
						</div>


					</>

		)

	}
}



export default CurrencyFees;

