import React, { Component } from 'react';
import LoadingMessage from './loading-message';

class CollectionSpinnerImportingComponent extends Component {


    render() {


		return (

			<div className="row mt-5">
				<div className="col-12">
					<h2 className="mt-5 text-center">Collection Not Ready</h2>
					<p className="text-center mt-5">This collection is not yet ready for viewing. If you are the owner of this collection, please log in to view its progress.</p>
				</div>
			</div>

		);
    }
}

export default CollectionSpinnerImportingComponent;