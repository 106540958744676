import React, { Component } from 'react';
import axios from 'axios';

import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import Scrollup from '../../components/Scrollup/Scrollup';
import QRCode from "react-qr-code";

import { VideoLink } from '../../components/videoLink'


class pageContent extends Component {

	state = {wallet:{classicAddress:"", secret:"xMart", xAddress:"", balance:0}}

	generate = e =>
	{
		window.showWaitMessage("Generating testnet funded wallet");

		const BASE_URL = this.props.config.apiServer + "/com/external/wallet.cfc?method=createFaucetWallet";

		axios.post(BASE_URL, { withCredentials: true })
		.then(res => {

			window.hideWaitMessage();

			if( ! res.data.success)
			{
				throw(res.data.errorMessage)
			}
			else
			{
				this.setState({wallet:res.data})
			}
		})
		.catch(err =>
			{ window.hideWaitMessage(); window.displayErrorMessage(err); }
		)

	}


    render() {

		const _title = this.props.config.serverMode === 'PRODUCTION' ? "Wallet Not Found" : "Test Configuration";

        return (
            <div className="main">
                <Header				config={ this.props.config } />
                <Breadcrumb			config={ this.props.config } state={this.state} title={_title} background="null" subpage="Tools" page={_title} />

				<section className="container mt-5">


				{ this.props.config.serverMode === 'PRODUCTION' ?
										(
											<>
												<div className="row mb-3 pb-2 bigdots">
													<div className="col-12">
														<div className="intro d-flex justify-content-between align-items-end m-0">
															<div className="intro-content">
																<h3 className="m-0">Your wallet was not found</h3>
															</div>
														</div>
													</div>
												</div>

												<p>This could be due to one or more reasons.</p>
												<div className="ps-4">
													<ol>
														<li>Your wallet may not yet be funded. You need to deposit some XRP to the wallet to be able to use it.</li>
														<li>You may be using a test wallet on the live network</li>
														{ this.props.config.urlParts.length === 5
														?
														(
															<li>Confirm your wallet information on the <a href={"https://nft-devnet.xrpl.org/accounts/" + this.props.config.urlParts[3]} target="_blank">XRPL Explorer</a></li>
														)
														: (<></>)}

													</ol>
												</div>
											</>
										)
										:
										(
											<div className="row">
												<div className="col-12 col-md-6">
													<div className="card p-0">
														<div className="card-header">Step 1 - Enable Xumm to connect to testnet</div>
														<div className="card-body p-4">

														<VideoLink label={"Watch YouTube Tutorial"} tag={"connect-to-testnet"} linkType="youtube" config={this.props.config} />

															<ol className="mt-3">
																<li>Download and install Xumm from <a href="https://xumm.app/">https://xumm.app/</a></li>
																<li>In the Xumm app, click the xumm logo at the bottom to scan a QR code</li>
																<li>Scan the QR code shown below</li>
																<li>Click continue when it warns about a custom node</li>
																<li>Click switch to begin using the XLS20 testnet</li>
															</ol>



															<p className="text-center mt-3">
																<img src="/img/misc/xumm-qr-code.png" style={{"maxWidth":"300px", "border":"3px solid #444", "borderRadius":"8px"}} width="300" height="300" alt="Xumm QR Code" />
															</p>

														</div>
													</div>
												</div>

												<div className="col-12 col-md-6">
													<div className="card p-0">
														<div className="card-header">Step 2 &bull; Get your testnet funded wallet</div>
														<div className="card-body p-4">



																{ this.state.wallet.classicAddress.length === 0 ?
																(
																	<div className="p-2">
																		<p>If you do not already have a testnet wallet, click the button below to generate a new one with 10,000XRP balance.</p>
																		<p>And no, you can't transfer that to your live wallet <i className="far fa-smile-wink"></i></p>
																		<button className="btn btn-bordered w-100 mb-4" onClick={this.generate}>Generate test wallet</button>



																	</div>
																)
																:
																(
																	<>
																		<h3 className="primary">Wallet Generated</h3>

																		<p className="text-center">
																			<QRCode value={this.state.wallet.secret} />
																		</p>

																		<p><strong>Wallet Address</strong></p>
																		<p>{this.state.wallet.classicAddress}</p>

																		<p><strong>Family Key/Secret</strong></p>
																		<p>{this.state.wallet.secret}</p>

																		<p><strong>X Address</strong></p>
																		<p>{this.state.wallet.xAddress}</p>

																		<p><strong>Balance</strong></p>
																		<p>{this.state.wallet.balance}</p>

																	</>


																	)
																}


														</div>

													</div>

													<p>&nbsp;</p>


												</div>


										</div>
										)
										}



				</section>


                <Footer				config={ this.props.config } />
                <ModalSearch		config={ this.props.config } />
                <ModalMenu			config={ this.props.config } />
                <Scrollup			config={ this.props.config } />
            </div>
        );
    }
}

export default pageContent;