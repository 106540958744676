import React, { Component } from 'react';

class ModalDeleteCollection extends Component
{

	state = {

		checkboxChecked:false,
		buttonDisabled:true,
		confirmation:"",
		secretWord:window.generateString(7)

    }



	confirmationChanged = e =>
	{
		this.setState({ confirmation:e.currentTarget.value, buttonDisabled:this.checkForm() })
	}

	checkboxChanged = e =>
	{
		this.setState({ checkboxChecked:e.target.checked })
		this.setState({ buttonDisabled:this.checkForm() })
	}


	doArchive = e =>
	{
		window.showWaitMessage("Archiving Collection");

		const _request = {
			component:"post",
			method:"archiveCollection",
			uuid:this.props.state.collectionData.id,
			userRedirect: this.props.config.userData.url
		}

		window.callAPI(
			_request,
			function (_jsonData)
			{
				if(_jsonData.success)
				{
					document.location.href = _jsonData.info.redirect;
				}
				else
				{
					window.displayErrorMessage("Deletion error - " + _jsonData.errorMessage)
				}
			}
		)

	}

	checkForm()
	{

		var _eval = false
		if(window.$('#confirmation').val().toUpperCase().trim() !== this.state.secretWord)
		{
			_eval = true
		}

		return _eval

	}

	componentDidMount()
	{
		//	this.checkForm()
	}


    render()
	{

        return (

			<div id="archive-modal" className="modal fade p-0">
				<div className="modal-dialog modal-lg dialog-animatedx modal-fullheight modal-fullscreen-lg-down">
					<div className="modal-content h-100">

						<div className="modal-header modal-header--sticky">
							Archive Collection <i className="fas fa-times-circle icon-close" data-bs-dismiss="modal" />
						</div>
						<div className="show-grid modal-body p-3 pb-4">
							<div className="container row">

								<div className="col-12 text-center">
									<img src={this.props.config.apiServer + this.props.state.collectionData.previewImage} className="modalImage" alt="Preview" />

								</div>

								<div className="col-12 text-center mt-5 mb-5">
									<p>
									You are about to archive a collection. The collection will be hidden from your account and audience facing parts of the website.
									The collection will be permanently deleted from xMart after 7 days. If in the meantime you need to recover an archived collection, please file a support request.</p>
									<p>To begin the archive process, please enter the word "<strong className="secretword">{this.state.secretWord}</strong>" into the confirmation box below.</p>
								</div>

								<div className="col-12 col-md-4 mt-1">
									<label htmlFor="mint-royalty" className="pb-2">Archive confirmation<span className="req">*</span></label>
								</div>
								<div className="col-12 col-md-8">
									<div className="form-group">

										<div className="d-flex align-items-center">
											<input type="text" className="form-control uppercase" id="confirmation" name="confirmation" data-word={this.state.secretWord} placeholder="Type the word in bold shown above" maxLength="15" required="required" onChange={this.confirmationChanged} defaultValue={this.state.confirmation} />
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="modal-footer modal-footer--sticky">
							<div className="row  w-100">
								<div className="col-12">
									<button type="button" disabled={this.state.buttonDisabled} onClick={this.doArchive} className="btn w-100">Archive this collection</button>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
        );
    }
}

export default ModalDeleteCollection;

